import * as React from "react";
import {useEffect, useRef, useState} from "react";
import * as PropTypes from "prop-types";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import {createEditStyles} from "./CreateEdit/CreateEditTheme";
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import ClickOutsideHandler from "./ClickOutsideHandler";
import Typography from '@mui/material/Typography';

const NO_CONTENT_ITEM_VALUE = 0;

const SSTDropdown = (props) => {

    let {error, classes, required, disabled, isLoadingContents, label, defaultValue, selectedValueId, setValueFunc, mappedList, fullWidth, style, variant, readOnly=false} = props;
    classes = (typeof classes === "undefined" || typeof classes.formControl === "undefined") ? createEditStyles().formControl : classes.formControl;
    fullWidth = (typeof fullWidth === "undefined") ? true : fullWidth;
    required = (typeof required === "undefined") ? true : required;
    style = (typeof style === "undefined") ? {} : style;
    variant = (typeof variant === "undefined") ? "standard" : variant;
    disabled = (typeof disabled === "undefined") ? false : disabled;
    label = (typeof label === "undefined") ? "No Label" : label;
    isLoadingContents = ((typeof isLoadingContents === "undefined") ? false : isLoadingContents);
    mappedList = (typeof mappedList === "undefined" || mappedList.length === 0)
        ? [<MenuItem key={0} value={NO_CONTENT_ITEM_VALUE} disabled sx={{
            '&.Mui-disabled': {
                opacity: 1
            },
        }}>No Content</MenuItem>]
        : mappedList;
    error = (typeof error === "undefined") ? false : error;
    selectedValueId = (typeof selectedValueId === "undefined") ? "" : selectedValueId;

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const selectElRef = useRef();

    const handleClickOutside = e => {
        setOpen(false);
        setAnchorEl(null);
    };

    useEffect(() => {
        if (error) {
            setOpen(true);
            setAnchorEl(selectElRef.current);
        }
    }, [error]);

    return (
        <ClickOutsideHandler event={open} eventHandler={handleClickOutside}>
            {(variant === "outlined") && <Typography><InputLabel style={{marginBottom:'0px'}}>{label}</InputLabel></Typography>}
            <FormControl
                className={classes}
                fullWidth={fullWidth}
                required={required}
                style={style}
                variant={variant}
                error={error}>
                {(variant !== "outlined") && <InputLabel>{label}</InputLabel>}
                <Select fullWidth
                        ref={selectElRef}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        value={selectedValueId}
                        inputProps={{
                          readOnly: readOnly
                        }}
                        onChange={(e, child) => {
                            setValueFunc(e, child);
                        }}>
                    {(isLoadingContents)
                        ? <CircularProgress
                            variant="indeterminate"
                            disableShrink={false}
                            color={'primary'}
                            size={23}/>
                        : mappedList}
                </Select>
                {required && <FormHelperText>Required</FormHelperText>}
                <Popover
                    anchorEl={anchorEl}
                    anchorReference={"anchorEl"}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}>
                    This field is required.
                </Popover>
            </FormControl>
        </ClickOutsideHandler>);
};

SSTDropdown.propTypes = {
    error: PropTypes.bool,
    classes: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoadingContents: PropTypes.bool,
    label: PropTypes.string.isRequired,
    selectedValueId: PropTypes.any.isRequired,
    setValueFunc: PropTypes.func.isRequired,
    mappedList: PropTypes.array.isRequired,
    fullWidth: PropTypes.bool,
    style: PropTypes.any,
    variant: PropTypes.string,//'standard' | 'outlined' | 'filled'
};

export default SSTDropdown;
export { NO_CONTENT_ITEM_VALUE };