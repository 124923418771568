import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        minWidth: '100vw',
        minHeight: '100vh',
        flexDirection: 'column',
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: 16,
        paddingRight: 64
    },
    selectContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    controlLabel: {
        margin: 0,
        marginRight: 8
    },
    iframeContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    iframe: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    footerContainer: {
        minHeight: 44,
        display: 'flex',
        alignItems: 'center',
        padding: `12px 26px`,
        borderTop: `1px solid ${theme.palette.darkGrey}`,
    }
}));

export default useStyles
