import React, { useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Context";
import SSTDropdown from "../../shared/components/SSTDropdown";
import ReportingWidget from '../../shared/components/ReportingWidget/ReportingWidget';
import {
  BASE_SST_API_URL,
  SST_API_PATH_LINES,
  SST_API_PATH_PLANTS,
  SST_API_PATH_RUNS
} from "../../Constants";
import { styled, MenuItem, Paper } from '@mui/material';


const ReportRow = styled('div')({
  margin: '15px 40px',
  display: 'flex',
  'justify-content': 'flex-start',
  'height': '450px'
});

const SelectWrapper = styled('div')({
  width: '200px',
  display: 'inline-block',
})

const FormPaper = styled(Paper)({
  padding: '15px',
  width: '800px',
  display: 'flex',
  'justify-content': 'space-around',
  margin: '30px auto'
})

const LINES_URL= BASE_SST_API_URL + SST_API_PATH_LINES;

const fetchData = async (url, props, updaterFunction, isLoading) => {

  isLoading(true);
  const response = await fetch(url, props);

  if (response.ok){
    const jsonResponse = await response.json();
    updaterFunction(jsonResponse);
  }
  isLoading(false);
} 

const StandardReport = (props) => {

  const {setBreadcrumbs, apiToken, userDetails} = useContext(SiteContext);

  const [plants, setPlants] = useState([])
  const [lines, setLines] = useState([])
  const [runs, setRuns] = useState([])
  const [loadingPlants, setLoadingPlants] = useState(false);
  const [loadingLines, setLoadingLines] = useState(true);
  const [loadingRuns, setLoadingRuns] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState("")
  const [selectedLine, setSelectedLine] = useState("")
  const [selectedRun, setSelectedRun] = useState("")
  const [renderReports, setRenderReports] = useState(false);
  const [fetchProps] = useState({
    method: 'GET',
    headers: { "Authorization": apiToken }
  });

  const PLANTS_URL = `${BASE_SST_API_URL}/customers/${userDetails.user.customerId}/plants`
  
  useEffect(() => {
    document.title = "Standard Report";
    setBreadcrumbs([
      {
        title: "Standard Report",
      }
    ]);
    fetchData(PLANTS_URL, fetchProps, setPlants, setLoadingPlants);
  }, [setBreadcrumbs, fetchProps, PLANTS_URL]);

  const handlePlantChange = (event) => {
    setSelectedPlant(event.target.value);
    setSelectedLine("")
    setSelectedRun("")
    setRenderReports(false)
    fetchData(BASE_SST_API_URL + SST_API_PATH_PLANTS + '/' + event.target.value + '/' + SST_API_PATH_LINES, fetchProps, setLines, setLoadingLines);
  }
  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
    setSelectedRun("")
    setRenderReports(false)
    fetchData(LINES_URL + '/' + event.target.value + '/' + SST_API_PATH_RUNS, fetchProps, setRuns, setLoadingRuns);
  }
  const handleRunChange = (event) => {
    setSelectedRun(event.target.value);
    setRenderReports(true);
  }

  return (
    <div data-testid="standard-report-page">
      
      <FormPaper>
        <SelectWrapper>
          <SSTDropdown
            label="Plant"
            isLoadingContents={loadingPlants}
            fullWidth={true}
            mappedList={plants.map((plant, index) => {return <MenuItem value={plant.id} key={index} >{plant.name}</MenuItem>})}
            setValueFunc={handlePlantChange}
            selectedValueId={selectedPlant}/>
        </SelectWrapper>
        <SelectWrapper>
          <SSTDropdown
            label="Line"
            isLoadingContents={loadingLines}
            fullWidth={true}
            mappedList={lines.map((line, index) => {return <MenuItem value={line.id} key={index} >{line.name}</MenuItem>})}
            setValueFunc={handleLineChange}
            selectedValueId={selectedLine}/>
        </SelectWrapper>
        <SelectWrapper>
          <SSTDropdown
            label="Run"
            isLoadingContents={loadingRuns}
            fullWidth={true}
            mappedList={runs.map((run, index) => {return <MenuItem value={run.id} key={index} >{run.name}</MenuItem>})}
            setValueFunc={handleRunChange}
            selectedValueId={selectedRun}/>
        </SelectWrapper>
      </FormPaper>
      


      {renderReports && 
        <div>
          <ReportRow>
            <ReportingWidget run={selectedRun} type='timelinechart' inputs={[{key: 'pressure', default: 'psi', options: ['psi', 'bar']}]} />
          </ReportRow>
          <ReportRow>
            <ReportingWidget run={selectedRun} type='pressuremap' inputs={[{key: 'pressure', default: 'psi', options: ['psi', 'bar']}]} />
            <ReportingWidget run={selectedRun} type='pressurepiechart' inputs={[{key: 'pressure', default: 'psi', options: ['psi', 'bar']}]} />
          </ReportRow>
          <ReportRow>
            <ReportingWidget run={selectedRun} type='spinpiechart' inputs={[{key: 'rotation', default: 'rpm', options: ['rpm', 'deg']}]} />
          </ReportRow>
        </div>}
      
      
    </div>
  );
};

export default StandardReport;