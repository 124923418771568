import {ReactComponent as DashboardIcon} from '../../img/icons/dashboard.svg';
import {ReactComponent as AdminIcon} from '../../img/icons/admin.svg';
import {ReactComponent as HelpIcon} from '../../img/icons/help.svg';

import SideMenuItem from '../../shared/components/SideMenu/SideMenuItem';
import SideMenuSubMenu from '../../shared/components/SideMenu/SideMenuSubMenu';
import translations from '../../translations/en.json';

const adminList = [
  { label: 'Users', link: '/users', access: { method: 'update', entity: 'CustomerUser' } },
  { label: 'Customers', link: '/list-customers', access: { method: 'get', entity: 'Customer', access: 'Platform' } },
  { label: 'Plants', link: '/list-plants', access: { method: 'update', entity: 'Plant' } },
  { label: 'Lines', link: '/list-lines', access: { method: 'update', entity: 'Line' } },
  { label: translations.common.machineCenters.machineCenters, link: '/list-machine-centers', access: { method: 'update', entity: 'Line' } },
  { label: 'Sensors', link: '/list-sensors', access: { method: 'update', entity: 'Multisensor' } },
  { label: 'Hubs', link: '/list-hubs', access: { method: 'get', entity: 'Hub', access: 'Platform' } },
  { label: 'Drones', link: '/list-drones', access: { method: 'get', entity: 'Drone', access: 'Platform' } },
  { label: 'Error Reports', link: '/list-error-reports', access: { method: 'get', entity: 'ErrorReport', access: 'Platform' } }
]


const navData = [
    // Dashboard
  {
    section: 'top',
    component: ({collapsed, highlight}) => <SideMenuItem icon={DashboardIcon} label="Dashboard" link="/dashboard" collapsed={collapsed} key="nav-Dashboard" highlight={highlight} />
  },
    // Administration
  {
    section: 'bottom',
    component: ({collapsed, highlight}, forceExpand, authFn) => <SideMenuSubMenu icon={AdminIcon} label="Administration" headerLink="/administration" collapsed={collapsed} forceExpand={forceExpand} key="nav-Administration" highlight={highlight} subMenuItems={adminList} authFn={authFn}/>
  },
    // Help
  {
    section: 'bottom',
    component: ({collapsed, highlight}) => <SideMenuItem icon={HelpIcon} label={translations.components.footer.help} link="/help-center" collapsed={collapsed} key="nav-HelpCenter" highlight={highlight} openInNewTab />
  }
];

export default navData