import React from 'react';
import {Typography, SvgIcon} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {ReactComponent as BackIcon} from '../../../img/icons/Back button.svg';
import * as GreyScale from '@Theme/GreyScale.js'

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: '1px solid #e4e4e4',
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 26px'
  },
  title: {
    flex: 1,
    fontSize: '20px',
    lineHeight: '24px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  backButton: {
    fontSize: '32px',
    color: GreyScale.GREY_4,
    display: 'block',
    cursor: 'pointer'
  },
  divider: {
    height: '27px',
    width: '1px',
    backgroundColor: '#8F8F8F',
    marginLeft: '26px',
    marginRight: '26px'
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(1)
    }
  },
  contentContainerSubContainerRight: {
    // Default
  }
}))

const PageHeader = (props) => {

  const {
    onBack,
    pageTitle,
    contentRight
  } = props

  const classes = useStyles();

  return (
    <div className={classes.header}>
      {onBack && <span onClick={onBack}>
        <SvgIcon component={BackIcon} viewBox="8 8 32 32" className={classes.backButton}/>
      </span>}
      {onBack && <span className={classes.divider}/>}
      <span className={classes.contentContainer}>

        <Typography className={classes.title}>
            {pageTitle}
        </Typography>

        {/* Content Right */}
        <div
          className={classes.contentContainerSubContainer}>
          {contentRight}
        </div>

      </span>
    </div>
  )
}

export default PageHeader