import * as React from "react";
import { useContext, useEffect, useMemo, useState } from 'react'
import {Link} from 'react-router-dom';
import {SiteContext} from "../../Context";
import { MenuItem, Typography } from '@mui/material'
import {makeStyles} from '@mui/styles';
import SSTDropdown from "../../shared/components/SSTDropdown";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import {ReactComponent as EditIcon} from '../../img/icons/edit.svg'
import {ReactComponent as SettingsIcon} from '../../img/icons/settings.svg'
import translations from '../../translations/en.json';

import {useQuery} from 'react-query';
import {
  getCustomerPlants,
  getCustomerLines,
  getCustomerMachineCenters,
} from '../../query/queries'

import '../PageStyles/TablePage.css';

import {
  DATE_TIME_TIMEZONE_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
  PERMISSION_METHOD_UPDATE,
} from '../../shared/Utilities'

import {
  SST_PAGE_CREATE_MACHINE_CENTER,
  SST_PAGE_EDIT_MACHINE_CENTER,
  SST_PAGE_MACHINE_CENTER_SETTINGS,
} from '../../Constants'
import CreateButton from '../../shared/components/CreateButton'
import QuantifeelSvgIcon from '../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import { v4 as uuidv4 } from 'uuid';
import ActiveStatusIndicator from '../../shared/components/ActiveStatusIndicator/ActiveStatusIndicator'
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment'
import { getMachineCenterRuns } from '../../query/entities/machineCenters'
import { getControlPoints } from '../../query/entities/controlPoints'

const pageTitle = translations.pages.listMachineCenters.title;
const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Plant', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const LAST_RUN_UPLOAD_KEY = 'lastRunUpload';
const CONTROL_POINTS_KEY = 'controlPoints';

const COLUMN_IDS = {
  MACHINE_CENTER: "machineCenter",
  PLANT: "plant",
  LINE: "line",
  TYPE: "type",
  LAST_RUN_UPLOAD: "lastRunUpload",
  NUM_CONTROL_POINTS: "numControlPoints",
  ACTIVE_STATUS: "activeStatus",
  ACTIONS: "actions"
};

const columns = [
  {id: COLUMN_IDS.MACHINE_CENTER, label: translations.common.machineCenters.machineCenter, width: "300px"},
  {id: COLUMN_IDS.PLANT, label: translations.common.plants.plant, width: "300px"},
  {id: COLUMN_IDS.LINE, label: translations.common.lines.line, width: "200px"},
  {id: COLUMN_IDS.TYPE, label: translations.common.type},
  {id: COLUMN_IDS.LAST_RUN_UPLOAD, label: translations.common.lastRunUpload},
  {id: COLUMN_IDS.NUM_CONTROL_POINTS, label: translations.common.machineCenters.numControlPoints},
  {id: COLUMN_IDS.ACTIVE_STATUS, label: translations.common.status},
  {id: COLUMN_IDS.ACTIONS, label: translations.common.actions, disableSort: true}
]

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '350px'
  },
  leftHeaderControlsContainer: {
    display: 'flex',
    marginRight: theme.spacing(3),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(3)
    },
  },
  textOverflowHiddenEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  actionsContainer: {
    '& > *': {
      marginRight: theme.spacing(3)
    }
  }
}));

const ALL_MENU_ITEM = uuidv4();

const ListMachineCenters = (props) => {

  const {
    history
  } = props;

  const {currentCustomer, linePageFilters, setLinePageFilters, userDetails, hasPermission} = useContext(SiteContext);
  const [selectedPlant, setSelectedPlant] = useState(linePageFilters?.plant || ALL_MENU_ITEM);
  const [selectedLine, setSelectedLine] = useState( linePageFilters?.line || ALL_MENU_ITEM);
  const [filteredEnrichedMachineCenters, setFilteredEnrichedMachineCenters] = useState([]);
  const [resetTablePagination, setResetTablePagination] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfRequestsInFlight, setNumberOfRequestsInFlight] = useState(0);

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {
    isLoading: isLoadingPlants,
    data: plants = []
  } = useQuery(['plants', {customerId: currentCustomer}], getCustomerPlants);

  // GET lines, omitting deleted lines, for purposes of populating Plants filter dropdown, which should not include deleted lines...
  const {
    isLoading: isLoadingLinesOmitDeleted,
    data: linesOmitDeleted = []
  } = useQuery(['lines', {customerId: currentCustomer}], getCustomerLines);

  // GET lines, including deleted lines and plant name, for purposes of enriching machine centers with plantName and lineName, regardless of if the parent line is deleted...
  const {
    isLoading: isLoadingLinesIncludedDeleted,
    data: linesIncludeDeleted = []
  } = useQuery(['lines', {customerId: currentCustomer}, {includeDeleted: true, includePlantName: true}], getCustomerLines);

  const {
    isLoading: isLoadingMachineCenters,
    data: machineCenters = []
  } = useQuery(['machineCenters', {customerId: currentCustomer}], getCustomerMachineCenters);

  const enrichedMachineCenters = useMemo(() => {
    return [...machineCenters]
  }, [machineCenters]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  /**
   * Given changes to loading states,
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingPlants ||
         isLoadingLinesOmitDeleted ||
         isLoadingLinesIncludedDeleted ||
         isLoadingMachineCenters ||
         numberOfRequestsInFlight > 0) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoadingPlants, isLoadingLinesOmitDeleted, isLoadingLinesIncludedDeleted, isLoadingMachineCenters, numberOfRequestsInFlight])
  
  /**
   * Given changes to linesIncludeDeleted, machineCenters,
   * Enrich machine centers w/ plantName, lineName, lastRunUpload, and controlPoints...
   */
  useEffect(() => {

    // Validate lines, enrichedMachineCenters...
    if ( linesIncludeDeleted.length === 0 ||
         enrichedMachineCenters.length === 0 ) {
      return;
    }

    // For each enrichedMachineCenters, enrich machine centers w/ plantName, lineName, and lastRunUpload...
    enrichedMachineCenters.forEach((enrichedMachineCenter) => {

      // Find line associated to machine center, on line.id === enrichedMachineCenter.lineId...
      const lineAssociatedToMachineCenter = linesIncludeDeleted.find((line) => { return line.id === enrichedMachineCenter.lineId });

      // ----------------
      // -- PLANT NAME --
      // ----------------

      // Get Line.plantName associated to machineCenter.lineId and enrich...
      enrichedMachineCenter.plantName = lineAssociatedToMachineCenter.plantName

      // ----------------
      // -- LINE NAME --
      // ----------------

      // Lookup Line.name associated to machineCenter.lineId and enrich...
      enrichedMachineCenter.lineName = lineAssociatedToMachineCenter.name

      // ---------------------
      // -- LAST RUN UPLOAD --
      // ---------------------

      // Get latest Run.dateRecorded, for Runs associated to machine center, and enrich...
      // a. Update numberOfRequestsInFlight, for loading indicator purposes...
      setNumberOfRequestsInFlight((previousState) => previousState + 1) // One request for runs means + 1 requests in flight...

      // b. Get runs for machine center, get latest Run.dateRecorded...
      getMachineCenterRuns({ queryKey: ['machineCenterRuns', { machineCenterId: enrichedMachineCenter.id }] })
        .then((machineCenterRuns) => {
          enrichedMachineCenter[LAST_RUN_UPLOAD_KEY] = machineCenterRuns[0]?.dateRecorded // Runs are sorted by dateRecorded DESC via API, so first run is latest run...
        })
        .finally(() => {
          // Decrement numberOfRequestsInFlight, for loading indicator purposes...
          setNumberOfRequestsInFlight((previousState) => previousState - 1 );
        })

      // --------------------
      // -- CONTROL POINTS --
      // --------------------

      // Get control points for machine center, and enrich...
      // a. Update numberOfRequestsInFlight, for loading indicator purposes...
      setNumberOfRequestsInFlight((previousState) => previousState + 1) // One request for control points means + 1 requests in flight...

      // b. Get control points for machine center...
      getControlPoints({ queryKey: ['machineCenterControlPoints', { machineCenterId: enrichedMachineCenter.id }] })
        .then((machineCenterControlPoints) => {
          enrichedMachineCenter[CONTROL_POINTS_KEY] = machineCenterControlPoints
        })
        .finally(() => {
          // Decrement numberOfRequestsInFlight, for loading indicator purposes...
          setNumberOfRequestsInFlight((previousState) => previousState - 1 );
        })

    })

  }, [linesIncludeDeleted, enrichedMachineCenters])

  /**
   * Given changes to selectedPlant, selectedLine, enrichedMachineCenters,
   * Filter enrichedMachineCenters...
   */
  useEffect(() => {

    // If selectedPlant and selectedLine are ALL_MENU_ITEM...
    if ( selectedPlant === ALL_MENU_ITEM &&
         selectedLine  === ALL_MENU_ITEM ) {
      setFilteredEnrichedMachineCenters(enrichedMachineCenters);
      return;
    }

    // If selectedPlant and selectedLine are not ALL_MENU_ITEM...
    // Filter machine centers to only include machine centers from selectedPlant and / or selectedLine...
    const filterPlant = selectedPlant !== ALL_MENU_ITEM;
    const filterLine = selectedLine  !== ALL_MENU_ITEM;

    let filteredMachineCenters = enrichedMachineCenters;
    filteredMachineCenters = filterPlant ? filteredMachineCenters.filter((machineCenter) => { return machineCenter.plantId === selectedPlant }) : filteredMachineCenters;
    filteredMachineCenters = filterLine ? filteredMachineCenters.filter((machineCenter) => { return machineCenter.lineId === selectedLine }) : filteredMachineCenters;
    setFilteredEnrichedMachineCenters(filteredMachineCenters);

  }, [selectedPlant, selectedLine, enrichedMachineCenters]);

  /**
   * Given changes to selectedPlant,
   * Determine if selectedLine should be updated, to avoid invalid selectedPlant / selectedLine combinations...
   */
  useEffect(() => {

    // If selectedLine === ALL_MENU_ITEM, no change required, so return...
    if (selectedLine === ALL_MENU_ITEM) {
      return;
    }

    // If linesOmitDeleted is empty, we cannot determine if selectedLine has selectedPlant as a parent, so return...
    if (linesOmitDeleted.length === 0) {
      return;
    }

    // If selectedPlant === ALL_MENU_ITEM or if selectedPlant is selectedLine's parent, selectedLine is still included in selectedPlant, so return...
    const selectedLineObject = linesOmitDeleted.find((line) => { return line.id === selectedLine });
    if ( selectedPlant === ALL_MENU_ITEM ||
         selectedPlant === selectedLineObject.plantId ) {
      return;
    }

    // If selectedPlant !== ALL_MENU_ITEM and selectedPlant is not selectedLine's parent, set selectedLine to ALL_MENU_ITEM...
    handleSetSelectedLineFromId(ALL_MENU_ITEM);

  }, [linesOmitDeleted, selectedPlant]); // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------
  // -- END useEffects --
  // --------------------

  const generateRows = (machineCenters) => {
    return machineCenters.map((machineCenter) => {
      return {

        [COLUMN_IDS.MACHINE_CENTER]:
          <CopyLabel value={machineCenter.id} rawval={machineCenter.name?.toLowerCase()}>
            {/*<QuantifeelTooltip title={translations.common.machineCenters.viewMachineCenter}>*/}
            {/*  <Link*/}
            {/*    className={classes.textOverflowHiddenEllipsis}*/}
            {/*    to={`/${SST_PAGE_VIEW_MACHINE_CENTER}/${machineCenter.id}`}>*/}
            {/*      {machineCenter.name}*/}
            {/*  </Link>*/}
            {/*</QuantifeelTooltip>*/}
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {machineCenter.name}
            </Typography>
          </CopyLabel>,

        [COLUMN_IDS.PLANT]:
          <CopyLabel value={machineCenter.plantId} rawval={machineCenter.plantName?.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {machineCenter.plantName}
            </Typography>
          </CopyLabel>,

        [COLUMN_IDS.LINE]:
          <CopyLabel value={machineCenter.lineId} rawval={machineCenter.lineName?.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {machineCenter.lineName}
            </Typography>
          </CopyLabel>,

        [COLUMN_IDS.TYPE]:
          <Typography className={classes.textOverflowHiddenEllipsis} rawval={machineCenter.machineCenterType?.name}>
            {!!(machineCenter.machineCenterType?.name) ? machineCenter.machineCenterType?.name : translations.common.notAssigned}
          </Typography>,

        [COLUMN_IDS.LAST_RUN_UPLOAD]:
          <ProtectedMoment
            date={machineCenter[LAST_RUN_UPLOAD_KEY]}
            format={DATE_TIME_TIMEZONE_FORMAT}
            rawval={machineCenter[LAST_RUN_UPLOAD_KEY]}
            tz={userDetails.user.tz}
          />,

        [COLUMN_IDS.NUM_CONTROL_POINTS]:
          machineCenter[CONTROL_POINTS_KEY]?.length,

        [COLUMN_IDS.ACTIVE_STATUS]:
          <ActiveStatusIndicator
            rawval={machineCenter.inactive ? "inactive" : "active"} // To enable sorting...
            isActive={!machineCenter.inactive}
          />,

        [COLUMN_IDS.ACTIONS]:
          <div className={classes.actionsContainer}>
            <Link to={`/${SST_PAGE_EDIT_MACHINE_CENTER}?machineCenterId=${machineCenter.id}`}>
              <QuantifeelSvgIcon
                component={EditIcon}
                viewBox="0 0 32 32"
                tooltipTitle={translations.common.machineCenters.editMachineCenter}
              />
            </Link>
            <Link to={`/${SST_PAGE_MACHINE_CENTER_SETTINGS}/${machineCenter.id}`}>
              <QuantifeelSvgIcon
                component={SettingsIcon}
                viewBox="0 0 32 32"
                style={{fill: 'black'}}
                tooltipTitle={translations.common.machineCenters.machineCenterSettings}
              />
            </Link>
          </div>

      }
    });
  }

  const handleSetSelectedPlantFromEvent = e => {

    const selectedPlant = e.target.value;

    // Set selected plant...
    setSelectedPlant(selectedPlant);

    // Set linePageFilters...
    if (selectedPlant === ALL_MENU_ITEM) {
      setLinePageFilters(f => ({ ...f, plant: undefined })); // Undefined since ALL_MENU_ITEM is not a plant. However, ALL_MENU_ITEM is default selectedPlant if !linePageFilters...
    } else {
      setLinePageFilters(f => ({ ...f, plant: selectedPlant }));
    }

    // Reset table pagination...
    setResetTablePagination(true);
  };

  const handleSetSelectedLineFromId = (lineId) => {

    // Set selected plant...
    setSelectedLine(lineId);

    // Set linePageFilters...
    if (lineId === ALL_MENU_ITEM) {
      setLinePageFilters(f => ({ ...f, line: undefined })); // Undefined since ALL_MENU_ITEM is not a plant. However, ALL_MENU_ITEM is default selectedPlant if !linePageFilters...
    } else {
      setLinePageFilters(f => ({ ...f, line: lineId }));
    }

    // Reset table pagination...
    setResetTablePagination(true);
  };

  const handleSetSelectedLineFromEvent = e => {

    const lineId = e.target.value;

    handleSetSelectedLineFromId(lineId)
  };

  const onCreateButtonClick = (e) => {
    if(selectedPlant === ALL_MENU_ITEM && selectedLine === ALL_MENU_ITEM) {
      props.history.push(`/${SST_PAGE_CREATE_MACHINE_CENTER}`);
    } else if(selectedPlant !== ALL_MENU_ITEM && selectedLine === ALL_MENU_ITEM) {
      props.history.push(`/${SST_PAGE_CREATE_MACHINE_CENTER}?plantId=${selectedPlant}`);
    } else {
      // Whether both dropdowns have selected values or that just the line dropdown has a selected value, the lineId is all we need to populate ancestry fields
      props.history.push(`/${SST_PAGE_CREATE_MACHINE_CENTER}?lineId=${selectedLine}`);
    }
  };

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {
    return (
      <div className="headerContainer">

        {/* Filter Controls */}
        <div className={classes.leftHeaderControlsContainer}>
          <SSTDropdown
            classes={classes}
            disabled={false}
            isLoadingContents={isLoadingPlants}
            label={translations.common.plants.plants}
            selectedValueId={selectedPlant}
            setValueFunc={handleSetSelectedPlantFromEvent}
            fullWidth={false}
            mappedList={getPlantDropdownMenuItems(plants)}
            required={false}
          />
          <SSTDropdown
            classes={classes}
            disabled={false}
            isLoadingContents={isLoadingLinesOmitDeleted}
            label={translations.common.lines.lines}
            selectedValueId={selectedLine}
            setValueFunc={handleSetSelectedLineFromEvent}
            fullWidth={false}
            mappedList={getLineDropdownMenuItems(linesOmitDeleted)}
            required={false}
          />
        </div>

        {/* Create Button */}
        {hasPermission('Line', 'insert') &&
          <div>
            <CreateButton
              onClick={onCreateButtonClick}
              isLoading={isLoading}
              label={translations.common.machineCenters.createMachineCenter}
            />
          </div>
        }
      </div>
    )
  }

  const getPlantDropdownMenuItems = (plants) => {

    const menuItems = [];

    // Add "All" item...
    menuItems.push(<MenuItem key={ALL_MENU_ITEM} value={ALL_MENU_ITEM}>{translations.common.all}</MenuItem>)

    // Add item for each plant...
    plants.forEach((plant) => {
      menuItems.push(
        <MenuItem key={plant.id} value={plant.id}>{plant.name}</MenuItem>
      )
    })

    return menuItems
  }

  const getLineDropdownMenuItems = (lines) => {

    const menuItems = [];

    // Add "All" item...
    menuItems.push(<MenuItem key={ALL_MENU_ITEM} value={ALL_MENU_ITEM}>{translations.common.all}</MenuItem>)

    if (selectedPlant === ALL_MENU_ITEM) {

      // If selectedPlant === ALL_MENU_ITEM, return menu item for all lines...
      lines.forEach((line) => {
        menuItems.push(
          <MenuItem key={line.id} value={line.id}>{line.name}</MenuItem>
        )
      })

    } else {

      // If selectedPlant !== ALL_MENU_ITEM, return menu items for lines associated to selectedPlant...
      lines.forEach((line) => {
        if (line.plantId === selectedPlant) {
          menuItems.push(
            <MenuItem key={line.id} value={line.id}>{line.name}</MenuItem>
          )
        }
      })

    }

    return menuItems
  }

  const renderTable = () => {
    return (
      <EnhancedTable
        order={'asc'}
        orderBy={COLUMN_IDS.MACHINE_CENTER}
        rows={generateRows(filteredEnrichedMachineCenters)}
        headCells={columns}
        isLoading={isLoading}
        enableFilter
        filterColumns={[COLUMN_IDS.MACHINE_CENTER, COLUMN_IDS.PLANT, COLUMN_IDS.LINE]}
        resetPagination={resetTablePagination}
        onResetPaginationComplete={() => setResetTablePagination(false)}
      />
    )
  }

  return (
    <div className="page" data-testid="list-machine-centers-page">
      <div className="container">
        {renderHeader()}
        {renderTable()}
      </div>
    </div>
  );
};

export default ListMachineCenters