import React from 'react'
import Dialog from '@mui/material/Dialog'
import useStyles from './Styles'
import {ReactComponent as CloseIcon} from '../../../../img/icons/close.svg'
import QuantifeelSvgIcon from '../../QuantifeelSvgIcon/QuantifeelSvgIcon'

const QuantifeelDialog = (props) => {

  const {
    open,
    disableCloseIcon,
    onClose,
    title,
    content,
    actions,
  } = props;

  const classes = useStyles();

  const handleClose = (event, reason) => {

    // If the user clicks the backdrop, don't close the dialog...
    if (reason && reason === "backdropClick") {
      return;
    }

    onClose();
  }

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
    >

      <div className={classes.dialogTitleContainer}>
        {title}
        <QuantifeelSvgIcon
          disabled={disableCloseIcon}
          component={CloseIcon}
          viewBox={'0 0 24 24'}
          className={classes.closeIcon}
          onClick={onClose}
        />
      </div>

      <div className={classes.dialogContentContainer}>
        {content}
      </div>

      <div className={classes.dialogActionsContainer}>
        {actions}
      </div>

    </Dialog>
  )
}

export default QuantifeelDialog