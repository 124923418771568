import {useEffect, useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom';
import {SiteContext} from '../../Context';

const Logout = () => {

  const {logout} = useContext(SiteContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    logout();
    if(location.state?.referrer?.pathname){
      const loginWithForward = {
        pathname:'/login',
        search: `redirect=${encodeURIComponent(location.state.referrer.pathname + location.state.referrer.search)}`
      }
      history.replace(loginWithForward);
    }
    else{
      history.push('/login');
    }    
  });  
  return null;
}

export default Logout;