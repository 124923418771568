import ReactEcharts from 'echarts-for-react'
import moment from 'moment'

const QuicksightChart = (props) => {
  const {
    categories,
    title,
    selectedSensorName,
    data,
    dates,
    chartHeight,
    chartWidth,
    chartUnit,
    isLoading,
    hasLegend,
    aggregatedBy,
  } = props

  const hasData = data.flat().some((el) => el !== null && el !== undefined)

  const isOuterRecord = (arr, index) => {
    if (!!arr && !!arr[index]) {
      let leftRecordExists =
        !!arr[index - 1] && arr[index - 1] !== null ? true : false
      let rightRecordExists =
        !!arr[index + 1] && arr[index + 1] !== null ? true : false

      return !leftRecordExists || !rightRecordExists
    }
    return false
  }

  const requiresTimestamp = (aggregatedBy) => {
    if (
      aggregatedBy === 'Day' ||
      aggregatedBy === 'Hour' ||
      aggregatedBy === 'Minute'
    ) {
      return true
    }
    return false
  }

  const handleChartTitle = () => {
    let chartTitle = title
    if (!!selectedSensorName) {
      chartTitle += ` | ${selectedSensorName}`
      if (!hasData) {
        chartTitle += `\n\nNo data within this time range.`
      }
    } else {
      chartTitle += ` | Multisensor not selected.`
    }
    return chartTitle
  }

  const options = {
    title: {
      text: handleChartTitle(),
      textStyle: {
        fontSize: 12,
        fontWeight: 'normal',
      },
    },
    grid: {
      containLabel: true,
      left: 25,
      top: 70,
      width: chartWidth,
      height: chartHeight,
    },
    tooltip: {
      trigger: 'axis',
      mode: 'x-axis',
      show: hasData,
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        saveAsImage: { show: true, title: 'Save as Image' },
      },
    },
    legend: {
      data: hasLegend ? categories : [],
      show: hasData,
      left: 'center',
      top: '20',
    },
    xAxis: {
      data: dates.map((date) => {
        let dateValue = moment(date, 'DD/MM/YYYY hh:mm:ss a')
        let dateFormat = ''
        if (requiresTimestamp(aggregatedBy)) {
          dateFormat = 'MM/DD/YYYY hh:mm a' //12/10/2022 12:00:00 am
        } else {
          dateFormat = 'MM/DD/YYYY'
        }
        return dateValue.format(dateFormat).replace(' ', '\n')
      }),
      axisPointer: {
        type: hasData ? 'shadow' : 'none',
      },
      axisLine: {
        show: hasData,
      },
      axisLabel: {
        show: hasData,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      name: data[0]?.length !== 0 ? chartUnit : null,
      show: hasData,
      axisLabel: {
        formatter: '{value}',
      },
    },
    series: categories.map((title, index) => {
      return {
        type: 'line',
        showAllSymbol: true,
        zlevel: 9,
        data:
          !!data[index] && data[index].length !== 0
            ? data[index].map((dataPoint, i) => {
                return {
                  value: dataPoint,
                  // Allows all data to be captured correctly without being too 'noisy'
                  symbol: isOuterRecord(data[index], i) ? 'circle' : 'none',
                }
              })
            : [],
        name: title,
      }
    }),
  }
  const loadingOption = {
    text: '', // Made blank intentionally
    //TODO: Refactor on color scheme unification
    color: '#0E4DA4',
    maskColor: '#F5F5F5',
    zlevel: 0,
    showSpinner: true,
    spinnerRadius: 20,
    lineWidth: 3,
  }

  return (
    <ReactEcharts
      option={options}
      showLoading={isLoading}
      loadingOption={loadingOption}
      notMerge
    />
  )
}

export default QuicksightChart
