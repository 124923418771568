import * as React from "react";
import Alert from "react-bootstrap/Alert";
import * as PropTypes from "prop-types";
import StatusVariant from "./StatusVariant";

const SSTAlert = (props) => {
    return (
        <Alert id="alertLabel"
               variant={(props.variant)?props.variant:StatusVariant.DANGER}
               style={{
                   display: (props.message) ? 'flex' : 'none',
                   maxWidth: (props.maxWidth) ? props.maxWidth : '500px'
               }}>
            {props.message}
        </Alert>
    );
};
SSTAlert.propTypes = {
    message: PropTypes.string.isRequired,
    variant: PropTypes.any,
    maxWidth: PropTypes.string,
};
export default SSTAlert;