import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  loadingBackdrop: {
    backdropFilter: 'blur(5px)',
    zIndex: 999,
  },
}))

const FullScreenCircularProgress = (props) => {

  const classes = useStyles();

  return (
    <Backdrop className={classes.loadingBackdrop} open>
      <CircularProgress size={80} />
    </Backdrop>
  )
}

export default FullScreenCircularProgress