import * as React from "react";
import { useContext, useEffect, useState } from 'react'
import {useQuery} from 'react-query';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {SiteContext} from "../../Context";
import {DATE_TIME_TIMEZONE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from "../../shared/Utilities";
import queryString from "query-string";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import ProtectedMoment from "../../shared/components/ProtectedMoment/ProtectedMoment";
import '../PageStyles/TablePage.css'

import {
  getLine,
  getLineConfigurations
} from '../../query/queries';
import PageHeader from '../../shared/components/PageHeader/PageHeader'
import translations from '../../translations/en.json'
import { makeStyles } from '@mui/styles'
import { SST_PAGE_LIST_LINES } from '../../Constants'
import FullScreenCircularProgress from '../../shared/components/FullScreenCircularProgress'

const pageTitle = 'List Line Configurations';
const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
})

const columns = [
  {id: 'id', label: 'Id', width: '390px'},
  {id: 'dateCreated', label: 'Date Created', width: '250px'},
  {id: 'versionId', label: 'Version Id', width: '175px'},
  {id: 'description', label: 'Description', width: '425px'},
  {id: 'actions', label: 'Actions', width: '175px'}
];

const genRows = (configs, details) => {
  return configs.map(config => ({
    id: config.id,
    dateCreated: <ProtectedMoment date={config.dateCreated} format={DATE_TIME_TIMEZONE_FORMAT} tz={details.user.tz}/>,
    versionId: config.versionId,
    description: config.description,
    actions: <Link to={`/lines/${config.lineId}/configurations/${config.id}`}>View Configuration</Link>
  }))
}

const ListLineConfigurations = (props) => {

  const history = useHistory();
  const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const lineId = queryString.parse(location.search).lineId

  const {isLoading: isLoadingLine, data: line = {}} = useQuery(['line', {lineId}], getLine);
  const {isLoading: isLoadingLineConfigurations, data: lineConfigurations = []} = useQuery(['lineConfigurations', {lineId}], getLineConfigurations);

  useEffect(() => {
    if (isLoadingLine) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingLine])

  useEffect(() => {
    document.title = "List Configurations";        
    setBreadcrumbs([{title: "List Configurations"}]);        
  }, [setBreadcrumbs]);

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-lines...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_LINES}`)
  }

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`${translations.pages.listLineConfigurations.lineConfigurationsFor} ${line.name}`}
      />
    )
  }

  return (
    <div className={classes.root}>

      {isLoading && <FullScreenCircularProgress/>}

      {renderHeader()}

      <div className="page" data-testid="list-line-configurations-page">
        {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
          <div className="container">
            <EnhancedTable
              isLoading={isLoadingLineConfigurations || isLoadingLine}
              order={'asc'}
              orderBy={'Name'}
              rows={genRows(lineConfigurations, userDetails)}
              headCells={columns}
              enableFilter
              filterColumns={['description', 'versionId', 'id']}
            />
          </div>
        }
      </div>

    </div>
  );
};

export default ListLineConfigurations;
