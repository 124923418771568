import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  heatmapContainer: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heatmapLoadingContainer:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingMessage: {
    marginTop: theme.spacing(1),
  },
  heatmap: {
    flex: 1,
    width: '100% !important', // Override default ReactECharts width...
    height: '100% !important', // Override default ReactECharts height...
    backgroundColor: '#F4F4F4'
  },
}))

export default useStyles
