import { makeStyles } from '@mui/styles'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  chartSideMenu: {
    minWidth: 200,
    width: 200,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${GreyScale.GREY_5}`,
    backgroundColor: GreyScale.GREY_1,

    '& > :not(:last-child)': {
      borderBottom: `1px solid ${GreyScale.GREY_5}`,
    }
  },
  yAxisSelectContainer: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(1),
  },
  yAxisSelect: {
    width: '100%',
    display: 'flex',

    '& > div': {
      backgroundColor: 'white',
    }
  },
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  chart: {
    width: '100% !important', // Override default ReactECharts width...
    height: '100% !important', // Override default ReactECharts height...
    display: 'flex',

    // Help chart resize to fit its container...
    '& > div:first-child': {
      width: '100% !important', // Override default ReactECharts width...
      height: '100% !important', // Override default ReactECharts height...
    }
  }
}));

export default useStyles
