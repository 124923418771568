import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Toolbar,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search'
import * as GreyScale from '@Theme/GreyScale.js'
import translations from '../../../translations/en.json';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'unset', // Override default minHeight of > 0, for styling purposes...
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',

    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    }
  },
  highlight: {},
  title: {
    flex: '1 1 100%',
    lineHeight: '48px'
  },
  searchTextField: {
    width: 350,
    backgroundColor: GreyScale.GREY_1,

    '& > .MuiInput-root input': {
      paddingRight: theme.spacing(1), // When there is input text, push the clear / 'x' icon away from the right side, for looks...
    }
  },
  searchIcon: {
    margin: `0 ${theme.spacing(1)}`
  }
}));


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const handleFilterUpdate = (event) => {
    if(props.onFilterChange){
      props.onFilterChange(event.target.value);
    }   
  }

  return (
    <Toolbar
      className={props.rootStyle ? props.rootStyle : classes.root}
      data-testid="EnhancedTableToolbar">

      {/* Title */}
      { props.title &&
        <Typography
          className={props.titleStyle ? props.titleStyle : classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
          data-testid="EnhancedTableTitle">
            {props.title}
        </Typography>
      }

      {/* Search Field */}
      {props.enableFilter &&
        <TextField
          className={classes.searchTextField}
          id="tableSearch"
          placeholder={translations.components.enhancedTable.search}
          type="search"
          inputProps={{'data-testid':"EnhancedTableFilter"}}
          InputProps={{
            startAdornment: (
              <SearchIcon className={classes.searchIcon}/>
            ),
          }}
          onChange={handleFilterUpdate}
        />
      }

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  onFilterChange: PropTypes.func
};

export default EnhancedTableToolbar;