import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {convertXData, convertYData, generateFFTGraphData} from "../../MultiSensorGraphsUtilities";
import ProtectedMoment from '../ProtectedMoment/ProtectedMoment';
import {DATE_TIME_FORMAT} from "../../Utilities";
import EchartGraph, {ICON_EXPORT_CSV, ICON_SETTINGS} from "../EchartGraph/EchartGraph";
import {fetchDownloadLink, fetchRequestDetailedVibrationData} from "../../FetchUtilities";
import {
    DATA_EXPORT_CSV,
    DATA_FORMAT_CSV,
    DATA_FORMAT_JSON,
    DATA_TYPE_FFT,
    DATA_TYPE_TIME_WAVEFORM,
    CURSOR_MOVABLE,
    CURSOR_HARMONIC,
    CURSOR_SIDEBAND,
    HARMONIC_REFERENCE_COLOR,
    HARMONIC_SIDEBAND_COLOR
} from "../../../Constants";
import {SiteContext} from "../../../Context";
import {Typography, Popper, TextField, Paper, MenuItem} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
  },
  graphContainer: {
    height:'100%'
  },
  menu: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  menuClose: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '15px',
    cursor: 'pointer'
  },
  menuSetting: {
    margin: '5px',
    width: '185px'
  }
}));

const genMarkLines = (mode, referenceFrequency, numberOfBands, bandWidth) => {  
  if(!referenceFrequency || mode === CURSOR_MOVABLE){
    return {data: []};
  }

  else if(mode === CURSOR_HARMONIC){
    const lines = [...Array(numberOfBands + 1)].map((val, index) => {
      return {
        label: {
            show: false
        },
        lineStyle: {
            type: 'dotted',
            color: (index === 0) ? HARMONIC_REFERENCE_COLOR : HARMONIC_SIDEBAND_COLOR,
            width: 2
        },
        xAxis: referenceFrequency * (index + 1)
      }
    })

    return {data: lines, symbol: 'none'}
  }

  else if(mode === CURSOR_SIDEBAND){
    const lines = [...Array((numberOfBands * 2)+ 1)].map((val, index) => {
      
      const offset = (index - numberOfBands) * bandWidth;
      
      return {
        label: {
            show: false
        },
        lineStyle: {
            type: 'dotted',
            color: (offset === 0) ? HARMONIC_REFERENCE_COLOR : HARMONIC_SIDEBAND_COLOR,
            width: 2
        },
        xAxis: referenceFrequency + offset
      }
    });
    return {data: lines, symbol: 'none'}
  }
}

const cursorModes = [
  CURSOR_MOVABLE,
  CURSOR_HARMONIC,
  CURSOR_SIDEBAND
];

const FFTDisplay = (props) => {
    
    const {apiToken} = useContext(SiteContext);
    
    const [vibrationFullXConverted, setVibrationFullXConverted] = useState([]);
    const [vibrationFullYConverted, setVibrationFullYConverted] = useState([]);
    const [vibrationFullZConverted, setVibrationFullZConverted] = useState([]);

    const [isLoadingFullFFTReading, setIsLoadingFullFFTReading] = useState(false);
    const [detailedFFTSelectedReading, setDetailedFFTSelectedReading] = useState();

    const [xAxisChartData, setXAxisChartData] = useState([]);
    const [yAxisChartData, setYAxisChartData] = useState([]);
    const [zAxisChartData, setZAxisChartData] = useState([]);
    const [dateRecorded, setDateRecorded] = useState("");

    const [menuAnchor, setMenuAnchor] = useState(null);
    const [referenceFrequency, setReferenceFrequency] = useState("");
    const [numberOfBands, setNumberOfBands] = useState(2);
    const [sidebandWidth, setSidebandWidth] = useState(10);
    const [cursorMode, setCursorMode] = useState(CURSOR_MOVABLE)

    useEffect(() => {
        if( typeof props.vibrationData !== "undefined"){
            setDetailedFFTSelectedReading(props.vibrationData);
            setIsLoadingFullFFTReading(false);
            return;
        }
        if (typeof props.vibrationReadingId !== "undefined") {
            fetchRequestDetailedVibrationData(props.vibrationReadingId,
                DATA_TYPE_FFT,
                DATA_FORMAT_JSON,
                true,
                setIsLoadingFullFFTReading,
                setDetailedFFTSelectedReading,
                apiToken);
        }
        setReferenceFrequency("");
    }, [props.vibrationReadingId, apiToken, props.vibrationData]);

    useEffect(() => {
        if (typeof detailedFFTSelectedReading !== "undefined") {
            generateFFTGraphData(detailedFFTSelectedReading, setXAxisChartData, setYAxisChartData, setZAxisChartData, setDateRecorded);
        }
    }, [detailedFFTSelectedReading]);

    useEffect(() => {
      convertYData(convertXData(xAxisChartData, xAxisChartData, props.selectedXUnit, ()=>{}), xAxisChartData, props.selectedYUnit, setVibrationFullXConverted);
      convertYData(convertXData(yAxisChartData, yAxisChartData, props.selectedXUnit, ()=>{}), yAxisChartData, props.selectedYUnit, setVibrationFullYConverted);
      convertYData(convertXData(zAxisChartData, zAxisChartData, props.selectedXUnit, ()=>{}), zAxisChartData, props.selectedYUnit, setVibrationFullZConverted);
    }, [xAxisChartData, yAxisChartData, zAxisChartData, props.selectedYUnit, props.selectedXUnit]);

    const toolbox = {
      saveAsImage: {
        title: "save as PNG",
        type: 'png',
      },
      dataZoom: {
        yAxisIndex: 'none',
        title: {
          zoom: "zoom",
          back: "back"
        }
      },
      myDownloadCsv: {
        show: true,
        title: "Download CSV",
        icon: ICON_EXPORT_CSV,
        onclick: function () {
          fetchDownloadLink(
            props.macAddress,
            props.vibrationReadingId,
            DATA_TYPE_TIME_WAVEFORM,
            DATA_FORMAT_CSV,
            DATA_EXPORT_CSV,
            setIsLoadingFullFFTReading,
            apiToken)
        }
      },
      myShowMore: {
        show: true,
        title: "More",
        icon: ICON_SETTINGS,
        iconStyle: {
          color: '#777'
        },
        onclick: (options, eChartsInstance) => {
          const el = eChartsInstance.getDom(),
            bounds = el.getBoundingClientRect(),
            height = 20,
            width = 20,
            yOffset = 10,
            xOffset = 5

          const newBounds = {
            top: bounds.top + yOffset,
            bottom: bounds.top + height + yOffset,
            height: height,
            left: bounds.right - width - xOffset,
            right: bounds.right - xOffset,
            width: bounds.width - xOffset,
            y: bounds.top + yOffset,
            x: bounds.right - width
          }

          openMenu({
            clientWidth: newBounds.width,
            clientHeight: newBounds.height,
            getBoundingClientRect: () => newBounds
          })
        }
      }
    }
          

    const openMenu = (element) => {
        setMenuAnchor(element);
    }

    const closeMenu = () => {
        setMenuAnchor(null)
    }

    const handleGraphClick = ({coordinates}) => {
      setReferenceFrequency(Number(coordinates.x.toFixed(1)))
    }

    const classes = useStyles();

    return <div className={classes.root}>
        {(vibrationFullXConverted.length === 0) && <center><b>{props.noDataMessage}</b></center>}
        {(props.showDateRecorded) &&
            <center>
                <b>
                    <Typography component="p" variant="h8" align="center">
                        <u>Recorded <ProtectedMoment date={dateRecorded} format={DATE_TIME_FORMAT}/></u>
                    </Typography>
                </b>
            </center>
        }
        {(vibrationFullXConverted.length > 0) &&
            <div className={classes.graphContainer}>
                <EchartGraph
                    className={classes.graphContainer}
                    chartRef={props.chartRef}
                    xAxisName={"x-Axis"}
                    yAxisName={"y-Axis"}
                    zAxisName={"z-Axis"}
                    xAxisData={vibrationFullXConverted}
                    yAxisData={vibrationFullYConverted}
                    zAxisData={vibrationFullZConverted}
                    selectedXUnit={props.selectedXUnit}
                    selectedYUnit={props.selectedYUnit}
                    showToolbox={props.showToolbox}
                    toolboxFeatures={props.showToolbox ? toolbox : {}}
                    isLoading={isLoadingFullFFTReading}
                    onGraphClick={handleGraphClick}
                    markLines={genMarkLines(cursorMode, referenceFrequency, numberOfBands, sidebandWidth)}/>
                <Popper
                    open={!!menuAnchor}
                    anchorEl={menuAnchor}
                    placement="bottom-end">
                    <Paper
                      className={classes.menu}>
                      <CloseIcon className={classes.menuClose} onClick={closeMenu}/>
                      <Typography component="p" variant="h6">Graph Settings</Typography>
                      <TextField 
                        select
                        className={classes.menuSetting}
                        label="Cursor Mode"
                        value={cursorMode}
                        onChange={(event) => setCursorMode(event.target.value)}>
                        {cursorModes.map((mode) => {
                          return <MenuItem value={mode} key={mode}>{mode}</MenuItem>
                        })}
                      </TextField>
                      {(cursorMode === CURSOR_HARMONIC || cursorMode === CURSOR_SIDEBAND) && 
                        <TextField
                          className={classes.menuSetting}
                          label="Reference Frequency"
                          value={referenceFrequency}
                          type="number"
                          inputProps={{
                            min: '0'
                          }}
                          onChange={(event) => {setReferenceFrequency(Number(event.target.value))}}/>}
                      
                      {(cursorMode === CURSOR_HARMONIC || cursorMode === CURSOR_SIDEBAND) &&
                        <TextField
                          className={classes.menuSetting}
                          label="Number of Bands"
                          value={numberOfBands}
                          type="number"
                          inputProps={{
                            min: '0'
                          }}
                          onChange={(event) => {setNumberOfBands(Number(event.target.value || 0))}}/>}
                      {(cursorMode === CURSOR_SIDEBAND) &&
                        <TextField
                          className={classes.menuSetting}
                          label="Band Width"
                          value={sidebandWidth}
                          type="number"
                          inputProps={{
                            min: '0'
                          }}
                          onChange={(event) => {setSidebandWidth(Number(event.target.value || 0))}}/>}
                       
                    </Paper>                                      
                </Popper>
            </div>
        }
    </div>
};

export default FFTDisplay;
