import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from "react-query";
import queryString from "query-string";
import {SiteContext} from "../../Context";
import {DATE_TIME_TIMEZONE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from "../../shared/Utilities";
import ProtectedMoment from "../../shared/components/ProtectedMoment/ProtectedMoment";
import EnhancedTable from '../../shared/components/EnhancedTable/EnhancedTable';
import '../PageStyles/TablePage.css'

import {getHubLogs, getHub} from '../../query/queries';
import PageHeader from '../../shared/components/PageHeader/PageHeader'
import { SST_PAGE_LIST_HUBS } from '../../Constants'
import { makeStyles } from '@mui/styles'

const pageTitle = 'Hub Logs';
const acceptablePagePermission = [
  {entity: 'HubLog', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Hub', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const columns = [
  {id: 'dateCreated', label: 'Sent', width: '250px'},
  {id: 'logMessage', label: 'Message', width: '1250px'}
]

const generateRows = (logs, details) => {
  return logs.map(log => ({
    dateCreated: <ProtectedMoment date={log.dateCreated} format={DATE_TIME_TIMEZONE_FORMAT} rawval={log.dateCreated} tz={details.user.tz}/>, 
    logMessage: log.logMessage
  }));
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
})

const HubLogs = () => {
  const location = useLocation();
  const history = useHistory();
  const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext);

  const [hubImei, setHubImei] = useState("");
  const {isLoading: isLoadingHub, data: hub} = useQuery(['hub', {hubImei: hubImei}], getHub, {enabled: !!hubImei});
  const {isLoading: isLoadingLogs, data: logs=[]} = useQuery(['hubLogs', {hubImei: hubImei}], getHubLogs, {enabled: !!hubImei});  

  useEffect(() => {

    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.title = "Hub Logs";
    const qs = queryString.parse(location.search);

    if (qs.imei) {
      setHubImei(qs.imei);
    }
    setBreadcrumbs([{title: "Hub Logs"}]);
  }, [location.search, setBreadcrumbs,]);

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-hubs...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_HUBS}`)
  }

  const classes = useStyles();

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`Hub Logs ${hub?.name ? `For ${hub.name}` : ''}`}
      />
    )
  }

  return (
    <div className={classes.root}>

      {renderHeader()}

      <div className="page" data-testid="hub-logs-page">
          <div className="container">
            <EnhancedTable
              isLoading={isLoadingLogs || isLoadingHub}
              order={'desc'}
              orderBy={'dateCreated'}
              rows={generateRows(logs, userDetails)}
              headCells={columns}
              enableFilter={true}
              filterColumns={['logMessage']}/>
          </div>
      </div>
    </div>
  );
};

export default HubLogs;