import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    fontFamily: 'sans-serif',
    display: 'inline-block',
    //border: '0.5px solid rgba(200, 200, 200 ,0.7)'
    boxShadow: 'inset 0 0 2px #444',
  },
  inner: {},
})

const renderPath = (identifier) => {
  switch (identifier) {
    case 0:
      return
    case 1:
      return (
        <text x="16" y="37" stroke="lime" fill="lime" strokeWidth="0.8">
          Start
        </text>
      )
    case 4:
      return <path stroke="black" fill="none" d="M 32,0 Q 32,32 64,32" />
    case 5:
      return <path stroke="black" fill="none" d="M 32,0 L 32,64" />
    case 6:
      return <path stroke="black" fill="none" d="M 32,0 Q 32,32 0,32" />
    case 7:
      return <path stroke="black" fill="none" d="M 32,64 Q 32,32 64,32" />
    case 8:
      return <path stroke="black" fill="none" d="M 32,64 Q 32,32 0,32" />
    case 9:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,0 Q 32,32 0,32 M 32,0 Q 32,32 64,32"
        />
      )
    case 10:
      return <path stroke="black" fill="none" d="M 0,32 L 64,32" />
    case 11:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 0,32 Q 32,32 32,0 M 0,32 Q 32,32 32,64"
        />
      )
    case 12:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 64,32 Q 32,32 32,0 M 64,32 Q 32,32 32,64"
        />
      )
    case 13:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,64 Q 32,32 0,32 M 32,64 Q 32,32 64,32"
        />
      )
    case 14:
      return <path stroke="black" fill="none" d="M 32,64 L 64,32" />
    case 15:
      return <path stroke="black" fill="none" d="M 32,0 L 0,32" />
    case 16:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 64,32 L 0,32 M 64,32 Q 32,32 32,0"
        />
      )
    case 17:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 0,32 L 64,32 M 0,32 Q 32,32 32,0"
        />
      )
    case 18:
      return <path stroke="black" fill="none" d="M 32,0 L 64,32" />
    case 19:
      return <path stroke="black" fill="none" d="M 32,64 L 0,32" />
    case 20:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 64,32 L 0,32 M 64,32 Q 32,32 32,64"
        />
      )
    case 21:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 0,32 L 64,32 M 0,32 Q 32,32 32,64"
        />
      )
    case 22:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,0 L 32,64 M 32,0 Q 32,32 64,32"
        />
      )
    case 23:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,0 L 32,64 M 32,0 Q 32,32 0,32"
        />
      )
    case 24:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,64 L 32,0 M 32,64 Q 32,32 64,32"
        />
      )
    case 25:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,64 L 32,0 M 32,64 Q 32,32 0,32"
        />
      )
    case 26:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 16,64 q 0,-8 -4,-8 q -12,0 -12,-24 q 0,-32 32,-32 q 32,0 32,32 q 0, 24 -12,24 q -4,0 -4,8 "
          strokeWidth="1.5"
        />
      )
    case 27:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 48,0 q 0,8 4,8 q 12,0 12,24 q 0,32 -32,32 q -32,0 -32,-32 q 0,-24 12,-24 q 4,0 4,-8 "
          strokeWidth="1.5"
        />
      )
    case 28:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 64,16 q -8,0 -8,-4 q 0,-12 -24,-12 q -32,0 -32,32 q 0,+32 32,32 q 24,0 24,-12 q 0,-4 8,-4 "
          strokeWidth="1.5"
        />
      )
    case 29:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 0,16 q 8,0 8,-4 q 0,-12 24,-12 q 32,0 32,32 q 0,+32 -32,32 q -24,0 -24,-12 q 0,-4 -8,-4 "
          strokeWidth="1.5"
        />
      )
    case 33:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 32,0 L 32,64 M 0,32 L 21,32 M 44,32 L 64,32"
        />
      )
    case 34:
      return (
        <path
          stroke="black"
          fill="none"
          d="M 0,32 L 64,32 M 32,0 L 32,21 M 32,44 L 32,64"
        />
      )
    default:
      return (
        <text x="28" y="36">
          ?
        </text>
      )
  }
}

const LinemapTile = (props) => {
  const { position, identifier: value } = props

  const classes = useStyles()

  //eslint-disable-next-line
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'TILE',
    collect: (monitor) => {
      // console.log(new Date());
      const isDragging = monitor.isDragging()
      // console.log(new Date());
      return { isDragging }
    },
    item: () => {
      // console.log(props.identifier);
      return {
        value: value,
        position,
      }
    },
    canDrag: () => {
      return true
    },
  }))

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'TILE',
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      }
    },
    drop: (item) => {
      console.log('dropped: ', item)
      console.log('to ', position)

      props.onDrop(item.value, position, item.position)
    },
    canDrop: () => props.canDrop,
  }))

  return (
    <div
      className={classes.root}
      ref={drop}
      style={{ backgroundColor: isOver && 'lightgrey' }}
    >
      <div ref={drag} style={{ opacity: isDragging ? 0.3 : 1 }}>
        <svg viewBox="0 0 64 64" strokeWidth="3">
          {renderPath(value)}
        </svg>
      </div>
    </div>
  )
}

export default LinemapTile
