import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {SiteContext} from "../../Context";
import queryString from "query-string";
import {MenuItem, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import SSTDropdown from "../../shared/components/SSTDropdown";

import {
    createSensor,
    getCustomer,
    getCustomerPlants,
    getEntityPermissions,
    getLine,
    getPlantLines,
    getSensor,
    updateSensor
} from '../../query/queries';
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_INSERT } from "../../shared/Utilities";

//Matches upper and lower case HEX values in groups of 2 with optional : or - between each group
const macAddressPattern = /^(?:[0-9a-fA-F]{2}[:-]?){5}[0-9a-fA-F]{2}$/;

const acceptablePagePermission = [
  {entity: 'Hub', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const validateMacAddress = (macAddress) => {
  return macAddress.match(macAddressPattern);
}

const emptySensor = {
  id: "",
  lineId: "",
  name: "",
  macAddress: "",
  sensorType: "vib-env-1"
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px'
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > *': {
      width: '50%',
      marginRight: '10px',

      '&:last-child': {
        marginRight: '0px',
      }

    }
  }
})

const CreateAndEditMultiSensor = (props) => {

  const { setBreadcrumbs, currentCustomer, hasPermission } = useContext(SiteContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [sensor, setSensor] = useState(emptySensor);

  const [selectedPlantId, setSelectedPlantId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { isLoading: isLoadingCustomer, data: customer = [] } = useQuery(['customers', {customerId: currentCustomer}], getCustomer);
  const { isLoading: isLoadingPlants, data: plants = [] } = useQuery(['plants', { customerId: currentCustomer}], getCustomerPlants, { enabled: !!currentCustomer })
  const { isLoading: isLoadingLines, data: lines = [] } = useQuery(['lines', { plantId: selectedPlantId }], getPlantLines, { enabled: !!selectedPlantId });

  const { isLoading: isLoadingLine, data: line } = useQuery(['line', { lineId: sensor.lineId }], getLine, { enabled: (!!sensor.lineId && isEdit) });

  const { isLoading: isLoadingSensor, data: sensorData } = useQuery(['sensor', { sensorId: sensor.id }], getSensor, { enabled: !!sensor.id })

  const {isLoading: isLoadingPermissions, data: permissions} = useQuery(['permissions', currentCustomer, 'multiSensor', {ids: [sensor.id]}], getEntityPermissions, {
    enabled: !!sensor.id
  });

  const readOnly = isEdit ? !permissions?.multisensor[sensor.id]?.update : false;
  const pageTitle = history.location.pathname === '/create-sensor' ? 'Create Multisensor' : 'Edit Multisensor';

  const { mutate: mutateCreate, isLoading: isCreating } = useMutation(createSensor, {
    onSuccess: (data) => {
      console.log('Create success');
      enqueueSnackbar('Sensor Created', { variant: 'success' });
      queryClient.removeQueries('sensors');
      queryClient.setQueryData(['sensor', { sensorId: data.id }], data);
      history.replace(`/list-sensors`);
    },
    onError: ({ response: { data } }) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    }
  });

  const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation(updateSensor, {
    onSuccess: (data) => {
      console.log('Update success');
      enqueueSnackbar('Sensor Updated', { variant: 'success' });
      queryClient.removeQueries('sensors');
      queryClient.setQueryData(['sensor', { sensorId: data.id }], data);
    },
    onError: ({ response: { data } }) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    }
  });


  useEffect(() => {
    if (sensorData) {
      const {customerId, ...rest} = sensorData
      setSensor(s => ({ ...s, ...rest }));
    }
  }, [sensorData])

  useEffect(() => {
    if (line) {
      setSelectedPlantId(line.plantId);
    }
  }, [line])

  useEffect(() => {
    if(sensor.macAddress){
      setIsMacAddressValid(validateMacAddress(sensor.macAddress))
    }
  }, [sensor.macAddress]);

  const [isMacAddressValid, setIsMacAddressValid] = useState(true);

  useEffect(() => {

    const qs = queryString.parse(props.location.search);

    if (qs.id) {
      setSensor(s => ({ ...s, id: qs.id }));
      document.title = "Edit Multisensor";
      setIsEdit(true);
      setBreadcrumbs([{title: "Edit Multisensor"}]);
    } else {
      document.title = "Create Multisensor";
      setIsEdit(false);
      setSensor(emptySensor);
      setBreadcrumbs([{title: "Create Multisensor"}]);
    }

  }, [props.location.search, setBreadcrumbs]);



  const submit = (e) => {
    e.preventDefault();
    if (isEdit) {
      mutateUpdate(sensor);
    }
    else {
      mutateCreate(sensor);
    }
  };

  const handleUpdate = (update) => {
    setSensor(s => ({ ...s, ...update }));
  }

  const handlePlantSelected = (e) => {
    setSelectedPlantId(e.target.value);
    handleUpdate({lineId: ''});
  }

  const classes = useStyles();

  const isLoading = (isLoadingCustomer || isLoadingLines || isLoadingLine || isLoadingPlants || isLoadingSensor || isUpdating || isCreating || isLoadingPermissions);

  return (
    <div data-testid={isEdit ? "edit-sensor-page" : "create-sensor-page"} className={classes.root}>
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <CreateEditWrapper
          onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-sensors')}
          buttonTitle={isEdit ? "Save" : "Create"}
          pageTitle={isEdit ? "Edit Sensor" : "Create Sensor"}
          submit={submit}
          isLoading={isLoading}
          classes={classes}
          disableSubmit={readOnly}>
            <div className={classes.content}>
              <div className={classes.formRow}>
                <TextField
                  value={sensor.name}
                  onChange={(e) => handleUpdate({ name: e.target.value })}
                  id="name"
                  name="name"
                  label="Name"
                  autoComplete='disabled'
                  autoFocus
                  required
                  disabled={isLoading}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  className={classes.formControl} />

                <TextField
                  value={sensor.macAddress}
                  onChange={(e) => handleUpdate({ macAddress: e.target.value })}
                  error={!isMacAddressValid}
                  helperText="Should be in the form of XXXXXXXXXXXX"
                  required
                  id="macAddress"
                  name="macAddress"
                  label="Mac Address"
                  autoComplete='disabled'
                  disabled={isLoading}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  className={classes.formControl}/>
              </div>
              <SSTDropdown
                classes={classes}
                disabled={isLoading}
                readOnly={true}
                required={true}
                isLoadingContents={isLoadingCustomer}
                label={"Customer"}
                selectedValueId={currentCustomer}
                mappedList={[customer].map((customer) => <MenuItem key={customer.id}
                  value={customer.id}>{customer.name}</MenuItem>)} />

              <SSTDropdown
                classes={classes}
                disabled={isLoading}
                readOnly={readOnly}
                required={false}
                isLoadingContents={isLoadingPlants}
                label={"Plant"}
                selectedValueId={selectedPlantId}
                setValueFunc={handlePlantSelected}
                mappedList={plants.map((plant) => <MenuItem key={plant.name}
                  value={plant.id}>{plant.name}</MenuItem>)} />

              <SSTDropdown
                classes={classes}
                disabled={isLoading}
                readOnly={readOnly}
                isLoadingContents={isLoadingLines}
                label={"Line"}
                selectedValueId={sensor.lineId}
                required
                setValueFunc={(e) => handleUpdate({ lineId: e.target.value })}
                mappedList={lines.map((line) => <MenuItem key={line.name}
                  value={line.id}>{line.name}</MenuItem>)} />
            </div>
        </CreateEditWrapper>
      }
    </div>);
};
export default CreateAndEditMultiSensor;
