import React from 'react'
import { makeStyles } from '@mui/styles';
import {ReactComponent as MinusIcon} from '../../../img/icons/minus.svg'
import {ReactComponent as PlusIcon} from '../../../img/icons/plus.svg'
import { Typography } from '@mui/material'
import * as GreyScale from '@Theme/GreyScale.js'
import QuantifeelSvgIcon from '../QuantifeelSvgIcon/QuantifeelSvgIcon'

const BASE_10 = 10;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    cursor: 'pointer',
    fontSize: 32,
  },
  numberContainer: {
    minWidth: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    backgroundColor: GreyScale.GREY_2,
    padding: `0 ${theme.spacing(1)}`,
    margin: `0 ${theme.spacing(2)}`,

    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  numberLabel: {
    fontWeight: 500,
    lineHeight: '24px',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}))

const NumberAdjuster = (props) => {

  const {
    value,
    valueLabel, // valueLabel + unitLabel will be used in numberLabel element, if valueLabel supplied. Otherwise, value + unitLabel will be used...
    minValue,
    maxValue,
    stepValue = 1,
    unitLabel,
    onIncrement,
    onDecrement,
    disabled = false,
  } = props;

  const handleOnDecrement = () => {

    // Decrement...
    const numberValue = parseInt(value, BASE_10); // parseInt(), in case of value as string...
    const updatedValue = numberValue - stepValue;

    // Callback...
    onDecrement(updatedValue);
  }

  const handleOnIncrement = () => {

    // Increment...
    const numberValue = parseInt(value, BASE_10); // parseInt(), in case of value as string...
    const updatedValue = numberValue + stepValue;

    // Callback...
    onIncrement(updatedValue);
  }

  const classes = useStyles();

  /**
   * Returns label to be used as numberLabel, in numberContainer.
   * valueLabel + unitLabel will be used in numberLabel element, if valueLabel supplied. Otherwise, value + unitLabel will be used...
   */
  const getNumberContainerLabel = () => {
    let label = valueLabel ? `${valueLabel}` :`${value}`;
    label = unitLabel ? `${label} ${unitLabel}` : label;
    return label;
  }

  return (
    <div className={classes.root}>

      <QuantifeelSvgIcon
        component={MinusIcon}
        className={classes.icon}
        viewBox={'0 0 32 33'}
        onClick={handleOnDecrement}
        disabled={disabled || (value <= minValue)}
      />

      <div className={classes.numberContainer}>
        <Typography variant="h6" className={classes.numberLabel}>{getNumberContainerLabel()}</Typography>
      </div>

      <QuantifeelSvgIcon
        component={PlusIcon}
        className={classes.icon}
        viewBox={'0 0 32 33'}
        onClick={handleOnIncrement}
        disabled={disabled || (value >= maxValue)}
      />

    </div>
  )
}

export default NumberAdjuster