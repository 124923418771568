import React, {useContext, useEffect, useState} from "react";
import {useMutation} from 'react-query';
import {SiteContext} from "../../Context";
import {Card, CardContent, CardHeader, CircularProgress, MenuItem, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {updateUserDefaultCustomer} from '../../query/queries';

const useStyles = makeStyles(theme => ({
  layout: {
      display: 'flex',
      minWidth: '500px',
      flexFlow: 'column wrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
  },
  paper: {
      display: 'flex',
      position: 'relative',
      minWidth: '500px',
      flexFlow: 'column wrap',
      alignItems: 'start',
      padding: 15
  },
  title: {
      padding: '0',
      margin: '0',
      color: theme.palette.text.primary,
      fontWeight: '700',
  },
  label: {
      color: theme.palette.text.primary,
      ...theme.typography.h6,
      marginTop: theme.spacing(2),
  },
  content: {
      color: theme.palette.text.secondary,
      ...theme.typography.subtitle1,
  },
  formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
  },
  loading: {
    position: 'absolute',
    top: 10,
    right: 10
  }
}));

const ProfilePage = (props) => {

    const {userDetails, userCustomers, setUserDefaultCustomer} = useContext(SiteContext);
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        document.title = "Profile";
    }, []);

    const {isLoading: isUpdating, mutate: mutateUpdate} = useMutation(updateUserDefaultCustomer, {
      onSuccess: (data) => {
        setUserDefaultCustomer(data.customerId);
        setErrorMsg('')
      },
      onError: (err) => {
        setErrorMsg('Update Failed. Please ensure that you have an account on this customer')
      }
    });

    const handleUpdate = ({target: {value: customerId}}) => {
      mutateUpdate({userId: userDetails.user.id, defaultCustomerId: customerId})
    }


    const classes = useStyles();
    return (
        <div className={classes.layout} data-testid="user-settings-page">
            <Card className={classes.paper}>
                {isUpdating && <CircularProgress className={classes.loading} size={25}/>}
                <CardHeader className={classes.title}
                            title={"Profile"}
                            titleTypographyProps={{variant: 'h2'}}/>
                <CardContent>
                    <Typography className={classes.label}>Name</Typography>
                    <Typography
                        className={classes.content}>{userDetails.user.firstName + " " + userDetails.user.lastName}</Typography>

                    <Typography className={classes.label}>Email</Typography>
                    <Typography className={classes.content}> {userDetails.user.emailAddress}</Typography>

                    <Typography className={classes.label}>Primary Customer</Typography>
                    <TextField
                      select
                      value={userDetails.user.customerId}
                      onChange={handleUpdate}
                      disabled={isUpdating}
                      error={!!errorMsg}
                      helperText={errorMsg}
                      InputProps={{
                        readOnly: userCustomers.length < 2
                      }}>
                        {userCustomers.map(c => <MenuItem value={c.id}>{c.name}</MenuItem>)}
                    </TextField>

                    <Typography className={classes.label}>Timezone</Typography>
                    <Typography
                        className={classes.content}>{userDetails.user.tz}</Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default ProfilePage;
