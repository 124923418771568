import {
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '@Shared/Utilities'
import React, { useContext, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, withRouter } from 'react-router-dom'
import { SST_SSO_LOGIN } from '~/Constants'
import { SiteContext } from '~/Context'

const SsoLogin = (props) => {
  const pageTitle = 'Sso Login'

  const queryClient = useQueryClient()
  const history = useHistory()
  const { logout, hasPermission } = useContext(SiteContext)
  const acceptablePagePermission = [
    { entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'Platform' },
  ]

  /**
   * Check if `code` exists in the queryString.
   * * If yes, try logging in.
   * * else, continue normal logging process
   *  **/
  useEffect(() => {
    if (
      !handlePermissionRedirect(
        pageTitle,
        history,
        hasPermission,
        acceptablePagePermission
      )
    ) {
      return
    }

    logout()
    window.location = `${SST_SSO_LOGIN}`
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Need to reset queries on login page load. Doing this during logout
    // caused the customers query to be refined, resulting in QF-1645
    queryClient.removeQueries()
  }, [queryClient])

  return <div data-testid="sso-page" />
}

export default withRouter(SsoLogin)
