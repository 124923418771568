import React from "react";
import { makeStyles } from '@mui/styles';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';

const useStyles = makeStyles({
  color: {
    color : 'white',
    backgroundColor: props => props.severity.color || 'purple',
  }
});


const DiagnosisSeverityIcon = (props) => {

  const classes = useStyles(props);

  const attributes = {
    className: `${props.className} ${classes.color}`,
    onClick: props.onClick,
    titleAccess: props.severity.description
  }

  const renderSwitch = (name) => {
    switch(name.toLowerCase()) {
      case 'green':
        return <ThumbUpIcon {...attributes}/>
      case 'yellow':
        return <SearchIcon {...attributes}/>
      case 'red':
        return <BuildIcon {...attributes}/> 
      case 'unknown':
        return <AssignmentIcon {...attributes}/>
      case 'monitoring':
        return <AssignmentIcon {...attributes}/>
      default:
        return null
    }
  }

  return renderSwitch(props.severity.name);
}

export default DiagnosisSeverityIcon