import jwt_decode from 'jwt-decode'
import {LOCAL_STORAGE_KEY_LOGIN_DETAILS} from '../Constants'

function msAjaxDateConverter(msAjaxFormedTimestamp) {
  var reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/ //Ms Ajax time format

  var a = reMsAjax.exec(msAjaxFormedTimestamp)

  if (a) {
    var b = a[1].split(/[-+,.]/)
    return new Date(b[0] ? +b[0] : 0 - +b[1])
  }
}

const msAjaxTimestampListConverter = (arrayOfTimestamps) => {
  var newList = []

  for (let i = 0; i < arrayOfTimestamps.length; i++) {
    newList.push(msAjaxDateConverter(arrayOfTimestamps[i]))
  }

  return newList
}

/**
 * Scales a number from an input range into an output range
 * @param num
 * @param in_min
 * @param in_max
 * @param out_min
 * @param out_max
 * @returns {*}
 */
const scale = (num, in_min, in_max, out_min, out_max) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

const getAssetNameFromProperty = (propertyName) => {
  var positionOfPeriod = propertyName.indexOf('.')

  if (positionOfPeriod === -1) {
    return ''
  }

  return propertyName.substring(0, positionOfPeriod)
}

const redirectToLoginIfUnauthorized = (props, response) => {
  if (response.status === 401) {
    //also
    this.props.history.push('/login')
  }
}

/**
 * Checks the current users permissions and based off those, the user is either redirected
 * to the dashboard if they do not have access to that page or is allowed to view that page.
 * @param pageName the name of the page that the user is trying to access
 * @param history the object returned by useHistory
 * @param hasPermission the Context.js function
 * @param acceptablePagePermission an array of acceptable permission objects
 * @returns {bool} Return bool if the user has access to the desired page
 */
const handlePermissionRedirect = (pageName, history, hasPermission, acceptablePagePermission) => {
    let isAllowed = false;
    acceptablePagePermission?.forEach((pagePermission) => {
        if(hasPermission(pagePermission.entity, pagePermission.method, pagePermission.modifier)){
            isAllowed = true;
        }
    })
    if(!isAllowed){
        history.push({pathname: '/dashboard', state: { previousAccessedPage: pageName }});
    }
    return isAllowed;
}

const isLoggedIn = (userDetails) => {
  if (userDetails) {
    if (userDetails.token) {
      return true
    }
  }
  return false
}

/**
 *
 * @param userDetails
 * @returns {boolean} True if there is a token and it is expired, or if there is no token. False otherwise.
 */
const isExpiredToken = (userDetails) => {
  if (userDetails) {
    if (userDetails.token) {
      const expireDate = new Date(jwt_decode(userDetails.token).exp * 1000)
      return expireDate.getTime() - new Date().getTime() < 5 * 60 * 1000
    }
  }

  return true
}

const isAdministrator = (userDetails) => {
  if (userDetails) {
    return userDetails.user.isAdministrator
  }
  return false
}

/**
 * If we are not logged in, redirect to the login page.
 * @param props
 * @param siteContext
 */
const ensureLoggedIn = (props, siteContext) => {
  if (
    !isLoggedIn(siteContext.userDetails) ||
    isExpiredToken(siteContext.userDetails)
  ) {
    if (siteContext) {
      siteContext.userDetails.token = ''
    }

    localStorage.removeItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS)

    if (props || typeof props.history !== 'undefined') {
      props.history.push('/login')
    }
    return false
  }
  return true
}

const adminEnsureLoggedIn = (props, siteContext) => {
  if (!isAdministrator(siteContext.userDetails)) {
    if (props) {
      props.history.push('/dashboard')
    }
    return false
  }
  return ensureLoggedIn(props, siteContext)
}

/***
 * Convert a boolean value to a string for humans.
 * @param bool
 * @returns {string} "True" if bool evaluates to true, "False" otherwise.
 */
const boolToYesOrNo = (bool) => {
  if (bool) {
    return 'Yes'
  } else {
    return 'No'
  }
}

/***
 * Return the largest value in an array
 * @param data array of numbers
 * @returns {number} largest number in array
 */
const largestValue = (data) => {
  let largest = 0
  data.forEach((yData) => {
    if (yData > largest) {
      largest = yData
    }
  })
  return largest
}

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const truncateNumberToDecimals = (number, decimalPlaces) => {

  if (decimalPlaces < 0) {
    return -1;
  }

  const multiplier = Math.pow(10, decimalPlaces);

  return Math.trunc(number * multiplier) / multiplier;
}

/**
 * Deeply compare two objects for equality.
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
const deepEqual = (obj1, obj2) => {

  // Check if both objects are the same type...
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Check if both objects are null or undefined...
  if (obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Check if both objects are non-object types or have a primitive value...
  if (typeof obj1 !== 'object') {
    return obj1 === obj2;
  }

  // Check if both objects have the same set of keys...
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length || !keys1.every(key => keys2.includes(key))) {
    return false;
  }

  // Recursively compare each property...
  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm:ss'
const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT
const DATE_TIME_TIMEZONE_FORMAT = DATE_TIME_FORMAT + ' z'
const DISPLAY_TYPE_ACCORDION = 'accordion'
const DISPLAY_TYPE_GRID = 'grid'
const PERMISSION_METHOD_UPDATE = 'Update'
const PERMISSION_METHOD_INSERT = 'Insert'
const PERMISSION_METHOD_GET = 'Get'
const PERMISSION_MODIFIERS = ['', 'Platform', 'children']

export {
    msAjaxTimestampListConverter,
    getAssetNameFromProperty,
    redirectToLoginIfUnauthorized,
    handlePermissionRedirect,
    isLoggedIn,
    isExpiredToken,
    isAdministrator,
    ensureLoggedIn,
    adminEnsureLoggedIn,
    boolToYesOrNo,
    msAjaxDateConverter,
    scale,
    largestValue,
    DATE_FORMAT,
    TIME_FORMAT,
    DATE_TIME_FORMAT,
    DATE_TIME_TIMEZONE_FORMAT,
    DISPLAY_TYPE_ACCORDION,
    DISPLAY_TYPE_GRID,
    formatBytes,
    PERMISSION_METHOD_INSERT,
    PERMISSION_METHOD_UPDATE,
    PERMISSION_METHOD_GET,
    PERMISSION_MODIFIERS,
    sleep,
    truncateNumberToDecimals,
    deepEqual,
} ;
