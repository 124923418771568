import {axios} from '../client';


export const getEntityPermissions = ({queryKey}) => {
  const [,customerId, entity, {ids}] = queryKey;
  
  const body = {
    entity,
    ids
  }
  
  return axios.post(`customers/${customerId}/authorization`, body).then(res => {    
    return {
      insert: res.data.parents.reduce((m, v) => {
        return m || v.insert
      }),
      parents: res.data.parents,
      [entity.toLowerCase()]: res.data.children.reduce((m, v) => {
        m[v.id] = v;
        return m
      }, {})
    }    
  });  
  

}




// Request:
// POST /authorization
// {
//   scopeEntity: Plants,
//   scopeId:9b3d29c7-b1b0-4975-b6ba-731ed6b3dbc1,
//   entity: Lines,
//   ids: [3c02737c-8721-4e6d-a47c-4dd2af687728, bf49249e-4581-4f42-a07f-f52ecf0c39c4]
// }

// Response:
// {
//   "insert": true, // Debatable how we return this part
//   "lines": { // Debatable how we want to key this for consistency
//     3c02737c-8721-4e6d-a47c-4dd2af687728: {get: true, update: false, delete: false},
//     bf49249e-4581-4f42-a07f-f52ecf0c39c4: {get: true, update: true, delete: false}
//   },
// }