import React from "react";
import { makeStyles } from '@mui/styles'
import { Link } from '@mui/material'

const QuantifeelLink = (props) => {

    const { className, children, ...rest } = props;

    const useStyles = makeStyles(theme => ({
        link: {
            color: theme.palette.hyperlinkBlue,
            textDecoration: 'none',
            cursor: 'pointer'
        }
    }));

    const classes = useStyles();

    return (
      <Link className={`${classes.link} ${className}`} {...rest}>
          {children}
      </Link>
    );
};

export default QuantifeelLink;