import React, {useState, useEffect} from "react";
import { styled } from '@mui/material/styles';
import {Menu, List, ListItem, ListItemText, ListSubheader, Checkbox, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageFromServer from '../ImageFromServer/ImageFromServer';
import {
  BASE_SST_API_URL,
  SST_API_PATH_RUNS,
  SST_API_PATH_RUNS_WIDGET
} from '../../../Constants';

const StyledContainer = styled('div')({
  position: 'relative',
  'margin-left': '10px',
  flex:'none 1 fit-content',
  display: 'inline-block',
  width: 'auto',
  'min-width': '450px'
})

const StyledMoreVertIcon = styled(MoreVertIcon)({
  position: 'absolute',
  top: '5px',
  right: '5px'
})

const widgetURL = (runid) => {
  return `${BASE_SST_API_URL}${SST_API_PATH_RUNS}/${runid}/${SST_API_PATH_RUNS_WIDGET}`;
}

const ReportingWidget = (props) => {

    const [params, setParams] = useState(props.inputs.reduce((memo, input) => {
      memo[input.key] = input.default;
      return memo;
    }, {type: props.type, renderingpause: '5'}));
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [imgUrl, setImgUrl] = useState(widgetURL(props.run));
    
    const openMenu = (event) => {
      setMenuAnchor(event.currentTarget)
    }

    const closeMenu = () => {
      setMenuAnchor(null)
    }

    useEffect( () => {
      setImgUrl(widgetURL(props.run));
    }, [props.run, props.inputs, props.type])
    

    const updateParams = (key, value) => {
      if(params[key] !== value){
        setParams({...params, [key]:value});
      }
    }

    return (
      <StyledContainer>
        <StyledMoreVertIcon onClick={openMenu}>Menu</StyledMoreVertIcon>
        <Menu
          id="customized-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={closeMenu}
        >
          {props.inputs.map(input => {
            return (
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {input.key}
                  </ListSubheader>
                }>
                {input.options.map(option => {
                  return (
                    <ListItem dense onClick={() => updateParams(input.key, option)}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={params[input.key] === option}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={option}/>
                    </ListItem>
                  )
                })}
              </List>              
            );
          })}
        </Menu>
        <ImageFromServer fetchUrl={imgUrl} fetchParams={params} />
      </StyledContainer>
                
    );
};

export default ReportingWidget