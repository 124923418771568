import React, {useContext, useEffect} from 'react';
import {SiteContext} from '../../Context';
import {Link, useHistory} from 'react-router-dom';
import {DATE_TIME_TIMEZONE_FORMAT, DATE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET} from '../../shared/Utilities';
import {useQuery} from 'react-query';
import {getAllCustomers, getDrones} from '../../query/queries'

import ProtectedMoment from "../../shared/components/ProtectedMoment/ProtectedMoment";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import '../PageStyles/TablePage.css'

const pageTitle = 'List Drones';
const acceptablePagePermission = [
  {entity: 'Drone', method: PERMISSION_METHOD_GET, modifier: ''}
]

const columns = [
  {id: "name", label: "Name", width: '180px'},
  {id: "latestDateUsed", label: "Date Last Used", width: '210px'},
  {id: "lastCalibrationDate", label: "Date Last Calibrated", width: '175px'},
  {id: "customer", label: "Latest Customer", width: '325px'},
  {id: "actions", label: "Actions", width: '130px'}
];

const genRows = (drones, customers, details) => {
  if (customers.length === 0){
    return [];
  }

  const customerMap = customers.reduce((memo, customer) => {
    memo[customer.id] = customer.name;
    return memo;
  }, {});

  return drones.map(drone => ({
    name: drone.name,
    latestDateUsed: <ProtectedMoment date={drone.lastUsed} format={DATE_TIME_TIMEZONE_FORMAT} rawval={drone.lastUsed} tz={details.user.tz}/>,
    lastCalibrationDate: <ProtectedMoment date={drone.lastCalibrationDate} format={DATE_FORMAT} rawval={drone.lastCalibrationDate}/>,
    customer: customerMap[drone.latestCustomerId],
    actions: <Link to={`/view-drone/${drone.id}`}>View Drone</Link>
  }))
}

const ListAllDrones = () => {
  const history = useHistory();
  const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext);
  const {isLoading: isLoadingDrones, data:drones=[]} = useQuery(['drones'], getDrones);
  const {isLoading: isLoadingCustomers, data:customers=[]} = useQuery(['customers'], getAllCustomers);

  useEffect(() => {
    document.title = pageTitle;
    setBreadcrumbs([{ title: pageTitle }]);
  }, [setBreadcrumbs]);

  return (
    <div className="page" data-testid="list-drones-page">
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <div className="container">
          <EnhancedTable
              order={'asc'}
              orderBy={'Name'}
              title="Drones"
              rows={genRows(drones, customers, userDetails)}
              headCells={columns}
              enableFilter={true}
              filterColumns={['name', 'customer']}
              isLoading={isLoadingDrones || isLoadingCustomers}/>
        </div>
      }
    </div>
  );
}

export default ListAllDrones;