import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useQuery} from 'react-query';
import {getLine, getLineAreas, getLineRuns} from '../../query/queries'
import {Link, useHistory} from 'react-router-dom';
import {SiteContext} from "../../Context";
import {DATE_TIME_FORMAT, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from "../../shared/Utilities";
import queryString from "query-string";
import ProtectedMoment from "../../shared/components/ProtectedMoment/ProtectedMoment";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import '../PageStyles/TablePage.css'
import { handlePermissionRedirect } from "../../shared/Utilities";
import PageHeader from '../../shared/components/PageHeader/PageHeader'
import translations from '../../translations/en.json'
import { makeStyles } from '@mui/styles'
import { SST_PAGE_LIST_LINES } from '../../Constants'
import FullScreenCircularProgress from '../../shared/components/FullScreenCircularProgress'

const pageTitle = 'List Runs';
const acceptablePagePermission = [
    {entity: 'Run', method: PERMISSION_METHOD_UPDATE, modifier: ''},
    {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
  ]

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
})

const ListRuns = (props) => {

    const history = useHistory();
    const {setBreadcrumbs, hasPermission} = useContext(SiteContext);

    const [isLoading, setIsLoading] = useState(true)
    const [columns] = useState([
        {id: 'id', label: 'Id', width: "130px"},
        {id: 'name', label: 'Name', width: "390px"},
        {id: 'type', label: 'Run Type', width: "200px"},
        {id: 'area', label: 'Area', width: "300px"},
        {id: 'dateRecorded', label: 'Date Recorded', width: "175px"},
        {id: 'duration', label: 'Duration', width: "130px"},
        {id: 'actions', label: 'Actions', width: "110px"}]);
    const [lineId, setLineId] = useState("")

    const lineQueryKey = ['line', {lineId: lineId}, {includePlantName: false, includeNumberOfMultiSensors:false}];
    const {isLoading: isLoadingLine, data: line={}} = useQuery(lineQueryKey, getLine, {enabled: !!lineId});
    const {isLoading: isLoadingRuns, data: runs=[]} = useQuery(['runs', {lineId: lineId}], getLineRuns, {enabled: !!lineId});
    const {isLoading: isLoadingAreas, data: areas=[]} = useQuery(['areas', {lineId: lineId}], getLineAreas, {enabled: !!lineId});

    useEffect(() => {
        if (isLoadingLine) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [isLoadingLine])

    useEffect(() => {
        document.title = pageTitle;
        const qs = queryString.parse(props.location.search);
        setLineId(qs.lineId);
        setBreadcrumbs([
            {
                title: pageTitle,
            }
        ]);
    }, [setBreadcrumbs, props.location.search]);

    const rows = runs.map(run => {
        const area = areas.find((x) => x.id === run.areaId);
        return {
            id: run.virtualRunId,
            name: <CopyLabel value={run.id} rawval={run.name.toLowerCase()}>{run.name}</CopyLabel>,
            type: run.runType ? 'Seamer' : 'Standard',
            area: area ? area.name : "",
            dateRecorded: <ProtectedMoment date={run.dateRecorded} format={DATE_TIME_FORMAT + ' z'} rawval={run.dateRecorded}/>,
            duration: <ProtectedMoment date={run.dateRecorded} add={{seconds: run.duration}} duration={run.dateRecorded} rawval={run.duration}/>,
            actions: <Link to={"/view-run?id=" + run.id}>Run Details</Link>
        }
    })

    const onBack = () => {
        // If there is a page in history, go back, else, go to /list-lines...
        return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_LINES}`)
    }

    // -------------------
    // -- BEGIN renders --
    // -------------------

    const classes = useStyles();

    const renderHeader = () => {
        return (
          <PageHeader
            onBack={onBack}
            pageTitle={`${translations.pages.listRuns.runsFor} ${line.name}`}
          />
        )
    }

    return (
      <div className={classes.root}>

        {isLoading && <FullScreenCircularProgress/>}

        {renderHeader()}

        <div className="page" data-testid="list-runs-page">
            {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
                <div className="container">
                    <EnhancedTable
                        isLoading={isLoadingRuns || isLoadingAreas || isLoadingLine}
                        order={'desc'}
                        orderBy={'id'}
                        rows={rows}
                        headCells={columns}
                        enableFilter={true}
                        filterColumns={['name', 'type', 'area']}
                    />
                </div>
            }
        </div>

      </div>
    );
};

export default ListRuns;
