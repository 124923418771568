import React from 'react'
import { SvgIcon, Typography } from '@mui/material'
import useStyles from '../LineRunStatistic/Styles'

const LineRunStatistic = (props) => {

  const {
    icon,
    data, // label, labelColor, value...
    selected = true,
    onClick,
  } = props;

  const classes = useStyles()

  return (
     <div className={classes.statContainer}>
       <SvgIcon
         component={icon}
         viewBox={'0 0 35 42'}
         className={`${classes.statIcon} ${selected ? '' : classes.statIconUnselected}`}
         onClick={onClick}
       />
        <div className={classes.statLabelsContainer}>
          <div className={classes.statLabelPairsContainer}>
            {
              (data != null && data.length > 0) && (

                data.map((item, index) => {
                  return (
                    <div className={classes.statLabelPairContainer} key={index}>
                      <Typography className={`${classes.statLabel} ${selected ? '' : classes.statLabelUnselected}`} style={{ color: item.labelColor }}>
                        { item.label }
                      </Typography>
                      <Typography className={classes.statValue}>
                        { item.value || item.value === 0 ? Number(item.value).toFixed(2) : '-'}
                      </Typography>
                    </div>
                  )
                })

              )
            }
          </div>
        </div>
      </div>
  )
}

export default LineRunStatistic