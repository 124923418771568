import CheckIcon from '@mui/icons-material/Check'
import moment from 'moment'
import ProtectedMoment from '../shared/components/ProtectedMoment/ProtectedMoment'
import {
  G,
  getDisplacement,
  getVelocity,
  inchesToMils,
  INCHES_PER_SECOND,
  meterToInches,
  meterToMicrons,
  meterToMillimeter,
  MICRONS,
  MILLIMETER_PER_SECOND,
  MILS,
} from '../shared/MultiSensorGraphsUtilities'
import { DATE_TIME_TIMEZONE_FORMAT } from '../shared/Utilities'

// Constants for the dropdowns + extra units where appropriate
const MINUTE = { label: 'Minute', numberOfDays: 1 / 1440 }
const HOUR = { label: 'Hour', numberOfDays: 1 / 24, numberOfMinutes: 60 }
const DAY = { label: 'Day', numberOfDays: 1 }
const WEEK = { label: 'Week', numberOfDays: 7 }
const MONTH = { label: 'Month', numberOfDays: 30 }
const YEAR = { label: 'Year', numberOfDays: 365 }
const DECADE = { label: 'Decade', numberOfDays: 3650 }
const METRIC = 'Metric'
const IMPERIAL = 'Imperial'
const ACTIVE = 'Active'
const INACTIVE = 'Inactive'

const ACCELERATION = 'Acceleration'
const VELOCITY = 'Velocity'
const DISPLACEMENT = 'Displacement'
const ERROR_VALUES_THRESHOLD = 20
const QUICKSIGHT_CHART_DATE_FORMAT = 'DD/MM/YYYY h:mm:ss a'

const ALL = 'All'
const DO_NOT_SHOW_ERROR_VALUES = 'Do not Show Error Values'
const SHOW_ERROR_VALUES = 'Show Error Values'
const STARTDATE_LABEL = 'Start Date'
const ENDDATE_LABEL = 'End Date'

const MULTISENSOR = 'Multisensor'
const AIR_PARAMETERS = 'Air Parameters'
const ATMOSPHERIC_PARAMETERS = 'Atmospheric Parameters'
const AIR_CHIP_TEMPERATURE = 'Air & Chip Temperature'
const BAND_SEVEN_VIBRATION_AMPLITUDE = 'Band 7 Vibration Amplitude'
const X_AXIS_BAND_ONE_TO_SEVEN = 'X Axis FFT Band 1-7'
const Y_AXIS_BAND_ONE_TO_SEVEN = 'Y Axis FFT Band 1-7'
const Z_AXIS_BAND_ONE_TO_SEVEN = 'Z Axis FFT Band 1-7'
const MULTISENSORID_OBJECT_PROPERTY = 'multiSensorId'
const DATERECORDED_OBJECT_PROPERTY = 'dateRecorded'

const CELSIUS = { label: 'Celsius', unit: 'C' }
const FAHRENHEIT = { label: 'Fahrenheit', unit: 'F' }
const K = 'K'
const PERCENT_RH = '%rh'

const READING = 'reading'
const VIBRATION = 'vibration'

/**
 * The following arrays are the values used in the dropdowns on the Quicksight pages.
 */
const unitValues = [
  { id: 1, value: METRIC },
  { id: 2, value: IMPERIAL },
]

const errorValues = [
  { id: 1, value: DO_NOT_SHOW_ERROR_VALUES },
  { id: 2, value: SHOW_ERROR_VALUES },
]

// Set of values determined by the data team.
const multiSensorFilterValues = [
  { id: 1, value: ALL },
  { id: 2, value: 'Drive' },
  { id: 3, value: 'Seamer' },
  { id: 4, value: 'Filler' },
  { id: 5, value: 'Shaft' },
  { id: 6, value: 'Starwheel' },
  { id: 7, value: '120L' },
  { id: 8, value: '121L' },
  { id: 9, value: '180L' },
  { id: 10, value: 'Compressor' },
  { id: 11, value: 'Gearbox' },
  { id: 12, value: 'Motor' },
  { id: 13, value: 'Pump' },
  { id: 14, value: 'Rail' },
  { id: 15, value: 'Syrop' },
  { id: 16, value: 'Transfer' },
  { id: 17, value: 'Turret' },
  { id: 18, value: 'Worm' },
]

const amplitudeValues = [
  { id: 1, value: ACCELERATION },
  { id: 2, value: DISPLACEMENT },
  { id: 3, value: VELOCITY },
]

const aggregationMethodValues = [
  { id: 1, value: 'Average' },
  { id: 2, value: 'Maximum' },
  { id: 3, value: 'Minimum' },
]

const aggregatedByValues = [
  { id: 1, value: MINUTE.label },
  { id: 2, value: HOUR.label },
  { id: 3, value: DAY.label },
  { id: 4, value: WEEK.label },
  { id: 5, value: MONTH.label },
  { id: 6, value: YEAR.label },
]

const activeInactiveValues = [
  { id: 1, value: ALL },
  { id: 2, value: ACTIVE },
  { id: 3, value: INACTIVE },
]

const airParameterValues = [
  { id: 1, value: 'Air Temperature' },
  { id: 2, value: 'Chip Temperature' },
  { id: 3, value: 'Air Humidity' },
  { id: 4, value: 'Air Pressure' },
  { id: 5, value: 'Co2' },
  { id: 6, value: 'Indoor Air Quality' },
  { id: 7, value: 'Light Intensity' },
  { id: 8, value: 'TVOC' },
]

const fftBandValues = [
  { id: 1, value: 'None' },
  { id: 2, value: '1' },
  { id: 3, value: '2' },
  { id: 4, value: '3' },
  { id: 5, value: '4' },
  { id: 6, value: '5' },
  { id: 7, value: '6' },
  { id: 8, value: '7' },
]

/**
 * Changes the unit based off the value of the amplitude (Velocity/Displacement/Acceleration)
 */
const handleUnitChange = (amplitude, isMetric, setChartUnit) => {
  switch (amplitude) {
    case VELOCITY:
      setChartUnit(isMetric ? MILLIMETER_PER_SECOND : INCHES_PER_SECOND)
      break
    case DISPLACEMENT:
      setChartUnit(isMetric ? MICRONS : MILS)
      break
    case ACCELERATION:
      setChartUnit(G)
      break
    default:
  }
}

/**
 * Changes the unit based off the value of the unit category (Metric/Imperial)
 */
const handleUnitCategoryChange = (val, setUnitCategory, setIsMetric) => {
  if (val === METRIC) {
    //Switch to Metric
    setUnitCategory(val)
    setIsMetric((metric) => !metric)
  } else {
    //Switch to Imperial
    setUnitCategory(val)
    setIsMetric((metric) => !metric)
  }
}

/**
 * Generates the row objects for the Quicksight tables
 */
const generateRows = (rows, timezone) => {
  return rows?.map((row) => {
    return {
      id: row.id,
      multisensor: row.name,
      active: moment(
        !!row.dateLastSeenRecord ? row.dateLastSeenRecord : row.dateLastUpdated
      ).isAfter(moment().subtract(7, DAY.label)) ? (
        <CheckIcon />
      ) : (
        ''
      ),
      lineName: row.lineName,
      plantName: row.plantName,
      customer: row.customerName,
      lastReading: (
        <ProtectedMoment
          date={
            !!row.dateLastSeenRecord
              ? row.dateLastSeenRecord
              : row.dateLastUpdated
          }
          format={DATE_TIME_TIMEZONE_FORMAT}
          tz={timezone}
        />
      ),
    }
  })
}

const allDefaults = { id: 0, name: ALL }

/**
 * Converts the chart data into its appropriate unit based off the current selected unit.
 */
const convertQuicksightChartData = (data, chartUnit, setFFTData) => {
  for (let index = 0; index < data.length; index++) {
    let convertedSubArray = []
    data[index].forEach((point) => {
      if (!!point) {
        if (chartUnit === INCHES_PER_SECOND) {
          convertedSubArray.push(getVelocity(point, 1) * meterToInches)
        } else if (chartUnit === MILLIMETER_PER_SECOND) {
          convertedSubArray.push(getVelocity(point, 1) * meterToMillimeter)
        } else if (chartUnit === MILS) {
          convertedSubArray.push(
            getDisplacement(point, 1) * meterToInches * inchesToMils
          )
        } else if (chartUnit === MICRONS) {
          convertedSubArray.push(getDisplacement(point, 1) * meterToMicrons)
        }
      } else {
        convertedSubArray.push(null)
      }
    })
    setFFTData((prev) => [...prev, convertedSubArray])
  }
}

/**
 * Converts the temperature chart data into its appropriate unit based off the current selected unit.
 */
const convertQuicksightTemperatureData = (data, setTempData) => {
  for (let index = 0; index < data.length; index++) {
    let convertedSubArray = []
    if (!!data[index].length) {
      data[index]?.forEach((point) => {
        convertedSubArray.push(convertCelsiusToFahrenheit(point))
      })
      setTempData((prev) => [...prev, convertedSubArray])
    }
  }
}

/**
 * Converts celsius into fahrenheit
 */
const convertCelsiusToFahrenheit = (data) => {
  if (!!data) {
    return (data * (9 / 5) + 32).toFixed(1)
  }
  return null
}

/**
 * Return true if date values pass basic validation. Otherwise, return false
 */
const validDateValues = (startDate, endDate) => {
  if (moment(startDate).isValid && moment(endDate).isValid) {
    let start = moment(startDate)
    let end = moment(endDate)
    let daysDifference = end.diff(start, DAY.label)

    if (
      moment(daysDifference).isValid() &&
      end.isAfter(start) &&
      daysDifference < DECADE.numberOfDays &&
      end.diff(start, MINUTE.label) >= HOUR.numberOfMinutes
    ) {
      return true
    }
  }
  return false
}

/**
 * Return true if Quicksight page conditions
 */
const validRefetchCondition = (
  startDate,
  endDate,
  selectedTableRow,
  aggregatedBy
) => {
  return (
    aggregatedBy !== 'ERROR' &&
    validDateValues(startDate, endDate) &&
    !!selectedTableRow?.id
  )
}

/**
 * Determines the Aggregated By Values based off the current start and end dates.
 */
const determineAggregatedByValues = (
  startDate,
  endDate,
  setAggregatedBySelections,
  setAggregatedBy,
  currentAggregatedBySelections,
  currentAggregatedByValue
) => {
  let start = moment(startDate)
  let end = moment(endDate)
  let difference = end.diff(start, DAY.label)
  let aggregatedSelectValues = []
  if (!validDateValues(startDate, endDate)) {
    aggregatedSelectValues = [{ id: 0, value: 'ERROR' }]
    setAggregatedBy('ERROR')
  } else if (
    difference >= YEAR.numberOfDays &&
    difference < DECADE.numberOfDays
  ) {
    aggregatedSelectValues = [
      aggregatedByValues[3],
      aggregatedByValues[4],
      aggregatedByValues[5],
    ]
  } else if (
    difference >= MONTH.numberOfDays &&
    difference < YEAR.numberOfDays
  ) {
    aggregatedSelectValues = [
      aggregatedByValues[2],
      aggregatedByValues[3],
      aggregatedByValues[4],
    ]
  } else if (
    difference >= WEEK.numberOfDays &&
    difference < MONTH.numberOfDays
  ) {
    aggregatedSelectValues = [
      aggregatedByValues[1],
      aggregatedByValues[2],
      aggregatedByValues[3],
    ]
  } else if (difference >= DAY.numberOfDays && difference < WEEK.numberOfDays) {
    aggregatedSelectValues = [
      aggregatedByValues[0],
      aggregatedByValues[1],
      aggregatedByValues[2],
    ]
  } else if (difference < DAY.numberOfDays) {
    aggregatedSelectValues = [aggregatedByValues[0], aggregatedByValues[1]]
  }

  if (
    JSON.stringify(aggregatedSelectValues) !==
    JSON.stringify(currentAggregatedBySelections)
  ) {
    setAggregatedBySelections(aggregatedSelectValues)
    if (aggregatedSelectValues.find((e) => e.value === 'ERROR')) {
      return
    }
    if (
      !aggregatedSelectValues.find((e) => e.value === currentAggregatedByValue)
    ) {
      setAggregatedBy(
        aggregatedSelectValues[aggregatedSelectValues.length - 1].value
      )
    }
  }
}

/**
 * Gets a list of sensor ids that is formatted as a string and has commas separating each id.
 */
const getListOfSensorIds = (sensors) => {
  return sensors.map((sensor) => sensor?.id).join(',')
}

/**
 * Used for the dropdown choices. Finds all the lines based off the selected plant and that are associated with the list of sensors.
 */
const filterLines = (lines, plant) => {
  let map = lines
    .filter((item) => item.id === 0 || item.plantId === plant.id)
    .map((item) => ({ name: item['name'], id: item['id'] }))
  return map
}

export {
  handleUnitChange,
  handleUnitCategoryChange,
  generateRows,
  convertQuicksightChartData,
  convertQuicksightTemperatureData,
  determineAggregatedByValues,
  getListOfSensorIds,
  validDateValues,
  validRefetchCondition,
  allDefaults,
  aggregatedByValues,
  aggregationMethodValues,
  amplitudeValues,
  errorValues,
  unitValues,
  multiSensorFilterValues,
  activeInactiveValues,
  airParameterValues,
  fftBandValues,
  filterLines,
  CELSIUS,
  FAHRENHEIT,
  METRIC,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
  DECADE,
  ACCELERATION,
  ALL,
  DO_NOT_SHOW_ERROR_VALUES,
  ACTIVE,
  ERROR_VALUES_THRESHOLD,
  QUICKSIGHT_CHART_DATE_FORMAT,
  MULTISENSORID_OBJECT_PROPERTY,
  DATERECORDED_OBJECT_PROPERTY,
  STARTDATE_LABEL,
  ENDDATE_LABEL,
  MULTISENSOR,
  AIR_PARAMETERS,
  AIR_CHIP_TEMPERATURE,
  ATMOSPHERIC_PARAMETERS,
  BAND_SEVEN_VIBRATION_AMPLITUDE,
  X_AXIS_BAND_ONE_TO_SEVEN,
  Y_AXIS_BAND_ONE_TO_SEVEN,
  Z_AXIS_BAND_ONE_TO_SEVEN,
  K,
  PERCENT_RH,
  READING,
  VIBRATION,
}
