import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import loading from '../../../img/loading-spinner.gif';
import { styled } from '@mui/material/styles';
import {fetchReportingWidget} from '../../../shared/FetchUtilities'

const Img = styled('img')({
  height: '100%',
  width: 'auto'
})


const ImageFromServer = (props) => {

    const [imgSrc, setImgSrc] = useState(loading);

    

    useEffect( () => {
      setImgSrc(loading);
      
      const loadImage = async () => {
        const paramString = Object.keys(props.fetchParams).reduce((memo, key) => {
          let temp =  memo.concat([`${key}=${props.fetchParams[key]}`]);
          return temp;
        }, []).join('&')
        console.log(paramString);
  
        return fetchReportingWidget(`${props.fetchUrl}${paramString ? '?' + paramString : ''}`, setImgSrc);
      }
      
      loadImage();
    }, [props.fetchUrl, props.fetchParams, props.fetchProps])

    return (      
        <Img src={imgSrc} />                
    );
};

ImageFromServer.defaultProps = {
  fetchProps: {method: 'GET'},
  fetchParams: {}
}

ImageFromServer.propTypes = {
  /**
   * Properties for the fetch. 
   * @see [See init param here](https://developer.mozilla.org/en-US/docs/Web/API/Request/Request)
   */
  fetchProps: PropTypes.object,
  /**
   * Object describing any URL Params to include in the request
   */
  fetchParams: PropTypes.object,
  /**
   * URL to fetch image from
   */
  fetchUrl:PropTypes.string
}

export default ImageFromServer