import React, {useContext, useEffect} from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import {isLoggedIn, isExpiredToken} from '../../Utilities';
import {SiteContext} from '../../../Context';

function ProtectedRoute({
  component: Component,
  requiresLogin,
  ...rest
}) {

  const location = useLocation();
  const {userDetails, setNavSection} = useContext(SiteContext);

  useEffect(() => {
    setNavSection(rest.navSection);
  }, [setNavSection, rest.navSection]);
  
  
  
  return <Route {...rest} render={(props) => {

    if(!requiresLogin){
      return <Component {...props}/>
    }
    else if(!isLoggedIn(userDetails) || isExpiredToken(userDetails)){
      const redirect = {
        pathname: '/logout',
        state: {
          referrer: location
        }
      }
      return <Redirect to={redirect} />
    }
    else{
      return <Component {...props}/>
    }
  }} />
  
}

export default ProtectedRoute;