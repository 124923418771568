import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  indicatorContainer: {
    width: 75,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px black solid',
    borderRadius: 15,
    padding: '2px 5px 2px 5px',
    overflow: 'hidden',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}));

export default useStyles
