import React, {useEffect, useState} from "react";
import ReactEcharts from "echarts-for-react";
import { makeStyles } from '@mui/styles';

export const ICON_EXPORT_CSV = "M249.856 389.12v-178.176c0-45.056 36.864-81.92 81.92-81.92h456.704l163.84 167.936v337.92c0 12.288-8.192 20.48-20.48 20.48s-20.48-8.192-20.48-20.48V337.92h-102.4c-34.816 0-61.44-26.624-61.44-61.44v-106.496h-415.744c-22.528 0-40.96 18.432-40.96 40.96v178.176h456.704c22.528 0 40.96 18.432 40.96 40.96v286.72c0 22.528-18.432 40.96-40.96 40.96h-456.704v61.44c0 22.528 18.432 40.96 40.96 40.96h538.624c22.528 0 40.96-18.432 40.96-40.96v-61.44c0-12.288 8.192-20.48 20.48-20.48s20.48 8.192 20.48 20.48v61.44c0 45.056-36.864 81.92-81.92 81.92h-538.624c-45.056 0-81.92-36.864-81.92-81.92v-61.44h-137.216c-22.528 0-40.96-18.432-40.96-40.96v-286.72c0-22.528 18.432-40.96 40.96-40.96h137.216z m538.624-202.752v90.112c0 10.24 8.192 20.48 20.48 20.48h86.016l-106.496-110.592z m-473.088 350.208c-14.336-38.912-40.96-57.344-83.968-59.392-59.392 4.096-90.112 36.864-94.208 102.4 2.048 65.536 34.816 100.352 94.208 102.4 47.104 0 77.824-22.528 88.064-67.584l-36.864-12.288c-4.096 32.768-22.528 47.104-49.152 47.104-34.816-2.048-53.248-26.624-55.296-71.68 2.048-45.056 20.48-67.584 55.296-69.632 24.576 2.048 40.96 14.336 47.104 36.864l34.816-8.192z m26.624 79.872c10.24 45.056 38.912 65.536 90.112 65.536s75.776-20.48 77.824-59.392c0-24.576-14.336-40.96-40.96-53.248l-36.864-12.288c-28.672-6.144-43.008-16.384-40.96-28.672 2.048-16.384 14.336-22.528 34.816-24.576 24.576 0 38.912 10.24 43.008 32.768l36.864-8.192c-6.144-36.864-34.816-57.344-81.92-55.296-45.056 2.048-69.632 20.48-71.68 53.248-2.048 28.672 16.384 47.104 57.344 57.344 10.24 2.048 20.48 4.096 30.72 8.192 22.528 6.144 32.768 16.384 30.72 30.72-2.048 18.432-14.336 26.624-38.912 28.672-28.672 0-47.104-14.336-51.2-45.056l-38.912 10.24z m380.928-137.216h-40.96l-49.152 145.408c-4.096 12.288-6.144 18.432-6.144 20.48 0-4.096-2.048-10.24-6.144-20.48l-51.2-147.456h-40.96l77.824 198.656h43.008l73.728-196.608z";
export const ICON_EXPORT_IBA = "M1024 640.192C1024 782.912 919.872 896 787.648 896h-512C123.904 896 0 761.6 0 597.504 0 451.968 94.656 331.52 226.432 302.976 284.16 195.456 391.808 128 512 128c152.32 0 282.112 108.416 323.392 261.12C941.888 413.44 1024 519.04 1024 640.192z m-682.688-70.016L512 756.48l170.688-186.24H341.312z m213.376 0v-256h-85.376v256h85.376z";
export const ICON_SETTINGS = "M 19.14 12.94 c 0.04 -0.3 0.06 -0.61 0.06 -0.94 c 0 -0.32 -0.02 -0.64 -0.07 -0.94 l 2.03 -1.58 c 0.18 -0.14 0.23 -0.41 0.12 -0.61 l -1.92 -3.32 c -0.12 -0.22 -0.37 -0.29 -0.59 -0.22 l -2.39 0.96 c -0.5 -0.38 -1.03 -0.7 -1.62 -0.94 l -0.36 -2.54 c -0.04 -0.24 -0.24 -0.41 -0.48 -0.41 h -3.84 c -0.24 0 -0.43 0.17 -0.47 0.41 l -0.36 2.54 c -0.59 0.24 -1.13 0.57 -1.62 0.94 l -2.39 -0.96 c -0.22 -0.08 -0.47 0 -0.59 0.22 L 2.74 8.87 c -0.12 0.21 -0.08 0.47 0.12 0.61 l 2.03 1.58 c -0.05 0.3 -0.09 0.63 -0.09 0.94 s 0.02 0.64 0.07 0.94 l -2.03 1.58 c -0.18 0.14 -0.23 0.41 -0.12 0.61 l 1.92 3.32 c 0.12 0.22 0.37 0.29 0.59 0.22 l 2.39 -0.96 c 0.5 0.38 1.03 0.7 1.62 0.94 l 0.36 2.54 c 0.05 0.24 0.24 0.41 0.48 0.41 h 3.84 c 0.24 0 0.44 -0.17 0.47 -0.41 l 0.36 -2.54 c 0.59 -0.24 1.13 -0.56 1.62 -0.94 l 2.39 0.96 c 0.22 0.08 0.47 0 0.59 -0.22 l 1.92 -3.32 c 0.12 -0.22 0.07 -0.47 -0.12 -0.61 l -2.01 -1.58 Z M 12 15.6 c -1.98 0 -3.6 -1.62 -3.6 -3.6 s 1.62 -3.6 3.6 -3.6 s 3.6 1.62 3.6 3.6 s -1.62 3.6 -3.6 3.6 Z"

const useStyles = makeStyles(theme => ({
  root: {
      height: '100%',
  },
  graph: {
      width: '100%',
      height: '100%'
  }
}));

const EchartGraph = (props) => {
    const [options, setOptions] = useState({});

    useEffect(() => {

      const axisNames = [props.xAxisName, props.yAxisName, props.zAxisName], 
            data = [props.xAxisData, props.yAxisData, props.zAxisData] ;

      if (data === null || typeof data === "undefined" || data.length === 0) {
          console.error("data cannot be empty!!");
          return;
      }
      if (data.length !== axisNames.length) {
          console.error("data[] should have the same size as axisName[]!!");
          return;
      }

      const titles = [];
      const legend = [];
      const grid = [];
      const xAxis = [];
      const yAxis = [];
      const series = [];
      let graphTopExtraMargin = 7;
      let graphTopMargin = 90;
      data.forEach((axis, index) => {
          titles.push({
              textAlign: 'left',
              text: axisNames[index],
              top: ((index * (graphTopMargin / data.length)) + '%'),
              textStyle: {
                  fontSize: 12,
                  fontWeight: 'bold'
              }
          });
          legend.push(axisNames[index]);
          grid.push({
              left: 50,
              right: 50,
              top: (graphTopExtraMargin + (index * (graphTopMargin / data.length)) + '%'),
              height: '20%',
          });
          xAxis.push({
              gridIndex: index,
              type: 'value',
              name: props.selectedXUnit,
              boundaryGap: false,
              axisLine: {onZero: true},
              position: 'bottom',

          });
          yAxis.push({
              gridIndex: index,
              name: props.selectedYUnit,
              type: 'value',

          });
          series.push({
              name: axisNames[index],
              xAxisIndex: index,
              yAxisIndex: index,
              type: 'line',
              symbol: 'none',
              zlevel: 10,
              lineStyle: {
                  width: 0.5
              },
              symbolSize: 8,
              hoverAnimation: false,
              data: data[index].map(xy => [xy.x, xy.y]),
              markLine: props.markLines || {}                
          });
      });
      setOptions({
          title: titles,
          legend: {
              data: legend
          },
          tooltip: {
              trigger: 'axis',
              type: 'item',
              animation: true,
          },
          axisPointer: {
              link: {
                  xAxisIndex: 'all'
              }
          },
          toolbox: {
              show: props.showToolbox,
              feature: props.toolboxFeatures
          },
          dataZoom: [
              {
                  type: 'inside',
                  show: true,
                  realtime: true,
                  // start: dataZoom.start,
                  // end: dataZoom.end,
                  xAxisIndex: [0, 1, 2]
              },
              {
                  type: 'slider',
                  show: true,
                  realtime: true,
                  // start: dataZoom.start,
                  // end: dataZoom.end,
                  xAxisIndex: [0, 1, 2]
              }
          ],
          grid: grid,
          xAxis: xAxis,
          yAxis: yAxis,
          series: series
      });
    }, [props.xAxisName, props.yAxisName, props.zAxisName, props.xAxisData, props.yAxisData, props.zAxisData, props.selectedXUnit, props.selectedYUnit, props.markLines, props.showToolbox, props.toolboxFeatures]);
    
    const classes = useStyles();

    const onChartReady = (echarts) => {
        if(props.onGraphClick){
            echarts.getZr().on('click', (event) => {
                const {zrX, zrY} = event.event;
                const series = echarts.getOption().series
                const seriesIndex = series.reduce((memo, _, index) => {
                    if(echarts.containPixel({seriesIndex: index}, [zrX, zrY])){
                        return index;
                    }
                    else{
                        return memo;
                    }
                }, undefined);
                if(seriesIndex !== undefined){                        
                    const coordinates = echarts.convertFromPixel({seriesIndex: seriesIndex}, [zrX, zrY]);
                    props.onGraphClick({series: series[seriesIndex], coordinates: {x: coordinates[0], y:coordinates[1]}})
                }
        
            });
        }
    }

    return (
        <div className={classes.root}>
            
            <ReactEcharts
                style={{ // don't use className, it doesn't work with ReactEcharts
                    width: '100%',
                    height: '100%'
                }}
                ref={ref => {
                    if (ref === null || typeof ref === "undefined") {
                        return;
                    }
                    if (props.chartRef) {
                        props.chartRef.push(ref);
                    }
                }}
                option={options}
                showLoading={props.isLoading}
                loadingOption={{
                    default: {
                        text: 'loading',
                        color: '#c23531',
                        textColor: '#000',
                        maskColor: 'rgba(255, 255, 255, 0.8)',
                        zlevel: 0,

                        // Font size. Available since `v4.8.0`.
                        fontSize: 12,
                        // Show an animated "spinner" or not. Available since `v4.8.0`.
                        showSpinner: true,
                        // Radius of the "spinner". Available since `v4.8.0`.
                        spinnerRadius: 10,
                        // Line width of the "spinner". Available since `v4.8.0`.
                        lineWidth: 5
                    }
                }}
                notMerge={true}
                lazyUpdate={true}
                onChartReady={onChartReady}
                opts={{renderer: 'canvas'}}/>
                
        </div>
    );
};

export default EchartGraph;
