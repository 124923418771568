import React, { useEffect, useState } from 'react'
import useStyles from './Styles.js';
import translations from '../../translations/en.json';
import queryString from 'query-string'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { SST_PRIVACY_POLICY_URL } from '../../Constants'
import { CircularProgress, Typography } from '@mui/material'
import TopBar from '../../shared/components/TopBar/TopBar'
import Footer from '../../shared/components/Footer/Footer'

const Privacy = (props) => {

    const EN = "en";
    const FR = "fr";
    const validQueryStrings = [EN, FR];
    const localeMapping = {
        [EN]: {
            label: translations.common.language.english,
            privacyPolicySrc: SST_PRIVACY_POLICY_URL.english
        },
        [FR]: {
            label: translations.common.language.french,
            privacyPolicySrc: SST_PRIVACY_POLICY_URL.french
        }
    }

    const classes = useStyles();

    const [locale, setLocale] = useState("");
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);

    // useEffect() to grab and set "locale" query string value from URL,
    // to be executed on initial component and locale query string update...
    useEffect(() => {

        // Set title...
        document.title = translations.pages.privacy.title;

        // Get locale query string value and set locale state attribute...
        const queryStrings = queryString.parse(props.location.search);
        if (queryStrings.locale &&
            validQueryStrings.includes(queryStrings.locale)) {
                setLocale(queryStrings.locale);
        } else {
            setLocale(EN);
        }

    }, [props.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    const localeDropdownOnChangeHandler = (event) => {
        const selectedValue = event.target.value;
        setIsIframeLoaded(false);
        setLocale(selectedValue);
    }

    const iframeOnLoadHandler = () => {
        setIsIframeLoaded(true);
    }

    const LocaleSelect = () => {
        return (
            <div className={classes.selectContainer}>
                <Typography className={classes.controlLabel}>
                    {translations.pages.privacy.selectLanguage}:
                </Typography>
                <Select
                    variant="standard"
                    label="Language"
                    value={locale}
                    onChange={(e) => localeDropdownOnChangeHandler(e)}
                >
                    {
                        Object.entries(localeMapping).map(([locale, localeObj]) => {
                            return (
                                <MenuItem key={locale} value={locale}>{localeObj.label}</MenuItem>
                            )
                        })
                    }
                </Select>
            </div>
        )
    }

    return (
        <div className={classes.root}>

            {/* TODO: Instead of including TopBar in this component, */}
            {/* consider having it be applied from the App component */}
            <div className={classes.topBarContainer}>
                <TopBar
                  profileLink='/user-settings'
                  logoutLink='/logout' />
            </div>

            <div className={classes.controlsContainer}>
                <LocaleSelect/>
            </div>

            <div className={classes.iframeContainer}>
                { !isIframeLoaded && <CircularProgress/> }
                { locale && (
                    <iframe
                        title={translations.pages.privacy.privacyPolicyHeader}
                        className={classes.iframe}
                        src={localeMapping[locale].privacyPolicySrc}
                        onLoad={(e) => iframeOnLoadHandler()}
                        style={{display: isIframeLoaded ? 'flex' : 'none'}}
                    />
                )}
            </div>

            <div className={classes.footerContainer}>
                <Footer/>
            </div>
        </div>
    );
};

export default Privacy;
