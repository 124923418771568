import { truncateNumberToDecimals } from '../../Utilities'

const NO_TIME_SERIES_DATA = "noTimeSeriesData";

/**
 * Get pure, updated axesDataZoom object.
 * @param axesDataZoom
 * @param elementId
 * @param start
 * @param end
 */
const getUpdatedAxesDataZoom = (axesDataZoom, elementId, start, end) => {

  let updatedAxesDataZoom = JSON.parse(JSON.stringify(axesDataZoom)); // Deep copy, since object has deep nesting...

  updatedAxesDataZoom[elementId].start = start
  updatedAxesDataZoom[elementId].end = end

  return updatedAxesDataZoom;
}

const getDefaultLegendSelected = (hasMaxPressureData, hasTopLoadData, hasShockData, hasTiltData, hasSpinData, hasRotationData, valueDictionary) => {
  const defaultLegendSelected = {};

  if ( hasMaxPressureData ) { defaultLegendSelected[valueDictionary.maxPressure.displayName] = true }
  if ( hasTopLoadData ) { defaultLegendSelected[valueDictionary.topLoad.displayName] = true }
  if ( hasShockData ) { defaultLegendSelected[valueDictionary.shock.displayName] = true }
  if ( hasTiltData ) { defaultLegendSelected[valueDictionary.tilt.displayName] = true }
  if ( hasSpinData ) { defaultLegendSelected[valueDictionary.spin.displayName] = true }
  if ( hasRotationData ) { defaultLegendSelected[valueDictionary.rotation.displayName] = true }

  return defaultLegendSelected;
}

/**
 * Get pure, updated legendSelected object.
 * @param legendSelected
 * @param isMaxPressureSelected
 * @param isTopLoadSelected
 * @param isShockSelected
 * @param isTiltSelected
 * @param isSpinSelected
 * @param isRotationSelected
 * @param valueDictionary
 */
const getUpdatedLegendSelected = (legendSelected, isMaxPressureSelected, isTopLoadSelected, isShockSelected, isTiltSelected, isSpinSelected, isRotationSelected, valueDictionary) => {
  
    const updatedLegendSelected = {...legendSelected}; // Copy...

    if ( updatedLegendSelected[valueDictionary.maxPressure.displayName] !== null ||
         updatedLegendSelected[valueDictionary.maxPressure.displayName] !== undefined ) {
      updatedLegendSelected[valueDictionary.maxPressure.displayName] = isMaxPressureSelected;
    }

    if ( updatedLegendSelected[valueDictionary.topLoad.displayName] !== null ||
         updatedLegendSelected[valueDictionary.topLoad.displayName] !== undefined ) {
      updatedLegendSelected[valueDictionary.topLoad.displayName] = isTopLoadSelected;
    }

    if ( updatedLegendSelected[valueDictionary.shock.displayName] !== null ||
         updatedLegendSelected[valueDictionary.shock.displayName] !== undefined ) {
      updatedLegendSelected[valueDictionary.shock.displayName] = isShockSelected;
    }

    if ( updatedLegendSelected[valueDictionary.tilt.displayName] !== null ||
         updatedLegendSelected[valueDictionary.tilt.displayName] !== undefined ) {
      updatedLegendSelected[valueDictionary.tilt.displayName] = isTiltSelected;
    }

    if ( updatedLegendSelected[valueDictionary.spin.displayName] !== null ||
         updatedLegendSelected[valueDictionary.spin.displayName] !== undefined ) {
      updatedLegendSelected[valueDictionary.spin.displayName] = isSpinSelected;
    }

    if ( updatedLegendSelected[valueDictionary.rotation.displayName] !== null ||
      updatedLegendSelected[valueDictionary.rotation.displayName] !== undefined ) {
      updatedLegendSelected[valueDictionary.rotation.displayName] = isRotationSelected;
    }

    return updatedLegendSelected;
}

/**
 * Get value by time in timeSeriesDataModel.
 *
 * If timeSeriesDataModel is null or undefined, return NO_TIME_SERIES_DATA,
 * so we can reliably match NO_TIME_SERIES_DATA in conditions.
 * @param timeSeriesDataModel
 * @param time
 * @returns {*|string}
 */
const getValueByTimeInTimeSeriesDataModel = (timeSeriesDataModel, time) => {

  if ( !timeSeriesDataModel ) {
    return NO_TIME_SERIES_DATA;
  }

  const timeSeries = timeSeriesDataModel.data[0];
  const dataSeries = timeSeriesDataModel.data[1];
  const indexOfDesiredValue = timeSeries.indexOf(parseFloat(time));

  return dataSeries[indexOfDesiredValue];
}

/**
 * Get rotation orientation by time in rotationData.
 *
 * If rotationData is null or undefined, return NO_TIME_SERIES_DATA,
 * so we can reliably match NO_TIME_SERIES_DATA in conditions.
 * @param rotationData
 * @param time
 * @returns {*|string}
 */
const getRotationOrientationByTime = (rotationData, time) => {

  if ( !rotationData ) {
    return NO_TIME_SERIES_DATA;
  }

  const rotationTimes = rotationData.times;
  const rotationOrientations = rotationData.orientations;

  // Get associated rotation time index...
  const associatedRotationTimeIndex = rotationTimes.indexOf(time);

  return rotationOrientations[associatedRotationTimeIndex];
}

const getChartFields = (pressureData, topLoadData, shockData, tiltData, spinData, rotationData) => {

  const possibleChartFields = ['time', 'maxPressure', 'topLoad', 'shock', 'tilt', 'spin', 'rotation']

  const chartFields = possibleChartFields.filter((field) => {
    switch (field) {
      case 'time':
        return !!pressureData || !!topLoadData || !!shockData || !!tiltData || !!spinData || !!rotationData
      case 'maxPressure':
        return !!pressureData
      case 'topLoad':
        return !!topLoadData
      case 'shock':
        return !!shockData
      case 'tilt':
        return !!tiltData
      case 'spin':
        return !!spinData
      case 'rotation':
        return !!rotationData
      default:
        return false
    }
  })

  return chartFields;
}

const getChartData = (pressureData, topLoadData, shockData, tiltData, spinData, rotationData) => {

  const chartData = [];

  // time
  // NOTE: rotationData has an extended timeline, unlike other data, therefore must take precedence when deriving time...
  (rotationData || pressureData || topLoadData || shockData || tiltData || spinData) &&
  chartData.push(rotationData?.times || pressureData?.data[0] || topLoadData?.data[0] || shockData?.data[0] || tiltData?.data[0] || spinData?.data[0]);

  pressureData && chartData.push(pressureData.data[1]); // maxPressure
  topLoadData && chartData.push(topLoadData.data[1]); // topLoad
  shockData && chartData.push(shockData.data[1]); // shock
  tiltData && chartData.push(tiltData.data[1]); // tilt
  spinData && chartData.push(spinData.data[1]); // spin
  rotationData && chartData.push(rotationData.orientations); // rotation

  return chartData;
}

const formatTimeValue = (timeValue) => {
  return truncateNumberToDecimals(timeValue, 3);
}

const formatDataValue = (dataValue) => {
  return Number(dataValue).toFixed(2);
}

const filterNonNullAndNonUndefined = (array) => {

  // If null or undefined...
  if (array === null || array === undefined) {
    return []
  }

  // If empty...
  if (array.length === 0 ) {
    return []
  }

  return array.filter(value =>
    value !== null && // Non null...
    value !== undefined // Non undefined...
  )
}

export {
  NO_TIME_SERIES_DATA,
  getUpdatedAxesDataZoom,
  getDefaultLegendSelected,
  getUpdatedLegendSelected,
  getValueByTimeInTimeSeriesDataModel,
  getRotationOrientationByTime,
  getChartFields,
  getChartData,
  formatTimeValue,
  formatDataValue,
  filterNonNullAndNonUndefined
}