import React from 'react';
import { makeStyles } from '@mui/styles';
import * as GreyScale from '@Theme/GreyScale.js'

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    height: '80px',
    display: 'flex',
    backgroundColor: GreyScale.GREY_1,
    borderTop: `1px solid ${GreyScale.GREY_3}`,
    padding: '0px 26px'
  },
}))

const PageFooter = (props) => {

  const {
    children
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      {children}
    </div>
  )
}

export default PageFooter