import React, {useContext, useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useQuery} from 'react-query';
import {SiteContext} from "../../Context";
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment';
import PageHeader from '../../shared/components/PageHeader/PageHeader';
import {DATE_TIME_TIMEZONE_FORMAT, DATE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET} from '../../shared/Utilities';
import ProgressWrapper from '../../shared/components/ProgressWrapper';
import XMLViewer from 'react-xml-viewer'

import {
  getDrone,
  getDroneRuns,
  getCustomer,
  getLine
} from '../../query/queries';

const pageTitle = 'View Drone';
const acceptablePagePermission = [
  {entity: 'Drone', method: PERMISSION_METHOD_GET, modifier: ''}
]

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    overflowY: 'auto',
    width: '100%',
    height: '100%'
  },
  card: {
    minWidth: '500px',
    margin: '0px auto'
  },
  content: {
    display: 'flex',
    margin: '20px 30px 10px',
    borderBottom: '1px solid #615E5E',

    '&>*': {
      flexGrow: '1'
    }
  },

  sensorPropertiesContainer: {
    margin: '20px 30px'
  },
  title: {
    marginBottom: '20px'
  },
  xmlContainer: {
    marginTop: '4px',
    height: '200px',
    maxWidth: '500px',
    overflowY: "scroll" ,
    boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.5)',
    borderRadius: '3px',
    padding: '6px 4px',
    '&::-webkit-scrollbar-thumb': {
      outline: 'none'
    }
  },
  linearLoad: {
    margin:'10px 0 26px 0',
    width: '250px'
  }
})

const ViewDrone = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const {setBreadcrumbs, hasPermission} = useContext(SiteContext)

  const {isLoading: isLoadingDrone, data:drone={}} = useQuery(['drone', {droneId: match.params.droneId}], getDrone);
  const {isLoading: isLoadingDroneRuns, data:droneRuns=[]} = useQuery(['droneRuns', {droneId: match.params.droneId}], getDroneRuns);

  const {isLoading: isLoadingCustomer, data:customer={}} = useQuery(['customer', {customerId: drone.latestCustomerId}], getCustomer, {enabled: !!drone?.latestCustomerId});
  const {isLoading: isLoadingLine, data:line={}} = useQuery(['line', {lineId: drone.latestLineId}], getLine, {enabled: !!drone?.latestLineId});

  const classes = useStyles();

  useEffect(() => {
    document.title = pageTitle;
    setBreadcrumbs([{ title: pageTitle }]);
  }, [setBreadcrumbs])

  const sortedDroneRuns = droneRuns.sort((x, y) => {
    return x.dateRecorded > y.dateRecorded
  });

  const firstRun = sortedDroneRuns[0],
        lastRun = sortedDroneRuns[sortedDroneRuns.length - 1];

  return (
    <div className={classes.root} data-testid="view-drone-page">
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <div>
          <PageHeader pageTitle="Drone Details" onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-drones')}/>

          <div className={classes.content}>

            <div className={classes.column}>
              <Typography variant="h5">Name</Typography>
              <ProgressWrapper linear isLoading={isLoadingDrone} linearClass={classes.linearLoad}>
                <Typography paragraph>{drone.name}</Typography>
              </ProgressWrapper>

              <Typography variant="h5">Latest Customer</Typography>
              <ProgressWrapper linear isLoading={isLoadingCustomer || isLoadingDrone} linearClass={classes.linearLoad}>
                <Typography paragraph>{customer.name}</Typography>
              </ProgressWrapper>


              <Typography variant="h5">Latest Line</Typography>
              <ProgressWrapper linear isLoading={isLoadingLine || isLoadingDrone} linearClass={classes.linearLoad}>
                <Typography paragraph>{line.name}</Typography>
              </ProgressWrapper>


              <Typography variant="h5">Last Used</Typography>
              <ProgressWrapper linear isLoading={isLoadingDrone} linearClass={classes.linearLoad}>
                <Typography paragraph><ProtectedMoment date={drone.lastUsed} format={DATE_TIME_TIMEZONE_FORMAT}></ProtectedMoment></Typography>
              </ProgressWrapper>

              <Typography variant="h5">Total Drone Runs</Typography>
              <ProgressWrapper linear isLoading={isLoadingDroneRuns || isLoadingDrone} linearClass={classes.linearLoad}>
                <Typography paragraph>{sortedDroneRuns.length}</Typography>
              </ProgressWrapper>
            </div>
            <div className={classes.column}>
            <Typography variant="h5">Drone Service Time</Typography>
            <ProgressWrapper linear isLoading={isLoadingDroneRuns || isLoadingDrone} linearClass={classes.linearLoad}>
              <Typography paragraph>
                <ProtectedMoment diff={firstRun?.dateRecorded} unit="days">{lastRun?.dateRecorded}</ProtectedMoment> Days, First used <ProtectedMoment format={DATE_FORMAT}>{firstRun?.dateRecorded}</ProtectedMoment>
              </Typography>
            </ProgressWrapper>

            <Typography variant="h5">BLE Version</Typography>
            <ProgressWrapper linear isLoading={isLoadingDrone} linearClass={classes.linearLoad}>
              <Typography paragraph>{drone.latestConfiguration?.bleVersion}</Typography>
            </ProgressWrapper>


            <Typography variant="h5">Firmware Version</Typography>
            <ProgressWrapper linear isLoading={isLoadingDrone} linearClass={classes.linearLoad}>
              <Typography paragraph>{drone.latestConfiguration?.firmwareVersion}</Typography>
            </ProgressWrapper>


            <Typography variant="h5">Last Calibration Date</Typography>
            <ProgressWrapper linear isLoading={isLoadingDrone} linearClass={classes.linearLoad}>
              <Typography paragraph><ProtectedMoment date={drone.latestConfiguration?.lastCalibrationDate} format={DATE_FORMAT}></ProtectedMoment></Typography>
            </ProgressWrapper>
            </div>
          </div>

          <div className={classes.sensorPropertiesContainer}>
            <Typography variant="h5">Current Sensor Properties</Typography>
            <ProgressWrapper isLoading={isLoadingDrone}>
              <div className={classes.xmlContainer}>
                <XMLViewer xml={drone.latestConfiguration?.sensorProperties || '<>'}/>
              </div>
            </ProgressWrapper>
          </div>
        </div>
      }
    </div>
  )
}

export default ViewDrone