import { makeStyles } from '@mui/styles'

const ConditionTrendSummaryStyles = makeStyles({
  root: {
    width: '100%',
    height: 'fit-content',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 12px 12px 12px',
  },
  paper: {
    width: '99%',
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid lightgrey',
  },
  titleHeader: {
    borderBottom: '1px solid lightgrey',
    margin: '0 12px 0 12px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  paperCard: {
    border: '1px solid lightgrey',
  },
  tableText: {
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    height: '5px',
    alignItems: 'center',
  },
  formControl: {
    width: '200px',
  },
  dropDown: {
    width: '200px',
  },
  slider: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiSlider-thumb': {
      height: 10,
      width: 10,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
  },
  sliderTextInput: {
    width: '75px',
    height: '20px',
    borderRadius: '5px',
    fontSize: '13px',
  },
  sliderTitle: {
    width: '150px',
  },
  sliderContainer: {
    display: 'flex',
    gap: '24px',
  },
  backButton: {
    fontSize: '32px',
    color: '#615E5E',
    padding: '8px',
    border: '1px solid black',
    borderRadius: '3px',
    display: 'block',
    cursor: 'pointer',
  },
  backButtonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  divider: {
    height: '25px',
    width: '1px',
    backgroundColor: '#8F8F8F',
    marginLeft: '26px',
    marginRight: '26px',
  },
  applyButton: {
    display: 'flex',
    alignItems: 'flex-start',
  },
})

export default ConditionTrendSummaryStyles
