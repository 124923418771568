import * as React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressWrapper = (props) => {

  return (
    <React.Fragment>
      {
        (props.isLoading)
          ? (props.linear
            ? <LinearProgress className={props.linearClass}/>
            : <CircularProgress 
                className="flex-progress" 
                variant="indeterminate"
                disableShrink={false} 
                color={'inherit'}
                size={23} />)
          : props.children
      }
    </React.Fragment>
  );
};

export default ProgressWrapper;