import chroma from 'chroma-js'

/**
 * Given an array of ranges (e.g. SAFE_PRESSURE_AND_COLOR_RANGES), and a number of sub-ranges to create for each range,
 * generate sub-ranges such that each sub-range contains a color that is an alpha gradient of the associated range's color,
 * creating a gradient, essentially creating a color gradient for each range's color.
 * @param ranges
 * @param numSubRanges
 */
const getSubRanges = (ranges, numSubRanges) => {

  const subRanges = [];

  ranges.forEach(range => {

    // If range.max is Infinity, then push the range as is, since we can't divide Infinity to create sub-ranges...
    if (range.max === Infinity) {
      subRanges.push(range);
      return;
    }

    // Derive subRanges...
    // 1. Determine the step value for the current range...
    const subRangeValueStep = (range.max - range.min) / numSubRanges;

    // 2. Create numSubRanges of subRanges, for the given current range...
    for (let i = 0; i < numSubRanges; i++) {

      // Define subRange...
      const subRange = {
        color: chroma(range.color), // Create color object, chroma, from color hex, to ease manipulation...
        min: range.min + subRangeValueStep * i,
        max: range.min + subRangeValueStep * (i + 1)
      };

      // Determine alpha value for subRanges, to create an alpha gradient of range.color...
      let subRangeAlpha;

      // If we create an alpha gradient, across subRanges, for a given range w/ associated color,
      // and each gradient starts at alpha = (i + 1) / numSubRanges, then each range.color
      // gradient will have a faint, almost completely transparent coloration, at the lower
      // end of the range, making it difficult to identify colors for values that are just
      // over a range's min value. (i.e. It's hard to see the difference in coloration between
      // faint green, or faint yellow, or faint red, etc.)
      //
      // As a solution, for the range with min = 0 (the range representing the lowest range of values),
      // have the alpha gradient start alpha = (i + 1) / numSubRanges, but for all other ranges,
      // have the alpha gradient start at alpha = 0.5.

      // If lowest range, start alpha gradient at (i + 1) / numSubRanges...
      if (range.min === 0) {
        subRangeAlpha = (i + 1) / numSubRanges;
      } else {
        // Start alpha gradient at 0.5...
        const alphaMin = 0.5;
        const alphaMax = 1;
        const alphaStep = (alphaMax - alphaMin) / (numSubRanges - 1); // (numSubRanges - 1), to ensure first subrange alpha is alphaMin...

        subRangeAlpha = alphaMin + (i * alphaStep);
      }

      // Set subRange color...
      subRange.color = subRange.color
        .alpha(subRangeAlpha)
        .css(); // To CSS string...

      subRanges.push(subRange);
    }
  });

  return subRanges;
};

export { getSubRanges }