import React, {useContext} from 'react';
import {SvgIcon, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {SiteContext} from "../../../Context";
import { default as theme } from "../../Theme/OriginalTheme.js"
import { GREY_1 } from '../../Theme/GreyScale.js';

const grey1 = '#C4C4C4';

const useStyles = makeStyles(theme => ({
  root: {
    '& *': {
      boxSizing: 'border-box'
    },
    display: 'inline-block',
    border: '1px solid black',
    borderRadius: '4px'
  },
  header: {
    backgroundColor: grey1,
    height: '60px',
    fontSize: '20px',
    lineHeight: '24px',
    verticalAlign: 'middle',
    padding: '18px'
  },
  children: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '20px',
    backgroundColor: 'white'
  },
  child: {
    height: '200px',
    width: '200px',
    border: '1px solid black',
    borderRadius: '4px',
    backgroundColor: GREY_1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'none',
    color: 'black',

    '&:hover' : {
      textDecoration: 'none',
      color: theme.palette.accent.main
    }
  }
}));



const AdminCard = (props) => {
  const {name, children, authFn} = props;
  const {checkPermission} = useContext(SiteContext);

  const classes = useStyles();

  const cards = authFn
      ? children.filter((i) => checkPermission(i, authFn))
      : children

  if (cards.length > 0) {
    return <div className={classes.root}>
      <Typography className={classes.header}>{name}</Typography>
      <div className={classes.children}>
        {cards.map(c => (<Link to={c.link} className={classes.link} key={'admin-card-' + c.label}>
          <div className={classes.child}>
            {c.icon && <SvgIcon component={c.icon} viewBox={c.viewBox} sx={{
              fontSize: 46,
              color: !!(c.doNotUseThemeIcon) || theme.palette.accent.main
            }}/>}
            <Typography className={classes.label}>{c.label}</Typography>          
          </div>
        </Link>))}
      </div>
    </div>
  }
  else{
    return null;
  }

}


export default AdminCard