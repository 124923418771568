import React, {useState} from "react";
import { makeStyles } from '@mui/styles';
import { DISPLAY_TYPE_GRID, DISPLAY_TYPE_ACCORDION } from "../../Utilities";
import { Typography } from "@mui/material";
import {ReactComponent as LayoutGridIcon} from '../../../img/icons/layoutGrid.svg'
import {ReactComponent as LayoutGridSelectedIcon} from '../../../img/icons/layoutGridSelected.svg'
import {ReactComponent as LayoutAccordionIcon} from '../../../img/icons/layoutAccordion.svg'
import {ReactComponent as LayoutAccordionSelectedIcon} from '../../../img/icons/layoutAccordionSelected.svg'

const useStyles = makeStyles({

  root: {
    display: 'flex',
    minHeight: '75px',
    maxHeight: '7%',
    boxShadow: '3px 3px 2px 1px #ccc',
    marginBottom: '.5rem',
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 1,
    top: 0,
  },
  cell: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  displayIcon: {
    margin:'0px 5px',
  },
  displayViewContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '2rem',
  },
  title: {
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    paddingLeft: '1rem',
  }
})

const SearchBar = (props) => {
  const layoutType = props?.layoutType || DISPLAY_TYPE_ACCORDION,
  onUpdateDisplay = props.onUpdateDisplay,
  title = props.title

  const classes = useStyles();
  
  const [selectedLayoutType, setSelectedLayoutType] = useState(layoutType)

  const toggleLayoutType = (layoutType) => {
    if(layoutType !== selectedLayoutType) {
      setSelectedLayoutType(layoutType)
      onUpdateDisplay(layoutType)
    }
  }

  const layoutGridIcon = selectedLayoutType === DISPLAY_TYPE_GRID ? <LayoutGridSelectedIcon /> : <LayoutGridIcon onClick={() => toggleLayoutType(DISPLAY_TYPE_GRID)} />
  const layoutAccordionIcon = selectedLayoutType === DISPLAY_TYPE_ACCORDION ? <LayoutAccordionSelectedIcon /> : <LayoutAccordionIcon onClick={() => toggleLayoutType(DISPLAY_TYPE_ACCORDION)} />

  return (<div className={classes.root}>
    <div className={classes.cell}>
      <Typography className={classes.title}>{title}</Typography>
    </div>
    <div className={classes.cell}></div>
    <div className={classes.displayViewContainer}>
      <div className={classes.displayIcon}>
        {layoutGridIcon}
      </div>
      <div className={classes.displayIcon}>
        {layoutAccordionIcon}
      </div>
    </div>
  </div>)
}

export default SearchBar;