import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import SettingsIcon from '@mui/icons-material/Settings'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterMoment from '@mui/lab/AdapterMoment'
import {
  FormControlLabel,
  FormLabel,
  MenuItem,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { makeStyles } from '@mui/styles'
import moment from 'react-moment'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DATA_FORMAT_JSON, DATA_TYPE_FFT } from '../../Constants'
import { SiteContext } from '../../Context'
import { ReactComponent as BackIcon } from '../../img/icons/Back button.svg'
import {
  getFaults,
  getParts,
  getSensor,
  getSeverities,
  getSymptoms,
  getVibrations,
  updateDiagnosis as UpdateDiagnosis,
} from '../../query/queries'
import Diagnosis from '../../shared/components/Diagnosis/Diagnosis'
import DiagnosisSeverityIcon from '../../shared/components/Diagnosis/DiagnosisSeverityIcon'
import EnhancedTable from '../../shared/components/EnhancedTable/EnhancedTable'
import FFTDisplay from '../../shared/components/FFTDisplay/FFTDisplay'
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment'
import TimeWaveformDisplay from '../../shared/components/TimeWaveformDisplay'
import { fetchRequestDetailedVibrationData } from '../../shared/FetchUtilities'
import {
  CYCLE_PER_MINUTE,
  DISPLACEMENT,
  G,
  GRAVITY,
  HERTZ,
  IMPERIAL,
  INCHES_PER_SECOND,
  METRIC,
  MICRONS,
  MILLIMETER_PER_SECOND,
  MILS,
  VELOCITY,
} from '../../shared/MultiSensorGraphsUtilities'
import {
  DATE_TIME_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
  PERMISSION_METHOD_UPDATE,
} from '../../shared/Utilities'

const pageTitle = 'Time Waveform and FFT'
const acceptablePagePermission = [
  { entity: 'Hub', method: PERMISSION_METHOD_UPDATE, modifier: '' },
  { entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children' },
]

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    padding: '15px',
    gridTemplateColumns: '410px 1fr 1fr',
    gridTemplateRows: '100px 1fr',
    gap: '20px 15px',
    gridTemplateAreas: `
        "header header header"
        "menu timeWaveform fft"`,
    backgroundColor: 'white',
    width: '100%',
  },
  header: {
    gridArea: 'header',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '16px 0px',
  },
  measurement: {
    width: '170px',
  },
  menu: {
    gridArea: 'menu',
  },
  timeWaveform: {
    gridArea: 'timeWaveform',
    width: '100%',
  },
  fft: {
    gridArea: 'fft',
    width: '100%',
  },
  tableCell: {
    padding: '5px',
  },
  diagnosis: {
    height: '100%',
    width: '100%',
  },
  diagnosisContainer: {
    position: 'relative',
    height: '690px',
  },
  tableContainer: {
    position: 'relative',
  },
  tableSettingsIcon: {
    position: 'absolute',
    top: '16px',
    right: '15px',
    color: '#333',
    cursor: 'pointer',
  },
  tableSettings: {
    padding: '10px',
    maxWidth: '260px',
  },
  diagnosisContainerClose: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: '#666',

    '&:hover': {
      color: '#333',
    },
  },
  diagnosisIcon: {
    borderRadius: '100px',
    padding: '4px',
    cursor: 'pointer',
  },
  createDiagnosisIcon: {
    color: '#333',
    padding: '2px',
    cursor: 'pointer',
  },
  diagnosisToggle: {
    marginBottom: '0px',
  },
  dateFilterContainer: {
    margin: '0px 6px',
    width: '350px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'relative',
  },
  dateFiltersStack: {
    width: '250px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  dateFilter: {
    cursor: 'pointer',
  },
  clearDate: {
    position: 'relative',
    left: '6px',
    bottom: '6px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  backButton: {
    fontSize: '32px',
    color: '#615E5E',
    padding: '8px',
    border: '1px solid black',
    borderRadius: '3px',
    display: 'block',
    cursor: 'pointer',
  },
  backButtonContainer: {
    width: '148px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  divider: {
    height: '75px',
    width: '1px',
    backgroundColor: '#8F8F8F',
    marginLeft: '26px',
    marginRight: '26px',
  },
}))

const columns = [
  { id: 'id', label: 'Id', hidden: true },
  { id: 'dateRecorded', label: 'Date Recorded' },
  { id: 'hasRaw', label: 'Has Raw', hidden: true },
  { id: 'diagnosis', label: 'Diagnosis' },
]

const measurements = [METRIC, IMPERIAL]

const CombinedMultiSensorVibration = (props) => {
  const queryClient = useQueryClient()
  const {
    params: { sensorId },
  } = useRouteMatch()
  const history = useHistory()

  const { apiToken, hasPermission } = useContext(SiteContext)
  const [detailedSelectedReading, setDetailedSelectedReading] = useState()

  const [selectedFFTxUnit, setSelectedFFTxUnit] = useState(HERTZ)
  const [selectedFFTyUnit, setSelectedFFTyUnit] = useState(
    MILLIMETER_PER_SECOND
  )
  const [FFTxUnits] = useState([CYCLE_PER_MINUTE, HERTZ])
  const [FFTyUnits, setFFTyUnits] = useState([
    G,
    MILLIMETER_PER_SECOND,
    MICRONS,
  ])
  const [measurement, setMeasurement] = useState(METRIC)

  // eslint-disable-next-line
  const [canAddDiagnosis, setCanAddDiagnosis] = useState(
    hasPermission('Diagnosis', 'insert')
  )
  // eslint-disable-next-line
  const [canUpdateDiagnosis, setCanUpdateDiagnosis] = useState(
    hasPermission('Diagnosis', 'update')
  )
  // eslint-disable-next-line
  const [canRetrieveDiagnosis, setCanRetrieveDiagnosis] = useState(
    hasPermission('Diagnosis', 'get')
  )

  const [selectedReadingObject, setSelectedReadingObject] = useState()

  const [showDiagnosis, setShowDiagnosis] = useState(false)
  const [tableSettingsRef, setTableSettingsRef] = useState()
  const [onlyDiagnosis, setOnlyDiagnosis] = useState(false)

  const { data: sensor = {}, isLoading: isLoadingSensor } = useQuery(
    ['sensor', { sensorId: sensorId }],
    getSensor
  )
  const { data: severities = [], isLoading: isLoadingSeverities } = useQuery(
    'severities',
    getSeverities
  )
  const { data: faults = [], isLoading: isLoadingFaults } = useQuery(
    'faults',
    getFaults
  )
  const { data: symptoms = [], isLoading: isLoadingSymptoms } = useQuery(
    'symptoms',
    getSymptoms
  )
  const { data: parts = [], isLoading: isLoadingParts } = useQuery(
    'parts',
    getParts
  )

  const updateDiagnosis = useMutation(UpdateDiagnosis)
  const stateHistory = history.location.state
    ? JSON.parse(history.location.state)
    : null

  const [fetchDianosisStartDate, setFetchDiagnosisStartDate] = useState(
    !!stateHistory && !!stateHistory?.startDate
      ? moment(stateHistory.startDate)
      : null
  )
  const [fetchDianosisEndDate, setFetchDiagnosisEndDate] = useState(
    !!stateHistory && !!stateHistory?.endDate
      ? moment(stateHistory.endDate)
      : null
  )

  const vibrationReadingQueryKey = [
    'vibrationReadings',
    {
      sensorId,
    },
    {
      dataFormat: 'none',
      limit:
        !!fetchDianosisStartDate || !!fetchDianosisEndDate ? '1000' : '100',
      includeDiagnoses: true,
      onlyReadingsWithDiagnoses: onlyDiagnosis,
      startDate: !!fetchDianosisStartDate
        ? fetchDianosisStartDate.toISOString()
        : undefined,
      endDate: !!fetchDianosisEndDate
        ? fetchDianosisEndDate.toISOString()
        : undefined,
    },
  ]
  const {
    data: vibrationReadings = [],
    isLoading: isLoadingVibrationReadings,
  } = useQuery(vibrationReadingQueryKey, getVibrations, { enabled: !!sensorId })

  useEffect(() => {
    document.title = 'List Vibrations'
  }, [])

  const updateSelectedFFTxUnit = (e, value) => {
    if (selectedFFTxUnit === value) {
      return
    }
    setSelectedFFTxUnit(value)
  }

  const updateSelectedFFTyUnit = (e, value) => {
    if (selectedFFTyUnit === value) {
      return
    }
    setSelectedFFTyUnit(value)
  }

  const updateMeasurement = (e) => {
    const yAxisValues = {
      Metric: [G, MILLIMETER_PER_SECOND, MICRONS],
      Imperial: [G, INCHES_PER_SECOND, MILS],
    }

    if (e.target.value === METRIC) {
      //Switch to Metric
      setFFTyUnits(yAxisValues.Metric)
      setSelectedFFTyUnit(
        yAxisValues.Metric[FFTyUnits.indexOf(selectedFFTyUnit)]
      )
      setMeasurement(METRIC)
    } else {
      //Switch to Imperial
      setFFTyUnits(yAxisValues.Imperial)
      setSelectedFFTyUnit(
        yAxisValues.Imperial[FFTyUnits.indexOf(selectedFFTyUnit)]
      )
      setMeasurement(IMPERIAL)
    }
  }

  const displayYUnit = (yUnit) => {
    if (yUnit === G) {
      return GRAVITY
    } else if (yUnit === INCHES_PER_SECOND || yUnit === MILLIMETER_PER_SECOND) {
      return VELOCITY
    } else if (yUnit === MILS || yUnit === MICRONS) {
      return DISPLACEMENT
    } else {
      return yUnit
    }
  }

  let echarts = []

  const handleRowOnClick = (event, key) => {
    fetchRequestDetailedVibrationData(
      key,
      DATA_TYPE_FFT,
      DATA_FORMAT_JSON,
      true,
      () => {},
      setDetailedSelectedReading,
      apiToken
    )
    const reading = vibrationReadings.find((x) => x.id === key)
    setSelectedReadingObject(reading)
  }

  const handleDiagnosisUpdate = (diagnosis) => {
    updateDiagnosis.mutate(diagnosis, {
      onSuccess: (data) => {
        console.log('Updated Diagnosis', data, diagnosis)
        const index = vibrationReadings.findIndex(
          (x) => x.id === data.vibrationReadingId
        )
        if (index !== -1) {
          console.log('invalidating cache')
          vibrationReadings[index].diagnosis = data
          queryClient.setQueryData(vibrationReadingQueryKey, [
            ...vibrationReadings,
          ])
        }
      },
    })
  }

  const handleDiagnosisCreate = (vibrationId) => {
    const tempDiagnosis = { vibrationReadingId: vibrationId }
    const index = vibrationReadings.findIndex((x) => x.id === vibrationId)

    if (index !== -1) {
      vibrationReadings[index].diagnosis = tempDiagnosis
      queryClient.setQueryData(vibrationReadingQueryKey, [...vibrationReadings])
      setShowDiagnosis(true)
    }
  }

  const classes = useStyles()

  const rows = vibrationReadings.map((reading) => {
    const hasDiagnosis = !!reading.diagnosis.id

    const diagnosisColumn = () => {
      if (hasDiagnosis && severities.length > 0) {
        const diagnosis = reading.diagnosis
        const severity = severities.find(
          (element) => element.id === diagnosis.severityId
        )
        return (
          <DiagnosisSeverityIcon
            className={classes.diagnosisIcon}
            severity={severity}
            onClick={(e) => {
              setShowDiagnosis(true)
            }}
          />
        )
      } else {
        if (canAddDiagnosis) {
          const vibrationId = reading.id
          return (
            <AddIcon
              className={classes.createDiagnosisIcon}
              onClick={(e) => {
                handleDiagnosisCreate(vibrationId)
              }}
            />
          )
        } else {
          return <div></div>
        }
      }
    }
    return {
      id: reading.id,
      dateRecorded: (
        <ProtectedMoment
          id={reading.id}
          date={reading.dateRecorded}
          format={DATE_TIME_FORMAT + ' z'}
        />
      ),
      hasRaw: reading.hasRaw,
      diagnosis: diagnosisColumn(),
    }
  })

  const handleDiagnosisToggle = () => {
    queryClient.removeQueries(vibrationReadingQueryKey)
    setOnlyDiagnosis((prev) => !prev)
  }

  const handleDateChange = (momentObj, startOrEnd) => {
    queryClient.removeQueries('vibrationReadings')
    if (momentObj) {
      let stateFn =
        startOrEnd === 'start'
          ? setFetchDiagnosisStartDate
          : setFetchDiagnosisEndDate
      stateFn(momentObj.clone())
    } else {
      setFetchDiagnosisStartDate(null)
      setFetchDiagnosisEndDate(null)
    }
  }

  return handlePermissionRedirect(
    pageTitle,
    history,
    hasPermission,
    acceptablePagePermission
  ) ? (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.backButtonContainer}>
          <span
            onClick={() =>
              !!history.location.state?.goBackURL
                ? history.push(history.location.state.goBackURL, {
                    previouslySelectedRow:
                      history.location.state.previouslySelectedRow,
                    startDate: history.location.state.startDate,
                    endDate: history.location.state.endDate,
                  })
                : history.goBack()
            }
          >
            <SvgIcon
              component={BackIcon}
              viewBox="8 8 32 32"
              className={classes.backButton}
            />
          </span>
          <span className={classes.divider} />
        </div>
        <div className={classes.dateFilterContainer}>
          <div className={classes.dateFiltersStack}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                className={classes.dateFilter}
                clearable={true}
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/DD/yyyy"
                emptyLabel={'Latest'}
                label={'Filter Start'}
                value={fetchDianosisStartDate}
                onChange={(momentObj) => handleDateChange(momentObj, 'start')}
                autoOk
                disabled={showDiagnosis}
                renderInput={(params) => <TextField {...params} />}
              />
              <DateTimePicker
                className={classes.dateFilter}
                clearable={true}
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/DD/yyyy"
                emptyLabel={'Latest'}
                label={'Filter End'}
                value={fetchDianosisEndDate}
                onChange={(momentObj) => handleDateChange(momentObj, 'end')}
                autoOk
                disabled={showDiagnosis}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <CloseIcon
            className={classes.clearDate}
            onClick={() => handleDateChange(null)}
          />
        </div>
        <Grid item style={{ marginLeft: '6px', marginRight: '6px' }}>
          <FormLabel>Frequency</FormLabel>
          <ToggleButtonGroup
            onChange={updateSelectedFFTxUnit}
            value={selectedFFTxUnit}
            exclusive
            aria-label={'Frequency'}
            size={'small'}
          >
            {FFTxUnits.map((xUnit, index) => (
              <ToggleButton
                key={index}
                value={xUnit}
                selected={selectedFFTxUnit === xUnit}
              >
                {xUnit}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item style={{ marginLeft: '6px', marginRight: '26px' }}>
          <FormLabel>Amplitude</FormLabel>
          <ToggleButtonGroup
            defaultValue={selectedFFTyUnit}
            onChange={updateSelectedFFTyUnit}
            exclusive
            size={'small'}
          >
            {FFTyUnits.map((yUnit, index) => (
              <ToggleButton
                key={index}
                value={yUnit}
                selected={selectedFFTyUnit === yUnit}
              >
                {displayYUnit(yUnit)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid component="div" container alignItems="center" spacing={1}>
          <TextField
            select
            className={classes.measurement}
            label="Measurement System"
            value={measurement}
            onChange={updateMeasurement}
          >
            {measurements.map((x) => (
              <MenuItem value={x} key={x}>
                {x}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </div>
      <div className={classes.menu}>
        <div>
          <Typography variant="h6" gutterBottom>
            {!isLoadingSensor
              ? `${sensor.name} - ${sensor.macAddress}`
              : 'Loading...'}
          </Typography>
        </div>
        {showDiagnosis ? (
          <div className={classes.diagnosisContainer}>
            {updateDiagnosis.isLoading ? (
              <CircularProgress
                className={classes.diagnosisContainerClose}
                size={18}
              />
            ) : (
              <CloseIcon
                className={classes.diagnosisContainerClose}
                onClick={() => {
                  setShowDiagnosis(false)
                }}
              />
            )}
            <Diagnosis
              readOnly={!(canUpdateDiagnosis || canUpdateDiagnosis)}
              className={classes.diagnosis}
              faults={faults}
              parts={parts}
              symptoms={symptoms}
              severities={severities}
              diagnosis={selectedReadingObject.diagnosis}
              onChange={handleDiagnosisUpdate}
            />
          </div>
        ) : (
          <div className={classes.tableContainer}>
            <SettingsIcon
              className={classes.tableSettingsIcon}
              onClick={(e) => setTableSettingsRef(e.target)}
            />
            <Popover
              open={!!tableSettingsRef}
              anchorEl={tableSettingsRef}
              onClose={() => setTableSettingsRef(null)}
            >
              <div className={classes.tableSettings}>
                <FormControlLabel
                  className={classes.diagnosisToggle}
                  control={
                    <Switch
                      checked={onlyDiagnosis}
                      onChange={handleDiagnosisToggle}
                    />
                  }
                  label="Only show readings with Diagnosis attached"
                />
              </div>
            </Popover>
            <EnhancedTable
              order={'asc'}
              orderBy={'Name'}
              rows={rows}
              rowsPerPage={10}
              rowPerPageOptions={[]}
              headCells={columns}
              isLoading={
                isLoadingVibrationReadings ||
                isLoadingFaults ||
                isLoadingParts ||
                isLoadingSymptoms ||
                isLoadingSeverities
              }
              isSelectable={true}
              findSelected={(x) => x.id === selectedReadingObject?.id}
              onClick={handleRowOnClick}
              classes={{ tableCell: classes.tableCell }}
            />
          </div>
        )}
      </div>
      <TimeWaveformDisplay
        className={classes.timeWaveform}
        chartRef={echarts}
        showDateRecorded={false}
        showToolbox={true}
        noDataMessage={'No Time Waveform Data Yet'}
        downloadCSV={true}
        downloadIBA={canRetrieveDiagnosis}
        macAddress={sensorId}
        vibrationReadingId={
          detailedSelectedReading ? detailedSelectedReading.id : undefined
        }
      />
      <FFTDisplay
        className={classes.fft}
        chartRef={echarts}
        showDateRecorded={false}
        showToolbox={true}
        selectedXUnit={selectedFFTxUnit}
        selectedYUnit={selectedFFTyUnit}
        noDataMessage={'No FFT Data Yet'}
        downloadCSV={true}
        macAddress={sensorId}
        vibrationReadingId={
          detailedSelectedReading ? detailedSelectedReading.id : undefined
        }
      />
    </div>
  ) : (
    <div className={classes.container} />
  )
}

export default CombinedMultiSensorVibration
