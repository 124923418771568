import {axios} from '../client';

import {
  SST_API_PATH_SENSORS,
  SST_API_PATH_VIBRATION,
  SST_API_PATH_CUSTOMERS,
  SST_API_PATH_DATA
} from './../../Constants';


export const getVibrations = ({queryKey}) => {
  const [, {macAddress, sensorId}, queryParams] = queryKey,
  url = `${SST_API_PATH_SENSORS}/${sensorId || macAddress}/${SST_API_PATH_VIBRATION}`;

  return axios.request({
    method: 'get',
    url: url,
    params: queryParams
  }).then(res => res.data);
}

export const getAggregatedSensorData = ({queryKey}) => {
  const [, {customerId}, queryParams] = queryKey,
  url = `${SST_API_PATH_CUSTOMERS}/${customerId}/${SST_API_PATH_SENSORS}/${SST_API_PATH_DATA}`;

  return axios.request({
    method: 'get',
    url: url,
    params: queryParams
  }).then(res => res.data);
}
