import React, {useState} from 'react'
import { makeStyles } from '@mui/styles';
import {FileCopyOutlined} from '@mui/icons-material';
import {Snackbar, Tooltip} from '@mui/material';
import Alert from '@mui/material/Alert';


const useStyles = makeStyles({
  root: {
    display: 'flex',

    '&:hover $icon': {
      opacity: 0.8,
      cursor: 'pointer',
    }
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginLeft: '5px',
    fontSize: 'inherit',
    cursor: 'pointer',
    transition: '100ms',
    opacity: 0.0
  },
  tooltip: {
    fontSize: '0.8em'
  }
});

function ReturnAlert(props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

const CopyLabel = (props) => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState("")

  const classes = useStyles();

  const handleClick = () => {    
    navigator.clipboard.writeText(props.value).then(() => {
      setMessage(`Copied "${props.value}" to your clipboard`)
      setOpen(true)
    })
    .catch(err => {
      setMessage('unable to copy item to clipboard')
      setOpenError(true)
    });    
  }

  const closeSnackBar = () => {
    setOpen(false)
    setOpenError(false)
  }

  return (
    <div className={classes.root}>
      {props.children}
      <Tooltip  
        title={<span style={{textAlign: 'center', display:'block'}}>{props.value} <br/> Click to copy</span>} 
        classes={{tooltip: classes.tooltip}}
        arrow 
        placement="top">

        <FileCopyOutlined className={classes.icon} onClick={handleClick}/>
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={5000}
        onClose={closeSnackBar}
        message={message}/>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={openError}
        autoHideDuration={5000}
        onClose={closeSnackBar}
        message={message}>
          <ReturnAlert onClose={closeSnackBar} severity="warning">
            {message}
          </ReturnAlert>      
      </Snackbar>
    </div>
  )
}

export default CopyLabel;