import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {SiteContext} from "../../Context";
import queryString from "query-string";
import {TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import SSTDropdown from "../../shared/components/SSTDropdown";
import MenuItem from '@mui/material/MenuItem';

import {
  getAllCustomers,
  getHub,
  updateHub,
  createHub,
  getEntityPermissions
} from '../../query/queries';
import { handlePermissionRedirect, PERMISSION_METHOD_INSERT, PERMISSION_METHOD_GET } from "../../shared/Utilities";

const acceptablePagePermission = [
  {entity: 'Hub', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'HubLog', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptyHub = {
  customerId: "",
  name: "",
  imei: ""
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px'
  }
})

const CreateAndEditHub = (props) => {

    const {setBreadcrumbs, currentCustomer, hasPermission} = useContext(SiteContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [isEdit, setIsEdit] = useState(false);

    const [hub, setHub] = useState(emptyHub)

    const {isLoading: isLoadingHub, data:hubData} = useQuery(['hub', {hubId: hub.id}], getHub, {enabled: !!hub.id});
    const {isLoading: isLoadingCustomers, data:customers=[]} = useQuery(['customers'], getAllCustomers);

    const {isLoading: isLoadingPermissions, data: permissions} = useQuery(['permissions', currentCustomer, 'hub', {ids: [hub.id]}], getEntityPermissions, {
      enabled: !!hub.id
    });

    const readOnly = isEdit ? !permissions?.hub[hub.id]?.update : false;
    const pageTitle = history.location.pathname === '/create-hub' ? 'Create Hub' : 'Edit Hub';

    const {mutate:mutateCreate, isLoading: isCreating} = useMutation(createHub, {
      onSuccess: (data) => {
        enqueueSnackbar('Hub Created', {variant: 'success'});
        queryClient.removeQueries('hubs');
        queryClient.setQueryData(['hub', {hubId: data.id}], data);
        history.replace(`/list-hubs`);
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    });

    const {mutate:mutateUpdate, isLoading: isUpdating} = useMutation(updateHub, {
      onSuccess: (data) => {
        enqueueSnackbar('Hub Updated', {variant: 'success'});
        queryClient.removeQueries('hubs');
        queryClient.setQueryData(['hub', {hubId: data.id}], data);
      },
      onError: ({response: {data}}) => {
        enqueueSnackbar(data.message, {variant: 'error'});
      }
    });

    useEffect(() => {
      if(hubData){
        setHub(h => {return {...h, ...hubData}});
      }
    }, [hubData])


    useEffect(() => {
        const qs = queryString.parse(props.location.search);

        //If an imei if specified in the query string, we are editing an existing hub.
        if (qs.hubId) {
            document.title = "Edit Hub";
            setIsEdit(true);
            setHub(h => {return {...h, id: qs.hubId}});            
            setBreadcrumbs([{title: "Edit Hub"}]);
        } else {
            document.title = "Create Hub";
            setIsEdit(false);
            setHub(emptyHub)
            setBreadcrumbs([{title: "Create Hub"}]);
        }        
    }, [props.location.search, setBreadcrumbs]);

    const submit = (e) => {
        e.preventDefault();
        
        if(isEdit){
          mutateUpdate(hub);
        }
        else {
          mutateCreate(hub);
        }
    };    

    const handleFormUpdate = (update) =>{
      setHub({...hub, ...update});
    }

    const classes = useStyles();

    return (
        <div data-testid={isEdit ? 'edit-hub-page' : 'create-hub-page'} className={classes.root}>
          {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
            <CreateEditWrapper
              onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-hubs')}
              buttonTitle={isEdit ? "Save" : "Create"}
              pageTitle={isEdit ? "Edit Hub" : "Create Hub"}
              submit={submit}
              isLoading={isLoadingHub || isLoadingCustomers || isUpdating || isCreating || isLoadingPermissions}
              disableSubmit={readOnly}
              classes={classes}>

                <div className={classes.content}>
                  <SSTDropdown 
                    classes={classes}
                    disabled={isLoadingHub || isUpdating || isCreating}
                    readOnly={readOnly}
                    isLoadingContents={isLoadingCustomers || isLoadingHub}
                    label={"Customers"}
                    selectedValueId={hub.customerId}
                    setValueFunc={event => {handleFormUpdate({customerId: event.target.value});}}
                    mappedList={customers.map((customer) => <MenuItem key={customer.id}
                                                                      value={customer.id}>{customer.name}</MenuItem>)}/>
                
                  <TextField 
                    value={hub.name} 
                    onChange={(e) => {handleFormUpdate({name: e.target.value})}}
                    label="Name"
                    autoComplete='disabled'
                    fullWidth 
                    autoFocus 
                    required 
                    disabled={isLoadingHub || isUpdating || isCreating}
                    InputProps={{
                      readOnly: readOnly
                    }}
                    className={classes.formControl} />
                
                  <TextField 
                    value={hub.imei} 
                    onChange={(e) => {handleFormUpdate({imei: e.target.value})}}
                    label="IMEI"
                    autoComplete='disabled'
                    fullWidth 
                    required 
                    disabled={isLoadingHub || isUpdating || isCreating}
                    InputProps={{
                      readOnly: readOnly
                    }}
                    className={classes.formControl}/>
                
                  <TextField 
                    value={hub.iccid || ""} 
                    onChange={(e) => {handleFormUpdate({iccid: e.target.value})}}
                    label="SIM"
                    autoComplete='disabled'
                    fullWidth 
                    disabled={isLoadingHub || isUpdating || isCreating}
                    InputProps={{
                      readOnly: readOnly
                    }}
                    className={classes.formControl}/>
                </div>
            </CreateEditWrapper>
          }
        </div>);
};

export default CreateAndEditHub;