import {useEffect} from "react";

const variant = {
    success: 'success',
    danger: 'danger',
    warning: 'warning'
};

const StatusVariant = () => {
    useEffect(() => {
    }, []);
};

StatusVariant.SUCCESS = variant.success;
StatusVariant.WARNING = variant.warning;
StatusVariant.DANGER = variant.danger;

export default StatusVariant;
