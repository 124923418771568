import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useMutation } from 'react-query'
import { validateLinemap } from '../../query/queries'
import LinemapEditor from '../../shared/components/LinemapEditor/LinemapEditor'

const useStyles = makeStyles({
  root: {
    padding: '40px',
    width: '100%',
    display: 'flex',
  },
  paletteContainer: {
    display: 'inline-block',
    marginRight: '40px',
    verticalAlign: 'top',
  },
  linemapContainer: {
    display: 'inline-block',
    height: '500px',
    width: '500px',
    overflow: 'auto',
  },
})

const paletteTiles = [
  10, 5, 0, 1, 7, 8, 0, 0, 4, 6, 0, 0, 12, 13, 0, 0, 9, 11, 0, 0, 20, 21, 0, 0,
  16, 17, 0, 0, 24, 25, 0, 0, 22, 23, 0, 0, 14, 15, 33, 34, 18, 19, 0, 0,
]

// const linemapData = new Array(1536).fill(0);

const LinemapBuilder = () => {
  const [status, setStatus] = useState()
  const [linemapData, setLinemapData] = useState(new Array(1536).fill(0))

  const classes = useStyles()
  const { mutate: mutateValidate } = useMutation(validateLinemap, {
    onSuccess: (data) => {
      console.log(data)
      setStatus('All Good')
    },
    onError: (error) => {
      console.log(error)
      setStatus('Sad Panda')
    },
  })

  const handleSave = (newData) => {
    console.log(newData)
    setLinemapData(newData)
    mutateValidate(newData)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.root}>
        <div className={classes.paletteContainer}>
          <LinemapEditor
            data={paletteTiles}
            height={11}
            width={4}
            tilesize={40}
            canDrop={false}
          />
          <p>{status}</p>
        </div>
        <div className={classes.linemapContainer}>
          <LinemapEditor
            data={linemapData}
            height={32}
            width={48}
            tilesize={40}
            canDrop={true}
            onChange={handleSave}
          />
        </div>
      </div>
    </DndProvider>
  )
}

export default LinemapBuilder
