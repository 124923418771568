import {axios} from '../client';

import {
  SST_API_PATH_RUNS,
  SST_API_PATH_RUNS_EXTRACT,
  SST_API_PATH_RUNS_DECOMPRESS
} from './../../Constants';

export const getRun = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}`).then(res => res.data);
}

export const deleteRun = (runId) => {
  return axios.delete(`${SST_API_PATH_RUNS}/${runId}`).then(res => res.data);
}

export const getRunDetails = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/details`).then(res => res.data);
}

export const getRunFlags = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/flags`).then(res => res.data);
}

export const getRunPressureData = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/max-pressure`).then(res => res.data);
}

export const getRunPressureMapDataRadius = (runId, timeOffset, radiusInSeconds) => {
  const queryParams = { timeOffset: timeOffset, radiusInSeconds: radiusInSeconds };
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/pressure-map/radius`, {
    params: queryParams
  }).then(res => res.data);
}

export const getRunShockData = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/shock`).then(res => res.data);
}

export const getRunTiltData = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/tilt`).then(res => res.data);
}

export const getRunTopLoadData = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/top-load`).then(res => res.data);
}

export const getRunSpinData = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/spin`).then(res => res.data);
}

export const getRunRotationData = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/data/rotation`).then(res => res.data);
}

export const getDroneConfigurationSensorCapabilities = ({queryKey}) => {
  const [, {runId}] = queryKey;
  return axios.get(`${SST_API_PATH_RUNS}/${runId}/drone-configuration/sensor-capabilities`).then(res => res.data);
}

export const queueRunForExtraction = (runId) => {
  return axios.post(`${SST_API_PATH_RUNS}/${runId}/${SST_API_PATH_RUNS_EXTRACT}`).then(res => res.data);
}

export const decompressRunData = (runId) => {
  return axios.post(`${SST_API_PATH_RUNS}/${runId}/${SST_API_PATH_RUNS_DECOMPRESS}`).then(res => res.data);
}

export const queueRunForLinemapGeneration = (runId) => {
  return axios.post(`${SST_API_PATH_RUNS}/${runId}/queue-for-linemap-generation`).then(res => res.data);
}