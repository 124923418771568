import * as GreyScale from '@Theme/GreyScale'
import ReactEcharts from 'echarts-for-react'
import React, { useRef, useEffect, useState, useMemo } from 'react'
import useStyles from './Styles'
import {
  getChartData,
  getChartFields,
  getUpdatedAxesDataZoom,
  getUpdatedLegendSelected,
  getValueByTimeInTimeSeriesDataModel,
  getRotationOrientationByTime,
  formatDataValue,
  formatTimeValue,
  NO_TIME_SERIES_DATA,
} from './Helpers'
import LineRunStatistics from '../LineRunStatistics/LineRunStatistics'
import OriginalTheme from '../../Theme/OriginalTheme'
import { MenuItem, TextField } from '@mui/material'

const TIME = "time";
const INSIDE = 'inside';

const valueDictionary = {
  maxPressure: {
    dataName: 'maxPressure',
    displayName: 'Max Pressure',
    measurementUnit: 'psi',
    color: OriginalTheme.palette.orange,
  },
  topLoad: {
    dataName: 'topLoad',
    displayName: 'Top Load',
    measurementUnit: 'lbf',
    color: '#d02c2f',
  },
  shock: {
    dataName: 'shock',
    displayName: 'Shock',
    measurementUnit: 'g',
    color: '#0E4DA4',
  },
  tilt: {
    dataName: 'tilt',
    displayName: 'Tilt',
    measurementUnit: '°',
    color: '#31C531',
    axisMax: 180,
  },
  spin: {
    dataName: 'spin',
    displayName: 'Spin',
    measurementUnit: 'rpm',
    color: OriginalTheme.palette.spinOrange,
  },
  rotation: {
    dataName: 'rotation',
    displayName: 'Rotation',
    measurementUnit: '°',
    color: OriginalTheme.palette.rotationYellow,
  },
}

const defaultLegendSelected = {
  [valueDictionary.maxPressure.displayName]: true,
  [valueDictionary.topLoad.displayName]: true,
  [valueDictionary.shock.displayName]: true,
  [valueDictionary.tilt.displayName]: true,
  [valueDictionary.spin.displayName]: true,
  [valueDictionary.rotation.displayName]: true,
}

const defaultDataZoom = { start: 0, end: 100 }; // 0% to 100%, i.e. the whole axis width...

const defaultAxesDataZoom = {
  [TIME]: {...defaultDataZoom},
  [valueDictionary.maxPressure.dataName]: {...defaultDataZoom},
  [valueDictionary.topLoad.dataName]: {...defaultDataZoom},
  [valueDictionary.shock.dataName]: {...defaultDataZoom},
  [valueDictionary.tilt.dataName]: {...defaultDataZoom},
  [valueDictionary.spin.dataName]: {...defaultDataZoom},
  [valueDictionary.rotation.dataName]: {...defaultDataZoom}
}

const LineRunChart = (props) => {

  const
    maxPressureData = props.maxPressureData,
    topLoadData = props.topLoadData,
    shockData = props.shockData,
    tiltData = props.tiltData,
    spinData = props.spinData,
    rotationData = props.rotationData,
    maxPressureAxisMax = props.maxPressureAxisMax,
    topLoadAxisMax = props.topLoadAxisMax,
    shockAxisMax = props.shockAxisMax,
    spinAxisMax = props.spinAxisMax,
    rotationAxisMax = props.rotationAxisMax,
    externalSelectedTime = props.selectedTime,
    flags = props.flags || [],
    selectedFlag = props.selectedFlag,
    onSeriesDataPointClick = props.onSeriesDataPointClick,
    resetZoomState = props.resetZoomState,
    onResetZoomStateComplete = props.onResetZoomStateComplete,
    resetState = props.resetState,
    onResetStateComplete = props.onResetStateComplete;

  const [fields, data] = useMemo(() => {
    return [
      getChartFields(maxPressureData, topLoadData, shockData, tiltData, spinData, rotationData),
      getChartData(maxPressureData, topLoadData, shockData, tiltData, spinData, rotationData)
    ];
  }, [maxPressureData, topLoadData, shockData, tiltData, spinData, rotationData]);

  const
    xAxisIndex = fields.indexOf('time'),
    xAxisData = data[xAxisIndex],
    yAxisIndexes = fields.slice(1),
    yAxisData = data.filter((_, index) => index !== xAxisIndex);

  const [selectedMaxPressure, setSelectedMaxPressure] = useState(null);
  const [selectedTopLoad, setSelectedTopLoad] = useState(null);
  const [selectedShock, setSelectedShock] = useState(null);
  const [selectedTilt, setSelectedTilt] = useState(null);
  const [selectedSpin, setSelectedSpin] = useState(null);
  const [selectedRotation, setSelectedRotation] = useState(null);
  const [selectedTime, setSelectedTime] = useState(externalSelectedTime);

  const [axesDataZoom, setAxesDataZoom] = useState(defaultAxesDataZoom);

  const [showMaxPressure, setShowMaxPressure] = useState(true);
  const [showTopLoad, setShowTopLoad] = useState(true);
  const [showShock, setShowShock] = useState(true);
  const [showTilt, setShowTilt] = useState(true);
  const [showSpin, setShowSpin] = useState(true);
  const [showRotation, setShowRotation] = useState(true);
  const [legendSelected, setLegendSelected] = useState(defaultLegendSelected);

  const [selectedYAxisIndex, setSelectedYAxisIndex] = useState(0);

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount,
   * Bind event handlers on chart...
   */
  const chartRef = useRef(null);
  useEffect(() => {

    let chart = chartRef.current.getEchartsInstance();

    chart.on('click', function (event) {
      handleChartClick(event);
    });

    chart.on('dataZoom', function (event) {
      handleDataZoom(event);
    });

    // Unbind event handlers on component unmount, or when rebinding as result of useEffect dependency change...
    return () => {
      chart.off("")
    }

  }, [yAxisIndexes]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to maxPressureAxisMax, topLoadAxisMax, shockAxisMax, spinAxisMax
   * Update valueDictionary.
   */
  useEffect(() => {
    valueDictionary.maxPressure.axisMax = maxPressureAxisMax;
    valueDictionary.topLoad.axisMax = topLoadAxisMax;
    valueDictionary.shock.axisMax = shockAxisMax;
    valueDictionary.spin.axisMax = spinAxisMax;
    valueDictionary.rotation.axisMax = rotationAxisMax;
  }, [maxPressureAxisMax, topLoadAxisMax, shockAxisMax, spinAxisMax, rotationAxisMax]);

  /**
   * Given changes to showMaxPressure, showTopLoad, showShock, showTilt, showSpin
   * Update legend selection / setLegendSelected()
   */
  useEffect(() => {
    setLegendSelected(
      getUpdatedLegendSelected(legendSelected, showMaxPressure, showTopLoad, showShock, showTilt, showSpin, showRotation, valueDictionary)
    )
  }, [showMaxPressure, showTopLoad, showShock, showTilt, showSpin, showRotation]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to resetZoomState,
   * Reset state of dataZoom and callback to the parent to notify of reset completion...
   */
  useEffect(() => {

    if (resetZoomState) {

      // Reset zoom...
      setAxesDataZoom(defaultAxesDataZoom);

      // Callback...
      onResetZoomStateComplete();
    }
  }, [resetZoomState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to resetState,
   * Reset state of select state variables and callback to the parent to notify of reset completion...
   */
  useEffect(() => {

    if (resetState) {

      // Reset...
      setAxesDataZoom(defaultAxesDataZoom);
      setLegendSelected(defaultLegendSelected);

      // Callback...
      onResetStateComplete();
    }
  }, [resetState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to externalSelectedTime,
   * Set (internal) selectedTime, to ensure externalSelectedTime and selectedTime are in sync...
   */
  useEffect(() => {
    setSelectedTime(externalSelectedTime);
  }, [externalSelectedTime]);

  /**
   * Given changes to maxPressureData, topLoadData, shockData, tiltData, spinData or selectedTime,
   * Set selected values, given selectedTime...
   */
  useEffect(() => {
    setSelectedMaxPressure(getValueByTimeInTimeSeriesDataModel(maxPressureData, selectedTime));
    setSelectedTopLoad(getValueByTimeInTimeSeriesDataModel(topLoadData, selectedTime));
    setSelectedShock(getValueByTimeInTimeSeriesDataModel(shockData, selectedTime));
    setSelectedTilt(getValueByTimeInTimeSeriesDataModel(tiltData, selectedTime));
    setSelectedSpin(getValueByTimeInTimeSeriesDataModel(spinData, selectedTime));

    // Rotation...
    let rotationOrientationAtTime = getRotationOrientationByTime(rotationData, selectedTime)
    rotationOrientationAtTime = rotationOrientationAtTime === null ? 0 : rotationOrientationAtTime;
    rotationOrientationAtTime = rotationOrientationAtTime === NO_TIME_SERIES_DATA ? null : rotationOrientationAtTime;
    setSelectedRotation(rotationOrientationAtTime);

  }, [maxPressureData, topLoadData, shockData, tiltData, spinData, rotationData, selectedTime]);

  // --------------------
  // -- END useEffects --
  // --------------------

  // --------------------------
  // -- BEGIN eChart options --
  // --------------------------

  const getSeriesOptions = ({name, data, color, index}) => {

    return {
      name: name,
      type: 'line',
      data: data.map((y, i) => [xAxisData[i], y]),
      animation: false,
      symbolSize: 10,
      zlevel: 9,
      z: 9,
      ticks: {
        beginAtZero: true,
      },
      showSymbol: false,
      symbol: 'circle',
      yAxisIndex: index,
      emphasis: {
        itemStyle: {
          color: color,
          borderWidth: 2,
        },
      },
      markLine: {
        symbol: 'none',
        animation: false,
        data: [
          ...flags.map((flag) => ({
            xAxis: flag.time,
            lineStyle: {
              type:
                !!selectedFlag?.id && selectedFlag?.id === flag.id
                  ? 'solid'
                  : 'dashed',
              color:
                !!selectedFlag?.id && selectedFlag?.id === flag.id
                  ? GreyScale.GREY_3
                  : GreyScale.GREY_2,
              width: 2,
            },
            label: {
              formatter: `${flag.name} - ${formatTimeValue(flag.time)}s`,
              show:
                !!selectedFlag?.id && selectedFlag?.id === flag.id
                  ? true
                  : false,
              offset: [0, -16], // Offset position by 16 pixels, along the y-axis...
            },
            emphasis: {
              label: {
                show: true,
              },
              lineStyle: {
                width: 2,
                color: GreyScale.GREY_3,
              },
            },
          })),
          ...getSelectedTimeMarkLineOptions()
        ]
      },
    }
  }

  const getSelectedTimeMarkLineOptions = () => {

    if (selectedTime === null ||
        selectedTime === undefined) {
      return [];
    }

    const selectedTimeFloat = parseFloat(selectedTime);

    return ([
        {
          xAxis: selectedTimeFloat,
          lineStyle: {
            type: 'solid',
            color: 'black',
            width: 5,
          },
          label: {
            formatter: `${formatTimeValue(selectedTimeFloat)}s`,
            show: true,
            offset: [0, 0], // Prevent label from moving at all, which is possible when other markLines have offsets...
          },
        }]
    )
  }

  const getCommonDataZoomOptions = () => {

    const commonSliderDataZoomOptions = {
      type: 'slider',
      filterMode: 'none', // Prevent data points from being removed when zooming...
      fillerColor: 'rgba(206,206,206,0.6)',
      backgroundColor: GreyScale.GREY_1,
      dataBackground: {
        lineStyle: {
          color: 'rgba(100,100,100,0.6)',
        },
      },
      moveHandleStyle: {
        color: GreyScale.GREY_1,
        opacity: 1,
      },
      emphasis: {
        moveHandleStyle: {
          color: GreyScale.GREY_1,
        },
      }
    }

    const commonInsideDataZoomOptions = {
      type: 'inside',
      filterMode: 'none', // Prevent data points from being removed when zooming...
    }

    return [
      commonSliderDataZoomOptions,
      commonInsideDataZoomOptions
    ]
  }

  const getDataZoomOptions = () => {

    const commonSliderZoomOptions = getCommonDataZoomOptions()[0];
    const commonInsideZoomOptions = getCommonDataZoomOptions()[1];

    let zoomOptions = [];

    // X / Time axis...
    zoomOptions.push(
      {
        id: TIME,
        start: axesDataZoom[TIME].start,
        end: axesDataZoom[TIME].end,
        labelFormatter: function(value) {
          return formatTimeValue(value);
        },
        ...commonSliderZoomOptions,
      },
      {
        id: TIME + INSIDE, // Cannot duplicate IDs, so append TIME...
        start: axesDataZoom[TIME].start,
        end: axesDataZoom[TIME].end,
        ...commonInsideZoomOptions
      }
    )

    // Y axes...
    yAxisIndexes.forEach((data, index) => {
      let valueDefinition = valueDictionary[data]
      zoomOptions.push(
        {
          id: valueDefinition.dataName,
          yAxisIndex: [index],// Specify the y-axes you want to enable data zoom on (0 for the first y-axis)
          start: axesDataZoom[valueDefinition.dataName].start,
          end: axesDataZoom[valueDefinition.dataName].end,
          show: selectedYAxisIndex === index && legendSelected[valueDefinition.displayName], // Show zoom slider for data stream, if data stream is selected y-axis data stream and is selected in legend...
          disabled: selectedYAxisIndex !== index,
          left: 40,
          labelFormatter: function(value) {
            return formatDataValue(value);
          },
          ...commonSliderZoomOptions,
        },
        {
          id: valueDefinition.dataName + INSIDE, // Cannot duplicate IDs, so append TIME...
          yAxisIndex: [index], // Specify the y-axes you want to enable data zoom on (0 for the first y-axis)
          start: axesDataZoom[valueDefinition.dataName].start,
          end: axesDataZoom[valueDefinition.dataName].end,
          disabled: selectedYAxisIndex !== index,
          ...commonInsideZoomOptions
        }
      )
    })

    return zoomOptions;
  }

  const eChartOptions = {
    color: yAxisIndexes.map((data) => {
      return valueDictionary[data].color
    }),
    grid: {
      top: 50,
      left: 135,
      right: 70,
    },
    legend: {
      show: false,
      data: yAxisIndexes.map((data) => {
        return valueDictionary[data].displayName
      }),
      selected: legendSelected,
    },
    tooltip: {
      trigger: 'axis',
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.4)',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params) {

        let dataTypeRecords = params.map((data, index) => {

          let dataKey =
            data.seriesName === 'Max Pressure' ? 'maxPressure'
              : data.seriesName === 'Top Load' ? 'topLoad'
              : data.seriesName.toLowerCase();

          // Exclude if null, unless rotation...
          if ( params[index].data[1] === null &&
               valueDictionary[dataKey].dataName !== valueDictionary.rotation.dataName ) {
            return null;
          }

          return `
            <div>
              <span style="display:inline-block;margin:0px 0px;border-radius:10px;width:10px;height:10px;background-color:${valueDictionary[dataKey].color}"></span>
              &nbsp;${valueDictionary[dataKey].displayName}:
            </div>
            <div style="text-align:right;">
              <b>${formatDataValue(params[index].data[1])}</b> <!-- formatDataValue(null) will return '0.00' -->
            </div>
            <div style="text-align:left;">
              ${
                  ( valueDictionary[dataKey].dataName !== valueDictionary.tilt.dataName && 
                    valueDictionary[dataKey].dataName !== valueDictionary.rotation.dataName )  ? // If not tilt or rotation...
                      `&nbsp${valueDictionary[dataKey].measurementUnit}` : // add space...
                           `${valueDictionary[dataKey].measurementUnit}` // else don't...
              }
            </div>
          `
        })

        return `<div style="display: flex; flex-direction: column; width:175px;">
            <div style="display: flex; justify-content: center;">
              <b>
                ${formatTimeValue(params[0].data[0])}
              </b>&nbsp;Seconds
            </div>
              <div style="display: grid; grid-template-columns:4fr 2fr 1fr">
                ${dataTypeRecords.join('')}
              </div>
            </div>
        </div>`
      },
    },
    dataZoom: [
      ...getDataZoomOptions()
    ],
    xAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value) {
          return formatTimeValue(value);
        }
      },
      axisPointer: {
        label: {
          formatter: function(params) {
            return formatTimeValue(params.value).toString();
          },
        }
      },
      axisTick: {
        alignWithLabel: true,
        show: false,
      },
      splitLine: {
        show: false,
      },
      itemStyle: {
        color: GreyScale.GREY_6,
      },
      min: 0,
      max: (v) => formatTimeValue(v.max),
    },
    yAxis: yAxisIndexes.map((data, index) => {
      let valueDefinition = valueDictionary[data]
      return {
        name: valueDefinition.displayName + ' ' + '(' + valueDefinition.measurementUnit + ')',
        nameLocation: 'center',
        nameGap: 105,
        position: 'left',
        axisLabel: {
          formatter: function(value) {
            return formatDataValue(value);
          },
        },
        axisPointer: {
          label: {
            backgroundColor: valueDefinition.color,
            show: selectedYAxisIndex === index,
          },
        },
        show: selectedYAxisIndex === index,
        max: valueDefinition.axisMax,
      }
    }),
    series: yAxisData.map((yData, index) => {
      let valueDefinition = valueDictionary[yAxisIndexes[index]]

        return getSeriesOptions({
          name: valueDefinition.displayName,
          data: yData,
          color: valueDefinition.color,
          index: index,
        });

    }),
  }

  // --------------------------
  // -- END eChart options --
  // --------------------------

  const handleChartClick = (event) => {

    if (event.componentType === 'series') {
      let timeValue = event.value[0]; // Time...

      // Parent component callback / aka "Set (external) time"...
      onSeriesDataPointClick(timeValue);

      // Set (internal) time...
      setSelectedTime(timeValue);
    }
  }

  const handleDataZoom = (event) => {

    if (event.hasOwnProperty("batch")) {

      // If batch, then multiple dataZooms were updated at once, therefore, loop...

      event.batch.forEach((event) => {

        if (event.dataZoomId === TIME + INSIDE) {
          setAxesDataZoom(prevState => getUpdatedAxesDataZoom(prevState, TIME, event.start, event.end))
        }

        yAxisIndexes.forEach((data, index) => {
          let valueDefinition = valueDictionary[data]
          if (event.dataZoomId === valueDefinition.dataName + INSIDE) {
            setAxesDataZoom(prevState => getUpdatedAxesDataZoom(prevState, valueDefinition.dataName, event.start, event.end))
          }
        })

      })

    } else {

      // If not batch, then only one dataZoom was updated, therefore, no need to loop...

      if (event.dataZoomId === TIME) {
        setAxesDataZoom(prevState => getUpdatedAxesDataZoom(prevState, TIME, event.start, event.end) )
        return;
      }

      for (const data of yAxisIndexes) {
        let valueDefinition = valueDictionary[data]
        if (event.dataZoomId === valueDefinition.dataName) {
          setAxesDataZoom(prevState => getUpdatedAxesDataZoom(prevState, valueDefinition.dataName, event.start, event.end))
          break;
        }
      }
    }

  }

  const toggleMaxPressure = () => {
    setShowMaxPressure(!showMaxPressure);
  }

  const toggleTopLoad = () => {
    setShowTopLoad(!showTopLoad);
  }

  const toggleShock = () => {
    setShowShock(!showShock);
  }

  const toggleTilt = () => {
    setShowTilt(!showTilt);
  }

  const toggleSpin = () => {
    setShowSpin(!showSpin);
  }

  const toggleRotation = () => {
    setShowRotation(!showRotation);
  }

  const handleSelectYAxisSelectOnChange = (event) => {
    setSelectedYAxisIndex(event.target.value)
  }

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles()

  const renderYAxisSelect = () => {

    return (
      <div className={classes.yAxisSelectContainer}>
        <TextField
          select
          className={classes.yAxisSelect}
          label={"y-axis"}
          value={selectedYAxisIndex}
          onChange={handleSelectYAxisSelectOnChange}
        >
          {
            yAxisIndexes.map((data, index) => {
              return (
                <MenuItem key={index} value={index}>{valueDictionary[data].displayName}</MenuItem>
              )
            })
          }
        </TextField>
      </div>
    )
  }

  return (
    <div className={classes.root}>

      <div className={classes.chartSideMenu}>

        {renderYAxisSelect()}

        <LineRunStatistics
          maxPressure={selectedMaxPressure}
          topLoad={selectedTopLoad}
          shock={selectedShock}
          tilt={selectedTilt}
          spin={selectedSpin}
          rotation={selectedRotation}
          showMaxPressure={selectedMaxPressure !== NO_TIME_SERIES_DATA}
          showTopLoad={selectedTopLoad !== NO_TIME_SERIES_DATA}
          isMaxPressureSelected={legendSelected[valueDictionary.maxPressure.displayName]}
          isTopLoadSelected={legendSelected[valueDictionary.topLoad.displayName]}
          isShockSelected={legendSelected[valueDictionary.shock.displayName]}
          isTiltSelected={legendSelected[valueDictionary.tilt.displayName]}
          isSpinSelected={legendSelected[valueDictionary.spin.displayName]}
          isRotationSelected={legendSelected[valueDictionary.rotation.displayName]}
          onMaxPressureClick={toggleMaxPressure}
          onTopLoadClick={toggleTopLoad}
          onShockClick={toggleShock}
          onTiltClick={toggleTilt}
          onSpinClick={() => { toggleSpin(); toggleRotation()} }
        />

      </div>

      <div className={classes.chartContainer}>
        <ReactEcharts
          ref={chartRef}
          className={classes.chart}
          option={eChartOptions}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>
    </div>
  )
}

export default LineRunChart
