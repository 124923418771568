import * as React from "react";
import {useContext, useEffect, useLayoutEffect, useState} from "react";
import {convertXData, convertYData, G, generateTimeWaveformGraphData, SECONDS} from "../MultiSensorGraphsUtilities";
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment';
import {DATE_TIME_FORMAT} from "../Utilities";
import EchartGraph, {ICON_EXPORT_CSV, ICON_EXPORT_IBA} from "./EchartGraph/EchartGraph";
import {fetchDownloadLink, fetchRequestDetailedVibrationData} from "../FetchUtilities";
import {
    DATA_EXPORT_CSV,
    DATA_EXPORT_IBA,
    DATA_FORMAT_CSV,
    DATA_FORMAT_JSON,
    DATA_TYPE_TIME_WAVEFORM
} from "../../Constants";
import {SiteContext} from "../../Context";
import {Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import echarts

const useStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      height: '100%',
  },
  graphContainer: {
    height:'100%'
  },
}));

const TimeWaveformDisplay = (props) => {

    const siteContext = useContext(SiteContext);

    const [selectedXUnit] = useState(SECONDS);
    const [selectedYUnit] = useState(G);
    const [vibrationFullXConverted, setVibrationFullXConverted] = useState([]);
    const [vibrationFullYConverted, setVibrationFullYConverted] = useState([]);
    const [vibrationFullZConverted, setVibrationFullZConverted] = useState([]);

    const [isLoadingFullTimeWaveformReading, setIsLoadingFullTimeWaveformReading] = useState(false);
    const [detailedTimeWaveformSelectedReading, setDetailedTimeWaveformSelectedReading] = useState();

    const [xAxisChartData, setXAxisChartData] = useState([]);
    const [yAxisChartData, setYAxisChartData] = useState([]);
    const [zAxisChartData, setZAxisChartData] = useState([]);
    const [dateRecorded, setDateRecorded] = useState("");

    useEffect(() => {
        if (typeof detailedTimeWaveformSelectedReading !== "undefined") {
            generateTimeWaveformGraphData(detailedTimeWaveformSelectedReading, setXAxisChartData, setYAxisChartData, setZAxisChartData, setDateRecorded);
        }
    }, [detailedTimeWaveformSelectedReading]);

    useLayoutEffect(() => {
        convertXData(xAxisChartData, xAxisChartData, selectedXUnit, setVibrationFullXConverted);
        convertXData(yAxisChartData, yAxisChartData, selectedXUnit, setVibrationFullYConverted);
        convertXData(zAxisChartData, zAxisChartData, selectedXUnit, setVibrationFullZConverted);

        convertYData(xAxisChartData, xAxisChartData, selectedYUnit, setVibrationFullXConverted);
        convertYData(yAxisChartData, yAxisChartData, selectedYUnit, setVibrationFullYConverted);
        convertYData(zAxisChartData, zAxisChartData, selectedYUnit, setVibrationFullZConverted);

    }, [zAxisChartData, yAxisChartData, xAxisChartData, selectedXUnit, selectedYUnit]);

    const toolbox = {
      saveAsImage: {
        title: "save as PNG",
        type: 'png',
      },
      dataZoom: {
        yAxisIndex: 'none',
        title: {
          zoom: "zoom",
          back: "back"
        }
      },
      myDownloadCsv: {
        show: true,
        title: "Download CSV",
        icon: ICON_EXPORT_CSV,
        onclick: function () {
          fetchDownloadLink(
            props.macAddress,
            props.vibrationReadingId,
            DATA_TYPE_TIME_WAVEFORM,
            DATA_FORMAT_CSV,
            DATA_EXPORT_CSV,
            setIsLoadingFullTimeWaveformReading,
            siteContext.userDetails.token)
        }
      },
      myDownloadIba: {
        show: true,
        title: "Download IBA",
        icon: ICON_EXPORT_IBA,
        onclick: function () {
          fetchDownloadLink(
            props.macAddress,
            props.vibrationReadingId,
            DATA_TYPE_TIME_WAVEFORM,
            DATA_FORMAT_CSV,
            DATA_EXPORT_IBA,
            setIsLoadingFullTimeWaveformReading,
            siteContext.userDetails.token)
        }
      },
    }
  


    useEffect(() => {
        if (typeof props.vibrationReadingId !== "undefined") {
            fetchRequestDetailedVibrationData(props.vibrationReadingId,
                DATA_TYPE_TIME_WAVEFORM,
                DATA_FORMAT_JSON,
                true,
                setIsLoadingFullTimeWaveformReading,
                setDetailedTimeWaveformSelectedReading,
                siteContext.userDetails.token);
            // setupToolbox();
        }
    }, [props.vibrationReadingId, siteContext.userDetails.token]);


    const classes = useStyles();

    return <div className={classes.root}>
        {(xAxisChartData.length === 0)
            ? <center>
                <Typography component="p" variant="subtitle2" align="center">
                    <b>{props.noDataMessage}</b>
                </Typography>
            </center>
            : <>
                {(props.showDateRecorded)
                    ? <center>
                        <Typography component="p" variant="subtitle2" align="center">
                            <b>
                                <u>Recorded <ProtectedMoment date={dateRecorded} format={DATE_TIME_FORMAT}/></u>
                            </b>
                        </Typography>
                    </center>
                    : <></>}
                <div className={classes.graphContainer}>
                    <EchartGraph
                        className={classes.timeWaveform}
                        chartRef={props.chartRef}
                        xAxisName={"x-Axis"}
                        yAxisName={"y-Axis"}
                        zAxisName={"z-Axis"}
                        xAxisData={vibrationFullXConverted}
                        yAxisData={vibrationFullYConverted}
                        zAxisData={vibrationFullZConverted}
                        selectedXUnit={selectedXUnit}
                        selectedYUnit={selectedYUnit}
                        showToolbox={props.showToolbox}
                        toolboxFeatures={props.showToolbox ? toolbox : {}}
                        isLoading={isLoadingFullTimeWaveformReading}/>
                </div>

            </>}

    </div>
};

export default TimeWaveformDisplay;
