import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({

    contentContainersContainer: {
        display: 'flex',
        flex: 1,
        padding: 16,
        flexDirection: 'column',
        backgroundColor: 'white',

        // Apply border to bottom of all children, except the last child...
        '& > :not(:last-child)': {
            borderBottom: '1px solid black'
        },

        // Since real estate is limited, reduce the font of certain elements holding
        // text found in Support component, so we take up less room...
        '& div, & input, & textarea, & label, & p': {
            fontSize: '14px !important',
        },
    },
    formContainer: {
        flex: 1,
    },
    contentContainer: {
        display: 'flex',
        padding: 16,
        flexDirection: 'column',
    },
    contentContainerHeader: {
        fontWeight: 500,
        fontSize: 24,
        marginBottom: 16,
    },
    keyValuePairContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    keyValuePairKey: {
        marginRight: 8,
    },
    link: {
        wordBreak: 'break-word',
    },
    linkText: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.accent.main,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.accent.main,
        }
    }
}));

export default useStyles
