import {axios} from '../client';

import {
  SST_API_PATH_HUBS
} from './../../Constants';

export const getHubs = ({queryKey}) => {
  const [, , queryParams] = queryKey;

  return axios.get(`${SST_API_PATH_HUBS}`, {
    params: queryParams
  }).then(res => res.data);
}

export const getHub = ({queryKey}) => {
  const [, params , queryParams] = queryKey;

  const hubId = params.hubId || params.hubImei;

  return axios.get(`${SST_API_PATH_HUBS}/${hubId}`, {
    params: queryParams
  }).then(res => res.data);
}

export const updateHub = (hub) => {
  const hubImei = hub.imei;
  if(hub.iccid === ""){
    hub.iccid = undefined; 
  }
  return axios.put(`${SST_API_PATH_HUBS}/${hubImei}`, hub).then(res => res.data);
}

export const createHub = (hub) => {
  if(hub.iccid === ""){
    hub.iccid = undefined; 
  }
  return axios.post(`${SST_API_PATH_HUBS}`, hub).then(res => res.data);
}

export const getHubLogs = ({queryKey}) => {
  const [, {hubImei}] = queryKey;
  return axios.get(`${SST_API_PATH_HUBS}/${hubImei}/logs`).then(res => res.data);
}