import {
  Add as AddIcon,
  Close as CloseIcon,
  Help as HelpIcon,
} from '@mui/icons-material'
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import useStyles from './Styles'

export const genNormalizedUserRoles = (userRoles, roles) => {
  return userRoles
    .map((ur) => {
      const role = roles.find((r) => r.id === ur.roleId)
      if (!role) {
        return undefined
      }
      const retval = {
        name: role.name,
        access: role.scopeEntity,
      }
      return { ...retval, ...ur.roleValues }
    })
    .filter((ur) => !!ur)
}

export const genUserRolesFromNormalizedUserRoles = (normalized, roles) => {
  return normalized.map((n) => {
    const { name, access, ...rest } = n
    const retval = {
      roleId: roles.find((r) => r.name === name && r.scopeEntity === access)
        ?.id,
      roleValues: {
        ...rest,
      },
    }
    return retval
  })
}

export const matchingRoles = (x, y) => {
  return (
    x.roleId === y.roleId &&
    x.roleValues &&
    y.roleValues &&
    x.roleValues.customerId === y.roleValues.customerId &&
    x.roleValues.plantId === y.roleValues.plantId &&
    x.roleValues.lineId === y.roleValues.lineId
  )
}

export const validateRoles = (userRoles) => {
  return !userRoles.some((ur) => {
    if (!ur.name || !ur.access) {
      return true
    }
    if (ur.access === 'Plant' && !ur.plantId) {
      return true
    }
    if (ur.access === 'Line' && !ur.lineId) {
      return true
    }
    if (ur.access === 'Customer' && !ur.customerId) {
      return true
    }
    return false
  })
}

export const computeRoleDelta = (og, newUserRoles) => {
  //trim down role objects from DB
  const originalUserRoles = og.map((o) => ({
    id: o.id,
    roleId: o.roleId,
    roleValues: o.roleValues,
  }))

  //compute added roles:
  const rolesToAdd = newUserRoles.filter((nur) => {
    return !originalUserRoles.find((our) => matchingRoles(our, nur))
  })

  //compute removed roles:
  const rolesToRemove = originalUserRoles.filter((our) => {
    return !newUserRoles.find((nur) => matchingRoles(nur, our))
  })

  return {
    added: rolesToAdd,
    removed: rolesToRemove,
  }
}

const RolesManager = (props) => {
  const {
    userRoles,
    customerId,
    roles,
    lines,
    plants,
    addingRole: isAdding,
  } = props

  const RoleToIdDictionary = roles.reduce((memo, role) => {
    //Since we don't support line, plant level roles yet, we want to omit them from the listing.
    if (role.scopeEntity !== 'Line' && role.scopeEntity !== 'Plant') {
      memo[role.name] = {
        name: role.name,
        access: {
          ...memo[role.name]?.access,
          [role.scopeEntity]: {
            access: role.scopeEntity,
            id: role.id,
          },
        },
      }
    }

    return memo
  }, {})

  const classes = useStyles()

  const handleUpdate = (index, { key, value }) => {
    if (key === 'name') {
      userRoles[index] = { [key]: value, access: '' }
    } else if (key === 'access') {
      userRoles[index][key] = value
      if (value === 'Customer') {
        userRoles[index].customerId = customerId
      }
    }
    props.onUpdate([...userRoles])
  }

  const removeRole = (index) => {
    userRoles.splice(index, 1)
    props.onUpdate([...userRoles])
  }

  const addRole = () => {
    props.onUpdate([...userRoles, { name: '' }])
  }

  const findRoleByName = (name) => {
    return roles.find((r) => r.name === name)
  }

  return (
    <div>
      <div className={classes.toolbar}>
        <Typography variant="h5" display="inline">
          Manage Roles
        </Typography>
        <IconButton
          size="small"
          className={classes.addButton}
          onClick={addRole}
        >
          <AddIcon />
        </IconButton>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell classes={{ head: classes.head }}>Role Name</TableCell>
              <TableCell classes={{ head: classes.head }}>Access</TableCell>
              <TableCell classes={{ head: classes.head }}>Plant</TableCell>
              <TableCell classes={{ head: classes.head }}>Line</TableCell>
              <TableCell classes={{ head: classes.head }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRoles.map((r, i) => {
              return (
                <TableRow
                  className={classes.newRoleRow}
                  key={`Role-Row-${r.name}-${i}`}
                >
                  <TableCell className={classes.newRoleCell}>
                    <TextField
                      className={classes.roleSelector}
                      value={r.name}
                      select
                      disabled={isAdding}
                      error={!r.name}
                      SelectProps={{
                        renderValue: (v) => {
                          return v ? v : <i>Role Name</i>
                        },
                        displayEmpty: true,
                      }}
                      onChange={(e) =>
                        handleUpdate(i, { key: 'name', value: e.target.value })
                      }
                    >
                      {Object.keys(RoleToIdDictionary).map((k) => (
                        <MenuItem value={k} key={k}>
                          {k}
                        </MenuItem>
                      ))}
                    </TextField>
                    {r.name && (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={findRoleByName(r.name)?.description}
                        placement="top"
                      >
                        <HelpIcon className={classes.roleDescription} />
                      </Tooltip>
                    )}
                  </TableCell>
                  {r.name ? (
                    <TableCell className={classes.newRoleCell}>
                      <TextField
                        className={classes.roleSelector}
                        value={r.access}
                        select
                        disabled={isAdding}
                        error={!r.access}
                        SelectProps={{
                          renderValue: (v) => {
                            return v ? v : <i>Access Level</i>
                          },
                          displayEmpty: true,
                        }}
                        onChange={(e) =>
                          handleUpdate(i, {
                            key: 'access',
                            value: e.target.value,
                          })
                        }
                      >
                        {/* {console.log(RoleToIdDictionary[r.name])} */}
                        {Object.keys(
                          RoleToIdDictionary[r.name]?.access || {}
                        ).map((k) => (
                          <MenuItem value={k} key={k}>
                            {k}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  ) : (
                    <TableCell>-</TableCell>
                  )}

                  {r.access === 'Line' || r.access === 'Plant' ? (
                    <TableCell className={classes.newRoleCell}>
                      <TextField
                        className={classes.roleSelector}
                        value={r.plantId}
                        select
                        disabled={isAdding}
                        SelectProps={{
                          renderValue: (v) => {
                            return v ? (
                              plants.find((p) => p.id === v).name
                            ) : (
                              <i>Plant</i>
                            )
                          },
                          displayEmpty: true,
                        }}
                        onChange={(e) =>
                          handleUpdate(i, {
                            key: 'plantId',
                            value: e.target.value,
                          })
                        }
                      >
                        {plants.map((p) => (
                          <MenuItem value={p.id} key={p.id}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  ) : (
                    <TableCell>-</TableCell>
                  )}
                  {r.access === 'Line' && r.plantId ? (
                    <TableCell className={classes.newRoleCell}>
                      <TextField
                        className={classes.roleSelector}
                        value={r.lineId}
                        select
                        disabled={isAdding}
                        SelectProps={{
                          renderValue: (v) => {
                            return v ? (
                              lines.find((l) => l.id === v).name
                            ) : (
                              <i>Line</i>
                            )
                          },
                          displayEmpty: true,
                        }}
                        onChange={(e) =>
                          handleUpdate(i, {
                            key: 'lineId',
                            value: e.target.value,
                          })
                        }
                      >
                        {lines
                          .filter((l) => l.plantId === r.plantId)
                          .map((l) => (
                            <MenuItem value={l.id} key={l.id}>
                              {l.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </TableCell>
                  ) : (
                    <TableCell>-</TableCell>
                  )}
                  <TableCell align="right">
                    {isAdding ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton
                        size="small"
                        className={classes.removeRoleButton}
                        onClick={() => {
                          removeRole(i)
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default RolesManager
