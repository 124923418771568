import {axios} from '../client';

import {
  SST_API_PATH_LINES,
  SST_API_PATH_CONFIGURATIONS
} from './../../Constants';

export const getLine = ({queryKey}) => {
  const [, {lineId}, queryParams={}] = queryKey;
  return axios.request({
    method: 'GET',
    url: `${SST_API_PATH_LINES}/${lineId}`,
    params: queryParams
  }).then(res => res.data);
}

export const createLine = (line) => {
  line.tagDetails = "{}";
  return axios.post(`${SST_API_PATH_LINES}`, line).then(res => res.data);
}

export const updateLine = (line) => {
  return axios.put(`${SST_API_PATH_LINES}/${line.id}`, line).then(res => res.data);
}

export const getActiveLineSettings = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/settings/active`).then(res => res.data);
}

export const getLineSettings = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/settings`).then(res => res.data);
}

export const updateLineSettings = ({lineId, lineSettings}) => {
  return axios.put(`${SST_API_PATH_LINES}/${lineId}/settings`, lineSettings).then(res => res.data);
}

export const deleteLine = (line) => {
  return axios.delete(`${SST_API_PATH_LINES}/${line.id}`).then(res => res.data);
}

export const getLineRuns = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/runs`).then(res => res.data);
}

export const getRunsForLineArea = ({queryKey}) => {
  const [, {lineId, areaId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/areas/${areaId}/runs`).then(res => res.data)
}

export const getTagsForLineArea = ({queryKey}) => {
  const [, {lineId, areaId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/areas/${areaId}/tags`).then(res => res.data)
}

export const getLineAreas = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/areas`).then(res => res.data);
}

export const getLineDetails = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}`).then(res => res.data);
}

export const getLatestLineConfiguration = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/configurations/latest`).then(res => res.data);
}

export const validateLinemap = (linemap) => {
  return axios.post('http://localhost:9080/linemaps/validate-path', {grid: linemap}).then(res => res.data);
}
export const getLineConfigurations = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/${SST_API_PATH_CONFIGURATIONS}`).then(res => res.data);
}

export const getLineConfiguration = ({queryKey}) => {
  const [, {lineConfigurationId, lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/${SST_API_PATH_CONFIGURATIONS}/${lineConfigurationId}`).then(res => res.data);
}