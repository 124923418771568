import React, {useContext, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useHistory, useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {SiteContext} from "../../Context";
import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import {MenuItem, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import queryString from "query-string";

import {
  getCustomer,
  getCustomerTypes,
  updateCustomer,
  createCustomer
} from '../../query/queries';
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_INSERT } from "../../shared/Utilities";
import translations from '../../translations/en.json'

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptySensor = {  
  name: "",
  customerTypeId: ""
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px'
  }
})


const CreateAndEditCustomer = (props) => {

  const { addCustomer, hasPermission } = useContext(SiteContext);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [customer, setCustomer] = useState(emptySensor);
  const [isEdit, setIsEdit] = useState(false);

  const {isLoading: isLoadingCustomer, data:customerData} = useQuery(['customer', {customerId: customer.id}], getCustomer, {enabled: !!customer?.id})
  const {isLoading: isLoadingCustomerTypes, data:customerTypes=[]} = useQuery(['customerTypes'], getCustomerTypes);

  const pageTitle = location.pathname === '/create-customer' ? 'Create Customer' : 'Edit Customer';

  const {mutate: mutateCreate, isLoading: isCreating} = useMutation(createCustomer, {
    onSuccess: (data) => {
      enqueueSnackbar('Customer Created', {variant: 'success'});
      queryClient.removeQueries('customers');
      queryClient.setQueryData(['customer', {customerId: data.id}], data);
      addCustomer(data);
      history.replace(`/list-customers`);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  })

  const {mutate: mutateUpdate, isLoading: isUpdating} = useMutation(updateCustomer, {
    onSuccess: (data) => {
      enqueueSnackbar('Customer Updated', {variant: 'success'});
      queryClient.removeQueries('customers');
      queryClient.setQueryData(['customer', {customerId: data.id}], data);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  })


  useEffect(() => {
    if(customerData){
      setCustomer(c => ({...c, ...customerData}))
    }
  }, [customerData])

  useEffect(() => {
    const qs = queryString.parse(location.search);
    if (qs.customerId) {
      document.title = "Edit Customer";
      setIsEdit(true);
      setCustomer(c => ({...c, id: qs.customerId}));
    } else {
      document.title = "Create Customer";
      setIsEdit(false);
      setCustomer(emptySensor);
    }
  }, [location.search]);

  const submit = (e) => {
    e.preventDefault();
    if(isEdit){
      mutateUpdate(customer);
    }
    else {
      mutateCreate(customer);
    }
  };

  const handleFormUpdate = (update) => {
    setCustomer({...customer, ...update});
  }

  const classes = useStyles();

  return (
    <div data-testid={isEdit ? 'edit-customer-page' : 'create-customer-page'} className={classes.root}>
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <CreateEditWrapper
          onBack={() => !!(history.location.key) ? history.goBack() : history.push('/list-customers')}
          buttonTitle={isEdit ? "Save" : "Create"}
          pageTitle={isEdit ? "Edit Customer" : "Create Customer"}
          submit={submit}
          isLoading={isLoadingCustomer || isLoadingCustomerTypes || isCreating || isUpdating}
          classes={classes}>
            <div className={classes.content}>
              <TextField
                value={customer.name}
                onChange={(e) => {handleFormUpdate({name: e.target.value})}}
                label="Customer Name"
                autoComplete="disabled"
                fullWidth
                required
                disabled={isLoadingCustomer || isCreating || isUpdating}
                className={classes.formControl}/>


              <TextField
                  value={customer.customerTypeId}
                  onChange={(e) => {handleFormUpdate({customerTypeId: e.target.value})}}
                  label="Customer Type"
                  autoComplete="disabled"
                  fullWidth
                  disabled={isLoadingCustomer || isLoadingCustomerTypes || isCreating || isUpdating}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (val) => {
                      if (!val){
                        return <i>{translations.common.notAssigned}</i>
                      }
                      else {
                        return customerTypes.find(x => x.id === val)?.name;
                      }
                    }
                  }}
                  className={classes.formControl}>
                  {customerTypes.map((type) => {
                    return <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
                  })}
                </TextField>
            </div>
        </CreateEditWrapper>
      }
    </div>);
};

export default CreateAndEditCustomer;