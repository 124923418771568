import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import {SvgIcon} from '@mui/material';
import {ReactComponent as CloseIcon} from './img/icons/close.svg';

import {SiteContextProvider} from "./Context";
import themes from '../src/shared/Theme/ThemeCustomizer';
import {createTheme, CssBaseline, ThemeProvider, StyledEngineProvider} from '@mui/material';
import App from "./pages/App/App";
import { QueryClientProvider } from 'react-query';
import {client as queryClient} from './query/client';

import { SnackbarProvider } from 'notistack';

import CheckIcon from '@mui/icons-material/Check';

const theme = createTheme({...themes.default});
let Router;

if(process.env.REACT_APP_PREVIEW === "true"){
    Router = HashRouter;
} else {
    Router = BrowserRouter;
}

queryClient.setDefaultOptions({
    queries: {
      staleTime: 30000,
      refetchOnWindowFocus: false,
      retry: false
    }
})

const notistackRef = React.createRef();

const onClickDismiss = (key) => {
  notistackRef.current.closeSnackbar(key);
}

const closeButtonStyles = {
  color: 'white',
  stroke: 'white',
  cursor: 'pointer'
}

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3} 
              anchorOrigin={{horizontal: 'right', vertical: 'top'}}
              iconVariant={{
                success: <CheckIcon style={{marginRight: '10px'}}/>
              }}
              action={(key) => (
                <SvgIcon 
                  component={CloseIcon} 
                  viewBox="0 0 24 24" 
                  onClick={() => {onClickDismiss(key)}}
                  style={closeButtonStyles}/>
              )}>
              <CssBaseline/>
              <SiteContextProvider>
                  <Router>
                      <App/>
                  </Router>
              </SiteContextProvider>
            </SnackbarProvider>
          </StyledEngineProvider>
        </ThemeProvider>
    </QueryClientProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
