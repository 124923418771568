import {axios} from '../client';

import {
  SST_API_PATH_DASHBOARDS
} from './../../Constants';

export const getDashboards = () => {
  return axios.get(`${SST_API_PATH_DASHBOARDS}`).then(res => res.data);  
}

export const getDashboard = ({queryKey}) => {
  const [, urlParams, queryParams] = queryKey;

  const {dashboardId} = urlParams;

  return axios.get(`${SST_API_PATH_DASHBOARDS}/${dashboardId}`, {
    params: queryParams
  }).then(res => res.data);
}