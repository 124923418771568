import React, {useEffect, useState, useContext} from "react";
import {useQuery} from 'react-query';
import { makeStyles } from '@mui/styles';
import {useLocation} from 'react-router-dom';
import {embedDashboard} from 'amazon-quicksight-embedding-sdk';
import queryString from "query-string";
import Loading from '../../shared/components/Loading/Loading';
import { SiteContext } from "../../Context";


import {
  getDashboard
} from '../../query/queries';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  quicksightContainer:{
    height: '100%',
    width: '100%'
  },
  loadingContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const QuickSightPage = (props) => {
  const location = useLocation();
  const {setBreadcrumbs} = useContext(SiteContext);

  const {dashboardId, ...urlParams} = queryString.parse(location.search);

  const [dashboardMounted, setDashboardMounted] = useState(false);
  const {isLoading, data} = useQuery(['dashboard', {dashboardId}, urlParams], getDashboard)
  
  useEffect(() => {
    if(data && !dashboardMounted){
      embedDashboard({
        url: data.embedUrl,
        container: document.getElementById("dashboardContainer"),
        scrolling: "yes",
        parameters: data.dashboard.dashboardSettings,
        height: "100%", 
        width: "100%",
        locale: "en-US",
        footerPaddingEnabled: true
      })
      setDashboardMounted(true);
    }
  }, [data, dashboardMounted]);

  useEffect(() => {
    document.title = "Quick Sight";
    setBreadcrumbs([
      {
          title: "Dashboards",
      }
  ]);
  }, [props.location.search, setBreadcrumbs]);

  const classes = useStyles(); 
  return (<div className={classes.root} data-testid='view-dashboard-page'>
    {(isLoading || !dashboardMounted) && <div className={classes.loadingContainer}> <Loading size={65}/> </div>}
    <div className={classes.quicksightContainer} id="dashboardContainer" ></div>
  </div>);
};

export default QuickSightPage;
