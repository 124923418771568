import {
    BASE_SST_API_URL, DATA_EXPORT_IBA, DATA_FORMAT_CSV,
    LOCAL_STORAGE_KEY_LOGIN_DETAILS,
    SST_API_PATH_CONFIGURATIONS,
    SST_API_PATH_CUSTOMERS,
    SST_API_PATH_DASHBOARDS, SST_API_PATH_DOWNLOAD,
    SST_API_PATH_HUBS,
    SST_API_PATH_LINES,
    SST_API_PATH_PLANTS,
    SST_API_PATH_RUNS,
    SST_API_PATH_PARTS,
    SST_API_PATH_FAULTS,
    SST_API_PATH_SYMPTOMS,
    SST_API_PATH_SEVERITY,
    SST_API_PATH_DIAGNOSIS,
    SST_API_PATH_SENSORS,
    SST_API_PATH_USERS,
    SST_API_PATH_VIBRATION,
    SST_API_QUERY_LIMIT, SST_API_QUERY_RETURN_BASE64, SST_API_QUERY_RETURN_DATA_EXPORT,
    SST_API_QUERY_RETURN_DATA_FORMAT,
    SST_API_QUERY_RETURN_DATA_TYPE,
    SST_API_QUERY_INCLUDE_DIAGNOSES,
    SST_DIAGNOSIS_GREEN_COLOR,
    SST_DIAGNOSIS_YELLOW_COLOR,
    SST_DIAGNOSIS_RED_COLOR,
    SST_DIAGNOSIS_UNKNOWN_COLOR,
    SST_DIAGNOSIS_GREEN_NAME,
    SST_DIAGNOSIS_YELLOW_NAME,
    SST_DIAGNOSIS_RED_NAME,
    SST_DIAGNOSIS_UNKNOWN_NAME
} from "../Constants";

/**
 * Retrieve all customers
 * @param setResultFunction function to which list of customers should be passed
 * @param setIsLoadingFunction function to which state of loading should be passed
 * @param authToken authorization token which should be used to retrieve list of customers.
 * @returns {Promise<void>}
 */
const fetchCustomers = async (setResultFunction, setIsLoadingFunction, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_CUSTOMERS,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setResultFunction(responseJson);

        return responseJson;
    }
}

const fetchCustomer = async(customerId, setIsLoadingFunction, setCustomerName, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_CUSTOMERS + "/" + customerId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {

        const jsonResponse = await response.json();

        setCustomerName(jsonResponse.name);

        return jsonResponse;
    }
}

const fetchUser = async(userId, setIsLoadingFunction, setFirstName, setLastName, setEmailAddress, setCustomerId, setTimezone, setIsAdministrator, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_USERS + "/" + userId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {

        const jsonResponse = await response.json();
        setFirstName(jsonResponse.firstName);
        setLastName(jsonResponse.lastName);
        setEmailAddress(jsonResponse.emailAddress);
        setCustomerId(jsonResponse.customerId);
        setTimezone(jsonResponse.tz);
        setIsAdministrator(jsonResponse.isAdministrator);

        return jsonResponse;
    }
}

const fetchUsers = async(setResultFunction, setIsLoadingFunction, authToken) =>
{
    setIsLoadingFunction(true);

    const response =  await fetch(BASE_SST_API_URL + SST_API_PATH_USERS,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setResultFunction(responseJson);

        return responseJson;
    }
};

const fetchPlants = async(customerId, setResultFunction, setIsLoadingFunction, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_CUSTOMERS + "/" + customerId + "/plants",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        // console.log("responseJson",responseJson)
        setResultFunction(responseJson);

        return responseJson;
    }
}

const fetchPlant = async(plantId, setIsLoadingFunction, setPlantName, setCustomerId, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_PLANTS + "/" + plantId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setPlantName(responseJson.name);
        setCustomerId(responseJson.customerId);

        return responseJson;
    }
}

const fetchPlantOnly = async(plantId, setIsLoadingFunction, setPlant, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_PLANTS + "/" + plantId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setPlant(responseJson);

        return responseJson;
    }
}

/**
 *
 * @param hubImei imei of the hub that should be loaded
 * @param setHubNameFunction
 * @param setHubImeiFunction
 * @param setHubCustomerIdFunction
 * @param setIsLoadingFunction
 * @param authToken
 *
 * @returns {Promise<void>}
 */
const fetchHub = async (hubImei, setHubNameFunction, setHubImeiFunction, setHubCustomerIdFunction, setIsLoadingFunction, authToken) => {

    setIsLoadingFunction(true);

    const loadHubResponse = await fetch(BASE_SST_API_URL + SST_API_PATH_HUBS + "/" + hubImei,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (loadHubResponse.ok) {

        const jsonResponse = await loadHubResponse.json();

        setHubNameFunction(jsonResponse.name);
        setHubImeiFunction(jsonResponse.imei);
        setHubCustomerIdFunction(jsonResponse.customerId);

        return jsonResponse;
    }
}

const fetchLines = async(plantId, setResultFunction, setIsLoadingFunction, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_PLANTS + "/" + plantId + "/lines",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setResultFunction(responseJson);

        return responseJson;
    }

    setIsLoadingFunction(false);
}

const fetchLineDetails = async(lineId, setIsLoadingFunction, setLineName, setPlantId, setTagDetails, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_LINES + "/" + lineId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {

        const jsonResponse = await response.json();

        setLineName(jsonResponse.name);
        setPlantId(jsonResponse.plantId);
        setTagDetails(jsonResponse.tagDetails);

        return jsonResponse;
    }
}

const fetchLine = async(lineId, setIsLoadingFunction, setLine, includePlantName= false, includeNumberOfMultiSensors= false, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_LINES + "/" + lineId
        + "?includePlantName=" + includePlantName
        + "&includeNumberOfMultiSensors=" + includeNumberOfMultiSensors,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    if (response.ok) {
        const jsonResponse = await response.json();
        setLine(jsonResponse);
    }
    setIsLoadingFunction(false);
};

const fetchLineSummaryImage = async(lineId, setIsLoadingFunction, setLineSummaryImage, authToken) =>
{
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_LINES + "/" + lineId + "/summaries/image",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    if (response.ok) {
        const jsonResponse = await response.json();
        setLineSummaryImage(jsonResponse.url);
    }
    setIsLoadingFunction(false);
};

const fetchAllHubs = async(setResultFunction, setIsLoadingFunction, authToken, detailed) => {

    setIsLoadingFunction(true);

    const loadHubResponse = await fetch(BASE_SST_API_URL + SST_API_PATH_HUBS +((detailed)? ("?detailed=true"): ""),
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (loadHubResponse.ok) {
        const jsonResponse = await loadHubResponse.json();
        setResultFunction(jsonResponse);

        return jsonResponse;
    }

}

const fetchAllRuns = async(setResultFunction, setIsLoadingFunction, authToken, lineId) => {

    setIsLoadingFunction(true);

    const loadRunsResponse = await fetch(BASE_SST_API_URL + SST_API_PATH_LINES + "/" + lineId + "/" + SST_API_PATH_RUNS,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (loadRunsResponse.ok) {
        const jsonResponse = await loadRunsResponse.json();
        setResultFunction(jsonResponse);

        return jsonResponse;
    }

};

const fetchAllConfigurations = async(setResultFunction, setIsLoadingFunction, authToken, lineId) => {

    setIsLoadingFunction(true);

    const loadRunsResponse = await fetch(BASE_SST_API_URL + SST_API_PATH_LINES + "/" + lineId + "/" + SST_API_PATH_CONFIGURATIONS,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (loadRunsResponse.ok) {
        const jsonResponse = await loadRunsResponse.json();
        setResultFunction(jsonResponse);

        return jsonResponse;
    }

};

const fetchHubLogs = async(hubImei, setLogsFunction, setIsLoadingFunction, authToken) => {

    setIsLoadingFunction(true);

    const loadLogsResponse = await fetch(BASE_SST_API_URL + SST_API_PATH_HUBS + "/" + hubImei + "/logs",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (loadLogsResponse.ok) {
        const jsonResponse = await loadLogsResponse.json();
        setLogsFunction(jsonResponse);

        return jsonResponse;
    }
}

const fetchRunDetails = async(runId, setRunDetailsFunction, setIsLoadingFunction, authToken) => {

    setIsLoadingFunction(true);

    const loadLogsResponse = await fetch(BASE_SST_API_URL + SST_API_PATH_RUNS + "/" + runId + "/details",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (loadLogsResponse.ok) {
        const jsonResponse = await loadLogsResponse.json();
        setRunDetailsFunction(jsonResponse);

        return jsonResponse;
    }
};

const fetchSensor = async(multiSensorId, setIsLoadingFunction, setName, setMac, setCustomerId, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS + "/" + multiSensorId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setName(responseJson.name);
        setCustomerId(responseJson.customerId);
        setMac(responseJson.macAddress);

        return responseJson;
    }
}

/**
 * Get multiSensor object
 */
const fetchSensorOnly = async(multiSensorId, setIsLoadingFunction, setMultiSensor, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS + "/" + multiSensorId,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setMultiSensor(responseJson);
        return responseJson;
    }
}

const fetchSensors = async(setSensors, detailed, setIsLoadingFunction,  authToken) =>
{

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS+ "?detailed=" + detailed,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setSensors(responseJson);

        return responseJson;
    }
}

const fetchLatestMultiSensorReadingsForLine = async (lineId, setIsLoadingFunction, setResultFunction, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_LINES + "/" + lineId + "/sensors/readings/latest",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();

        setResultFunction(responseJson);

        return responseJson;
    }
}

const fetchSensorReadingsWithinDateRange = async (sensorMacAddress, setIsLoadingFunction, setResultFunction, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS + "/" + sensorMacAddress + "/readingsWithinDateRange",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {

        const responseJson = await response.json();

        setResultFunction(responseJson);

        return responseJson;
    }
}

const fetchSevenBandSummaryForSensor = async (sensorMacAddress, setIsLoadingFunction, setResultFunction, authToken) => {

    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS + "/" + sensorMacAddress + "/sevenBandSummary",
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {

        const responseJson = await response.json();

        setResultFunction(responseJson);

        return responseJson;
    }
}

const fetchDashboardsForCustomer = async (customerId, setIsLoadingFunction, setResultFunction, authToken) => {
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_CUSTOMERS + "/" + customerId + "/" + SST_API_PATH_DASHBOARDS,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    setIsLoadingFunction(false);

    if (response.ok) {
        const responseJson = await response.json();
        setResultFunction(responseJson);

        return responseJson;
    }

    setIsLoadingFunction(false);
}

const fetchRequestDetailedVibrationData = async (vibrationId, dataType, responseFormat, isBase64, setIsLoadingFunction, setResultFunction, authToken) => {
    setIsLoadingFunction(true);

    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS
        + "/" + SST_API_PATH_VIBRATION
        + "/" + vibrationId
        + "?" + SST_API_QUERY_RETURN_DATA_FORMAT + "=" + responseFormat
        + "&" + SST_API_QUERY_RETURN_DATA_TYPE + "=" + dataType
        + "&" + SST_API_QUERY_RETURN_BASE64 + "=" + isBase64,
        {
            method: "GET",
            headers: {"Authorization": authToken}
        });

    if (response.ok) {

        const responseJson = await response.json();
        setResultFunction(responseJson);
    }

    setIsLoadingFunction(false);
};

const fetchRequestVibrationData = async (mac, limit, responseFormat, includeDiagnosis, setIsLoadingFunction, setResultFunction, authToken) => {
    setIsLoadingFunction(true);
    const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS
        + "/" + mac
        + "/" + SST_API_PATH_VIBRATION
        + "?" + SST_API_QUERY_RETURN_DATA_FORMAT + "=" + responseFormat
        + "&" + SST_API_QUERY_LIMIT + "=" + limit
        + ((includeDiagnosis) ? ("&" + SST_API_QUERY_INCLUDE_DIAGNOSES + '=true') : ""), 
        {
            method: "GET",
            headers: {"Authorization": authToken}
        }).finally(()=>{
        setIsLoadingFunction(false);
    });

    if (response.ok) {
      let responseJson = await response.json();
      setResultFunction(responseJson);
    }
    setIsLoadingFunction(false);
};

const fetchDownloadLink = async (macAddress, selectedReadingId, dataType, dataFormat, linkType, setIsLoadingFunction, authToken) => {
    setIsLoadingFunction(true);

    await fetch(BASE_SST_API_URL + SST_API_PATH_SENSORS + "/"
        + macAddress + "/"
        + SST_API_PATH_VIBRATION + "/"
        + selectedReadingId + "/"
        + SST_API_PATH_DOWNLOAD
        + "?" + SST_API_QUERY_RETURN_DATA_TYPE + "=" + dataType
        + "&" + SST_API_QUERY_RETURN_DATA_FORMAT + "=" + dataFormat
        + ((linkType === DATA_FORMAT_CSV) ? "" : "&" + SST_API_QUERY_RETURN_DATA_EXPORT + "=" + DATA_EXPORT_IBA),
        {
            method: "GET",
            headers: {"Authorization": authToken}
        }).then(response => {
        // HTTP 302 response
        if (response.redirected) {
            window.location.href = response.url;
        }

    }).finally(()=>{
        setIsLoadingFunction(false);
    });
};

let userAuthToken = (() => {
    try {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS)).token;
    }
    catch(e){
        return "";
    }
})();

const setAuthToken = (token) => {
    userAuthToken = token;
}

const fetchReportingWidget = async (url, setResultFunction) => {
    const response = await fetch(url, {
        method: "GET",
        headers: {"Authorization": userAuthToken}
    });

    if (response.ok){
        const arrayBuffer = await response.arrayBuffer();
        const decodedImage = new TextDecoder("utf-8").decode(arrayBuffer);
        setResultFunction("data:image/png;base64, " + decodedImage)
    }
}

const fetchParts = async (setResultFunction, setLoadingFunction) => {
  
  if(setLoadingFunction){
    setLoadingFunction(true);
  }
  
  const response = await fetch(BASE_SST_API_URL + SST_API_PATH_PARTS, {
    method: "GET",
    headers: { "Authorization": userAuthToken }
  });

  if (response.ok) {
    let responseJson = await response.json();
    if(setLoadingFunction){
      setLoadingFunction(false);
    }
    setResultFunction(responseJson);
  }
}

const fetchSymptoms = async (setResultFunction, setLoadingFunction) => {
  if(setLoadingFunction){
    setLoadingFunction(true);
  }
  
  const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SYMPTOMS, {
    method: "GET",
    headers: { "Authorization": userAuthToken }
  });

  if (response.ok) {
    let responseJson = await response.json();
    if(setLoadingFunction){
      setLoadingFunction(false);
    }
    setResultFunction(responseJson);
  }
}

const fetchFaults = async (setResultFunction, setLoadingFunction) => {
  if(setLoadingFunction){
    setLoadingFunction(true);
  }
  
  const response = await fetch(BASE_SST_API_URL + SST_API_PATH_FAULTS, {
    method: "GET",
    headers: { "Authorization": userAuthToken }
  });

  if (response.ok) {
    let responseJson = await response.json();
    if(setLoadingFunction){
      setLoadingFunction(false);
    }
    setResultFunction(responseJson);
  }
}

const fetchSeverity = async (setResultFunction, setLoadingFunction) => {
  if (setLoadingFunction) {
    setLoadingFunction(true);
  }

  const colors = {
    Green: SST_DIAGNOSIS_GREEN_COLOR,
    Yellow: SST_DIAGNOSIS_YELLOW_COLOR,
    Red: SST_DIAGNOSIS_RED_COLOR,
    Unknown: SST_DIAGNOSIS_UNKNOWN_COLOR
  }

  const displayNames = {
    Green: SST_DIAGNOSIS_GREEN_NAME,
    Yellow: SST_DIAGNOSIS_YELLOW_NAME,
    Red: SST_DIAGNOSIS_RED_NAME,
    Unknown: SST_DIAGNOSIS_UNKNOWN_NAME
  }

  const response = await fetch(BASE_SST_API_URL + SST_API_PATH_SEVERITY, {
    method: "GET",
    headers: { "Authorization": userAuthToken }
  });

  if (response.ok) {
    let responseJson = await response.json();

    responseJson = responseJson.map(severity => {
      severity.color = colors[severity.name];
      severity.displayName = displayNames[severity.name]
      return severity
    });

    if (setLoadingFunction) {
      setLoadingFunction(false);
    }
    setResultFunction(responseJson);
  }
}

const updateDiagnosis = async (diagnosis, setDiagnosis) => {
  const diagnosisId = diagnosis.id;

  const updateObject = {
    id: diagnosis.id,
    symptoms: diagnosis.symptoms || [],
    machineParts: diagnosis.machineParts || [],
    note: diagnosis.note || "",
    faultTypes: diagnosis.faultTypes || [],
    vibrationReadingId: diagnosis.vibrationReadingId,
    severityId: diagnosis.severityId,
  }

  const response = await fetch(BASE_SST_API_URL + SST_API_PATH_DIAGNOSIS + '/' + diagnosisId, {
    method: "PUT",
    headers: { "Authorization": userAuthToken },
    body: JSON.stringify(updateObject)
  });

  if (response.ok) {
    let responseJson = await response.json();

    if(setDiagnosis){
      setDiagnosis(responseJson);
    }
  }
}

const createDiagnosis = async (vibrationId, severityId, done) => {

  const createObject = {
    symptoms: [],
    machineParts: [],
    note: "",
    faultTypes: [],
    vibrationReadingId: vibrationId,
    severityId: severityId,
  }

  const response = await fetch(BASE_SST_API_URL + SST_API_PATH_DIAGNOSIS, {
    method: "POST",
    headers: { "Authorization": userAuthToken },
    body: JSON.stringify(createObject)
  });

  if (response.ok) {
    const responseText = await response.text()
    console.log(responseText);

    createObject.id = responseText;

    if(done){
      done(createObject);
    }
  }
} 

export {
    fetchCustomers, fetchHub, fetchAllHubs, fetchHubLogs,fetchAllRuns,fetchRunDetails,fetchCustomer,
    fetchLineDetails, fetchLine, fetchLineSummaryImage,
    fetchPlants, fetchPlant, fetchPlantOnly,
    fetchUser, fetchUsers, fetchSensor, fetchSensorOnly, fetchSensors, fetchLines, fetchLatestMultiSensorReadingsForLine,
    fetchSevenBandSummaryForSensor, fetchSensorReadingsWithinDateRange, fetchAllConfigurations,
    fetchDashboardsForCustomer, fetchRequestVibrationData, fetchRequestDetailedVibrationData, fetchDownloadLink, fetchReportingWidget, setAuthToken,
    fetchParts, fetchSymptoms, fetchFaults, fetchSeverity, updateDiagnosis, createDiagnosis
}
