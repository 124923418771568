import React, { } from 'react'
import useStyles from '../LineRunStatistics/Styles'
import {ReactComponent as PressureBottleIcon} from '../../../img/icons/pressure-bottle.svg'
import {ReactComponent as TopLoadBottleIcon} from '../../../img/icons/top load-bottle.svg'
import {ReactComponent as ShockBottleIcon} from '../../../img/icons/impact-bottle.svg'
import {ReactComponent as TiltBottleIcon} from '../../../img/icons/tilt-Bottle.svg'
import {ReactComponent as SpinBottleIcon} from '../../../img/icons/spin_bottle.svg'
import translations from '../../../translations/en.json';
import LineRunStatistic from '../LineRunStatistic/LineRunStatistic'
import OriginalTheme from '../../Theme/OriginalTheme'

const LineRunStatistics = (props) => {

  const {
    maxPressure,
    topLoad,
    shock,
    tilt,
    spin,
    rotation,
    showMaxPressure = true,
    showTopLoad = true,
    showShock = true,
    showTilt = true,
    showSpin = true,
    isMaxPressureSelected = true,
    isTopLoadSelected = true,
    isShockSelected= true,
    isTiltSelected = true,
    isSpinSelected= true,
    onMaxPressureClick,
    onTopLoadClick,
    onShockClick,
    onTiltClick,
    onSpinClick,
  } = props;

  const classes = useStyles()

  return (
    <div className={classes.statsContainer}>

      {/* maxPressure */}
      { showMaxPressure &&
        <LineRunStatistic
          icon={PressureBottleIcon}
          data={[
            {
              label: translations.common.unitsOfMeasure.psi.toUpperCase(),
              labelColor: OriginalTheme.palette.orange,
              value: maxPressure
            }
          ]}
          selected={isMaxPressureSelected}
          onClick={onMaxPressureClick}
        />
      }

      {/* topLoad */}
      { showTopLoad &&
        <LineRunStatistic
          icon={TopLoadBottleIcon}
          data={[
            {
              label: translations.common.unitsOfMeasure.lbf.toUpperCase(),
              labelColor: 'red',
              value: topLoad
            }
          ]}
          selected={isTopLoadSelected}
          onClick={onTopLoadClick}
        />
      }

      {/* shock */}
      { showShock &&
        <LineRunStatistic
          icon={ShockBottleIcon}
          data={[
            {
              label: translations.common.unitsOfMeasure.g.toUpperCase(),
              labelColor: 'lightBlue',
              value: shock
            }
          ]}
          selected={isShockSelected}
          onClick={onShockClick}
        />
      }

      {/* tilt */}
      { showTilt &&
        <LineRunStatistic
          icon={TiltBottleIcon}
          data={[
            {
              label: translations.common.unitsOfMeasure.degrees.toUpperCase(),
              labelColor: 'lightgreen',
              value: tilt
            },
          ]}
          selected={isTiltSelected}
          onClick={onTiltClick}
        />
      }

      {/* spin, rotation */}
      { showSpin &&
        <LineRunStatistic
          icon={SpinBottleIcon}
          data={[
            {
              label: translations.common.unitsOfMeasure.rpm.toUpperCase(),
              labelColor: OriginalTheme.palette.spinOrange,
              value: spin
            },
            {
              label: translations.common.unitsOfMeasure.degrees.toUpperCase(),
              labelColor: OriginalTheme.palette.rotationYellow,
              value: rotation
            }
          ]}
          selected={isSpinSelected}
          onClick={onSpinClick}
        />
      }

    </div>
  )
}

export default LineRunStatistics