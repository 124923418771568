import { makeStyles } from '@mui/styles'

const ConditionTrendDetailsStyles = makeStyles({
  root: {
    width: '100%',
    height: 'fit-content',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 12px 12px 12px',
  },
  paper: {
    width: '100%',
    paddingBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid lightgrey',
  },
  paperCard: {
    border: '1px solid lightgrey',
  },
  controlText: {
    marginLeft: '12px',
  },
  titleHeader: {
    borderBottom: '1px solid lightgrey',
    margin: '0 12px 0 12px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tableText: {
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    height: '5px',
    alignItems: 'center',
  },
  formControl: {
    width: '200px',
  },
  dropDown: {
    width: '200px',
  },
  bandInput: {
    width: '100px',
    height: '30px',
    borderRadius: '2px',
    border: '1px solid lightgrey',
    paddingLeft: '5px',
    fontSize: '14px',
  },
  bandText: {
    fontSize: '14px',
  },
  bandFieldContainer: {
    width: '150px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '12px',
  },
  airParameterDropdown: {
    height: '10px',
  },
  backButton: {
    fontSize: '32px',
    color: '#615E5E',
    padding: '8px',
    border: '1px solid black',
    borderRadius: '3px',
    display: 'block',
    cursor: 'pointer',
  },
  backButtonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  divider: {
    height: '25px',
    width: '1px',
    backgroundColor: '#8F8F8F',
    marginLeft: '26px',
    marginRight: '26px',
  },
  applyButton: {
    marginRight: '12px',
  },
})

export default ConditionTrendDetailsStyles
