import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({

  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  lineDetailsStyle: {
    height: 48,
    backgroundColor: '#EAEAEA',
    border: '1px solid #CECECE',
    display: 'flex',
    padding: '0 26px',
    alignItems: 'center'
  },
  lineHeadingStyle: {
    flex: 1
  },
  lineDetailsHeader: {
    fontWeight: 'bold',
  },
  lineDetailsActionsContainer: {
    '& > *': {
      marginRight: theme.spacing(3)
    }
  },
  linemapImageContainer: {
    flex: 1,
    display: 'flex',
    border: '1px solid #333333',
    margin: 26,
    minHeight: '450px',
    backgroundColor: '#f4f4f4', // Ignoring MUI color pallette for this, needs to exact match linemap image color.
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '26px',

    borderTop: `1px solid black`,

    '& > :not(:last-child)': {
      marginBottom: '52px'
    }
  },
  tableContainer: {
    '& > :not(:last-child)': {
      marginBottom: '26px'
    }
  },
  tableContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textOverflowHiddenEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
}));
export default useStyles