import { makeStyles } from '@mui/styles'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
    width: 200,
    borderRight: `1px solid ${GreyScale.GREY_5}`,
    padding: theme.spacing(1),
    backgroundColor: GreyScale.GREY_1,

    '& > :not(:last-child)': {
      marginBottom: theme.spacing(.5)
    },

    overflowY: 'auto',
  }
}));

export default useStyles