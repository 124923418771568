import { makeStyles } from '@mui/styles'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  statContainer: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    border: `1px solid ${GreyScale.GREY_3}`,
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  statIcon: {
    fontSize: 40,
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  statIconUnselected:{
    '& > *': {
      fill: `${GreyScale.GREY_3}`,
      stroke: `${GreyScale.GREY_3}`,
      '& > *': {
        fill: `${GreyScale.GREY_3}`,
        stroke: `${GreyScale.GREY_3}`,
      }
    }
  },
  statLabelsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  statLabelPairsContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > :not(:last-child)': {
      marginBottom: 3
    }
  },
  statLabelPairContainer: {
    display: 'flex',
    flexDirection: 'row',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    },
  },
  statLabel: (props) => ({
    width: 50,
    height: 20,
    backgroundColor: 'black',
    textAlign: 'center',
    fontSize: 12
  }),
  statLabelUnselected: {
    color: `${GreyScale.GREY_3} !important`,
  },
  statValue: {
    textAlign: 'center',
    width: 50,
    height: 20,
    backgroundColor: GreyScale.GREY_0,
    border: `1px solid ${GreyScale.GREY_2}`,
    fontSize: 12
  },
}));

export default useStyles
