import {axios} from '../client';

import {
  SST_API_PATH_DRONES
} from './../../Constants';

export const getDrones = () => {
  return axios.get(`${SST_API_PATH_DRONES}`).then(res => res.data);  
}

export const getDrone = ({queryKey}) => {
  const [, {droneId}] = queryKey; 
  return axios.get(`${SST_API_PATH_DRONES}/${droneId}`,)
  .then(res => res.data);
}

export const getDroneRuns = ({queryKey}) => {
  const [, {droneId}] = queryKey; 
  return axios.get(`${SST_API_PATH_DRONES}/${droneId}/runs`,)
  .then(res => res.data);
}

export const getLatestDroneConfiguration= ({queryKey}) => {
  const [, {droneId}] = queryKey; 
  return axios.get(`${SST_API_PATH_DRONES}/${droneId}/configurations/latest`,)
  .then(res => res.data);
}