import AdapterDateFns from '@date-io/date-fns'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import {
  getAggregatedSensorData,
  getCustomerLines,
  getCustomerPlants,
  getSensorsByCustomer,
} from '@Queries'
import {
  ACCELERATION,
  aggregatedByValues,
  aggregationMethodValues,
  ALL,
  amplitudeValues,
  ATMOSPHERIC_PARAMETERS,
  BAND_SEVEN_VIBRATION_AMPLITUDE,
  CELSIUS,
  convertQuicksightChartData,
  convertQuicksightTemperatureData,
  DATERECORDED_OBJECT_PROPERTY,
  DAY,
  determineAggregatedByValues,
  DO_NOT_SHOW_ERROR_VALUES,
  ENDDATE_LABEL,
  errorValues,
  ERROR_VALUES_THRESHOLD,
  FAHRENHEIT,
  filterLines,
  generateRows,
  getListOfSensorIds,
  handleUnitCategoryChange,
  handleUnitChange,
  METRIC,
  MULTISENSOR,
  multiSensorFilterValues,
  MULTISENSORID_OBJECT_PROPERTY,
  QUICKSIGHT_CHART_DATE_FORMAT,
  READING,
  STARTDATE_LABEL,
  unitValues,
  validRefetchCondition,
  VIBRATION,
} from '@Shared/QuicksightUtilities'
import moment from 'react-moment'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { SiteContext } from '../../Context'
import { ReactComponent as BackIcon } from '../../img/icons/Back button.svg'
import EnhancedTable from '../../shared/components/EnhancedTable/EnhancedTable'
import QuicksightChart from '../../shared/components/QuicksightChart/QuicksightChart'
import SSTDropdown from '../../shared/components/SSTDropdown'
import { G } from '../../shared/MultiSensorGraphsUtilities'
import { allDefaults } from '../../shared/QuicksightUtilities'
import ConditionTrendSummaryStyles from './Styles/ConditionTrendSummaryStyles'

const axis = ['X', 'Y', 'Z']
const airParameterObjectProperties = [
  'airHumidity',
  'airPressure',
  'airTemperature',
  'chipTemperature',
]
const atmosphericParameterLabels = [
  'Air Humidity',
  'Air Pressure',
  'Air Temperature',
  'Chip Temperature',
]
const bandSevenAxisLabels = ['X Overall(Hz)', 'Y Overall(Hz)', 'Z Overall(Hz)']
const BAND_SEVEN = 'band7'
const HUMIDITY = 'humidity'
const TEMPERATURE = 'temperature'
const PRESSURE = 'pressure'

const tableColumns = [
  { id: 'id', label: 'Id', hidden: true },
  { id: 'multisensor', label: 'Multisensor' },
  { id: 'active', label: '' },
  { id: 'lineId', label: 'Line' },
  { id: 'plantName', label: 'Plant' },
  { id: 'customer', label: 'Customer' },
  { id: 'lastReading', label: 'Last Reading' },
]

const ConditionTrendSummary = () => {
  const history = useHistory()
  const location = useLocation()

  const plantId = queryString.parse(location.search)?.plantId
  const lineId = queryString.parse(location.search)?.lineId

  const { setBreadcrumbs, userDetails, currentCustomer } =
    useContext(SiteContext)

  const [bandSlider, setBandSlider] = useState(0)
  const [humiditySlider, setHumiditySlider] = useState(0)
  const [tempSlider, setTempSlider] = useState(0)
  const [pressureSlider, setPressureSlider] = useState(0)
  const [bandSliderData, setBandSliderData] = useState([])
  const [humiditySliderData, setHumiditySliderData] = useState([])
  const [temperatureSliderData, setTemperatureSliderData] = useState([])
  const [pressureSliderData, setPressureSliderData] = useState([])

  const [isControlsOpen, setIsControlsOpen] = useState(true)
  const [showErrorValues, setShowErrorValues] = useState(false)
  const [isMetric, setIsMetric] = useState(true)

  const [atmosphericParameterData, setAtmosphericParameterData] = useState([])
  const [XYZBandData, setXYZBandData] = useState([])
  const [
    convertedAtmosphericParameterData,
    setConvertedAtmosphericParameterData,
  ] = useState([])
  const [convertedXYZBandData, setConvertedXYZBandData] = useState([])
  const [chartDates, setChartDates] = useState([])
  const [chartUnit, setChartUnit] = useState(G)
  const [startDate, setStartDate] = useState(
    !!location.state?.startDate
      ? moment(location.state.startDate).toISOString()
      : moment().subtract(7, DAY.label).toISOString()
  )
  const [endDate, setEndDate] = useState(
    !!location.state?.endDate
      ? moment(location.state.endDate).toISOString()
      : moment().toISOString()
  )
  const [aggregatedBy, setAggregatedBy] = useState(DAY.label)
  const [plant, setPlant] = useState(allDefaults)
  const [line, setLine] = useState(allDefaults)
  const [multiSensorNameFilter, setMultiSensorNameFilter] = useState(ALL)
  const [aggregationMethod, setAggregationMethod] = useState({
    key: 'max',
    value: 'Maximum',
  })
  const [amplitude, setAmplitude] = useState(ACCELERATION)
  const [measurementSystem, setMeasurementSystem] = useState(METRIC)
  const [errorValue, setErrorValues] = useState(DO_NOT_SHOW_ERROR_VALUES)
  const [aggregatedBySelections, setAggregatedBySelections] =
    useState(aggregatedByValues)
  const [selectedTableRow, setSelectedTableRow] = useState(undefined)
  const [sensorList, setSensorList] = useState([])
  const [plants, setPlants] = useState([allDefaults])
  const [lines, setLines] = useState([allDefaults])

  const { enqueueSnackbar } = useSnackbar()

  const { isLoading: isLoadingPlants, data: plantsResponse = [] } = useQuery(
    ['plants', { customerId: currentCustomer }],
    getCustomerPlants
  )
  const { isLoading: isLoadingLines, data: linesResponse = [] } = useQuery(
    ['lines', { customerId: currentCustomer }],
    getCustomerLines,
    { enabled: plants.length > 0 }
  )

  const { isLoading: isLoadingSensors, data: sensors = [] } = useQuery(
    ['sensors', { customerId: currentCustomer }, { detailed: true }],
    getSensorsByCustomer
  )

  const {
    isLoading: isAggregatedVibrationsLoading,
    data: aggregatedVibrations,
  } = useQuery(
    [
      'sensorAggregationVibrations',
      { customerId: currentCustomer },
      {
        sensorIds: getListOfSensorIds(
          !!selectedTableRow ? [selectedTableRow] : sensorList
        ),
        startDate: startDate,
        endDate: endDate,
        readingType: VIBRATION,
        resolution: aggregatedBy,
        aggregationFunction: aggregationMethod.key,
      },
    ],
    getAggregatedSensorData,
    {
      enabled: validRefetchCondition(
        startDate,
        endDate,
        selectedTableRow,
        aggregatedBy
      ),
    }
  )

  const { isLoading: isAggregatedReadingsLoading, data: aggregatedReadings } =
    useQuery(
      [
        'sensorAggregationReadings',
        { customerId: currentCustomer },
        {
          sensorIds: getListOfSensorIds(
            !!selectedTableRow ? [selectedTableRow] : sensorList
          ),
          startDate: startDate,
          endDate: endDate,
          readingType: READING,
          resolution: aggregatedBy,
          aggregationFunction: aggregationMethod.key,
        },
      ],
      getAggregatedSensorData,
      {
        enabled: validRefetchCondition(
          startDate,
          endDate,
          selectedTableRow,
          aggregatedBy
        ),
        onError: (error) => {
          if (error?.response?.data?.message) {
            enqueueSnackbar(error.response.data.message, {
              variant: 'error',
            })
          }
        },
      }
    )

  const filterTable = useCallback(() => {
    return sensorList?.filter((sensor) => {
      let plantCondition =
        plant.name !== allDefaults.name ? sensor.plantName === plant.name : true
      let lineCondition =
        line.name !== allDefaults.name ? sensor.lineName === line.name : true
      let multiSensorCondition =
        multiSensorNameFilter !== ALL
          ? sensor.name.includes(multiSensorNameFilter)
          : true
      let bandCondition =
        bandSlider !== 0 && bandSlider !== ''
          ? bandSliderData.includes(sensor.id)
          : true
      let pressureCondition =
        pressureSlider !== 0 && pressureSlider !== ''
          ? pressureSliderData.includes(sensor.id)
          : true
      let temperatureCondition =
        tempSlider !== 0 && tempSlider !== ''
          ? temperatureSliderData.includes(sensor.id)
          : true
      let humidityCondition =
        humiditySlider !== 0 && humiditySlider !== ''
          ? humiditySliderData.includes(sensor.id)
          : true

      return (
        plantCondition &&
        lineCondition &&
        multiSensorCondition &&
        bandCondition &&
        pressureCondition &&
        temperatureCondition &&
        humidityCondition
      )
    })
  }, [
    bandSlider,
    bandSliderData,
    humiditySlider,
    humiditySliderData,
    line,
    multiSensorNameFilter,
    plant,
    pressureSlider,
    pressureSliderData,
    tempSlider,
    temperatureSliderData,
    sensorList,
  ])

  //eslint-disable-next-line
  const handleInputAndSliderChange = (value, id) => {
    switch (id) {
      case BAND_SEVEN:
        return setBandSlider(value === '' || isNaN(value) ? '' : Number(value))
      case HUMIDITY:
        return setHumiditySlider(
          value === '' || isNaN(value) ? '' : Number(value)
        )
      case TEMPERATURE:
        return setTempSlider(value === '' || isNaN(value) ? '' : Number(value))
      case PRESSURE:
        return setPressureSlider(
          value === '' || isNaN(value) ? '' : Number(value)
        )
      default:
        return
    }
  }

  const handleRowOnClick = (_, key) => {
    let selectedRowIndex = sensorList.findIndex((sensor) => sensor.id === key)
    if (selectedTableRow?.id !== sensorList[selectedRowIndex].id) {
      setSelectedTableRow({
        id: key,
        name: sensorList[selectedRowIndex].name,
        dateLastUpdated: sensorList[selectedRowIndex].dateLastUpdated,
      })
    }
  }

  const handleBandData = (
    reading,
    FFTBandNumber,
    data,
    errorValuesThreshold
  ) => {
    filterBandData(reading, data, errorValuesThreshold, FFTBandNumber)
    filterSensorsBasedOfSliderValues(reading, FFTBandNumber)
    filterSensorDates(reading, FFTBandNumber)
  }

  const filterBandData = (reading, data, errorThreshold, FFTBandNumber) => {
    if (!showErrorValues) {
      if (reading[`fft${axis[FFTBandNumber]}7`] < errorThreshold) {
        data.push(reading[`fft${axis[FFTBandNumber]}7`])
      }
    } else if (showErrorValues) {
      data.push(reading[`fft${axis[FFTBandNumber]}7`])
    }
  }

  const filterSensorsBasedOfSliderValues = (reading, FFTBandNumber) => {
    if (reading['fftX' + 7] > bandSlider && FFTBandNumber === 0) {
      setBandSliderData((prev) => [
        ...prev,
        reading[MULTISENSORID_OBJECT_PROPERTY],
      ])
    }
    if (reading['fftX' + 7] > tempSlider && FFTBandNumber === 0) {
      setTemperatureSliderData((prev) => [
        ...prev,
        reading[MULTISENSORID_OBJECT_PROPERTY],
      ])
    }
    if (reading['fftX' + 7] > pressureSlider && FFTBandNumber === 0) {
      setPressureSliderData((prev) => [
        ...prev,
        reading[MULTISENSORID_OBJECT_PROPERTY],
      ])
    }
    if (reading['fftX' + 7] > humiditySlider && FFTBandNumber === 0) {
      setHumiditySliderData((prev) => [
        ...prev,
        reading[MULTISENSORID_OBJECT_PROPERTY],
      ])
    }
  }

  const filterSensorDates = (reading, FFTBandNumber) => {
    if (FFTBandNumber === 0) {
      setChartDates((prev) => [
        ...prev,
        moment(reading[DATERECORDED_OBJECT_PROPERTY]).format(
          QUICKSIGHT_CHART_DATE_FORMAT
        ),
      ])
    }
  }

  const controlDropDowns = [
    {
      id: 1,
      label: 'Aggregated By',
      value: aggregatedBy,
      action: (e) => setAggregatedBy(e.target.value),
      list: aggregatedBySelections.map((aggregatedByObj) => (
        <MenuItem key={aggregatedByObj.id} value={aggregatedByObj.value}>
          {aggregatedByObj.value}
        </MenuItem>
      )),
    },
    {
      id: 2,
      label: 'Plant',
      value: plant.name,
      action: (e) => {
        setPlant(plants.find((plant) => plant.name === e.target.value))
        setLine(allDefaults)
      },
      list: plants.map((plantObj) => (
        <MenuItem key={plantObj.id} value={plantObj.name}>
          {plantObj.name}
        </MenuItem>
      )),
    },
    {
      id: 3,
      label: 'Line',
      value: line.name,
      action: (e) => {
        setLine(lines.find((line) => line.name === e.target.value))
      },
      list: filterLines(lines, plant).map((lineObj) => (
        <MenuItem key={lineObj.id} value={lineObj.name}>
          {lineObj.name}
        </MenuItem>
      )),
    },
    {
      id: 4,
      label: 'Multisensor Filter',
      value: multiSensorNameFilter,
      action: (e) => setMultiSensorNameFilter(e.target.value),
      list: multiSensorFilterValues.map((sensorFilterObj) => (
        <MenuItem key={sensorFilterObj.id} value={sensorFilterObj.value}>
          {sensorFilterObj.value}
        </MenuItem>
      )),
    },
    {
      id: 5,
      label: 'Aggregation Method',
      value: aggregationMethod.value,
      action: (e) =>
        setAggregationMethod({
          key:
            e.target.value === 'Average'
              ? 'Avg'
              : e.target.value.substring(0, 3),
          value: e.target.value,
        }),
      list: aggregationMethodValues.map((aggregateMethodObj) => (
        <MenuItem key={aggregateMethodObj.id} value={aggregateMethodObj.value}>
          {aggregateMethodObj.value}
        </MenuItem>
      )),
    },
    {
      id: 6,
      label: 'Amplitude',
      value: amplitude,
      action: (e) => setAmplitude(e.target.value),
      list: amplitudeValues.map((amplitudeObj) => (
        <MenuItem key={amplitudeObj.id} value={amplitudeObj.value}>
          {amplitudeObj.value}
        </MenuItem>
      )),
    },
    {
      id: 7,
      label: 'Units',
      value: measurementSystem,
      action: (e) =>
        handleUnitCategoryChange(
          e.target.value,
          setMeasurementSystem,
          setIsMetric
        ),
      list: unitValues.map((unitObj) => (
        <MenuItem key={unitObj.id} value={unitObj.value}>
          {unitObj.value}
        </MenuItem>
      )),
    },
    {
      id: 8,
      label: 'Error Values',
      value: errorValue,
      action: (e) => {
        setErrorValues(e.target.value)
        setShowErrorValues(!showErrorValues)
      },
      list: errorValues.map((errorObj) => (
        <MenuItem key={errorObj.id} value={errorObj.value}>
          {errorObj.value}
        </MenuItem>
      )),
    },
  ]

  const clearStates = () => {
    setChartDates([])
    setAtmosphericParameterData([])
    setXYZBandData([])
    clearConvertedStates()
  }

  const clearConvertedStates = () => {
    setConvertedXYZBandData([])
    setConvertedAtmosphericParameterData([])
  }

  // TODO: Uncomment upon functionality implemented
  // const controlSliders = [
  //   {
  //     id: 1,
  //     label: 'FFT Band 7',
  //     value: bandSlider,
  //     textfieldAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, BAND_SEVEN),
  //     sliderAction: (e) => handleInputAndSliderChange(e.target.value, 'band7'),
  //     max: 10,
  //     step: 0.1,
  //   },
  //   {
  //     id: 2,
  //     label: 'Air Humidity',
  //     value: humiditySlider,
  //     textfieldAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, HUMIDITY),
  //     sliderAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, 'humidity'),
  //     max: 100,
  //     step: 1,
  //   },
  //   {
  //     id: 3,
  //     label: 'Air Temperature',
  //     value: tempSlider,
  //     textfieldAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, TEMPERATURE),
  //     sliderAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, 'temperature'),
  //     max: 50,
  //     step: 1,
  //   },
  //   {
  //     id: 4,
  //     label: 'Air Pressure',
  //     value: pressureSlider,
  //     textfieldAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, PRESSURE),
  //     sliderAction: (e) =>
  //       handleInputAndSliderChange(e.target.value, 'pressure'),
  //     max: 110,
  //     step: 10,
  //   },
  // ]

  useEffect(() => {
    document.title = 'Condition Trend Summary'
    setBreadcrumbs([{ title: 'Condition Trend Summary' }])
  }, [setBreadcrumbs])

  useEffect(() => {
    if (!isLoadingPlants && plantsResponse.length > 0 && plants.length === 1) {
      setPlants([...plants, ...plantsResponse])
    }
  }, [plantsResponse, plants, isLoadingPlants])

  useEffect(() => {
    if (!isLoadingLines && linesResponse.length > 0 && lines.length === 1) {
      setLines([...lines, ...linesResponse])
    }
  }, [isLoadingLines, linesResponse, lines])

  useEffect(() => {
    let initialPlant =
      !isLoadingPlants && plantsResponse.find((plant) => plant.id === plantId)
    let initialLine =
      !isLoadingLines && linesResponse.find((line) => line.id === lineId)

    if (initialPlant) {
      setPlant(initialPlant)
    } else {
      setPlant(allDefaults)
    }

    if (initialLine) {
      setLine(initialLine)
    } else {
      setLine(allDefaults)
    }

    setSensorList(sensors)
  }, [
    sensors,
    history.location.state,
    lineId,
    plantId,
    lines,
    linesResponse,
    plantsResponse,
    isLoadingLines,
    isLoadingPlants,
  ])

  useEffect(() => {
    clearStates()
    determineAggregatedByValues(
      startDate,
      endDate,
      setAggregatedBySelections,
      setAggregatedBy,
      aggregatedBySelections,
      aggregatedBy
    )

    aggregatedReadings?.sort(
      (a, b) =>
        moment(a.dateRecorded).valueOf() - moment(b.dateRecorded).valueOf()
    )
    aggregatedVibrations?.sort(
      (a, b) =>
        moment(a.dateRecorded).valueOf() - moment(b.dateRecorded).valueOf()
    )

    for (let FFTBandNumber = 0; FFTBandNumber < axis.length; FFTBandNumber++) {
      let xyzData = []
      aggregatedVibrations?.forEach((reading) => {
        handleBandData(reading, FFTBandNumber, xyzData, ERROR_VALUES_THRESHOLD)
      })
      setXYZBandData((prev) => [...prev, xyzData])
    }

    for (
      let AirParamIndex = 0;
      AirParamIndex < airParameterObjectProperties.length;
      AirParamIndex++
    ) {
      let airParamData = []
      aggregatedReadings?.forEach((reading) => {
        airParamData.push(reading[airParameterObjectProperties[AirParamIndex]])
      })
      setAtmosphericParameterData((prev) => [...prev, airParamData])
    }
    if (!!aggregatedVibrations) {
      setChartDates([
        ...new Set(
          aggregatedVibrations.map((reading) => {
            return moment(reading.dateRecorded).format(
              QUICKSIGHT_CHART_DATE_FORMAT
            )
          })
        ),
      ])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    plantId,
    aggregatedBy,
    aggregatedBySelections,
    aggregatedReadings,
    aggregatedVibrations,
    sensorList.length,
  ])

  useEffect(() => {
    clearConvertedStates()
    handleUnitChange(amplitude, isMetric, setChartUnit)
    if (!isMetric) {
      convertQuicksightTemperatureData(
        atmosphericParameterData,
        setConvertedAtmosphericParameterData
      )
    }
  }, [amplitude, isMetric, atmosphericParameterData])

  useEffect(() => {
    if (chartUnit !== G) {
      convertQuicksightChartData(
        XYZBandData,
        chartUnit,
        setConvertedXYZBandData
      )
    }
  }, [chartUnit, XYZBandData])

  const classes = ConditionTrendSummaryStyles()
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div
          id="header"
          className={classes.titleHeader}
          onClick={() => setIsControlsOpen((isControlsOpen) => !isControlsOpen)}
        >
          <div className={classes.backButtonContainer}>
            <span onClick={() => history.push('/dashboard')}>
              <SvgIcon
                component={BackIcon}
                viewBox="8 8 32 32"
                className={classes.backButton}
              />
            </span>
            <span className={classes.divider} />
            <Typography variant="h6" style={{ whiteSpace: 'nowrap' }}>
              Condition Trend Summary
            </Typography>
          </div>
          <Typography variant="h6">Controls</Typography>
        </div>
        <Collapse
          in={isControlsOpen}
          sx={{ borderBottom: '1px solid lightgrey' }}
        >
          <Grid container spacing={3} sx={{ padding: '12px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item>
                <DateTimePicker
                  onChange={(e) => {
                    setStartDate(e)
                  }}
                  renderInput={(props) => <TextField {...props} />}
                  label={STARTDATE_LABEL}
                  value={startDate}
                  minDateTime={moment().subtract(10, 'year')._d}
                  maxDateTime={moment().add(10, 'year')._d}
                />
              </Grid>
              <Grid item>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label={ENDDATE_LABEL}
                  value={endDate}
                  minDateTime={moment().subtract(10, 'year')._d}
                  maxDateTime={moment().add(10, 'year')._d}
                  InputProps={{ className: classes.dropDown }}
                  onChange={(newDate) => {
                    setEndDate(newDate)
                  }}
                />
              </Grid>
            </LocalizationProvider>
            {controlDropDowns.map((control) => (
              <Grid item key={control.id}>
                <SSTDropdown
                  classes={classes}
                  disabled={false}
                  label={control.label}
                  selectedValueId={control.value}
                  setValueFunc={control.action}
                  required={false}
                  fullWidth={false}
                  mappedList={control.list}
                />{' '}
              </Grid>
            ))}

            {/* TODO: Uncomment upon sliders having proper functionality
            {controlSliders.map((control) => {
              return (
                <Grid item key={control.id}>
                  <div key={control.id} className={classes.slider}>
                    <Typography className={classes.sliderTitle}>
                      {control.label}
                    </Typography>
                    <TextField
                      value={control.value}
                      size={'small'}
                      onChange={control.textfieldAction}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.sliderTextInput,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                    />
                    <Slider
                      value={control.value}
                      onChange={control.sliderAction}
                      max={control.max}
                      step={control.step}
                    />
                  </div>
                </Grid>
              )
            })} */}
          </Grid>
        </Collapse>
        <Box sx={{ padding: '12px' }}>
          <Grid container spacing={2} columns={3} sx={{ width: '100%' }}>
            <Grid item xs={1}>
              <EnhancedTable
                tableText={classes.tableText}
                order={'asc'}
                orderBy={MULTISENSOR}
                rowsPerPageOptions={[5, 10, 15]}
                rows={generateRows(filterTable(), userDetails.user.tz)}
                headCells={tableColumns}
                isLoading={isLoadingSensors}
                isSelectable={true}
                hasRightClick={true}
                pageURL={location.pathname + location.search}
                startDate={startDate}
                endDate={endDate}
                tableRowValues={selectedTableRow}
                onClick={handleRowOnClick}
              />
            </Grid>
            <Grid container item rowSpacing={2} xs={2} direction={'row'}>
              <Grid item xs={4} sx={{ width: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={ATMOSPHERIC_PARAMETERS}
                    categories={atmosphericParameterLabels}
                    hasLegend={true}
                    chartWidth={'95%'}
                    chartHeight={'70%'}
                    chartUnit={isMetric ? CELSIUS.unit : FAHRENHEIT.unit}
                    isLoading={
                      isAggregatedVibrationsLoading ||
                      isAggregatedReadingsLoading ||
                      isLoadingSensors
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={
                      convertedAtmosphericParameterData.length > 0
                        ? convertedAtmosphericParameterData
                        : atmosphericParameterData
                    }
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4} sx={{ width: '100%' }}>
                <Paper className={classes.paperCard}>
                  <QuicksightChart
                    title={BAND_SEVEN_VIBRATION_AMPLITUDE}
                    categories={bandSevenAxisLabels}
                    hasLegend={true}
                    chartWidth={'95%'}
                    chartHeight={'70%'}
                    chartUnit={chartUnit}
                    isLoading={
                      isAggregatedVibrationsLoading ||
                      isAggregatedReadingsLoading ||
                      isLoadingSensors
                    }
                    selectedSensorName={selectedTableRow?.name}
                    data={
                      convertedXYZBandData.length > 0
                        ? convertedXYZBandData
                        : XYZBandData
                    }
                    dates={chartDates}
                    aggregatedBy={aggregatedBy}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  )
}

export default ConditionTrendSummary
