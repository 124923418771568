import React from "react";
import {Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DiagnosisSeverityIcon from './DiagnosisSeverityIcon';
import {ERROR_TEXT_COLOR} from '../../../Constants';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    padding: '5px',
    opacity: '0.2',
    fontSize: '27px',
    borderRadius: '100px',
    margin: '2px 6px',
    boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.5)',

    '&:hover': {
      opacity: '0.7'
    },
    '&.selected': {
      opacity: '1.0'
    }
  },
  message: {
    width: 'min-content',
    whiteSpace: 'nowrap',
    margin: 'auto',
    textTransform: 'capitalize',
    fontStyle: props => props.severity ? 'normal': 'italic',
    color: props => props.severity ? 'inherit' : ERROR_TEXT_COLOR
  }  
});


const DiagnosisSeverity = (props) => {

  const onClick = (id) => {
    if(props.disabled){
      return;
    }
    props.onChange(id);
  }

  const getClassName = (id) => {
    return `${classes.button} ${props.severity === id ? 'selected' : ''}`
  }

  const severityObj = props.options.find(x => x.id === props.severity);
  const message = severityObj ? `Severity: ${severityObj.displayName}` : 'Please Select a Severity to save';

  const classes = useStyles(props);

  return (<div className={classes.root}>
      <Typography component={"p"} className={classes.message}>
          {message}
      </Typography>
      <div className={classes.buttonContainer}>
        {props.options.map(severity => {
          return <DiagnosisSeverityIcon severity={severity} className={getClassName(severity.id)} onClick={() => {onClick(severity.id)}} key={severity.id}/>
        })}
      </div>      
    </div>    
  )
}

export default DiagnosisSeverity