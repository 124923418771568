import React, {useContext, useEffect} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {makeStyles} from '@mui/styles';
import {useSnackbar} from 'notistack';
import './App.css';

import navData from './NavData';
import {useHistory, useLocation, withRouter} from 'react-router-dom';

import {SiteContext} from '../../Context';

import {getCustomerUserRoles} from '../../query/queries';

import ContentContainer from './ContentContainer'

import TopBar from '../../shared/components/TopBar/TopBar';
import SideMenu from '../../shared/components/SideMenu/SideMenu';
import Footer from '../../shared/components/Footer/Footer'

const excludeHeader = [
  "/",
  "/login",
  "/forgot-password",
  "/reset-password",
  "/support",
  "/privacy" // The privacy page includes its own instance of <TopBar/>...
];

const excludeSidebar = [
    "/help-center"
]

const useStyles = makeStyles((theme) => ({
  topBarContainer: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  sideMenuContainer: {
    height: 'calc(100vh - 52px)'
  },
  contentContainer: (params) => ({
    marginTop: params.hideHeader ? '0px' : '52px',
    display: 'flex',
    height: params.hideHeader ? '100%' : 'calc(100vh - 52px)'
  }),
  innerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto'
  },
  footerContainer: {
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    padding: `12px 26px`,
    borderTop: `1px solid ${theme.palette.darkGrey}`,
  }
}));



const App = (props) => {
  const {
    userDetails,
    currentCustomer,
    setCurrentCustomer,
    navSection,
    hasPermission,
    setCustomerRoles,
    userCustomers,
    resetCustomerDependentValues,
  } = useContext(SiteContext)
  
  //Should really come up with a better way of switching customer context, but this will do for now
  const {isLoading: isLoadingCustomerUserRoles} = useQuery(['customerUserRoles', {customerId: currentCustomer, userId: userDetails?.user?.id}], getCustomerUserRoles, 
  {
    enabled: !!userDetails.token && !!currentCustomer,
    onSettled: (data=[]) => {
      setCustomerRoles(data);
    },
    onError: null
  });

  const customerList = userCustomers;

  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {    
    const queryDefaultOptions = queryClient.getDefaultOptions();    
    queryClient.setDefaultOptions({
      queries:{
        ...queryDefaultOptions.queries,
        onError: (err) => {
          if(err?.response?.status === 403){
            history.push({pathname: '/dashboard', state: { previousAccessedPage: 'AuthCheck' }});
            console.log('Authorization check failed. Redirecting to Dashboard. Error Status: 403');
          }
        }
      }
    })
  }, [queryClient, enqueueSnackbar, history])

  const hideHeader = excludeHeader.indexOf(location.pathname) >= 0;
  const hideSidebar = excludeSidebar.indexOf(location.pathname) >= 0;

  const classes = useStyles({hideHeader});

  const handleCustomerChange = (customer) => {

    history.push('/dashboard');

    resetCustomerDependentValues();

    setCurrentCustomer(customer);
  }

  return (
    <div>

      {hideHeader ||
        <div className={classes.topBarContainer}>
          <TopBar logoutLink='/logout' profileLink='/user-settings' disableCustomerList={isLoadingCustomerUserRoles} customers={customerList} selectedCustomer={currentCustomer} onCustomerChange={handleCustomerChange}/>
        </div>
      }

      <div className={classes.contentContainer}>

        {(hideHeader || hideSidebar) ||
          <div className={classes.sideMenuContainer}>
            <SideMenu menuItems={navData} highlight={navSection} authFn={hasPermission}/>
          </div>
        }

        <div className={classes.innerContentContainer}>

          <ContentContainer/>

          {hideHeader ||
            <div className={classes.footerContainer}>
              <Footer/>
            </div>
          }

        </div>
      </div>
    </div>
  );
};

export default withRouter(App);