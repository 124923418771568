import React from 'react'
import { Tooltip } from '@mui/material'

const TOOLTIP_ENTER_DELAY = 1000;

const QuantifeelTooltip = (props) => {

  const {
    children,
    ...tooltipProps
  } = props;

  return (
    <Tooltip
      arrow
      enterDelay={TOOLTIP_ENTER_DELAY}
      {...tooltipProps}>
        {children}
    </Tooltip>
  )
}

export default QuantifeelTooltip