import React, {useRef} from 'react';
import { makeStyles } from '@mui/styles';
import LinemapTile from '../LinemapTile/LinemapTile';


const useStyles = makeStyles({
  root:{
    height: props => props.height * props.tilesize,
    width: props => props.width * props.tilesize
  },
  row: {
    height: props => props.tilesize,
    width: props => props.width * props.tilesize
  },
  tileContainer: {
    position: 'relative'
  },
  tile: {
    height: props => props.tilesize,
    width: props => props.tilesize,
    display: 'inline-block',
    position: 'absolute',
    backgroundColor: 'white',

    '&.big': {
      height: props => props.tilesize * 2,
      width: props => props.tilesize * 2,
      zIndex: 1
    }
  }
})

const bigTiles = [26, 27, 28, 29];

const sanatizeData = (data, width) => {  
  const dataCopy = [...data];
  return dataCopy.map((value, index, arr) => {
    if(bigTiles.indexOf(value) !== -1){
      const x = index % width,
            y = Math.floor(index / width);

      console.log('Encountered ', value, ' at', x, y);

      arr[index + 1] = 0;
      arr[index + width] = 0;
      arr[index + width + 1] = 0;
    }
    return value;
  })
}

const unSanatizeData = (data, width) => {
  const positions = data.reduce((memo, value, index) => {
    if(bigTiles.indexOf(value) !== -1){
      memo.push([index, value]);
    }
    return memo;
  }, []);

  const dataCopy = [...data];
  
  positions.forEach(([index, value]) => {
    dataCopy[index + 1] = value;
    dataCopy[index + width] = value;
    dataCopy[index + width + 1] = value;
  })

  return dataCopy;
}


const LinemapEditor = (props) => {

  const {width, data, tilesize} = props;

  const grid = useRef(sanatizeData(data, width));

  const classes = useStyles(props);

  const handleDrop = (value, to, from) => {
    if(from){
      grid.current[(from.y * width) + from.x] = 0;
    } 
    grid.current[(to.y * width) + to.x] = value;

    if(props.onChange){
      const modifiedGrid = unSanatizeData(grid.current, width);
      props.onChange(modifiedGrid);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.tileContainer}>
        {grid.current.map((tile, index) => {
          const x = index % width,
                y = Math.floor(index / width);
          return (
            <div 
              className={[26, 27, 28, 29].indexOf(tile) === -1 ? classes.tile : classes.tile + ' big'} 
              key={`tile-${x}-${y}-${tile}`}
              style={{top: y * tilesize, left: x * tilesize}}>
              <LinemapTile identifier={tile} position={props.canDrop ? {x, y} : null} onDrop={handleDrop} canDrop={props.canDrop}/>
            </div>
          )          
        })}
      </div>      
    </div>
  )
}

export default LinemapEditor;