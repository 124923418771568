import {axios} from '../client';

import {
  SST_API_PATH_MACHINE_CENTERS,
} from './../../Constants'

export const getControlPoints = ({queryKey}) => {
  const [, {machineCenterId}] = queryKey;
  return axios.get(`${SST_API_PATH_MACHINE_CENTERS}/${machineCenterId}/control-points`).then(res => res.data);
}
