import {axios} from '../client';

import {
  SST_API_PATH_PLANTS
} from './../../Constants';

export const getPlant = ({queryKey}) => {
  const [, {plantId}, queryParams={}] = queryKey;
  return axios.request({
    method: 'GET',
    url: `${SST_API_PATH_PLANTS}/${plantId}`,
    params: queryParams
  }).then(res => res.data);
}

export const updatePlant = (plant) => {
  const defaultVals = {
    includesCanLines: false,
    includesBottleLines: false,
    latitude: 0,
    longitude: 0
  };

  delete plant.dateCreated;
  delete plant.dateLastUpdated;

  return axios.put(`${SST_API_PATH_PLANTS}/${plant.id}`, {...defaultVals, ...plant}).then(res => res.data);  
}

export const createPlant = (plant) => {
  const defaultVals = {
    includesCanLines: false,
    includesBottleLines: false,
    latitude: 0,
    longitude: 0
  };

  delete plant.dateCreated;
  delete plant.dateLastUpdated;

  return axios.post(`${SST_API_PATH_PLANTS}`, {...defaultVals, ...plant}).then(res => res.data);  
}

export const deletePlant = (plant) => {
  return axios.delete(`${SST_API_PATH_PLANTS}/${plant.id}`)
    .then(res => res.data);
}

export const getPlantLines = ({queryKey}) => {
  const [, {plantId}] = queryKey; 
  return axios.get(`${SST_API_PATH_PLANTS}/${plantId}/lines`)
  .then(res => res.data);
}

export const getPlantLinesSequentially = async ({queryKey}) => {
  const [, {plants}] = queryKey;
  let lines = [];

  for (const plant of plants) {
    const res = await axios.get(`${SST_API_PATH_PLANTS}/${plant.id}/lines`)
    lines = lines.concat(res.data);
  };

  return lines;
}