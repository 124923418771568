import translations from '../../../translations/en.json';

const TABS = {
  ONE: "one",
  TWO: "two",
  THREE: "three"
}

const LINE_SETTINGS_KEYS = {

  PRESSURE_UNITS: {
    key: "pressure-unit",
    options: {
      PSI: {key: "PSI"},
      BAR: {key: "BAR"},
      CONVERT_TO_FORCE: {key: "Convert to Force Units"}
    }
  },

  FORCE_UNITS: {
    key: "force-unit",
    options: {
      NEWTONS: {key: "NEWTONS"},
      KG: {key: "KG"},
      LB: {key: "LB"}
    }
  },

  ROTATION_UNITS: {
    key: "spin-unit",
    options: {
      RPM: {key: "RPM"},
      DEG: {key: "DEG"}
    }
  },

  DISTANCE_UNITS: {
    key: "distance-unit",
    options: {
      FT: {key: "FT"},
      METRE: {key: "METRE"}
    }
  },

  TEMPERATURE_UNITS: {
    key: "temperature-unit",
    options: {
      CELSIUS: {key: "CELSIUS"},
      FAHRENHEIT: {key: "FAHRENHEIT"}
    }
  },

  SHOCK_UNITS: {
    key: "preferred-shock-unit",
    options: {
      IPS: {key: "IPS"},
      G: {key: "G"}
    }
  },

  SAFE_PRESSURE_PSI: {
    key: "safe-pressure-psi",
  },

  SAFE_IMPACT_IPS: {
    key: "safe-ips",
  },

  REAL_TIME_FEEDBACK_DISPLAY_MODE: {
    key: "run-screen-default-display",
    options: {
      PRESSURE_MAP: {key: "PRESSURE_MAP"},
      VIDEO: {key: "VIDEO"},
      PRESSURE_MAP_AND_VIDEO: {key: "PRESSURE_MAP_AND_VIDEO"}
    }
  },

  PRESSURE_DISPLAY_MODE: {
    key: "pressure-display-mode",
    options: {
      MAX_PRESSURE_ONLY: {key: "MAXPRESSUREONLY"},
      SPLIT_HEEL_SHOULDER_CENTER: {key: "SPLITHEELSHOULDERCENTER"},
      TOTAL_FORCE: {key: "TOTALFORCE"}
    }
  },

  SHOCK_DISPLAY_MODE: {
    key: "shock-display-preference",
    options: {
      MAX_SHOCK_ONLY: {key: "MAXSHOCKONLY"},
      HORIZONTAL_AND_VERTICAL: {key: "HORIZONTALANDVERTICAL"}
    }
  },

  AUTO_RECORD_VIDEO: {
    key: "auto-record-video-setting",
    options: {
      ON: {key: "ON"},
      FORCED_ON: {key: "FORCEDON"},
      OFF: {key: "OFF"},
      FORCED_OFF: {key: "FORCEDOFF"}
    }
  },

  SEAMER_DETECTION: {
    key: "detect-filler-seamer",
    options: {
      DISABLED: {key: "disabled"},
      ONE_TWO_X:  {key: "120/121"},
      ONE_FOUR_X:  {key: "140"},
      ONE_EIGHT_X: {key: "180/181"},
    }
  },

  PREFER_PRESSURE_OR_FORCE: {
    key: "prefer-pressure-or-force",
    options: {
      PRESSURE: {key: "PRESSURE"},
      FORCE: {key: "FORCE"}
    }
  },

  IS_VIAL_MODE: { key: "is-vial-mode" }
}

const LINE_SETTINGS_KEY_TO_LABEL_MAP = {

  // Pressure
  [LINE_SETTINGS_KEYS.PRESSURE_UNITS.key]: translations.common.pressure,
  [LINE_SETTINGS_KEYS.PRESSURE_UNITS.options.PSI.key]: translations.common.unitsOfMeasure.psi,
  [LINE_SETTINGS_KEYS.PRESSURE_UNITS.options.BAR.key]: translations.common.unitsOfMeasure.bar,
  [LINE_SETTINGS_KEYS.PRESSURE_UNITS.options.CONVERT_TO_FORCE.key]: translations.pages.lineSettings.pressureUnit.convertToForce,

  // Force
  [LINE_SETTINGS_KEYS.FORCE_UNITS.key]: translations.common.force,
  [LINE_SETTINGS_KEYS.FORCE_UNITS.options.NEWTONS.key]: translations.common.unitsOfMeasure.N,
  [LINE_SETTINGS_KEYS.FORCE_UNITS.options.KG.key]: translations.common.unitsOfMeasure.kgf,
  [LINE_SETTINGS_KEYS.FORCE_UNITS.options.LB.key]: translations.common.unitsOfMeasure.lbf,

  // Rotation
  [LINE_SETTINGS_KEYS.ROTATION_UNITS.key]: translations.common.rotation,
  [LINE_SETTINGS_KEYS.ROTATION_UNITS.options.RPM.key]: translations.common.unitsOfMeasure.rpm,
  [LINE_SETTINGS_KEYS.ROTATION_UNITS.options.DEG.key]: translations.common.unitsOfMeasure.degPerSecond,

  // Distance
  [LINE_SETTINGS_KEYS.DISTANCE_UNITS.key]: translations.common.distance,
  [LINE_SETTINGS_KEYS.DISTANCE_UNITS.options.FT.key]: translations.common.unitsOfMeasure.feet,
  [LINE_SETTINGS_KEYS.DISTANCE_UNITS.options.METRE.key]: translations.common.unitsOfMeasure.metres,

  // Temperature
  [LINE_SETTINGS_KEYS.TEMPERATURE_UNITS.key]: translations.common.temperature,
  [LINE_SETTINGS_KEYS.TEMPERATURE_UNITS.options.CELSIUS.key]: translations.common.unitsOfMeasure.C,
  [LINE_SETTINGS_KEYS.TEMPERATURE_UNITS.options.FAHRENHEIT.key]: translations.common.unitsOfMeasure.F,

  // Shock
  [LINE_SETTINGS_KEYS.SHOCK_UNITS.key]: translations.common.shock,
  [LINE_SETTINGS_KEYS.SHOCK_UNITS.options.IPS.key]: translations.common.unitsOfMeasure.ips,
  [LINE_SETTINGS_KEYS.SHOCK_UNITS.options.G.key]: translations.common.unitsOfMeasure.g,

  // Safe Pressure
  [LINE_SETTINGS_KEYS.SAFE_PRESSURE_PSI.key]: translations.pages.lineSettings.safePressure,

  // Safe Impact
  [LINE_SETTINGS_KEYS.SAFE_IMPACT_IPS.key]: translations.pages.lineSettings.safeImpact,

  // Realtime Feedback Display
  [LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.key]: translations.pages.lineSettings.realTimeFeedbackDisplay.header,
  [LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.options.PRESSURE_MAP.key]: translations.pages.lineSettings.realTimeFeedbackDisplay.pressureMap,
  [LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.options.VIDEO.key]: translations.pages.lineSettings.realTimeFeedbackDisplay.video,
  [LINE_SETTINGS_KEYS.REAL_TIME_FEEDBACK_DISPLAY_MODE.options.PRESSURE_MAP_AND_VIDEO.key]: translations.pages.lineSettings.realTimeFeedbackDisplay.pressureMapAndVideo,

  // Pressure/Force Display Mode
  [LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.key]: translations.pages.lineSettings.pressureForceDisplayMode.header,
  [LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.options.MAX_PRESSURE_ONLY.key]: translations.pages.lineSettings.pressureForceDisplayMode.maxOverall,
  [LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.options.SPLIT_HEEL_SHOULDER_CENTER.key]: translations.pages.lineSettings.pressureForceDisplayMode.splitLowerUpperCenter,
  [LINE_SETTINGS_KEYS.PRESSURE_DISPLAY_MODE.options.TOTAL_FORCE.key]: translations.pages.lineSettings.pressureForceDisplayMode.totalForce,

  // Shock Display Mode
  [LINE_SETTINGS_KEYS.SHOCK_DISPLAY_MODE.key]: translations.pages.lineSettings.shockDisplayMode.header,
  [LINE_SETTINGS_KEYS.SHOCK_DISPLAY_MODE.options.HORIZONTAL_AND_VERTICAL.key]: translations.pages.lineSettings.shockDisplayMode.horizontalAndVertical,
  [LINE_SETTINGS_KEYS.SHOCK_DISPLAY_MODE.options.MAX_SHOCK_ONLY.key]: translations.pages.lineSettings.shockDisplayMode.totalShock,

  // Auto Record Display Mode
  [LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.key]: translations.pages.lineSettings.autoRecordVideo.header,
  [LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.options.ON.key]: translations.pages.lineSettings.autoRecordVideo.on,
  [LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.options.OFF.key]: translations.pages.lineSettings.autoRecordVideo.off,
  [LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.options.FORCED_ON.key]: translations.pages.lineSettings.autoRecordVideo.forcedOn,
  [LINE_SETTINGS_KEYS.AUTO_RECORD_VIDEO.options.FORCED_OFF.key]: translations.pages.lineSettings.autoRecordVideo.forcedOff,

  // Seamer Detection
  [LINE_SETTINGS_KEYS.SEAMER_DETECTION.key]: translations.pages.lineSettings.seamerDetection.header,
  [LINE_SETTINGS_KEYS.SEAMER_DETECTION.options.DISABLED.key]: translations.pages.lineSettings.seamerDetection.disabled,
  [LINE_SETTINGS_KEYS.SEAMER_DETECTION.options.ONE_TWO_X.key]: translations.pages.lineSettings.seamerDetection.oneTwoX,
  [LINE_SETTINGS_KEYS.SEAMER_DETECTION.options.ONE_FOUR_X.key]: translations.pages.lineSettings.seamerDetection.oneFourX,
  [LINE_SETTINGS_KEYS.SEAMER_DETECTION.options.ONE_EIGHT_X.key]: translations.pages.lineSettings.seamerDetection.oneEightX,
}

/**
 * Convert given psi values to bar
 * @param psiValue
 * @returns Converted value
 */
const convertPsiToBar = (psiValue) => {
  const conversionFactor = 14.5037738007 // 1 bar == 14.5037738007 psi. Same conversionFactor used in -app as of Nov '23...
  return psiValue / conversionFactor
}

/**
 * Round given value to given number of decimal places
 * @param value
 * @param decimalPlaces
 * @returns rounded value
 */
const round = (value, decimalPlaces) => {
  return Number(value).toFixed(decimalPlaces); // Similar to -app's rounding, as of Nov '23.
}

/**
 * Update line settings object with given key and value
 * @param lineSettingsObject
 * @param key
 * @param value
 * @returns Updated line settings object.
 */
const updateLineSettings = (lineSettingsObject, key, value) => {
  const updatedLineSettingsObject = {...lineSettingsObject}
  updatedLineSettingsObject[key] = value
  return updatedLineSettingsObject
}

/**
 * Given an object of line settings defaults and options, return an object of just the defaults.
 * @param lineSettingsDefaultsAndOptions
 * @returns line settings defaults
 */
const getLineSettingsDefaults = (lineSettingsDefaultsAndOptions) => {
  const lineSettingsDefaults = {}
  Object.keys(lineSettingsDefaultsAndOptions).forEach(key => {
    lineSettingsDefaults[key] = lineSettingsDefaultsAndOptions[key].default
  })
  return lineSettingsDefaults
}

export {
  TABS,
  LINE_SETTINGS_KEYS,
  LINE_SETTINGS_KEY_TO_LABEL_MAP,
  convertPsiToBar,
  round,
  updateLineSettings,
  getLineSettingsDefaults,
}