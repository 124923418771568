import React, {useContext, useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useQuery} from 'react-query';
import {SiteContext} from "../../Context";
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment';
import {DATE_TIME_TIMEZONE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from '../../shared/Utilities';
import ProgressWrapper from '../../shared/components/ProgressWrapper';
import PageHeader from '../../shared/components/PageHeader/PageHeader';
import ReactJson from 'react-json-view'

import {
  getLineConfiguration
} from '../../query/queries';

const pageTitle = 'View Line Configurations';
const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'auto'
  },
  card: {
    maxWidth: '1000px',
    margin: '0px auto'
  },
  content: {
    margin: '20px 30px',
    display: 'flex'
  },
  title: {
    marginBottom: '20px'
  },
  linearLoad: {
    margin:'10px 0 26px 0',
    width: '250px'
  },
  details: {
    minWidth: 400,
    marginRight: theme.spacing(2)
  },
  jsonView: {
    minWidth: 750,
    maxHeight: 500,
    overflow: 'auto',
    border: '1px solid #666',
    borderRadius: 5,
    padding: 10,
    marginTop: 54
  }
}))

const ViewLineConfiguration = () => {

  const match = useRouteMatch();
  const history = useHistory();
  const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext);
  const {isLoading: isLoadingConfiguration, data:lineConfiguration={}} = useQuery(['lineConfiguration', match.params], getLineConfiguration)
    
  const classes = useStyles();

  const config = lineConfiguration.config ? JSON.parse(lineConfiguration.config) : {};

  useEffect(()=> {
    let crumbs = [
      {
          title: "Line Configurations",
          href: `/list-line-configurations?lineId=${match.params.lineId}`
      }
    ];

    if(lineConfiguration.description){
      crumbs = crumbs.concat({
        title: lineConfiguration.description
      });
    }

    setBreadcrumbs(crumbs);
  }, [setBreadcrumbs, lineConfiguration.description, match.params.lineId])

  return (
    <div className={classes.root} data-testid="view-line-configuration-page">
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <div>
          <PageHeader onBack={() => !!(history.location.key) ? history.goBack() : history.push(`/list-line-configurations?lineId=${lineConfiguration.lineId}`)} pageTitle="Line Configuration" />
            <div className={classes.content}>
              <div className={classes.details}>
                <Typography variant="h5">Version Id</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph>{lineConfiguration.versionId}</Typography>
                </ProgressWrapper>

                <Typography variant="h5">Description</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph>{lineConfiguration.description}</Typography>
                </ProgressWrapper>

                <Typography variant="h5">Line Id</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph>{lineConfiguration.lineId}</Typography>
                </ProgressWrapper>

                <Typography variant="h5">Last Updated</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph><ProtectedMoment date={lineConfiguration.dateLastUpdated} format={DATE_TIME_TIMEZONE_FORMAT} tz={userDetails.user.tz}></ProtectedMoment></Typography>
                </ProgressWrapper>

                <Typography variant="h5">Date Created</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph><ProtectedMoment date={lineConfiguration.dateCreated} format={DATE_TIME_TIMEZONE_FORMAT} tz={userDetails.user.tz}></ProtectedMoment></Typography>
                </ProgressWrapper>

                <Typography variant="h5">Software Version</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph>{lineConfiguration.softwareVersion}</Typography>
                </ProgressWrapper>

                <Typography variant="h5">Lowest Supported Software Version</Typography>
                <ProgressWrapper linear isLoading={isLoadingConfiguration} linearClass={classes.linearLoad}>
                  <Typography paragraph>{lineConfiguration.lowestSupportedSoftwareVersion}</Typography>
                </ProgressWrapper>
              </div>
              <div className={classes.jsonView}>
                <ReactJson src={config} />
              </div>
          </div>
        </div>
      }
    </div>)
}

export default ViewLineConfiguration