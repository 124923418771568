import { QueryClient } from 'react-query';
import Axios from 'axios';

import {
  BASE_SST_API_URL,
  LOCAL_STORAGE_KEY_LOGIN_DETAILS
} from '../Constants';

// setup your client
export const client = new QueryClient();

export const axios = Axios.create({
  baseURL: BASE_SST_API_URL
});

let apiToken = (() => {
  try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS)).token;
  }
  catch(e){
      return "";
  }
})();

axios.defaults.headers.common['Authorization'] = apiToken;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export const setAuthToken = (token) => {
  apiToken = token;
  axios.defaults.headers.common['Authorization'] = token;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
}