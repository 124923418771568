import React, {useState, useEffect} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import { Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import DiagnosisSeverity from './DiagnosisSeverity';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles({
  autocomplete: {
    margin: '20px 5px',
    width: '100%',
    '& .Mui-disabled': {
      opacity: 1.0
    }
  },
  notesContainer:{
    width: '100%'
  },
  paper: {
    padding: '10px',
    overflowX: 'auto'
  }
})

const MDConverter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const Diagnosis = (props) => {

  const classes = useStyles();

  const [editorState, setEditorState] = useState(props.readOnly ? "preview" : "write");
  const [diagnosis, setDiagnosis] = useState({faultTypes: [], symptoms: [], machineParts: [], note:"", ...props.diagnosis});

  useEffect(()=>{
    setDiagnosis(d => ({...d, id: props.diagnosis.id}));
  }, [props.diagnosis.id]);

  const debouncedUpdate = useDebouncedCallback(
    () => {
      props.onChange(diagnosis)
    },
    350
  )

  const onChange = (obj) => {
    const newDiagnosis = {...diagnosis, ...obj}
    setDiagnosis(newDiagnosis);
    if(props.onChange && newDiagnosis.severityId){
      debouncedUpdate.callback();
    }
  }

  const getLabel = (option, list) => {
    return list.find(x => x.id === option).name;
  }

  const getOptions = (list) => {
    return list.map(item => {return item.id});
  }

  return (
    <Paper className={`${classes.paper} ${props.className}`}>
      <DiagnosisSeverity disabled={props.readOnly} severity={diagnosis.severityId} options={props.severities} onChange={(severity) => {onChange({severityId: severity})}} />
      <Autocomplete
        disabled={props.readOnly}
        multiple
        options={getOptions(props.symptoms)}
        value={diagnosis.symptoms}
        onChange={(event, obj) => {onChange({symptoms: obj})}}
        getOptionLabel={(option) => {return getLabel(option, props.symptoms)}}
        className={classes.autocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Selected Symptoms"
            placeholder="Symptoms"
          />
        )}/>
      <Autocomplete
        disabled={props.readOnly}
        multiple
        options={getOptions(props.parts)}
        value={diagnosis.machineParts}
        onChange={(event, obj) => {onChange({machineParts: obj})}}
        getOptionLabel={(option) => {return getLabel(option, props.parts)}}
        className={classes.autocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Selected Parts"
            placeholder="Part"
          />
        )}/>
      <Autocomplete
        disabled={props.readOnly}
        multiple
        options={getOptions(props.faults)}
        getOptionLabel={(option) => {return getLabel(option, props.faults)}}
        value={diagnosis.faultTypes}
        onChange={(event, obj) => {onChange({faultTypes: obj})}}
        className={classes.autocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Selected Faults"
            placeholder="Fault"
          />
        )}/>
      <div className={classes.notesContainer}>
        <ReactMde
          value={diagnosis.note}
          onChange={(val) => {onChange({note: val})}}
          selectedTab={editorState}
          onTabChange={setEditorState}
          toolbarCommands={[]}
          generateMarkdownPreview={markdown =>
            Promise.resolve(MDConverter.makeHtml(markdown))
          }
        />
      </div>    
    </Paper>
    
  )
}

export default Diagnosis