import * as React from "react";
import {useEffect} from "react";
import * as PropTypes from "prop-types";

const ClickOutsideHandler = ({event, eventHandler, children}) => {


    useEffect(() => {
        if (event) {
            document.addEventListener("mousedown", eventHandler);
        } else {
            document.removeEventListener("mousedown", eventHandler);
        }

        return () => {
            document.removeEventListener("mousedown", eventHandler);
        };
    }, [event, eventHandler]);

    return (
        <>
            {children}
        </>);
};
ClickOutsideHandler.propTypes = {
    event: PropTypes.any.isRequired,
    eventHandler: PropTypes.func
};
export default ClickOutsideHandler;