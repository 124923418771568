import React, {useContext, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {useQuery} from 'react-query';
import {getErrorReports} from '../../query/queries';

import {SiteContext} from "../../Context";
import {DATE_TIME_TIMEZONE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET} from "../../shared/Utilities";
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment';
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";

import '../PageStyles/TablePage.css'

import {
  ERROR_REPORT_UPLOAD_COMPLETE
 } from  "../../Constants";

const pageTitle = 'List Error Reports';
const acceptablePagePermission = [
  {entity: 'ErrorReport', method: PERMISSION_METHOD_GET, modifier: ''}
]

const columns = [
  {id: "submittedName", label: "Submitted Name", width: '275px'},
  {id: "submittedEmail", label: "Submitted Email", width: '500px'},
  {id: "submittedCompany", label: "Submitted Company", width: '225px'},
  {id: "dateCreated", label: "Created", width: '210px'},
  {id: "actions", label: "Actions", width: '130px'}
];

const generateRows = (reports, details) => {

  return reports.filter(r => r.uploadStatus === ERROR_REPORT_UPLOAD_COMPLETE).map(report => {
    return {
      submittedName: report.submittedName || "-",
      submittedEmail: report.submittedEmail || "-",
      submittedCompany: report.submittedCompany || "-",
      dateCreated: <ProtectedMoment date={report.dateCreated} format={DATE_TIME_TIMEZONE_FORMAT} rawval={report.dateCreated} tz={details.user.tz} />,
      actions:  <Link to={`error-reports/${report.id}`}>View Details</Link>
    }  
  })
}


const ListErrorReports = (props) => {

    const history = useHistory();
    const {setBreadcrumbs, userDetails, hasPermission} = useContext(SiteContext);

    const {isLoading: isLoadingReports, data: reports=[]} = useQuery(['errorReports'], getErrorReports);
    
    useEffect(() => {
      document.title = pageTitle;
      setBreadcrumbs([{title: pageTitle}]);
    }, [setBreadcrumbs]);
    
    return (
        <div className="page" data-testid="list-error-reports-page">
          {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
            <div className="container">
              <EnhancedTable
                  title="Error Reports"
                  isLoading={isLoadingReports}
                  order={'desc'}
                  orderBy={'dateCreated'}
                  rows={generateRows(reports, userDetails)}
                  headCells={columns}
                  enableFilter={true}
                  filterColumns={['submittedName', 'submittedEmail', 'submittedCompany']}/>
            </div>
          }
        </div>
    );
};

export default ListErrorReports;
