import React, { useState, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import { sleep } from '../../Utilities'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1.2px solid #7E7E7E',
    borderRadius: '5px',
    boxShadow: 'none',
  },
  summary: {
    borderRadius: '4px',
    flexDirection: 'row-reverse',
    color: '#272727',
    width: '100%',
    '&:hover': {
      backgroundColor: '#C4C4C4',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '24px',
  },
  details: {
    backgroundColor: '#F5F5F5',
    flexGrow: 1,
    padding: '16px',
  },
})

const SSTAccordion = (props) => {
  const id = props.id,
    content = props.content,
    disabled = props.disabled,
    name = props.name,
    expandOnInit = props.expandOnInit,
    tooltipEnabled = props.tooltipEnabled,
    tooltipLabel = props.tooltipLabel,
    focusOnExpand = !!props.focusOnExpand,
    storeLocalState = !!props.storeLocalState

  //sessionStorage of expanded state takes priority over expandOnInit value
  const determineExpanded = (localStateStored, id, expandOnInit) => {
    let expanded = expandOnInit

    if (localStateStored) {
      const sessionStorageRetrievalKey = `panel${id}-expanded`
      const localState = sessionStorage.getItem(sessionStorageRetrievalKey)
      if (localState === null) {
        sessionStorage.setItem(sessionStorageRetrievalKey, expanded)
      } else {
        expanded = localState.toLowerCase() === 'true'
      }
    }
    return expanded
  }

  const [expanded, setExpanded] = useState(
    determineExpanded(storeLocalState, id, expandOnInit)
  )
  const detailsRef = useRef()

  const classes = useStyles({ disabled })

  function scrollIntoView() {
    const details = detailsRef
    if (detailsRef.current) {
      details.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  async function handleClick() {
    const expandedValue = !expanded
    if (storeLocalState) {
      sessionStorage.setItem(`panel${id}-expanded`, expandedValue)
    }
    setExpanded(expandedValue)
    if (focusOnExpand && expandedValue === true) {
      if (focusOnExpand) {
        await sleep(165)
        scrollIntoView()
      }
    }
  }

  return (
    <Accordion
      className={classes.root}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={expandOnInit && !storeLocalState}
      disabled={disabled}
      expanded={expanded}
    >
      <Tooltip
        className={classes.tooltip}
        disableHoverListener={!tooltipEnabled}
        title={tooltipLabel ?? ''}
        placement={'top'}
        followCursor={true}
      >
        <span>
          <AccordionSummary
            id={`panel${id}-header`}
            aria-controls={`panel${id}-content`}
            className={classes.summary}
            expandIcon={<ExpandMoreIcon className={classes.summary} />}
            onClick={handleClick}
          >
            <Typography className={classes.title}>{name}</Typography>
          </AccordionSummary>
        </span>
      </Tooltip>
      <AccordionDetails
        id={`panel${id}-details`}
        className={classes.details}
        ref={detailsRef}
        children={content}
      />
    </Accordion>
  )
}

export default SSTAccordion
