import translations from '../../translations/en.json';

const PREVIOUS = 0;
const NEXT = 1;
const RADIUS_IN_SECONDS = 1.0

/**
 * Given a timeSeriesDataModel, and additionalTimes, enrich the timeSeriesDataModel's times and values,
 * such that its times includes the additionalTimes (in sorted order), and its values includes values
 * at the same indices that the additionalTimes were inserted at.
 *
 * To determine which value to insert for an additional time, we find the timeSeriesDataModel time nearest
 * to the additionalTime and get the value at that time. Example: Given timeSeriesDataModel times [1, 2],
 * values [10, 20], and additionalTimes [1.1, 1.2, 1.9]: 1.1 is closer to 1 than 2, so we get the value at
 * time 1, which is 10. 1.2 is closer to 1 than 2, so we get the value at time 1, which is 10. 1.9 is closer
 * to 2 than 1, so we get the value at time 2, which is 20. Result is timeSeriesDataModel times
 * [1, 1.1, 1.2, 1.9, 2], values [10, 10, 10, 20, 20].
 *
 * NOTES:
 *
 * - Impure / mutator function, to reduce memory consumption. Specifically, we're mutating the
 *   given timeSeriesDataModel.
 *
 * @param timeSeriesDataModel
 * @param additionalTimes
 */
const enrichTimeSeriesDataModelWithAdditionalData = (timeSeriesDataModel, additionalTimes) => {

  // Validate timeSeriesDataModel...
  if ( !timeSeriesDataModel ) {
    return timeSeriesDataModel;
  }

  // Validate additionalTimes...
  if ( !additionalTimes ||
       additionalTimes.length === 0) {
    return timeSeriesDataModel;
  }

  // Track times, values...
  const times = timeSeriesDataModel.data[0]
  const values = timeSeriesDataModel.data[1]

  // Maintain enrichmentDataBuffer; to ensure enrichment data, derived from timeSeriesDataModel,
  // is not influenced by other enrichment data in timeSeriesDataModel...
  let enrichmentDataBuffer = [];

  // For each additionalTime, determine at which index to insert it, in timeSeriesDataModel's times collection.
  // Then, determine which value to insert at the same index in timeSeriesDataModel's values collection. Finally,
  // add the enrichment data to enrichmentDataBuffer...
  for (const additionalTime of additionalTimes) {

    // Find index of first time greater than additionalTime...
    let indexToInsert = times.findIndex(time => time > additionalTime)

    // If no time greater than additionalTime, then insert additionalTime at end...
    indexToInsert = indexToInsert === -1 ? times.length - 1 : indexToInsert

    // Derive timeToInsert...
    const timeToInsert = additionalTime;

    // Derive valueToInsert...
    const valueToInsert = getNearestValueToAdditonalTime({
      times,
      values,
      additionalTime,
      indexOfFirstTimeGreaterThanAdditionalTime: indexToInsert
    })

    // Buffer enrichment data so we can add it later. We do this to help ensure that
    // enrichment data is not influenced by other enrichment data in timeSeriesDataModel...
    enrichmentDataBuffer.push({index: indexToInsert, time: timeToInsert, value: valueToInsert})

  }

  // Enrich data, with buffered data...
  let pointsAddedCount = 0;
  enrichmentDataBuffer.forEach(currentBufferedObject => {
    times.splice(currentBufferedObject.index + pointsAddedCount, 0, currentBufferedObject.time) // time
    values.splice(currentBufferedObject.index + pointsAddedCount, 0, currentBufferedObject.value) // value
    pointsAddedCount++;
  })

}

/**
 * Given a pressureMap sorted by key, and additionalTimes, enrich the pressureMap's times and values,
 * such that its KVPs include additionalTimes and associated values.
 *
 * To determine which value to insert for an additional time, we find the pressureMap time nearest
 * to the additionalTime and get the value at that time.
 *
 * NOTES:
 *
 * - Impure / mutator function, to reduce memory consumption. Specifically, we're mutating the
 *   given pressureMap.
 *
 * @param pressureMap
 * @param additionalTimes
 */
const enrichPressureMapWithAdditionalData = (pressureMap, additionalTimes) => {

  // Validate timeSeriesDataModel...
  if ( !pressureMap ||
       pressureMap.length === 0) {
    return pressureMap;
  }

  // Validate additionalTimes...
  if ( !additionalTimes ||
       additionalTimes.length === 0) {
    return pressureMap;
  }

  // Maintain enrichmentDataBuffer; to ensure enrichment data, derived from pressureMap,
  // is not influenced by other enrichment data in pressureMap...
  let enrichmentDataBuffer = [];

  // Get times, timesMin, timesMax, values...
  const pressureMapAsMap = new Map(Object.entries(pressureMap));
  let times = Array.from(pressureMapAsMap.keys()); // Get array of keys in the same order they appear in the map...
  const timesMin = times[0]
  const timesMax = times[times.length - 1]
  let values =  Array.from(pressureMapAsMap.values()); // Get array of values in the same order they appear in the map...

  // For each additionalTime, determine at which indexes the surrounding pressureMap KVPs are
  // located. Then, determine which KVP.value to associate to the additional time. Finally,
  // add the enrichment data to enrichmentDataBuffer...
  for (const additionalTime of additionalTimes) {

    // If additionalTime is outside the range of times in pressureMap, skip it...
    if (additionalTime < timesMin || additionalTime > timesMax) {
      continue;
    }

    // Find index of first time greater than additionalTime...
    let indexOfFirstTimeGreaterThanAdditionalTime = times.findIndex(time => time > additionalTime)

    // Derive timeToInsert...
    let timeToInsert = additionalTime;

    // Derive valueToInsert...
    const valueToInsert = getNearestValueToAdditonalTime({
      times,
      values,
      additionalTime,
      indexOfFirstTimeGreaterThanAdditionalTime
    })

    // Buffer data, so we can add it later. We do this to help ensure that
    // enrichment data is not influenced by enrichment data in pressureMap...
    enrichmentDataBuffer.push( { time: timeToInsert, value: valueToInsert } )

  }

  // Enrich data, with buffered data. (Order doesn't matter)...
  enrichmentDataBuffer.forEach(currentBufferedObject => {
    pressureMap[currentBufferedObject.time] = currentBufferedObject.value;
  })

}

/**
 * Given collections of times and values (of same length, associated at the indexes), an additionalTime,
 * and indexOfFirstTimeGreaterThanAdditionalTime; determine which value, in values, is nearest to additionalTime.
 *
 * @param times
 * @param values
 * @param additionalTime
 * @param indexOfFirstTimeGreaterThanAdditionalTime
 * @returns {*}
 */
const getNearestValueToAdditonalTime = ({times, values, additionalTime, indexOfFirstTimeGreaterThanAdditionalTime}) => {

  // Derive indexes...
  const previousIndex = indexOfFirstTimeGreaterThanAdditionalTime === 0 ? 0 : indexOfFirstTimeGreaterThanAdditionalTime - 1 // If indexOfFirstTimeGreaterThanAdditionalTime is 0, set previousIndex to 0, for simplicity...
  const currentIndex = indexOfFirstTimeGreaterThanAdditionalTime

  // Determine which time is nearest to additionalTime, between timeAtPreviousIndex and timeAtIndex...

  const timeAtPreviousIndex = times[previousIndex]
  const timeAtIndex = times[currentIndex]

  // a. Find the distances between timeAtPreviousIndex and timeAtIndex, from additionalTime...
  const distanceToTimeAtPreviousIndex = Math.abs(timeAtPreviousIndex - additionalTime)
  const distanceToTimeAtIndex = Math.abs(timeAtIndex - additionalTime)

  // b. Determine which time is nearest to additionalTime, and get the associated value...
  let nearestValue = distanceToTimeAtPreviousIndex < distanceToTimeAtIndex ?
    values[previousIndex] :
    values[currentIndex];

  return nearestValue;
}

/**
 * Calculate minTimeKey as selectedTime - RADIUS_IN_SECONDS, but not less than timeDataMin...
 * @param selectedTime
 * @param timeDataMin
 * @returns {*|number}
 */
const getPressureMapMinTimeKey = (selectedTime, timeDataMin) => {
  let pressureMapMinTimeKey = selectedTime - RADIUS_IN_SECONDS;
  pressureMapMinTimeKey = pressureMapMinTimeKey < timeDataMin ? timeDataMin : pressureMapMinTimeKey;

  return pressureMapMinTimeKey;
}

/**
 * Calculate maxTimeKey as selectedTime + RADIUS_IN_SECONDS, but not more than timeDataMax...
 * @param selectedTime
 * @param timeDataMax
 * @returns {*|number}
 */
const getPressureMapMaxTimeKey = (selectedTime, timeDataMax) => {
  let pressureMapMaxTimeKey = selectedTime + RADIUS_IN_SECONDS;
  pressureMapMaxTimeKey = pressureMapMaxTimeKey > timeDataMax ? timeDataMax : pressureMapMaxTimeKey;

  return pressureMapMaxTimeKey;
}

/**
 * Given a collection of ascendingTimes, find the nearest time to targetTime...
 * @param ascendingTimes
 * @param targetTime
 * @returns {*}
 */
const getNearestTime = (ascendingTimes, targetTime) => {

  // 1. Find targetTime in ascendingTimes and return it, if it exists. Otherwise,
  //    find the indexes of the surrounding ascendingTimes, relative to targetTime...

  let lowIndex = 0;
  let highIndex = ascendingTimes.length - 1;

  while (lowIndex <= highIndex) {

    const midIndex = Math.floor((lowIndex + highIndex) / 2);
    const midValue = ascendingTimes[midIndex];

    if (midValue === targetTime) {
      // targetTime was found, so return it...
      return targetTime;
    }

    if (midValue < targetTime) {
      lowIndex = midIndex + 1;
    }

    if (midValue > targetTime) {
      highIndex = midIndex - 1;
    }
  }

  // 2. If targetTime was not found in ascendingTimes, return the nearest ascendingTime...

  if (highIndex < 0) {
    // All ascendingTimes are greater than targetTime, so return the first (smallest) ascendingTime...
    return ascendingTimes[0];
  }

  if (lowIndex >= ascendingTimes.length) {
    // All ascendingTimes are smaller, so return the last (largest) ascendingTime...
    return ascendingTimes[ascendingTimes.length - 1];
  }

  // Compare the values at lowIndex and highIndex to determine which is nearest to targetTime...
  const lowValue = ascendingTimes[lowIndex];
  const highValue = ascendingTimes[highIndex];

  if (Math.abs(lowValue - targetTime) < Math.abs(highValue - targetTime)) {
    return lowValue;
  } else {
    return highValue;
  }

}

/**
 * Given a collection of times, get the first adjacent time to currentTime, that is not in additionalTimes,
 * in the directionToNavigate...
 * @param times
 * @param currentTime
 * @param directionToNavigate
 * @param additionalTimes
 * @returns First time adjacent to currentTime, in times, that is not in additionalTimes
 */
const getAdjacentTimeNotInAdditionalTimes = ({times, currentTime, directionToNavigate, additionalTimes}) => {

  // Get adjacent time...
  let adjacentTime = getAdjacentTime(times, currentTime, directionToNavigate);

  // If adjacentTime is in additionalTimes, then get next time, in the directionToNavigate...
  while (additionalTimes.includes(adjacentTime)) {
    adjacentTime = getAdjacentTime(times, adjacentTime, directionToNavigate);
  }

  return adjacentTime;
};

/**
 * Given a collection of times, get the time adjacent to currentTime, in the directionToNavigate...
 * @param times
 * @param currentTime
 * @param directionToNavigate
 * @returns Time adjacent to currentTime in timeData
 */
const getAdjacentTime = (times, currentTime, directionToNavigate) => {

  // Validate timeData, selectedTime...
  if ( !times ||
    currentTime === null ||
    currentTime === undefined ) {
    return;
  }

  // Determine current timeData index...
  const currentTimeDataIndex = times.indexOf(currentTime);

  // Determine updated timeData index...
  let updatedTimeDataIndex = null;
  updatedTimeDataIndex = directionToNavigate === PREVIOUS ? currentTimeDataIndex - 1 : updatedTimeDataIndex;
  updatedTimeDataIndex = directionToNavigate === NEXT ? currentTimeDataIndex + 1 : updatedTimeDataIndex;

  // Return adjacent time...
  return times[updatedTimeDataIndex];
}

const getPressureMapDataForTime = (pressureMapData, time) => {

  // Since Javascript interprets numbers with trailing zeros as that number without trailing zeroes (1.0 as 1, 1.00 as 1, 1.10 as 1.1, etc.),
  // and since the pressureMapData.pressureMap object uses doubles, where ints have trailing zeroes (e.g. 1.0, 2.0), as keys, AS STRINGS
  // (since JSON keys are strings), we need to convert time param to a string with trailing zeroes, when time param is an integers, so we can
  // hope to match pressureMap key "1.0" to "1.0" (match!), instead of "1.0" to 1 (no match)...
  if (Number.isInteger(time)) {
    time = time.toString() + ".0";
  }

  return pressureMapData?.pressureMap[time];
}

const getHeatmapChartMessage = (droneConfigurationSupportsPressure, droneConfigurationSupportsCustomLayout, selectedRunTime) => {

  // Check if pressure data is supported by the drone configuration...
  if ( !droneConfigurationSupportsPressure ) {
    return translations.pages.customerLineDetails.noPressureDataAvailable;
  }

  // Check if custom layout is supported by the drone configuration...
  if ( droneConfigurationSupportsCustomLayout ) {
    return translations.pages.customerLineDetails.customLayoutsNotSupported;
  }

  // Check if time selected...
  if ( !selectedRunTime ) {
    return translations.pages.customerLineDetails.noTimeSelected;
  }

  return null;
}

export {
  PREVIOUS,
  NEXT,
  RADIUS_IN_SECONDS,
  enrichTimeSeriesDataModelWithAdditionalData,
  enrichPressureMapWithAdditionalData,
  getPressureMapMinTimeKey,
  getPressureMapMaxTimeKey,
  getNearestTime,
  getAdjacentTimeNotInAdditionalTimes,
  getPressureMapDataForTime,
  getHeatmapChartMessage,
}