import defaultTheme from './OriginalTheme';

const theme = {
    default: defaultTheme
}

export default theme;

export const overrides = {

    typography: {
        fontFamily: 'WorkSans',
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2rem',
        },
        h3: {
            fontSize: '1.64rem',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.285rem',
        },
        h6: {
            fontSize: '1.142rem',
        }
    }
};
