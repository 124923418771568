import {axios} from '../client';

import {
  SST_API_PATH_ROLES,
  SST_API_PATH_CUSTOMERS,
} from './../../Constants';

import {
  updateCustomerUser
} from './users'

export const getRoles = ({queryKey}) => {
  const [,{onPlatformCustomer}] = queryKey;
  return axios.get(`${SST_API_PATH_ROLES}`).then(res => {
    const roles = res.data;
    return roles.filter(r => ((r.scopeEntity === 'Platform') === onPlatformCustomer));
  });
}

export const insertCustomerUserRole = ({customerId, userId, body}) => {
  return axios.post(`${SST_API_PATH_CUSTOMERS}/${customerId}/users/${userId}/roles`, body).then(res => res.data);
}

export const removeCustomerUserRole = ({customerId, userId, roleId}) => {
  return axios.delete(`${SST_API_PATH_CUSTOMERS}/${customerId}/users/${userId}/roles/${roleId}`).then(res => res.data);
}

export const updateUserAndRoles = ({customerUser, delta}) => {
  const {customerId, userId} = customerUser;
  const userPromise = updateCustomerUser(customerUser);
  const addRolePromises = delta.added.map(role => insertCustomerUserRole({customerId, userId, body: role}));
  const removeRolePromises = delta.removed.map(role => removeCustomerUserRole({customerId, userId, roleId: role.id}));

  return Promise.all([userPromise, ...addRolePromises, ...removeRolePromises]);
}