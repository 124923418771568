import Tree from './parent-relation.json';

//Bonus points for recursion
const walk = (leaf, path=[], memo={}) => {
  if(path.length > 0){
    const copy = [...path];
    const last = copy.pop();
    memo[last] = copy;
  }
  const branches = Object.keys(leaf);
  if(branches.length > 0){
    branches.map((b) => walk(leaf[b], [...path, b], memo));
  }
  return memo;
}

const authChecks = walk(Tree);

export default authChecks;