import React, { useContext, useEffect, useMemo, useState } from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { useHistory, useLocation } from 'react-router-dom'

import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import {useSnackbar} from 'notistack';
import { MenuItem, TextField } from '@mui/material'
import {makeStyles} from '@mui/styles';
import queryString from "query-string";
import {SiteContext} from "../../Context";
import SSTDropdown, { NO_CONTENT_ITEM_VALUE } from '../../shared/components/SSTDropdown'
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_INSERT } from "../../shared/Utilities";

import {
  getCustomer,
  getCustomerLines,
  getCustomerPlants,
  getCustomerTypes,
  getEntityPermissions,
  getPlantLines,
} from '../../query/queries'
import { BEVERAGE_CUSTOMERTYPE_ID, BEVERAGE_MC_TYPE_ID, PHARMA_CUSTOMERTYPE_ID, PHARMA_MC_TYPE_ID, SST_PAGE_CREATE_MACHINE_CENTER, SST_PAGE_LIST_MACHINE_CENTERS } from '../../Constants'
import translations from '../../translations/en.json';
import {
  createMachineCenter,
  getMachineCenter,
  getMachineCenterTemplates,
  updateMachineCenter
} from '../../query/entities/machineCenters'
import QuantifeelSwitch from '../../shared/components/QuantifeelSwitch'
import { getMachineCenterTypes } from '../../query/entities/machineCenterTypes'

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptyMachineCenter = {};

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px'
  }
})

const CreateAndEditMachineCenter = (props) => {

  const {setBreadcrumbs, currentCustomer, hasPermission} = useContext(SiteContext);
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [machineCenter, setMachineCenter] = useState(emptyMachineCenter);
  const [customerTypeId, setCustomerTypeId] = useState("");
  const [filteredMachineCenterTypes, setFilteredMachineCenterTypes] = useState([]);
  
  const [isEdit, setIsEdit] = useState(null); // Defaulting to null, as a means to prevent useQueries that are dependent on isEdit from enabling before isEdit is derived / set...

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {isLoading: isLoadingMachineCenterData, data: machineCenterData} = useQuery(
    ['machineCenter', {machineCenterId: machineCenter.id}],
    getMachineCenter,
    {enabled: !!machineCenter.id}
  );

  const {isLoading: isLoadingCustomer, data: customer = []} = useQuery(
    ['customers', {customerId: currentCustomer}],
    getCustomer
  );

  // Used in finding the customer type name
  const {isLoading: isLoadingCustomerTypes, data: customerTypes = []} = useQuery(
    ['customerTypes'], 
    getCustomerTypes
  );

  // Get plants, omitting deleted plants, for purposes of populating Plants dropdown, when creating a new machine center...
  const {isLoading: isLoadingPlantsOmitDeleted, data: plantsOmitDeleted} = useQuery(
    ['plants', {customerId: currentCustomer}],
    getCustomerPlants,
    { enabled: isEdit === false } // Since only required when creating, enable when isEdit is false...
  );

  // Get plants, including deleted plants, for purposes of populating Plants dropdown, when editing a machine center, regardless of if the plant is deleted...
  const {isLoading: isLoadingPlantsIncludeDeleted, data: plantsIncludeDeleted=[]} = useQuery(
    ['plants', {customerId: currentCustomer}, { includeDeleted: true }],
    getCustomerPlants,
    { enabled: isEdit === true } // Since only required when editing, enable when isEdit is true...
  );

  // Get plant lines, omitting deleted lines, for purposes of populating Lines dropdown, when creating a new machine center...
  const {isLoading: isLoadingPlantLinesOmitDeleted, data: plantLinesOmitDeleted=[]} = useQuery(
    ['lines', {plantId: machineCenter.plantId}],
    getPlantLines,
    {enabled:
        !!machineCenter.plantId &&
        ( isEdit === false ) // Since only required when creating, enable when isEdit is false...
    }
  );

  // Get customer lines, including deleted lines, for purposes of deriving plantLinesIncludeDeleted...
  const {isLoading: isLoadingCustomerLinesIncludeDeleted, data: customerLinesIncludeDeleted=[]} = useQuery(
    ['lines', {customerId: currentCustomer}, { includeDeleted: true }],
    getCustomerLines,
    { enabled: isEdit === true } // Since only required when editing, enable when isEdit is true...
  );

  // Get customer lines, not including deleted lines, for purposes of validating pre-populated line dropdown...
  const {isLoading: isLoadingCustomerLinesOmitDeleted, data: customerLinesOmitDeleted} = useQuery(
      ['lines', {customerId: currentCustomer}],
      getCustomerLines,
      { enabled: isEdit === false } // Since only required when creating, enable when isEdit is false...
  );

  // Get machine center templates, for purposes of populating the metadata
  const {isLoading: isLoadingTemplates, data: machineCenterTemplates=[]} = useQuery(
      ['machineCenterTemplates'],
      getMachineCenterTemplates,
      { enabled: isEdit === false } // Since only required when creating, enable when isEdit is false...
  );


  // Derive plantLinesIncludeDeleted, which is a subset of customerLinesIncludeDeleted, filtered by machineCenter.plantId,
  // for purposes of populating Lines dropdown, when editing a machine center, regardless of if the plant is deleted...
  const plantLinesIncludeDeleted = useMemo(() => {
    return customerLinesIncludeDeleted.filter((line) => line.plantId === machineCenter.plantId);
  }, [customerLinesIncludeDeleted, machineCenter]);

  // Used to filter the machine center types since the types can be based on the selected line.
  const selectedLine = useMemo(() => {
    return isEdit ? 
      plantLinesIncludeDeleted.find(line => line.id === machineCenter.lineId) :
      plantLinesOmitDeleted.find(line => line.id === machineCenter.lineId);
  }, [plantLinesIncludeDeleted, plantLinesOmitDeleted, machineCenter.lineId]);

  const {isLoading: isLoadingMachineCenterTypes, data: machineCenterTypes=[]} = useQuery(
    ['machineCenterTypes'],
    getMachineCenterTypes,
  );

  const {isLoading: isLoadingPermissions, data: permissions} = useQuery(
    ['permissions', currentCustomer, 'machineCenter', {ids: [machineCenter.id]}],
    getEntityPermissions,
    {enabled: !!machineCenter.id}
  );

  const readOnly =
    isEdit ?
    !permissions?.machinecenter[machineCenter.id]?.update : // Determine if user has permission to update the given machine center...
    false;

  const pageTitle =
    location.pathname === `/${SST_PAGE_CREATE_MACHINE_CENTER}` ?
      translations.common.machineCenters.createMachineCenter :
      translations.common.machineCenters.editMachineCenter;

  const {mutate: mutateUpdate, isLoading: isUpdating} = useMutation(updateMachineCenter, {
    onSuccess: (data) => {
      enqueueSnackbar(translations.pages.createAndEditMachineCenters.machineCenterUpdated, {variant: 'success'});
      queryClient.removeQueries('machineCenters');
      queryClient.setQueryData(['machineCenter', {machineCenterId: data.id}], data);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  const {mutate: mutateCreate, isLoading: isCreating} = useMutation(createMachineCenter, {
    onSuccess: (data) => {
      enqueueSnackbar(translations.pages.createAndEditMachineCenters.machineCenterCreated, {variant: 'success'})
      queryClient.removeQueries('machineCenters');
      queryClient.setQueryData(['machineCenter', {machineCenterId: data.id}], data);
      history.push(`/${SST_PAGE_LIST_MACHINE_CENTERS}`);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  const isLoading = useMemo(() => {
    return isLoadingMachineCenterData ||
           isLoadingCustomer ||
           isLoadingPlantsOmitDeleted ||
           isLoadingPlantsIncludeDeleted ||
           isLoadingPlantLinesOmitDeleted ||
           isLoadingCustomerLinesIncludeDeleted ||
           isLoadingCustomerLinesOmitDeleted ||
           isLoadingMachineCenterTypes ||
           isLoadingPermissions ||
           isUpdating ||
           isCreating ||
           isLoadingTemplates ||
           isLoadingCustomerTypes;
  }, [
    isLoadingMachineCenterData,
    isLoadingCustomer,
    isLoadingPlantsOmitDeleted,
    isLoadingPlantsIncludeDeleted,
    isLoadingPlantLinesOmitDeleted,
    isLoadingCustomerLinesIncludeDeleted,
    isLoadingCustomerLinesOmitDeleted,
    isLoadingMachineCenterTypes,
    isLoadingPermissions,
    isUpdating,
    isCreating,
    isLoadingTemplates,
    isLoadingCustomerTypes
  ]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  useEffect(() => {
    if(machineCenterData){

      const enrichedMachineCenterData = {...machineCenterData};

      // Set machineCenterTypeId, since required by update API, lest the API interpret our request as including setting machineCenterType to null...
      enrichedMachineCenterData.machineCenterTypeId = enrichedMachineCenterData.machineCenterType?.id;

      setMachineCenter(enrichedMachineCenterData);
    }
  }, [machineCenterData]);

  useEffect(() => {

      const qs = queryString.parse(location.search);

      if (qs.machineCenterId) { // If we get passed in a MachineCenterID, then assume that we are editing that machine center
          document.title = translations.common.machineCenters.editMachineCenter;
          setIsEdit(true);
          setMachineCenter(l => {return {...l, id: qs.machineCenterId}});
          setBreadcrumbs([{title: translations.common.machineCenters.editMachineCenter}]);
      } else {
          document.title = translations.common.machineCenters.createMachineCenter;
          setIsEdit(false);
          setBreadcrumbs([{title: translations.common.machineCenters.createMachineCenter}]);
          if (qs.plantId) {
            let queryParamPlant = plantsOmitDeleted?.find(plantUnderCustomer => plantUnderCustomer.id === qs.plantId);
            if (queryParamPlant) {
              setMachineCenter(l => {return {...l, plantId: qs.plantId}});
            }
          } else if (qs.lineId) {
            let queryParamLine = customerLinesOmitDeleted?.find(lineUnderCustomer => lineUnderCustomer.id === qs.lineId);
            if(queryParamLine) {
              setMachineCenter(l => {return {...l, plantId: queryParamLine.plantId, lineId: queryParamLine.id}});
            }
          }
      }
  }, [setBreadcrumbs, location.search, plantsOmitDeleted, customerLinesOmitDeleted]);

  useEffect(() => {
    if(customerTypes.length !== 0 && customer.customerTypeId) {
      let customerTypeName = customerTypes.find(type => type.id === customer.customerTypeId).id; // Find the name of the customer type
      setCustomerTypeId(customerTypeName);
    }
  }, [customerTypes, customer.customerTypeId]);

  useEffect(() => {
    if(selectedLine !== undefined) {
      setFilteredMachineCenterTypes(filterMachineCenterTypes(selectedLine.isPharma, machineCenterTypes));
    }
  }, [selectedLine, machineCenterTypes]);

  // --------------------
  // -- END useEffects --
  // --------------------

  const submit = (e) => {
    e.preventDefault();
    if (isEdit) {
      mutateUpdate(machineCenter);
    }
    else {

      /**
       * Add templated metadata to machine center, with "" values...
       *
       * This is a stopgap - in the future we should enforce metadata specification at creation,
       * but this allows us to skip that step for now. This way, going to edit metadata will have
       * the keys present for type enforcement...
       */
      if(!!(machineCenter.machineCenterTypeId)) {
        let template = machineCenterTemplates.find(a => a.id === machineCenter.machineCenterTypeId).metadata;
        template.forEach((a) => {
          a.value = ""; // Required because metadata can't have a null value
        })
        machineCenter.machineCenterMetadata = template;
      }

      mutateCreate(machineCenter);
    }
  };

  const handlePlantSelected = (e) => {
    const plantId = e.target.value;
    handleUpdate({
      plantId, // Set plantId...
      lineId: '' // Clear lineId, since plantId has changed...
    });
  }

  const handleUpdate = (update) => {
    setMachineCenter(mc => ({...mc, ...update}));
  }

  const filterMachineCenterTypes = (isLinePharma, mcTypes) => {
    let isCustomerTypeNotPharmaOrBeverage = customerTypeId !== BEVERAGE_CUSTOMERTYPE_ID && customerTypeId !== PHARMA_CUSTOMERTYPE_ID;
    // Checks that will return all machine center types options
    if((!isLinePharma && isCustomerTypeNotPharmaOrBeverage) || (isLinePharma && customerTypeId === BEVERAGE_CUSTOMERTYPE_ID) || (isEdit && machineCenter.machineCenterTypeId)) {
      return mcTypes;
    }
    // Customer type is pharma or line is in vial mode, only show Pharma Capper option
    if(isLinePharma) {
      return mcTypes.filter(type => type.id === PHARMA_MC_TYPE_ID);
    }
    // Customer type is either not pharma or line is not in vial mode and the customer type is beverage, only show Filler/Seamer option
    if(!isLinePharma && customerTypeId === BEVERAGE_CUSTOMERTYPE_ID) {
      return mcTypes.filter(type => type.id === BEVERAGE_MC_TYPE_ID);
    }      
  }

  // ------------------
  // -- BEGIN render --
  // ------------------

  const classes = useStyles();

  return (
      <div data-testid={isEdit ? 'edit-machine-center-page' : 'create-machine-center-page'} className={classes.root}>
        {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
          <CreateEditWrapper
            onBack={() => !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_MACHINE_CENTERS}`)}
            buttonTitle={isEdit ? translations.common.save : translations.common.create}
            pageTitle={isEdit ? translations.common.machineCenters.editMachineCenter : translations.common.machineCenters.createMachineCenter}
            submit={submit}
            isLoading={isLoading}
            classes={classes}
            disableSubmit={readOnly}
            headerContentRight={
              <QuantifeelSwitch
                checked={!machineCenter.inactive}
                onChange={(e) => {handleUpdate({inactive: !e.target.checked})}}
              />
            }>
              <div className={classes.content}>

                  {/* Customer */}
                  <SSTDropdown
                    classes={classes}
                    disabled={true}
                    isLoadingContents={isLoadingCustomer}
                    label={translations.common.customers.customer}
                    defaultValue={''}
                    setValueFunc={() => {}}
                    selectedValueId={currentCustomer || ''}
                    mappedList={[customer].map((c) =>
                      <MenuItem
                        key={c.name}
                        value={c.id}>
                          {c.name}
                      </MenuItem>
                    )}
                  />

                  {/* Plant */}
                  <SSTDropdown
                    classes={classes}
                    disabled={isLoading || isEdit}
                    readOnly={readOnly}
                    isLoadingContents={isLoadingPlantsIncludeDeleted || isLoadingPlantsOmitDeleted}
                    label={translations.common.plants.plant}
                    defaultValue={''}
                    selectedValueId={machineCenter.plantId || ''}
                    setValueFunc={handlePlantSelected}
                    mappedList={
                      // If isEdit; map plantsIncludeDeleted, since we could be editing a machine center w/ deleted ancestors; else map plantsOmitDeleted...
                      ( isEdit === true ?
                          plantsIncludeDeleted :
                          ( plantsOmitDeleted != null ? plantsOmitDeleted : [] )
                      ).map((plant) =>
                        <MenuItem
                          key={plant.name}
                          value={plant.id}>
                          {plant.name}
                        </MenuItem>
                      )
                    }
                  />

                  {/* Line */}
                  <SSTDropdown
                    classes={classes}
                    disabled={isLoading || isEdit}
                    readOnly={readOnly}
                    isLoadingContents={isLoadingCustomerLinesIncludeDeleted || isLoadingPlantLinesOmitDeleted}
                    label={translations.common.lines.line}
                    defaultValue={''}
                    selectedValueId={
                      machineCenter.lineId === NO_CONTENT_ITEM_VALUE ? // If lineId is 0, as defaulted by SSTDropdown...
                      machineCenter.lineId : // ...then supply machineCenter.lineId of 0
                      machineCenter.lineId || ''
                    }
                    setValueFunc={(e) => {handleUpdate({lineId: e.target.value})}}
                    mappedList={
                      // If isEdit; map plantLinesIncludeDeleted, since we could be editing a machine center w/ deleted ancestors; else map plantLinesOmitDeleted...
                      (isEdit === true ? plantLinesIncludeDeleted : plantLinesOmitDeleted).map((plantLine) =>
                        <MenuItem
                          key={plantLine.name}
                          value={plantLine.id}>
                          {plantLine.name}
                        </MenuItem>
                      )
                    }
                  />

                  {/* Machine Center Type */}
                  {/* Intentionally modeled after customerType field on CreateAndEditCustomer, for UX consistency */}
                  <TextField
                    className={classes.formControl}
                    select
                    fullWidth
                    autoComplete="disabled"
                    disabled={
                      isLoading ||
                      !(machineCenter.lineId) ||
                      machineCenter.machineCenterType != null // Disable the field if there is already a persisted type, to prevent modification...
                    }
                    label={translations.pages.createAndEditMachineCenters.machineCenterType}
                    value={
                      // When setting type, it is tracked by machineCenter.machineCenterTypeId. When a type has already been set / persisted,
                      // it is returned via machineCenter.machineCenterType.id. When determining the selected value for the dropdown, prioritize
                      // the value being set (machineCenter.machineCenterTypeId) over the value being returned (machineCenter.machineCenterType.id).
                      machineCenter.machineCenterTypeId || // Value being set...
                      (machineCenter.machineCenterType ? machineCenter.machineCenterType.id : '') // Persisted value...
                    }
                    onChange={(e) => {handleUpdate({machineCenterTypeId: e.target.value})}}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (val) => {
                        if (!val){
                          return <i>{translations.common.notAssigned}</i>
                        }
                        else {
                          return filteredMachineCenterTypes.find(x => x.id === val)?.name;
                        }
                      }
                    }}>
                      {filteredMachineCenterTypes.map((type) =>
                        <MenuItem
                          key={type.id}
                          value={type.id}>
                          {type.name}
                        </MenuItem>
                      )}
                  </TextField>

                  {/* Machine Center Name */}
                  <TextField
                    value={machineCenter.name || ''}
                    onChange={(e) => {handleUpdate({name: e.target.value})}}
                    required
                    id="machineCenterName"
                    name="machineCenterName"
                    label={translations.pages.createAndEditMachineCenters.machineCenterName}
                    fullWidth
                    InputProps={{
                      readOnly: readOnly
                    }}
                    disabled={isLoading}
                  />

              </div>

          </CreateEditWrapper>
        }
      </div>);
};

export default CreateAndEditMachineCenter;
