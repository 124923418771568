import React from 'react';
import { makeStyles } from '@mui/styles';
import img from '../../../img/loading-light.gif';

const useStyles = makeStyles({
  container: {
    height: (props) => props.size
  },
  image: {
    height: '100%'
  }
})

const Loading = (props) => {

  const classes = useStyles(props);

  return (<div className={classes.container}>
    <img className={classes.image} src={img} alt=""/>
  </div>)
}

export default Loading;

