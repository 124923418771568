import { makeStyles } from '@mui/styles'
import React, { useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import ProtectedRoute from '../../shared/components/ProtectedRoute/ProtectedRoute'
import Routes from './Routes'

const useStyles = makeStyles({
  contentContainer: {
    flexGrow: '1',
    display: 'flex',
  },
})

const ContentContainer = () => {
  const classes = useStyles()
  const contentContainerRef = useRef()
  const history = useHistory()

  // Assemble all routes defined in Routes.js
  const routes = Routes.map((r) => {
    return (
      <ProtectedRoute
        exact
        {...r}
        key={r.path}
        className={classes.childRoute}
      />
    )
  })

  // Add fallback route, for when a path matches no other defined routes.
  // NOTE: If a user is logged in, they will fallback to /dashboard.
  const fallbackPath = "/"
  const fallbackRoute = <Route key={uuidv4()} render={() => <Redirect to={fallbackPath} />} />
  routes.push(fallbackRoute);

  useEffect(() => {
    const scrollToElement = history.location?.state?.scrollToElement
    if (contentContainerRef.current && scrollToElement) {
      const scrollCandidate = document.getElementById(scrollToElement)
      if (scrollCandidate) {
        scrollCandidate.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [history.location?.state?.scrollToElement])

  return (
    <div
      className={classes.contentContainer}
      ref={contentContainerRef}
      id="contentContainer"
    >
      <Switch>{routes}</Switch>
    </div>
  )
}

export default ContentContainer
