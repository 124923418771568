import queue from 'async/queue'

/**
 * Notes regarding the behavior of resources in this class:
 *
 * - There are lineIds. There is a cache. There is a queue.
 * - lineIds associate to entries in the cache and in the queue.
 * - If a lineId is represented in the cache, it is also represented in the queue, and vice versa
 *
 * For the ABOVE points, see trimImage().
 *
 * - If a cache entry HAS entry.data === null, it's queue entry HAS NOT been processed.
 * - If a cache entry DOES NOT HAVE entry.data === null, it's queue entry HAS been processed.
 *
 * Good luck.
 */

const QUEUE_CONCURRENCY = 1;
const CACHE_TTL = 3; // Minutes...

const cache = []

// Define queue function....
const trimImage = ({ imageUrl, lineId, cb }, done) => {
  cache[lineId] = {
    data: null,
    done,
    cb,
  }
  workerInstance.postMessage({
    imageUrl,
    lineId,
  })
}

const getQueueInstance = () => {
  return queue(trimImage, QUEUE_CONCURRENCY)
}

// Initialize queue...
let q = getQueueInstance()

const isLinemapProcessedByWorker = (cacheEntry) => {
  return cacheEntry.data !== null;
}

export const clearQueue = () => {

  /**
   * kill() removes the drain callback and empties remaining tasks from the queue forcing it to go idle.
   * No more tasks should be pushed to the queue after calling this function. Invoke with queue.kill().
   */
  q.kill();

  // "No more tasks should be pushed to the queue after calling this function", therefore, reinitialize....
  q = getQueueInstance();

}

const workerInstance = new Worker(
  new URL('./LinemapTrimmer.js', import.meta.url)
)

workerInstance.onmessage = ({ data }) => {
  const { lineId } = data

  if (!!cache[lineId]) {
    cache[lineId].data = data
    cache[lineId].done() // Tell queue we're done, freeing up concurrency slot
    cache[lineId].cb(data)
  }
}

export const addToQueue = (imageUrl, lineId, cb) => {

  // If there's an image to process, and there's no cache / queue entry, add entry to cache / queue...
  if (imageUrl !== undefined && !cache[lineId]) {
    q.push({ imageUrl, lineId, cb })
    return;
  }

  if (isLinemapProcessedByWorker(cache[lineId])) { // If cached / queued item HAS BEEN PROCESSED by worker...

    let currentMinutes = new Date().getMinutes()
    let cacheDateMinutes = cache[lineId]?.data?.dateTime.getMinutes()

    if (currentMinutes <= cacheDateMinutes + CACHE_TTL) { // If in cache / queue, and not expired...
      cb(cache[lineId].data) // callback with cached data...
    } else { // If in cache / queue, and expired...
      delete cache[lineId] // Delete from cache...
      q.push({ imageUrl, lineId, cb }) // Upsert to cache / queue
    }

  } else { // If cached / queued item HAS NOT BEEN PROCESSED by worker...
    q.push({ imageUrl, lineId, cb }) // Upsert to cache / queue
  }
}
