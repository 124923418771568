// As defined in SmartSkinTech Figma StyleGuide.
// Ordered by lightest -> darkest.

// White
export const
  GREY_0 = '#F5F5F5',
  GREY_1 = '#EAEAEA',
  GREY_2 = '#CECECE',
  GREY_3 = '#7E7E7E',
  GREY_4 = '#615E5E',
  GREY_5 = '#333333',
  GREY_6 = '#272727',
  GREY_7 = '#101010'
// Black
