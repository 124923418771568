const meterToInches = 39.36;
const inchesToMils = 1000;
const gToMeterPerSecond = 9.81;
const meterToMicrons = 1000000;
const meterToMillimeter = 1000;
const CYCLE_PER_MINUTE = "cpm";
const HERTZ = "hz";
const SECONDS = "s";
const MILS = "mils";
const MICRONS = "microns";
const G = "g";
const INCHES_PER_SECOND = "ips";
const MILLIMETER_PER_SECOND = "mm/s";

const GRAVITY = "ACCELERATION";
const VELOCITY = "VELOCITY";
const DISPLACEMENT = "DISPLACEMENT";

const IMPERIAL = "Imperial";
const METRIC = "Metric";

const convertXData = (dataToConvert, originalData, xUnit, newDataSetter) => {
    let convertedData = [];
    if (xUnit === CYCLE_PER_MINUTE) {
        convertedData = convertHzToCpm(dataToConvert, originalData);
    } else if (xUnit === HERTZ) {
        convertedData = returnHz(dataToConvert, originalData);
    } else {
        convertedData = originalData;
    }
    newDataSetter(convertedData);
    return convertedData;
};
const convertHzToCpm = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;
    let y = 0;
    for (let index = 0; index < data.length; index++) {
        x = originalData[index].x * 60;
        x = isNaN(x) ? 0 : Number(x.toFixed(2));
        y = data[index].y;
        y = isNaN(y) ? 0 : y;
        convertedData[index] = {x: x, y: y};
    }
    return convertedData;
};
const returnHz = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;
    let y = 0;
    for (let index = 0; index < data.length; index++) {
        x = originalData[index].x;
        x = isNaN(x) ? 0 : Number(x.toFixed(2));
        y = data[index].y;
        y = isNaN(y) ? 0 : y;
        convertedData[index] = {x: x, y: y};
    }
    return convertedData;
};

const convertYData = (dataToConvert, originalData, yUnit, newDataSetter) => {
    let convertedData = [];
    if (yUnit === INCHES_PER_SECOND) {
        convertedData = convertGToIPS(dataToConvert, originalData);
    } else if (yUnit === MILLIMETER_PER_SECOND) {
        convertedData = convertGToMMPS(dataToConvert, originalData);
    } else if (yUnit === G) {
        convertedData = returnG(dataToConvert, originalData);
    } else if (yUnit === MILS) {
        convertedData = convertGToMils(dataToConvert, originalData);
    } else if (yUnit === MICRONS) {
        convertedData = convertGToMicrons(dataToConvert, originalData);
    } else {
        convertedData = originalData;
    }
    newDataSetter(convertedData);
    return convertedData;
};
const convertGToIPS = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;
    let y = 0;

    for (let index = 0; index < data.length; index++) {
        if (index === 0 || index === 1 || index === 2) {
            x = 0;
            y = 0;
        } else {
            x = data[index].x;
            x = isNaN(x) ? 0.0000000001 : x;
            y = getVelocity(originalData[index].y, originalData[index].x) * meterToInches;
            y = isNaN(y) ? 0 : y;
        }
        convertedData[index] = {x: x, y: y};
    }
    return convertedData;
};
const convertGToMMPS = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;
    let y = 0;

    for (let index = 0; index < data.length; index++) {
        if (index === 0 || index === 1 || index === 2) {
            x = 0;
            y = 0;
        } else {
            x = data[index].x;
            x = isNaN(x) ? 0.0000000001 : x;
            y = getVelocity(originalData[index].y, originalData[index].x) * meterToMillimeter;
            y = isNaN(y) ? 0 : y;
        }
        convertedData[index] = {x: x, y: y};
    }
    return convertedData;
};
const returnG = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;

    for (let index = 0; index < data.length; index++) {
        x = data[index].x;
        x = isNaN(x) ? 0 : x;
        convertedData[index] = {x: x, y: originalData[index].y};
    }
    return convertedData;
};
const convertGToMils = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;
    let y = 0;

    for (let index = 0; index < data.length; index++) {
        if (index === 0 || index === 1 || index === 2) {
            x = 0;
            y = 0;
        } else {
            x = data[index].x;
            x = isNaN(x) ? 0 : x;
            y = getDisplacement(originalData[index].y, (originalData[index].x === 0) ? 0.0000000001 : originalData[index].x) * meterToInches * inchesToMils;
            y = isNaN(y) ? 0 : y;
        }
        convertedData[index] = {x: x, y: y};
    }
    return convertedData;
};
const convertGToMicrons = (data, originalData) => {
    const convertedData = new Array(data.length);
    let x = 0;
    let y = 0;

    for (let index = 0; index < data.length; index++) {
        if (index === 0 || index === 1 || index === 2) {
            x = 0;
            y = 0;
        } else {
            x = data[index].x;
            x = isNaN(x) ? 0 : x;
            y = getDisplacement(originalData[index].y, (originalData[index].x === 0) ? 0.0000000001 : originalData[index].x) * meterToMicrons;
            y = isNaN(y) ? 0 : y;
        }
        convertedData[index] = {x: x, y: y};
    }
    return convertedData;
};

const convertXTicks = (data, xUnit) => {
    if (xUnit === CYCLE_PER_MINUTE) {
        return convertTicksHzToCpm(data);
    } else if (xUnit === HERTZ) {
        return convertTicksCpmToHz(data);
    }
    return [];
};
const convertTicksHzToCpm = (data) => {
    const convertedData = new Array(data.length);
    let value = 0;
    for (let index = 0; index < data.length; index++) {
        value = data[index] * 60;
        value = isNaN(value) ? 0 : value;
        convertedData[index] = value;
    }
    return convertedData;
};
const convertTicksCpmToHz = (data) => {
    const convertedData = new Array(data.length);
    let value = 0;
    for (let index = 0; index < data.length; index++) {
        value = data[index];
        value = isNaN(value) ? 0 : value;
        convertedData[index] = value;
    }
    return convertedData;
};

const getMaxY = (data) => {
    let maxY = 0;
    for (let index = 0; index < data.length; index++) {
        if (data[index].y > maxY) {
            maxY = data[index].y;
        }
    }
    maxY = (maxY === 0) ? 0.001 : maxY;
    return maxY;
};
const getMaxX = (data) => {
    let maxX = 0;
    for (let index = 0; index < data.length; index++) {
        if (data[index].x > maxX) {
            maxX = data[index].x;
        }
    }
    return maxX;
};
const getVelocity = (acc, freq) => {
    return ((acc * gToMeterPerSecond) / (2 * Math.PI * freq));
};
const getDisplacement = (acc, freq) => {
    return ((acc * gToMeterPerSecond) / (4 * Math.PI * Math.PI * freq * freq))
};


const generateTimeWaveformGraphData = (vibrationReading, setXAxisChartData, setYAxisChartData, setZAxisChartData, setDateRecorded) => {
    if (vibrationReading.data !== undefined) {

        let data = atob(vibrationReading.data);
        let dataAsJson = JSON.parse(data);

        let x = 1 / vibrationReading.sampleRate;
        let curX = 0;

        let xAxis = [];
        let yAxis = [];
        let zAxis = [];
        for (let i = 0; i < vibrationReading.numberOfSamples; i++) {
            curX = parseFloat((x * [i]).toPrecision(5));

            xAxis.push({
                x: curX,
                y: dataAsJson.xAxis[i]
            })
            yAxis.push({
                x: curX,
                y: dataAsJson.yAxis[i]
            })
            zAxis.push({
                x: curX,
                y: dataAsJson.zAxis[i]
            })
        }
        setXAxisChartData(xAxis);
        setYAxisChartData(yAxis);
        setZAxisChartData(zAxis);

        if (vibrationReading.dateRecorded !== undefined) {
            setDateRecorded(vibrationReading.dateRecorded);
        }
    }
};
const generateFFTGraphData = (vibrationReading, setXAxisChartData, setYAxisChartData, setZAxisChartData, setDateRecorded) => {
    if (vibrationReading.data !== undefined) {

        let data = atob(vibrationReading.data);
        let dataAsJson = JSON.parse(data);
        setXAxisChartData(dataAsJson.xAxis);
        setYAxisChartData(dataAsJson.yAxis);
        setZAxisChartData(dataAsJson.zAxis);

        if (vibrationReading.dateRecorded !== undefined) {
            setDateRecorded(vibrationReading.dateRecorded);
        }
    }
};

export {
    convertXData,
    convertYData,
    convertXTicks,
    CYCLE_PER_MINUTE,
    HERTZ,
    SECONDS,
    MILS,
    MICRONS,
    G,
    INCHES_PER_SECOND,
    MILLIMETER_PER_SECOND,
    GRAVITY,
    VELOCITY,
    DISPLACEMENT,
    IMPERIAL,
    METRIC,
    inchesToMils,
    meterToInches,
    meterToMicrons,
    meterToMillimeter,
    generateTimeWaveformGraphData,
    generateFFTGraphData,
    getMaxY,
    getMaxX,
    getVelocity,
    getDisplacement
}
