import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import {
  getCustomerLinesQuery,
  getRunPressureData,
} from '../../../query/queries'
import { addToQueue } from '../../../utils/LinemapFetcher'
import { DISPLAY_TYPE_ACCORDION } from '../../Utilities'
import NoAreaLineRunChart from '../NoAreaLineRunChart/NoAreaLineRunChart'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: (props) =>
      props.viewType === DISPLAY_TYPE_ACCORDION ? '560px' : '620px',
    minWidth: (props) =>
      props.viewType === DISPLAY_TYPE_ACCORDION ? '500px' : '620px',
    height: (props) =>
      props.viewType === DISPLAY_TYPE_ACCORDION ? '420px' : '480px',
    flexGrow: 1,
    borderRadius: '2px',
    border: '1px solid #000',
  },
  header: {
    padding: '16px 20px 8px 16px',
    backgroundColor: '#C4C4C4',
  },
  line: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  plant: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  image: {
    flexGrow: '1',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#F4F4F4',
    backgroundImage: (props) => showBackgroundImage(props),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graphContainer: {
    flexGrow: '1',
    width: '100%',
    height: '100%',
  },
})

function showBackgroundImage(props) {
  let lineImage = props.lineSummaryImage
  if (!props.isLoading) {
    if ((lineImage?.success && lineImage?.image) || !props.isLoadingRunData) {
      return `url(${lineImage?.image})`
    }
  }
  return 'none'
}

const LinemapCard = (props) => {
  const { plant, line, viewType } = props

  const [lineSummaryImage, setLineSummaryImage] = useState({
    loading: true,
    success: false,
    image: null,
    queueSubscription: false,
  })
  const [loadRunData, setLoadRunData] = useState(false)
  const [loadNoLineRunMap, setLoadNoLineRunMap] = useState(false)
  const linemapMountedRef = useRef(false)

  const { isLoading: isLoadingRunData, data: runData = {} } = useQuery(
    [
      'customers',
      { customerId: plant.customerId },
      { lineId: line.id },
      { sortBy: 'DateRecorded' },
      { sortOrder: 'DESC' },
      { pageSize: 1 },
      { page: 0 },
      { filter: 'ExtractionDate ne null' },
    ],
    getCustomerLinesQuery,
    { enabled: loadRunData }
  )
  const runId =
    runData?.data?.content && runData?.data?.content.length
      ? runData.data.content[0].id
      : ''
  const { isLoading: isLoadingPressureData, data: pressureData = {} } =
    useQuery(['runs', { runId: runId }], getRunPressureData, {
      enabled: !!runId,
    })

  const isLoading =
    isLoadingRunData || isLoadingPressureData || lineSummaryImage?.loading

  const queueCallbackFunction = useCallback(
    (lineImage) => {
      if (linemapMountedRef.current) {
        setLineSummaryImage(lineImage)
      }
    },
    [setLineSummaryImage]
  )

  useEffect(() => {
    linemapMountedRef.current = true

    if (lineSummaryImage?.loading && !lineSummaryImage?.success) {
      addToQueue(line.linemapImageUrl, line.id, queueCallbackFunction)
    }

    if (lineSummaryImage?.error) {
      setLoadRunData(true)
    }

    if (loadRunData && pressureData.data) {
      setLoadNoLineRunMap(true)
    }

    return () => {
      // Cleanup function to avoid state update on unmounted component.
      linemapMountedRef.current = false
    }
  }, [
    line,
    lineSummaryImage,
    loadRunData,
    isLoadingRunData,
    pressureData.data,
    queueCallbackFunction,
  ])

  const classes = useStyles({
    loadNoLineRunMap,
    lineSummaryImage,
    isLoading,
    runData,
    viewType,
  })

  return (
    <div className={classes.root} id={line.id}>
      <div className={classes.header}>
        <Typography className={classes.line}>{line.name}</Typography>
        <Typography className={classes.plant}>{plant.name}</Typography>
      </div>
      <Link
        className={!loadNoLineRunMap ? classes.image : classes.graphContainer}
        to={{
          pathname: `/customer-line-details/${line.id}`,
          search: `?runId=${runId}`,
          state: { scrollToElementId: line.id },
        }}
        style={{ textDecoration: 'none' }}
      >
        {isLoading && <CircularProgress size={23} />}
        {!isLoading && loadNoLineRunMap && (
          <NoAreaLineRunChart
            data={pressureData}
            dateRecorded={runData.data.content[0].dateRecorded}
          />
        )}
      </Link>
    </div>
  )
}

export default LinemapCard
