import React from 'react'
import { makeStyles } from '@mui/styles'
import { SvgIcon } from '@mui/material'
import QuantifeelTooltip from '../QuantifeelTooltip'

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.5,
    cursor: 'unset !important'
  }
}));

const QuantifeelSvgIcon = (props) => {

  const {
    disabled,
    className,
    tooltipTitle = "", // The prop `title` is marked as required in `ForwardRef(Tooltip)`, therefore default to empty string...
    onClick,
    ...rest } = props;

  const classes = useStyles();

  return (
    <QuantifeelTooltip
      title={tooltipTitle}
      disableHoverListener={ !tooltipTitle } // Disable hover listener, if no tooltipTitle, rather than show empty... */}
    >
      <SvgIcon
        className={[disabled ? classes.disabled : undefined, className].join(' ')} // Apply disabled styles if disabled...
        onClick={disabled ? undefined : onClick} // Prevent onClick, if disabled...
        {...rest}
      />
    </QuantifeelTooltip>
  );
};

export default QuantifeelSvgIcon;