import React from 'react'
import { makeStyles } from '@mui/styles'
import { SvgIcon, Typography } from '@mui/material'
import * as GreyScale from '@Theme/GreyScale.js'
import {ReactComponent as LockIcon} from '../../img/icons/lock.svg'

const ReadOnlyNotification = (props) => {

  const {
    message,
  } = props;

  const useStyles = makeStyles(theme => ({
    root:{
      width: '100%',
      height: 60,
      display: 'flex',
      alignItems: 'center',

      border: `1px solid ${GreyScale.GREY_3}`,
      borderRadius: 5,
      backgroundColor: GreyScale.GREY_1,
    },
    icon: {
      margin: `0 ${theme.spacing(1)}`
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon
        component={LockIcon}
        viewBox={'0 0 32 32'}
        className={classes.icon}
      />
      <Typography variant="subtitle2">{message}</Typography>
    </div>
  )
}

export default ReadOnlyNotification