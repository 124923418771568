import {axios} from '../client';

import {
    SST_API_PATH_SENSORS,
    SST_API_PATH_CUSTOMERS
} from './../../Constants';

export const getSensors = ({queryKey}) => {
  const [,,queryParams] = queryKey;
  return axios.get(`${SST_API_PATH_SENSORS}`, {params: queryParams}).then(res => res.data);
}

export const getSensorsByCustomer = ({queryKey}) => {
  const [,{customerId},queryParams] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/${SST_API_PATH_SENSORS}`, {params: queryParams}).then(res => res.data);
}

export const getSensor = ({queryKey}) => {
  const [,{sensorId}] = queryKey;
  return axios.get(`${SST_API_PATH_SENSORS}/${sensorId}`).then(res => res.data);
}

export const updateSensor = (sensor) => {
  return axios.put(`${SST_API_PATH_SENSORS}/${sensor.id}`, sensor).then(res => res.data);  
}

export const createSensor = (sensor) => { 
  delete sensor.id; 
  return axios.post(`${SST_API_PATH_SENSORS}`, sensor).then(res => res.data);  
}
