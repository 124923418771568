import {axios} from '../client';
import {
  SST_API_PATH_ERROR_REPORTS,
  SST_API_PATH_SOFTWARE,
  SST_API_PATH_RELEASES,
} from '../../Constants'

export const getErrorReports = () => {
  return axios.get(`${SST_API_PATH_ERROR_REPORTS}`).then(res => res.data);  
}

export const getErrorReport = ({queryKey}) => {
  const [, {errorReportId}] = queryKey; 
  return axios.get(`${SST_API_PATH_ERROR_REPORTS}/${errorReportId}`).then(res => res.data);
}

export const downloadErrorReport = (errorReportId) => {
  return axios.get(`${SST_API_PATH_ERROR_REPORTS}/${errorReportId}/download`).then(res => res.data);
}

export const getQuantifeelSoftware = (softwareId) => {
  return axios.get(`${SST_API_PATH_SOFTWARE}/${softwareId}`).then(res => res.data);
}

export const getQuantifeelSoftwareRelease = (softwareId, softwareReleaseId) => {
  return axios.get(`${SST_API_PATH_SOFTWARE}/${softwareId}/${SST_API_PATH_RELEASES}/${softwareReleaseId}`).then(res => res.data);
}