import {
  CircularProgress,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import {
  getDrone,
  getDroneConfigurationSensorCapabilities,
  getLine,
  getLineAreas,
  getLineRuns,
  getRun,
  getRunFlags,
  getRunPressureData,
  getRunPressureMapDataRadius,
  getRunShockData,
  getRunTiltData,
  getRunTopLoadData,
  getRunSpinData,
  getRunRotationData
} from '@Queries'
import noDataFound from '@Shared/404.png'
import CopyLabel from '@Shared/components/CopyLabel/CopyLabel'
import FlagTable from '@Shared/components/Flag/FlagTable'
import HeatmapChart from '@Shared/components/HeatmapChart/HeatmapChart'
import LineRunChart from '@Shared/components/LineRunChart/LineRunChart'
import NoAreaLineRunChart from '@Shared/components/NoAreaLineRunChart/NoAreaLineRunChart'
import ProtectedMoment from '@Shared/components/ProtectedMoment/ProtectedMoment'
import {
  DATE_TIME_TIMEZONE_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '@Shared/Utilities'
import jimp from 'jimp'
import qs from 'query-string'
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { SiteContext } from '~/Context'
import { ReactComponent as BackIcon } from '~/img/icons/Back button.svg'
import useStyles from './Styles'
import {
  enrichPressureMapWithAdditionalData,
  enrichTimeSeriesDataModelWithAdditionalData,
  getAdjacentTimeNotInAdditionalTimes,
  getHeatmapChartMessage, getNearestTime,
  getPressureMapDataForTime, getPressureMapMaxTimeKey, getPressureMapMinTimeKey,
  NEXT,
  PREVIOUS,
  RADIUS_IN_SECONDS,
} from './Helpers'
import {ReactComponent as DoubleArrowLeftBlueIcon} from '../../img/icons/double_arrow_left_blue.svg'
import {ReactComponent as DoubleArrowRightBlueIcon} from '../../img/icons/double_arrow_right_blue.svg'
import translations from '../../translations/en.json';
import WidgetContainer from '../../shared/components/WidgetContainer/WidgetContainer'
import { truncateNumberToDecimals } from '../../shared/Utilities'
import {filterAreas} from "../../shared/AreaUtilities";

const LoadingCard = () => {
  const classes = useStyles()
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress size={40} />
    </div>
  )
}

const pageTitle = 'Customer Line Details'
const acceptablePagePermission = [
  { entity: 'Run', method: PERMISSION_METHOD_GET, modifier: '' },
]

const stateDefaults = {
  selectedTime: null,
  pressureMapTimeKey: null,
  enrichedData: null,
  isDataEnriched: false,
}

const CustomerLineDetails = () => {

  const lineId = useRouteMatch().params?.lineId
  const location = useLocation()
  const history = useHistory()

  const urlParams = qs.parse(location.search)
  const { setBreadcrumbs, userDetails, hasPermission } = useContext(SiteContext)
  const [selectedRun, setSelectedRun] = useState('')
  const [runFilter, setRunFilter] = useState('')
  const [virtualIdLength, setVirtualIdLength] = useState('')
  const [linemapImage, setLineMapImage] = useState('')
  const [renderNoAreaLinemap, setRenderNoAreaLinemap] = useState(false)
  const [scrollToElementId, setScrollToElementId] = useState(null)
  const linemapImageContainer = useRef(null)
  const [selectedFlag, setSelectedFlag] = useState(undefined)
  const [selectedTime, setSelectedTime] = useState(stateDefaults.selectedTime)
  const [pressureMapMinTimeKey, setPressureMapMinTimeKey] = useState(stateDefaults.pressureMapTimeKey);
  const [pressureMapMaxTimeKey, setPressureMapMaxTimeKey] = useState(stateDefaults.pressureMapTimeKey);
  const [enrichedPressureMapData, setEnrichedPressureMapData] = useState(stateDefaults.enrichedData)
  const [numPressureMapDataRequestsInFlight, setNumPressureMapDataRequestsInFlight] = useState(0)
  const [isLoadingPressureMapData, setIsLoadingPressureMapData] = useState(false)
  const [resetLineRunChartZoomState, setResetLineRunChartZoomState] = useState(false);
  const [resetLineRunChartState, setResetLineRunChartState] = useState(false);

  const [isPressureDataEnriched, setIsPressureDataEnriched] = useState(stateDefaults.isDataEnriched);
  const [isTopLoadDataEnriched, setIsTopLoadDataEnriched] = useState(stateDefaults.isDataEnriched);
  const [isShockDataEnriched, setIsShockDataEnriched] = useState(stateDefaults.isDataEnriched);
  const [isTiltDataEnriched, setIsTiltDataEnriched] = useState(stateDefaults.isDataEnriched);
  const [isSpinDataEnriched, setIsSpinDataEnriched] = useState(stateDefaults.isDataEnriched);

  const [contentGridHeight, setContentGridHeight] = useState(0)
  const contentGridRef = useRef(null)

  // --------------------
  // -- BEGIN useQuery --
  // --------------------

  const { isLoading: isLoadingLine, data: line = {} } = useQuery(
    ['line', { lineId: lineId }, { includePlantName: true }],
    getLine
  )
  const { isLoading: isLoadingRuns, data: runs = [] } = useQuery(
    ['runs', { lineId: lineId }],
    getLineRuns
  )
  const { isLoading: isLoadingAreas, data: areas = [] } = useQuery(
    ['areas', { lineId: lineId }],
    getLineAreas
  )
  const { isLoading: isLoadingRunDetails, data: runDetails = {} } = useQuery(
    ['run', { runId: selectedRun }],
    getRun,
    { enabled: !!selectedRun }
  )
  const { isLoading: isLoadingDroneConfiguationSensorCapabilities, data: droneConfigurationSensorCapabilities = {} } = useQuery(
    ['droneConfigurationSensorCapabilities', { runId: selectedRun }],
    getDroneConfigurationSensorCapabilities,
    { enabled: !!selectedRun && !isLoadingRuns }
  )
  const { isLoading: isLoadingRotationData, data: rotationData } = useQuery(
    ['rotationData', { runId: selectedRun }],
    getRunRotationData,
    { enabled: !!selectedRun && !isLoadingRuns }
  )
  const { isLoading: isLoadingPressureData, data: pressureData } = useQuery(
    ['pressureData', { runId: selectedRun }],
    getRunPressureData,
    {
      enabled: !!selectedRun && !isLoadingRuns && !isLoadingDroneConfiguationSensorCapabilities && droneConfigurationSensorCapabilities.supportsPressure,
      cacheTime: 0 // Disable caching, since the component mutates pressureData, and we want to ensure we always fetch the raw data...
    }
  )
  const { isLoading: isLoadingShockData, data: shockData } = useQuery(
    ['shockData', { runId: selectedRun }],
    getRunShockData,
    {
      enabled: !!selectedRun && !isLoadingRuns,
      cacheTime: 0 // Disable caching, since the component mutates shockData, and we want to ensure we always fetch the raw data...
    }
  )
  const { isLoading: isLoadingTiltData, data: tiltData } = useQuery(
    ['tiltData', { runId: selectedRun }],
    getRunTiltData,
    {
      enabled: !!selectedRun && !isLoadingRuns,
      cacheTime: 0 // Disable caching, since the component mutates tiltData, and we want to ensure we always fetch the raw data...
    }
  )
  const { isLoading: isLoadingTopLoadData, data: topLoadData } = useQuery(
    ['topLoadData', { runId: selectedRun }],
    getRunTopLoadData,
    {
      enabled: !!selectedRun && !isLoadingRuns && !isLoadingDroneConfiguationSensorCapabilities && droneConfigurationSensorCapabilities.supportsTopLoad,
      cacheTime: 0 // Disable caching, since the component mutates topLoadData, and we want to ensure we always fetch the raw data...
    }
  )
  const { isLoading: isLoadingSpinData, data: spinData } = useQuery(
    ['spinData', { runId: selectedRun }],
    getRunSpinData,
    {
      enabled: !!selectedRun && !isLoadingRuns,
      cacheTime: 0 // Disable caching, since the component mutates spinData, and we want to ensure we always fetch the raw data...
    }
  )
  const { isLoading: isLoadingRunTags, data: runFlags = [] } = useQuery(
    ['flags', { runId: selectedRun }],
    getRunFlags,
    { enabled: !!selectedRun && !isLoadingRuns }
  )
  const {
    isError: droneError,
    isLoading: isLoadingDrone,
    data: drone,
  } = useQuery(['drone', { droneId: runDetails.droneId }], getDrone, {
    enabled: !!runDetails.droneId && hasPermission('Drone', 'get'),
  })

  // ------------------
  // -- END useQuery --
  // ------------------

  // ------------------------------------------
  // -- BEGIN useMemo, for state derivatives --
  // ------------------------------------------

  /**
   * Given changes to pressureData, topLoadData, shockData, tiltData, spinData,
   * isPressureDataEnriched, isTopLoadDataEnriched, isShockDataEnriched, isTiltDataEnriched, isSpinDataEnriched,
   * Derive isNotNullOrUndefinedDataEnriched...
   */
  const isNotNullOrUndefinedDataEnriched = useMemo(() => {

    return (
      ( !pressureData ? true : isPressureDataEnriched ) && // pressure
      ( !topLoadData ? true : isTopLoadDataEnriched ) && // topLoad
      ( !shockData ? true : isShockDataEnriched ) && // shock
      ( !tiltData ? true : isTiltDataEnriched ) && // tilt
      ( !spinData ? true : isSpinDataEnriched ) // spin
    )

  }, [pressureData, topLoadData, shockData, tiltData, spinData, isPressureDataEnriched, isTopLoadDataEnriched, isShockDataEnriched, isTiltDataEnriched, isSpinDataEnriched])

  /**
   * Given changes to pressureData, topLoadData, shockData, tiltData, spinData
   * Derive timeData from pressureData, topLoadData, shockData, tiltData or spinData...
   */
  const timeData = useMemo(() => {
    return (
      rotationData?.times || // rotationData has an extended timeline, unlike other data streams, and must take precedence when deriving time...
      pressureData?.data[0] ||
      topLoadData?.data[0] ||
      shockData?.data[0] ||
      tiltData?.data[0] ||
      spinData?.data[0]
    )
  }, [pressureData, topLoadData, shockData, tiltData, spinData, rotationData])

  /**
   * Given changes to timeData,
   * Derive timeDataMin, timeDataMax, from timeData...
   */
  const [timeDataMin, timeDataMax] = useMemo(() => {

    const timeDataMin = timeData?.[0];
    const timeDataMax = timeData?.[timeData?.length - 1];

    return [
      timeDataMin,
      timeDataMax
    ];

  }, [timeData]);

  // ----------------------------------------
  // -- END useMemo, for state derivatives --
  // ----------------------------------------

  const isLoadingChart =
    isLoadingPressureData ||
    isLoadingShockData ||
    isLoadingTiltData ||
    isLoadingTopLoadData ||
    isLoadingSpinData ||
    isLoadingRotationData ||
    !isNotNullOrUndefinedDataEnriched;

  const isLoading =
    isLoadingAreas || isLoadingRuns || isLoadingLine || isLoadingRunDetails || isLoadingDroneConfiguationSensorCapabilities || isLoadingChart

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount,
   * Determine the height of the contentGrid, and set it as state, so it can be passed to styles...
   */
  useEffect(() => {
    if (contentGridRef.current) {

      const computedStyle = getComputedStyle(contentGridRef.current);
      const contentGridGapValue = parseInt(computedStyle.getPropertyValue('grid-gap'));
      const contentGridHeightWithGapValue = contentGridRef.current.clientHeight;

      // Calculate the total height, including gaps...
      const contentGridHeightWithoutGaps = contentGridHeightWithGapValue - contentGridGapValue;
      setContentGridHeight(contentGridHeightWithoutGaps);
    }
  }, [])

  useEffect(() => {
    const scrollToElementId = location?.state?.scrollToElementId
    if (scrollToElementId) {
      setScrollToElementId(scrollToElementId)
    }
  }, [location, location?.state?.scrollToElementId, setScrollToElementId])

  useEffect(() => {
    if (runs.length > 0) {
      if (urlParams.runId && runs.find((x) => x.id === urlParams.runId)) {
        setSelectedRun(urlParams.runId)
      } else {
        history.replace({
          pathname: `/customer-line-details/${lineId}`,
          search: `runId=${runs.sort(sortRuns).find((x) => x.isExtracted)?.id}`,
          state: location.state,
        })
      }
      setVirtualIdLength((runs.length + 1).toString().length * 11 + 'px')
    } else if (!isLoading) {
      setSelectedRun('')
      setLineMapImage(noDataFound)
    }
  }, [runs, urlParams.runId, lineId, isLoading, history, location.state])

  useEffect(() => {
    document.title = 'Line Details'
    setBreadcrumbs([{ title: 'Line Details' }])
  }, [setBreadcrumbs, history])

  useEffect(() => {
    const imageUrl =
      runDetails.runType === 0
        ? runDetails.linemapImage
        : runDetails.linemapImageSeamer

    // Don't bother with s3 query if areaId is null
    if (imageUrl && runDetails.areaId !== null) {
      jimp
        .read({
          url: imageUrl,
        })
        .then((image) => {
          setRenderNoAreaLinemap(false)
          image.autocrop({
            leaveBorder: 40,
            cropOnlyFrames: false,
          })
          image.getBase64Async(jimp.MIME_PNG).then((base64) => {
            setLineMapImage(base64)
          })
        })
        .catch((e) => {
          setLineMapImage('')
          // Can remove this check if we decide to support other datatypes.
          //if (chartFields.includes('maxPressure')) {
          setRenderNoAreaLinemap(true)
          //}
        })
    }
  }, [runDetails])

  const additionalTimesFromRotationData = useMemo(() => {
    return rotationData?.additionalTimes;
  }, [rotationData])

  /**
   * Given changes to Run data streams, additionalTimesFromRotationData from rotation data API response,
   * enrich all available Run data streams to include the additional times and derived values, while
   * maintaining sorted order...
   *
   * Ex: Where [times][values]...
   * rotationData.additionalTimes: [1.1, 1.2, 1.3]
   * pressureData: [0, 1, 2, 3] [e, f, g, h], where [times][values]
   * updatedPressureData: [0, 1, 1.1, 1.2, 1.3, 2, 3] [e, f, derivedVal, derivedVal, derivedVal, g, h]
   */
  useEffect(() => {

    // With this condition, we're assuming that rotation data exists for every Run in the cloud, which is the case on
    // Jan '24. If we encounter a run that does not have rotation data, we will need to update this condition,
    // otherwise, the page will not load...
    if (!additionalTimesFromRotationData) {
      return;
    }

    // pressure...
    if (!isPressureDataEnriched && pressureData) {
      enrichTimeSeriesDataModelWithAdditionalData(pressureData, additionalTimesFromRotationData) // Mutate pressureData...
      setIsPressureDataEnriched(true);
    }

    // topLoad...
    if (!isTopLoadDataEnriched && topLoadData) {
      enrichTimeSeriesDataModelWithAdditionalData(topLoadData, additionalTimesFromRotationData) // Mutate topLoadData...
      setIsTopLoadDataEnriched(true);
    }

    // shock...
    if (!isShockDataEnriched && shockData) {
      enrichTimeSeriesDataModelWithAdditionalData(shockData, additionalTimesFromRotationData) // Mutate shockData...
      setIsShockDataEnriched(true);
    }

    // tilt...
    if (!isTiltDataEnriched && tiltData) {
      enrichTimeSeriesDataModelWithAdditionalData(tiltData, additionalTimesFromRotationData) // Mutate tiltData...
      setIsTiltDataEnriched(true);
    }

    // spin...
    if (!isSpinDataEnriched && spinData) {
      enrichTimeSeriesDataModelWithAdditionalData(spinData, additionalTimesFromRotationData) // Mutate spinData...
      setIsSpinDataEnriched(true);
    }

  }, [pressureData, topLoadData, shockData, tiltData, spinData, additionalTimesFromRotationData]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to selectedTime,
   * Determine if selectedTime is in enrichedPressureMapData. If not, fetch new pressureMapData...
   */
  useEffect(() => {

    // 0. Validate to ensure droneConfigurationSensorCapabilities.supportsPressure AND !droneConfigurationSensorCapabilities.supportsCustomLayout.
    // If not, no use trying to pull pressureMapData...
    if ( !droneConfigurationSensorCapabilities.supportsPressure || droneConfigurationSensorCapabilities.supportsCustomLayout ) {
      return;
    }

    // 1. Validate selectedTimeParam...
    if ( selectedTime === null ||
         selectedTime === undefined ) {
      return;
    }

    // 2. If pressureMapData is being fetched and selectedTime is between pressureMapMinTimeKey and pressureMapMaxTimeKey, return...
    // 2a. Derive booleans...
    const isSelectedTimeBetweenPressureMapMinMaxTimeKeys =
      selectedTime >= pressureMapMinTimeKey &&
      selectedTime <= pressureMapMaxTimeKey;

    // 2b. Check...
    if ( isLoadingPressureMapData && isSelectedTimeBetweenPressureMapMinMaxTimeKeys ) {
      return;
    }

    // 3. If pressureMapData is not fetched and selectedTime is NOT between pressureMapMinTimeKey and pressureMapMaxTimeKey,
    //    fetch new pressureMapData...
    // 3a. Derive booleans...
    const isPressureDataFetched = enrichedPressureMapData !== stateDefaults.pressureMapData;
    const isPressureMapDataFetchedAndSelectedTimeNotBetweenPressureMapMinMaxTimeKeys =
      isPressureDataFetched &&
      !isSelectedTimeBetweenPressureMapMinMaxTimeKeys;

    // 3b. Check...
    if ( !isPressureDataFetched || isPressureMapDataFetchedAndSelectedTimeNotBetweenPressureMapMinMaxTimeKeys) {

      // Get and set pressureMapMinTimeKey and pressureMapMaxTimeKey, which we can determine given
      // selectedTime, timeDataMin (0) and timeDataMax (Run.Duration), and RADIUS_IN_SECONDS...
      setPressureMapMinTimeKey(getPressureMapMinTimeKey(selectedTime, timeDataMin));
      setPressureMapMaxTimeKey(getPressureMapMaxTimeKey(selectedTime, timeDataMax));

      // Increment numPressureMapDataRequestsInFlight...
      setNumPressureMapDataRequestsInFlight( (previousState) => previousState + 1 );

      // GET enrichedPressureMapData...
      getRunPressureMapDataRadius(selectedRun, selectedTime, RADIUS_IN_SECONDS)
        .then((data) => {

          // Enrich pressureMapData with additional points...
          enrichPressureMapWithAdditionalData(data.pressureMap, additionalTimesFromRotationData);

          // Set...
          setEnrichedPressureMapData(data);
        })
        .finally(() => {
          // Decrement numPressureMapDataRequestsInFlight...
          setNumPressureMapDataRequestsInFlight( (previousState) => previousState - 1 );
        })

    }

  }, [selectedTime]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to numPressureMapDataRequestsInFlight,
   * Set isLoadingPressureMapData
   */
  useEffect(() => {

    if (numPressureMapDataRequestsInFlight === 0) {
      setIsLoadingPressureMapData(false);
    }

    if (numPressureMapDataRequestsInFlight > 0) {
      setIsLoadingPressureMapData(true);
    }

  }, [numPressureMapDataRequestsInFlight])

  /**
   * Given changes to selectedFlag,
   * Get selectedTime from selectedFlag...
   */
  useEffect(() => {

    // Validate params...
    if ( selectedFlag === null ||
         selectedFlag === undefined ||
         !timeData ) { // timeData is expected to always be available, but in case it is not, return, lest we pass null to getNearestTime...
      return;
    }

    setSelectedTime(getNearestTime(timeData, selectedFlag.time));

  }, [selectedFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------
  // -- END useEffects --
  // --------------------

  const handleRunChange = (e) => {
    history.push({
      pathname: `/customer-line-details/${lineId}`,
      search: `runId=${e.target.value}`,
    })
    setLineMapImage('')
    setResetLineRunChartState(true);
    setSelectedTime(stateDefaults.selectedTime);
    setPressureMapMinTimeKey(stateDefaults.pressureMapTimeKey);
    setPressureMapMaxTimeKey(stateDefaults.pressureMapTimeKey);

    // Enriched data state vars...
    setEnrichedPressureMapData(stateDefaults.enrichedData);
    setIsPressureDataEnriched(stateDefaults.isDataEnriched);
    setIsTopLoadDataEnriched(stateDefaults.isDataEnriched);
    setIsShockDataEnriched(stateDefaults.isDataEnriched);
    setIsTiltDataEnriched(stateDefaults.isDataEnriched);
    setIsSpinDataEnriched(stateDefaults.isDataEnriched);
  }

  const handleAreaChange = (e) => {
    setRunFilter(e.target.value)
  }

  const runDropdownLabel =
    runs.length > 0 ? 'Please Select a Run' : 'No Runs Available'
  const area =
    runDetails.areaId && areas.find((x) => x.id === runDetails.areaId)

  const filteredRuns = runs.filter((run) => {
    if (!run.isExtracted) {
      return false
    } else if (!runFilter) {
      return true
    } else if (runFilter === 'none') {
      return run.areaId === null || run.areaId === undefined
    }
    return run.areaId === runFilter
  })

  const sortRuns = (a, b) => {
    return b.virtualRunId - a.virtualRunId
  }

  const navigateTimeData = (directionToNavigate) => {

    // Get the first adjacentTime, not in rotationData.additionalTimes, in the directionToNavigate...
    const adjacentTime = getAdjacentTimeNotInAdditionalTimes({
      times: timeData,
      currentTime: selectedTime,
      directionToNavigate: directionToNavigate,
      additionalTimes: rotationData.additionalTimes
    });

    // Validate adjacentTime...
    if (adjacentTime === null || adjacentTime === undefined) {
      return; // If there is no valid adjacent time, stay on the current selectedTime...
    }

    setSelectedTime(adjacentTime);
  }

  const handleFlagTableRowClick = (flag) => {
    setSelectedFlag(flag)
    setResetLineRunChartZoomState(true);
  }

  const handleNavigateTimeDataPrevious = () => {
    navigateTimeData(PREVIOUS);
  }

  const handleNavigateTimeDataNext = () => {
    navigateTimeData(NEXT);
  }

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles(contentGridHeight);

  const renderRunDetailsRow = (label, value) => {
    return (
      <div className={classes.detailRow}>
        <Typography className={classes.detailRowCell}>{label}</Typography>
        <Typography className={classes.detailRowCell}>{value}</Typography>
      </div>
    )
  }

  const renderRunDetails = () => {

    return (
      <WidgetContainer
        header={translations.components.customerLineDetails.runDetails}
        isLoading={isLoadingRunDetails || isLoadingDrone || isLoadingRuns}>

          <div className={classes.details}>

            {renderRunDetailsRow(
              "Run Name",
               runDetails.name
            )}

            <div className={classes.detailRow}>
              <Typography className={classes.detailRowCell}>{"Virtual Run ID"}</Typography>
              <CopyLabel className={classes.detailRowCell} value={runDetails.id}>
                {runDetails.virtualRunId}
              </CopyLabel>
            </div>

            {renderRunDetailsRow(
              "Run Area",
              area?.name || 'No Area'
            )}

            {!droneError && !!drone && (
              renderRunDetailsRow(
                "Drone",
                <Link to={`/view-drone/${drone.id}`}>
                  {drone?.name}
                </Link>
              )
            )}

            {runDetails.cansPerMinute && (
              renderRunDetailsRow(
                "CPM (Estimate)",
                  Math.round(runDetails.cansPerMinute)
              )
            )}

            {renderRunDetailsRow(
              "Run Duration",
              <ProtectedMoment
                date={runDetails.dateRecorded}
                duration={runDetails.dateRecorded}
                add={{ seconds: runDetails.duration }}
              />
            )}

            {renderRunDetailsRow(
              "Date Recorded",
              <ProtectedMoment
                date={runDetails.dateRecorded}
                format={DATE_TIME_TIMEZONE_FORMAT}
                tz={userDetails.user.tz}
              />
            )}

            {renderRunDetailsRow(
              "Notes",
              runDetails.annotation || 'N/A'
            )}

          </div>

      </WidgetContainer>
    )
  }

  const renderLinemap = () => {

    const isRunAssociatedToArea = runDetails.areaId != null;
    const showLineMap = !renderNoAreaLinemap && isRunAssociatedToArea;
    const showNoAreaLineMap = (renderNoAreaLinemap || !isRunAssociatedToArea) && !!pressureData;

    // Linemap widget...
    if (!isLoading && showLineMap) {
      return (
        <WidgetContainer
          header={translations.components.customerLineDetails.lineMap}
          isLoading={!linemapImage}>
          <div
            ref={linemapImageContainer}
            className={classes.linemapImageContainer}
            style={{ backgroundImage: `url(${linemapImage})` }}
          />
        </WidgetContainer>
      )
    }

    // No area linemap / pressure timeline widget...
    if (!isLoading && showNoAreaLineMap ) {
      return (
        <WidgetContainer header={translations.components.customerLineDetails.pressureTimeline}>
          <div className={classes.linemapImageContainer}>
            <NoAreaLineRunChart
              data={pressureData}
              backgroundColor={'white'}
              showTitle={false}
            />
          </div>
        </WidgetContainer>
      )
    }

    // Empty widget...
    return (
      <WidgetContainer
        header={isRunAssociatedToArea ? translations.components.customerLineDetails.lineMap : translations.components.customerLineDetails.pressureTimeline}>
          <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography>{translations.pages.customerLineDetails.noPressureDataAvailable}</Typography>
          </div>
      </WidgetContainer>
    )
  }

  const renderHeatmap = () => {
    return (
      <WidgetContainer header={translations.components.heatmapChart.heatmap}>
        <HeatmapChart
          isLoading={isLoadingPressureMapData}
          message={getHeatmapChartMessage(droneConfigurationSensorCapabilities.supportsPressure, droneConfigurationSensorCapabilities.supportsCustomLayout, selectedTime)}
          data={getPressureMapDataForTime(enrichedPressureMapData, selectedTime)}
          mapWidth={enrichedPressureMapData?.layoutX}
          mapHeight={enrichedPressureMapData?.layoutY}
        />
      </WidgetContainer>
    )
  }

  const renderFlagTable = () => {
    return (
      <FlagTable
        isLoading={isLoadingRunTags}
        runFlags={runFlags}
        onRowClick={handleFlagTableRowClick}
        selectedFlag={selectedFlag}
      />
    )
  }

  const renderLineRunChartControls = () => {

    return (
      <div className={classes.lineRunChartControlsContainer}>

        {/* Previous Button */}
        <SvgIcon
          component={DoubleArrowLeftBlueIcon}
          viewBox={'0 0 32 32'}
          onClick={handleNavigateTimeDataPrevious}
          className={classes.arrowIcon}
        />

        <Typography className={classes.selectedTimeLabel}>
          { (selectedTime != null || selectedTime !== undefined) ?
            truncateNumberToDecimals(selectedTime, 3) :
            translations.components.customerLineDetails.selectTime }
        </Typography>

        {/* Next Button */}
        <SvgIcon
          component={DoubleArrowRightBlueIcon}
          viewBox={'0 0 32 32'}
          onClick={handleNavigateTimeDataNext}
          className={classes.arrowIcon}
        />

      </div>
    )
  }

  const renderLineRunChartHeader = () => {
    return (
      <div className={classes.lineRunChartHeaderContainer}>
        <Typography>{translations.components.customerLineDetails.runTimeline}</Typography>
        {renderLineRunChartControls()}
      </div>
    )
  }

  const renderLineRunChart = () => {
    return (
      <div className={classes.lineRunChartContainer}>
        {renderLineRunChartHeader()}
        <div className={classes.graphContainer}>
          { (!!pressureData || !!shockData || !!tiltData) && !isLoadingChart && (
            <LineRunChart
              maxPressureData={pressureData}
              topLoadData={topLoadData}
              shockData={shockData}
              tiltData={tiltData}
              spinData={spinData}
              rotationData={rotationData}
              maxPressureAxisMax={droneConfigurationSensorCapabilities.maxPressure}
              topLoadAxisMax={250}
              shockAxisMax={droneConfigurationSensorCapabilities.maxShock ? Math.ceil(droneConfigurationSensorCapabilities.maxShock) : undefined} // round up maxShock, if maxShock...
              spinAxisMax={droneConfigurationSensorCapabilities.maxSpin}
              rotationAxisMax={360} // 360 degrees...
              selectedTime={selectedTime}
              flags={runFlags}
              selectedFlag={selectedFlag}
              onSeriesDataPointClick={setSelectedTime}
              resetZoomState={resetLineRunChartZoomState}
              onResetZoomStateComplete={() => setResetLineRunChartZoomState(false)}
              resetState={resetLineRunChartState}
              onResetStateComplete={() => setResetLineRunChartState(false)}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && <LoadingCard />}

      <div className={classes.root} data-testid="customer-line-details-page">
        {handlePermissionRedirect(
          pageTitle,
          history,
          hasPermission,
          acceptablePagePermission
        ) && (
          <>
            <div className={classes.header}>
              <div className={classes.backButtonContainer}>
                <span
                  onClick={() => {
                    history.push(`/dashboard`, {
                      scrollToElement: scrollToElementId,
                    })
                  }}
                >
                  <SvgIcon
                    component={BackIcon}
                    viewBox="8 8 32 32"
                    className={classes.backButton}
                  />
                </span>
                <span className={classes.divider} />
              </div>
              <div className={classes.headerLabelsContainer}>
                <Typography variant="h6">{line.plantName}</Typography>
                <Typography>{line.name}</Typography>
              </div>
              <div className={classes.runSelector}>
                {!isLoading && (
                  <div className={classes.lineInfo}>
                    <TextField
                      select
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (val) => {
                          if (val === '') {
                            return <i>All Areas</i>
                          } else if (val === 'none') {
                            return <i>No Area Assigned</i>
                          } else {
                            return areas.find((x) => x.id === val).name
                          }
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      className={classes.select}
                      label="Area Filter"
                      value={runFilter}
                      onChange={handleAreaChange}
                    >
                      {[
                        { id: '', name: 'All Areas' },
                        { id: 'none', name: 'No Area Assigned' },
                      ]
                        .concat(filterAreas(areas, line))
                        .map((x) => {
                          return (
                            <MenuItem value={x.id} key={x.id}>
                              {x.name}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                    <TextField
                      select
                      className={classes.select}
                      label={runDropdownLabel}
                      value={selectedRun}
                      onChange={handleRunChange}
                      disabled={!selectedRun}
                    >
                      {filteredRuns.length > 0 &&
                        filteredRuns.sort(sortRuns).map((run) => {
                          return (
                            <MenuItem value={run.id} key={run.id}>
                              #
                              <span style={{ minWidth: virtualIdLength }}>
                                {run.virtualRunId}
                              </span>{' '}
                              {run.name}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                  </div>
                )}
              </div>
            </div>

            <div
              ref={contentGridRef}
              className={classes.contentGrid}
            >
              <div className={classes.gridCell}>
                {renderRunDetails()}
              </div>
              <div className={classes.gridCell}>
                {renderLinemap()}
              </div>
              <div className={classes.gridCell}>
                {renderHeatmap()}
              </div>
              <div className={classes.gridCell}>
                {renderFlagTable()}
              </div>
              <div className={classes.gridCell} style={{gridColumn: 'span 2'}}>
                {renderLineRunChart()}
              </div>
            </div>

          </>
        )}
      </div>
    </>
  )

  // -------------------
  // -- END renders --
  // -------------------

}

export default CustomerLineDetails
