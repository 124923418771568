import {axios} from '../client';

import {
  SST_API_PATH_USERS,
  SST_API_PATH_CUSTOMERS,
} from './../../Constants';


export const getUsers = () => {
  return axios.get(`${SST_API_PATH_USERS}`).then(res => res.data);
}

export const getUser = ({queryKey}) => {
  const [, {userId}] = queryKey;
  return axios.get(`${SST_API_PATH_USERS}/${userId}`).then(res => res.data);
}

export const searchUser = (emailAddress) => {
  return axios.get(`${SST_API_PATH_USERS}/search`, {params: {emailAddress}}).then(res => res.data);
}

export const updateUser = (user) => {
  delete user.password;
  return axios.put(`${SST_API_PATH_USERS}/${user.id}`, user).then(res => res.data);
}

export const updateUserDefaultCustomer = ({userId, defaultCustomerId}) => {
  console.log(defaultCustomerId);
  return axios.put(`${SST_API_PATH_USERS}/${userId}/preferences`, {defaultCustomerId}).then(res => res.data);
}

export const createUser = (user) => {
  delete user.enabled;
  return axios.post(`${SST_API_PATH_USERS}`, user).then(res => res.data);
}

export const getCustomerUser = ({queryKey}) => {
  const [, {userId, customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/users/${userId}`).then(res => res.data);
}

export const getCustomerUserRoles = ({queryKey}) => {
  const [, {userId, customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/users/${userId}/roles`).then(res => res.data);
}

export const updateCustomerUser = (customerUser) => {
  delete customerUser.password;
  return axios.put(`${SST_API_PATH_CUSTOMERS}/${customerUser.customerId}/users/${customerUser.userId}`, customerUser).then(res => res.data);
}

export const deleteCustomerUser = (customerUser) => {
  return axios.delete(`${SST_API_PATH_CUSTOMERS}/${customerUser.customerId}/users/${customerUser.userId}`).then(res => res.data);
}

export const updateUserViaCustomerUser = (user) => {
  const body = {
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.emailAddress,
    tz: user.timezone,
    customerId: user.customerId,
    isAdministrator: user.isAdministrator,
    enabled: user.isEnabled
  }

  return axios.put(`${SST_API_PATH_USERS}/${user.userId}`, body).then(res => res.data);
}

export const createCustomerUser = (user) => {
  return axios.post(`${SST_API_PATH_CUSTOMERS}/${user.customerId}/users`, user).then(res => res.data);
}