import React, {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {SiteContext} from "../../Context";
import { makeStyles } from '@mui/styles';
import {BASE_SST_API_URL, SST_API_PATH_DASHBOARDS} from "../../Constants";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";

const ListDashboards = (props) => {

    const {setBreadcrumbs, apiToken} = useContext(SiteContext);

    const [columns] = useState([
        {id: "id", label: "Id"},
        {id: "name", label: "Name"},
        {id: "actions", label: "Actions"}]);
    const [rows, setRows] = useState([]);
    const [isLoadingDashboards, setIsLoadingDashboards] = useState(false);

    useEffect(() => {
        document.title = "List Dashboards";
        
        const getDashboards = async () => {
            setIsLoadingDashboards(true);
            const response = await fetch(BASE_SST_API_URL + SST_API_PATH_DASHBOARDS,
                {
                    method: "GET",
                    headers: {"Authorization": apiToken}
                });
    
            if (response.ok) {
                const jsonResponse = await response.json();
                setRows(jsonResponse.map((dashboard) => {
                    return {
                        id: dashboard.id,
                        name: dashboard.name,
                        actions: <Link to={"/view-Dashboard?dashboardId=" + dashboard.id + "&datePeriod=90d"}>View Dashboard</Link>
                            
                    }
                }))
            } 
            setIsLoadingDashboards(false);
        };

        getDashboards();
        setBreadcrumbs([
            {
                title: "List Dashboards",
            }
        ]);
    }, [apiToken, setBreadcrumbs]);

    

    const useStyles = makeStyles(theme => ({
      root: {        
        width: '100%',
        height: '100%',
        padding: '16px 32px',
        margin: '16px',
        justifyContent: 'center',
        alignItems: 'center',
      },
      container: {
        margin: 'auto'
      },
      createButton: {
        display: 'flex',
        width: '100%',
        padding: '0 32px 0 0',
        justifyContent: "flex-end",
      }
    }));
    const classes = useStyles();
    return (
        <div className={classes.root} data-testid="list-dashboards-page">  
          <div className={classes.container}>
              <EnhancedTable
                title="Dashboards"
                order={'asc'}
                orderBy={'Name'}
                rows={rows}
                headCells={columns}
                isLoading={isLoadingDashboards}/>
          </div>           
        </div>
    );
};

export default ListDashboards;
