import ReactEcharts from 'echarts-for-react'
import useStyles from './Styles'
import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import translations from '../../../translations/en.json';
import { SAFE_PRESSURE_AND_COLOR_RANGES } from '../../../Constants'
import { getSubRanges } from './Helpers'

const HeatmapChart = (props) => {

  const {
    isLoading,
    message,
    data,
    mapWidth,
    mapHeight
  } = props;

  // Generate x-axis and y-axis data...
  const xAxisData = Array.from({ length: mapWidth }, (_, index) => index);
  const yAxisData = Array.from({ length: mapHeight }, (_, index) => index);

  const renderTooltip = (params) => {
    const formattedPressureValue = Number(params.value[2]).toFixed(2);
    const pressureUnits = translations.common.unitsOfMeasure.psi;

    return `<b>${formattedPressureValue}</b>${pressureUnits}`;
  }

  const options = {
    tooltip: {
      confine: true,
      formatter: (params) => { return renderTooltip(params) }
    },
    animation: false,
    grid: {
      // Set padding around the chart...
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      show: false,
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      show: false,
      inverse: true, // Invert y-axis
    },
    visualMap: {
      show: false,
      pieces: getSubRanges(SAFE_PRESSURE_AND_COLOR_RANGES, 15)
    },
    series: [
      {
        name: translations.common.pressure,
        type: 'heatmap',
        data: data,
        label: {
          show: false,
        },
        // onHover of chart cell...
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  const classes = useStyles();

  const renderHeatmap = () => {
    return (
      <div className={classes.heatmapContainer}>

        {/* Loading */}
        { isLoading &&
          <div className={classes.heatmapLoadingContainer}>
            <CircularProgress/>
            <Typography className={classes.loadingMessage}>{translations.components.heatmapChart.loadingData}</Typography>
          </div>
        }

        {/* If not loading, and message, show message */}
        { !isLoading && !!message && <Typography>{message}</Typography>}

        {/* If not loading and no message, show heatmap */ }
        { !isLoading && !message && <ReactEcharts option={options} className={classes.heatmap} /> }

      </div>
    )
  }

  return (
    <div className={classes.root}>
      {renderHeatmap()}
    </div>
  );
}

export default HeatmapChart
