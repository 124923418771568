
const AUTO_FILLER_SEAMER_INTERNALNAME = "auto_filler_seamer";
const AUTO_FILLER_SEAMER_PROPERTYTAG = "detect-filler-seamer";
const DISABLED = "disabled";

/**
 * Filter a list of areas based off if the line is a pharma line, is seamer detection is off, or is there are no activeLineSettings
 * @param unFilteredAreas
 * @param line
 * @returns {*}
 */
const filterAreas = (unFilteredAreas, line) => {
    let excludeAutoFillerSeamerArea = line.isPharma || ((line.activeLineSettings?.settings[AUTO_FILLER_SEAMER_PROPERTYTAG] === DISABLED) && !!(line.activeLineSettings));
    if(excludeAutoFillerSeamerArea) {
        return unFilteredAreas.filter(area => area.internalName !== AUTO_FILLER_SEAMER_INTERNALNAME);
    } else {
        return unFilteredAreas;
    }
}

export {
    filterAreas
}