import {axios} from '../client';
import {
    SST_API_PATH_DOCUMENTATION
} from './../../Constants';

export const getDocumentation = () => {
    return axios.get(`${SST_API_PATH_DOCUMENTATION}`).then(res => res.data)
};

export const downloadDocument = (downloadUrl) => {
    return axios.get(downloadUrl).then(res => res.data);
}