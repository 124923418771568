import React, { useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DATE_TIME_TIMEZONE_FORMAT, largestValue } from '../../Utilities'
import {SiteContext} from '../../../Context';
import ReactEcharts from 'echarts-for-react';
import {graphic} from 'echarts'
import ProtectedMoment from '../ProtectedMoment/ProtectedMoment';
import { SAFE_PRESSURE_AND_COLOR_RANGES } from '../../../Constants'

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    height: '100%',
    backgroundColor: '#F4F4F4'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    color: '#333333'
  },
  title: {
    textAlign: 'left',
    margin: '1% 2%',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '150%',
  },
  info: {
    textAlign: 'left',
    margin: '1% 2%',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#31C531'
  },
  borderSpan: {
    position: 'relative',
    top: '-1px',
    height: '1px',
    left: '0.5%',
    width: '99%',
    backgroundColor: 'grey',
  },
  chartContainer: {
    height: "100%"
  },
  chart: {
    width: '100% !important', // Override default ReactECharts height...
    height: '100% !important', // Override default ReactECharts height...

    // Help chart resize to fit its container...
    '& > div': {
      width: '100% !important', // Override default ReactECharts height...
      height: '100% !important', // Override default ReactECharts height...

      '& > canvas': {
        width: '100% !important', // Override default ReactECharts height...
        height: '100% !important', // Override default ReactECharts height...
      }
    }
  }
});

const NoAreaLineRunChart = (props) => {

  let {
    backgroundColor = '#F4F4F4',
    showTitle = true,
  } = props

  const fields = props.data.fields,
  data = props.data.data,
  dateRecorded = props.dateRecorded,
  xAxisIndex = fields.indexOf('time'),
  yAxisIndex = fields.indexOf('maxPressure'),
  xAxisData = data[xAxisIndex],
  yAxisData = data[yAxisIndex]

  const {userDetails} = useContext(SiteContext)
  const [chart, setChart] = useState(null)

  const onChartReadyCallback = e => {
    if(!!chart) {
      return
    }
    setChart(e)
  }

  function renderItem(params, api) {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var color = api.value(4);
    var height = api.size([0, 1])[1] * 0.7;
    var rectShape = graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height
      }
    );
    return (
      rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: { fill: color }
      }
    );
  }

// Function to compare data points with pixels and generate colored data segments
const calculateSegments = (xAxisData, yAxisData) => {

  let normalizedData = [];

  const segments = xAxisData.length > 100 ? 100 : xAxisData.length
  const pointsPerSegment = Math.floor(xAxisData.length / segments)

  let startIndex = 0
  let endIndex = pointsPerSegment
  let segment = 0

  while(endIndex <= (xAxisData.length - 1)) {

    // Last iteration - capture stragglers in last segment
    if((endIndex + pointsPerSegment) >= (xAxisData.length)) {
      endIndex = xAxisData.length;
    }

    let timeRange = xAxisData.slice(startIndex, endIndex)
    let segmentMaxPressure = largestValue(yAxisData.slice(startIndex, endIndex))

    let baseTime = timeRange[0]
    let duration = timeRange[timeRange.length - 1] - baseTime

    let segmentColor = SAFE_PRESSURE_AND_COLOR_RANGES.find((c) => segmentMaxPressure >= c.min && segmentMaxPressure <= c.max).color

    normalizedData.push({
      value: [segment, baseTime, (baseTime += duration), duration, segmentColor],
    });

    // start at end of last segment to prevent gaps in data
    startIndex = endIndex - pointsPerSegment
    endIndex = ((segment++) * pointsPerSegment)
  }

  return normalizedData;
}

  const eChartOptions = {
    grid: {
      height: '70%',
      top: '5%',
      show: false
    },
    xAxis: {
      name: 'Seconds',
      nameLocation: 'middle',
      nameGap: 25,
      nameTextStyle: {
        color: '#272727',
        fontWeight: 500,
      },
      type: 'value',
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { 
        color: '#7E7E7E', 
        margin: 0 
      },
      minorSplitLine: { show: false },
      splitLine: { show: false },
      max: (v) => Number.parseFloat(v.max).toFixed(1)
    },
    yAxis: {
      data: ['Pressure'],
      show: false
    },
    series: [
      {
        type: 'custom',
        renderItem: renderItem,
        silent: true,
        encode: {
          x: [1, 2]
        },
        data: calculateSegments(xAxisData, yAxisData)
      }
    ]
  };

  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{backgroundColor: backgroundColor}}
    >
      <div className={classes.toolbar}>

        { showTitle && (
          <>
            <Typography className={classes.title}>Pressure Timeline</Typography>
            <div className={classes.borderSpan} />
          </>
        )}

        {dateRecorded && <Typography className={classes.info}>Last run recorded: <ProtectedMoment date={dateRecorded} format={DATE_TIME_TIMEZONE_FORMAT} tz={userDetails.user.tz}/></Typography>}

      </div>
      <div className={classes.chartContainer}>
          <ReactEcharts
            className={classes.chart}
            onChartReady={onChartReadyCallback}
            option={eChartOptions}
            notMerge={true}
            lazyUpdate={true}/>
      </div>
    </div>
  )
}

export default NoAreaLineRunChart;
