import React from 'react'
import useStyles from '../ActiveStatusIndicator/Styles'
import { Typography } from '@mui/material'
import translations from '../../../translations/en.json';

const ActiveStatusIndicator = (props) => {

  const {
    isActive
  } = props;

  const classes = useStyles()

  return (
    <div className={`${classes.indicatorContainer} ${isActive ? classes.active : ''}`}>
      <Typography variant="body2">
        {
          isActive ?
          translations.components.activeStatusIndicator.active.toUpperCase() :
          translations.components.activeStatusIndicator.inactive.toUpperCase()
        }
      </Typography>
    </div>
  )
}

export default ActiveStatusIndicator