/**
 * Get flag, from flags, given flagId...
 * @param flags
 * @param flagId
 */
const getFlag = (flags, flagId) => {
  return flags.find(flag => flag.id === flagId)
}

export {
  getFlag
}