
import {axios} from './../client';

import {
  SST_API_PATH_PARTS,
  SST_API_PATH_SEVERITY,
  SST_API_PATH_FAULTS,
  SST_API_PATH_SYMPTOMS,
  SST_API_PATH_DIAGNOSIS,
  SST_DIAGNOSIS_GREEN_COLOR,
  SST_DIAGNOSIS_YELLOW_COLOR,
  SST_DIAGNOSIS_RED_COLOR,
  SST_DIAGNOSIS_UNKNOWN_COLOR,
  SST_DIAGNOSIS_GREEN_NAME,
  SST_DIAGNOSIS_YELLOW_NAME,
  SST_DIAGNOSIS_RED_NAME,
  SST_DIAGNOSIS_UNKNOWN_NAME
} from '../../Constants';


export const getSeverities = () => {

  const colors = {
    Green: SST_DIAGNOSIS_GREEN_COLOR,
    Yellow: SST_DIAGNOSIS_YELLOW_COLOR,
    Red: SST_DIAGNOSIS_RED_COLOR,
    Unknown: SST_DIAGNOSIS_UNKNOWN_COLOR
  }

  const displayNames = {
    Green: SST_DIAGNOSIS_GREEN_NAME,
    Yellow: SST_DIAGNOSIS_YELLOW_NAME,
    Red: SST_DIAGNOSIS_RED_NAME,
    Unknown: SST_DIAGNOSIS_UNKNOWN_NAME
  }

  return axios.get(SST_API_PATH_SEVERITY).then((res) => {
    return res.data.map(severity => {
      severity.color = colors[severity.name];
      severity.displayName = displayNames[severity.name]
      return severity
    });
  });
}

export const getFaults = () => {
  return axios.get(SST_API_PATH_FAULTS).then(res => res.data);
}

export const getSymptoms = () => {
  return axios.get(SST_API_PATH_SYMPTOMS).then(res => res.data);
}

export const getParts = () => {
  return axios.get(SST_API_PATH_PARTS).then(res => res.data);
}

export const updateDiagnosis = (diagnosis) => {

  const diagnosisId = diagnosis.id;

  const updateObject = {
    symptoms: diagnosis.symptoms || [],
    machineParts: diagnosis.machineParts || [],
    note: diagnosis.note || "",
    faultTypes: diagnosis.faultTypes || [],
    vibrationReadingId: diagnosis.vibrationReadingId,
    severityId: diagnosis.severityId,
  }

  if(diagnosisId) {
    return axios.put(`${SST_API_PATH_DIAGNOSIS}/${diagnosisId}`, {id: diagnosisId, ...updateObject}).then(res => res.data); 
  }
  else {
    return axios.post(`${SST_API_PATH_DIAGNOSIS}`, updateObject).then(res => res.data); 
  }  
}